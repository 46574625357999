import React, {useEffect, useRef} from 'react';
import axios from 'axios';
import {serviceColumnSchema} from '../data/columnSchema';
import {Table, Card, Space, Row, Col, Button, InputNumber, DatePicker, Modal, Checkbox} from 'antd';
import {Content} from 'antd/es/layout/layout';
import Breadcrumbs from '../../../Components/Breadcrumbs';
import {DeleteOutlined, EditOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';
import {FormComponent} from '../../../Components/FormComponent';

const {confirm} = Modal;

export default function ServiceDataComponent(){

    const content = useSelector(state => state);
    const dispatch = useDispatch();

    const inputRef = useRef([]);
    const handler = data => e => {
        if(data != 'price'){
            if(e){
                switch (data.item.type){
                    case 'enum':
                        dispatch({
                            type: 'FORM-DATA',
                            formDData: {
                                ...content.formData,
                                [data.item.key]: e
                            }
                        })
                        // setFormData({
                        //     ...formData,
                        //     [data.item.key]: e
                        // })
                        break;
                    case 'input':
                        dispatch({
                            type: 'FORM-DATA',
                            formDData: {
                                ...content.formData,
                                [e.target.name]: e.target.value
                            }
                        })
                        // setFormData({
                        //     ...formData,
                        //     [e.target.name]: e.target.value
                        // })
                        break;
                    case 'textarea':
                        // setFormData({
                        //     ...formData,
                        //     [e.target.name]: e.target.value
                        // })
                        dispatch({
                            type: 'FORM-DATA',
                            formDData: {
                                ...content.formData,
                                [e.target.name]: e.target.value
                            }
                        })
                        break;
                    case 'number':
                        // setFormData({
                        //     ...formData,
                        //     [e.target.name]: e.target.value
                        // })
                        dispatch({
                            type: 'FORM-DATA',
                            formDData: {
                                ...content.formData,
                                [e.target.name]: e.target.value
                            }
                        })
                        break;
                    case 'date':
                        // setFormData({
                        //     ...formData,
                        //     'birthday': e.format()
                        // })

                        dispatch({
                            type: 'FORM-DATA',
                            formDData: {
                                ...content.formData,
                                'birthday': e.format()
                            }
                        })
                        break;
                    case 'select':
                        // setFormData({
                        //     ...formData,
                        //     [data.item.key]: e
                        // })
                        dispatch({
                            type: 'FORM-DATA',
                            formDData: {
                                ...content.formData,
                                [data.item.key]: e
                            }
                        })
                        break;
                    case 'file':
                        dispatch({
                            type: 'FORM-DATA',
                            formDData: {
                                ...content.formData,
                                [data.item.key]: e.target.value
                            }
                        })
                    case 'time':
                        console.log('+++')
                        // setFormData({
                        //     ...formData,
                        //     [data.item.key]: e
                        // })

                        dispatch({
                            type: 'FORM-DATA',
                            formDData: {
                                ...content.formData,
                                [data.item.key]: e
                            }
                        })
                        break;
                }
            }
        }
        const next = inputRef.current[e];
        if(next){
            next.focus()
        }
    };

    const onChange = data => ev => {
        console.log(data);
        console.log(ev);
    }

    let title ='Стоимость услуг',
        parent ='Главная';
    const remoteUrl = `${process.env.REACT_APP_REMOTE_HOST}service/`;

    function getTableData(){return dispatch => {
        axios.get(`${remoteUrl}all`).then(res => {
            console.log('==SOURCE-TABLE', res);
            dispatch({
                type: 'SOURCE-TABLE',
                tableSource: res.data,
            });
        });
    };}

    function getAdditionalData(){
        return dispatch => {
            axios.get(`${process.env.REACT_APP_REMOTE_HOST }category`).then(res => {
                //res.data.items.unshift(columnSchema[2].options[0])
                dispatch({
                    type: 'ADDITIONAL-DATA-CATEGORY',
                    category: res.data
                });
            });
        }
    }

    function getServiceBySalon(salonId){
        return dispatch => {
            axios.get(`${process.env.REACT_APP_REMOTE_HOST}salon/${salonId}?relations=}`).then(res => {
                console.log('==ADDIT_-DATA-CATEG_-FOR-SALON', res.data);
                //res.data.items.unshift(columnSchema[2].options[0])
                dispatch({
                    type: 'ADDITIONAL-DATA-CATEGORY-FOR-SALON',
                    salonsData: res.data,
                });
            });
        }
    }

    function getTableColumn() {
        return dispatch => {
            if(serviceColumnSchema.some(item => {
                return item.key == 'action'
            })){
                ;
            }else{
                serviceColumnSchema.push({
                    title: 'Action',
                    key: 'action',
                    width: 80,
                    render: (text, record) => <Space size="middle">
                        <a onClick={() => onDeleteClick(record.id)}><DeleteOutlined /></a>
                        <a onClick={()=> onEditClick(text)}><EditOutlined /></a>
                    </Space>,
                })
            }
            dispatch({
                type: 'COLUMN-TABLE',
                tableColumn: serviceColumnSchema,
            })
        }
    }

    function deleteDateFromUrl(url, id) {
        axios.delete(remoteUrl + id).then(async response => {
            dispatch(getAdditionalData());
            dispatch(getTableData());
            dispatch(getTableColumn());
        }).catch(e => {
            console.log(e);
        })
    }

    let onEditClick = data => {
        let obj = {}
        serviceColumnSchema.forEach(item => {
            obj = {
                ...obj,
                [item.key]: data[item.key],
            }
        })
        dispatch({
            type: 'TOGGLE-FORM',
            isFormShow: false,
        })

        dispatch({
            type: 'FORM-IS-EDIT',
            isEditForm: {
                flag: true,
                id: data.id,
            },
        })

        dispatch({
            type: 'FORM-DATA',
            formDData: obj,
        })
    }

    let onDeleteClick = id => {
        confirm({
            title: 'Вы действительно хотите удалить этот элемент?',
            icon: <ExclamationCircleOutlined />,
            content: 'При удалении этот элемент удалится навсегда',
            okText:'Удалить',
            cancelText:'Отменить',
            onOk() {
                deleteDateFromUrl(remoteUrl, id)
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }
    let onNewClick = () => {
        dispatch({
            type: 'TOGGLE-FORM',
            isFormShow: false,
        });
        dispatch({
            type: 'FORM-IS-EDIT',
            isEditForm: {
                flag: false,
                id: 0,
            },
        });
    }
    useEffect( () => {
        dispatch(getAdditionalData());
        dispatch(getTableData());
        dispatch(getTableColumn());
        dispatch(getServiceBySalon('e2713ebb-d250-4d9b-bd6c-35aecfdc1b37'));

        // TODO Когда переходим с другой страницы надо сделать чтобы форма автоматом была в состоянии закрыта
    }, [])

    console.log(content)
    return <Content style={{margin: 20}}>
        <Card style={{marginBottom: '20px'}}>
            <Row gutter={24}>
                <Col span={18}>
                    <Breadcrumbs parent={parent} title={title} style={{margin: 0}}/>
                </Col>
                <Col span={6} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <Row>
                        <Button type={'primary'} onClick={() => onNewClick()}>
                            Новая запись
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Card>
        <FormComponent columtSchema={serviceColumnSchema} inputRef={inputRef} additionalData={content.additionalData}
            handler={handler} formData={content.formData} url={'service/'}/>
        <Card>
            {content.additionalData
                ? content.additionalData.category.map(item => {
                    return<>
                        <h3>{item.title}</h3>
                        <div style={{}}>
                        {item.children
                            ? item.children.map(children => {
                                return <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', margin: 5}} key={children.id}>
                                    <div style={{width:'30%'}}>
                                        <Checkbox value={children.id}>
                                            {children.title}
                                        </Checkbox>
                                    </div>
                                    <div style={{width:'50%', alignItems: 'center'}}>
                                        <InputNumber step={100} min={0} style={{width: 95}} defaultValue={1500} onChange={onChange('price')}/>
                                    </div>
                                </div>;
                            })
                        : null}
                        </div>
                    </>;
                }) : null
            }
        </Card>
        {/*<Card>*/}
        {/*    <Table locale={{emptyText: (<span>Нет данных</span>)}} columns={content.tableColumn} dataSource={content.tableSource.items} scroll={{ x: null, y: null }} />*/}
        {/*</Card>*/}
    </Content>;
}
