import { useLocationContext } from '../Services/Location.tsx';
import { useCallback, useEffect, useState } from 'react';
import { Map, Marker } from '@2gis/mapgl/global';
import { useHistory } from 'react-router-dom';
import { CommonSalonsAPI } from '../api/common/salons.ts';
import { load } from '@2gis/mapgl';
import moment from 'moment/moment';
import { mediaFileHost } from '../api/index.ts';
import {
    FilterType,
    MoscowLocation,
} from '../Components/Home/HomeSalonsMap.tsx';
import { ISalon } from '../Types/SalonTypes.ts';
import { IMedia } from '../Types/Common.ts';

import circleGreen from '../img/icon/circleGreen.svg';
import circleBlue from '../img/icon/circleBlue.svg';

function getCardHtml(
    cardData: {
        fillPercentage: number;
        rating: number;
        id: number;
        title: string;
        address?: string;
        isSalonOpened: boolean;
        endJobTime: string;
        logo?: IMedia;
        price: number;
    },
    isAltMode: boolean
) {
    return `
                            <div class='bt-2g__wrapper'>
                                <div class='bt-2g__text-content'>
                                    <div>
                                        <span class='bt-2g-rate'>
                                            <div class="bt-2g-rateMain">
                                                <svg viewBox="0 0 32 30" class="bt-2g-star">
                                                    <use xlink:href="#star"></use>
                                                </svg>
                                                <svg viewBox="0 0 32 30" class="bt-2g-star">
                                                    <use xlink:href="#star"></use>
                                                </svg>
                                                <svg viewBox="0 0 32 30" class="bt-2g-star">
                                                    <use xlink:href="#star"></use>
                                                </svg>    
                                                <svg viewBox="0 0 32 30" class="bt-2g-star">
                                                    <use xlink:href="#star"></use>
                                                </svg>    
                                                <svg viewBox="0 0 32 30" class="bt-2g-star">
                                                    <use xlink:href="#star"></use>
                                                </svg>    
                                            </div>
                                            <div class="bt-2g-rateSecondary" style="width: ${
                                                cardData.fillPercentage
                                            }%">
                                                <svg viewBox="0 0 32 30" class="bt-2g-star bt-2g-star-shrink">
                                                    <use xlink:href="#star"></use>
                                                </svg>
                                                <svg viewBox="0 0 32 30" class="bt-2g-star bt-2g-star-shrink">
                                                    <use xlink:href="#star"></use>
                                                </svg>
                                                <svg viewBox="0 0 32 30" class="bt-2g-star bt-2g-star-shrink">  
                                                    <use xlink:href="#star"></use>
                                                </svg>    
                                                <svg viewBox="0 0 32 30" class="bt-2g-star bt-2g-star-shrink">
                                                    <use xlink:href="#star"></use>
                                                </svg>    
                                                <svg viewBox="0 0 32 30" class="bt-2g-star bt-2g-star-shrink">
                                                    <use xlink:href="#star"></use>
                                                </svg>    
                                            </div>     
                                        </span>
                                        <span>${cardData.rating.toFixed(
                                            1
                                        )}</span>
                                    </div>
                                    <a href='/salon/${
                                        cardData.id
                                    }' target='_blank' data-rc-bs-type='link' class='bt-2g-name'>${
        cardData.title
    }</a>
                                    ${
                                        !isAltMode
                                            ? '<span>салон красоты</span>'
                                            : ''
                                    }
                                    <span class='bt-2g-address'>${
                                        cardData.address
                                    }</span>
                                    ${
                                        isAltMode
                                            ? ''
                                            : `<div class='bt-2g__info'>
                                        <span class='bt-2g__status ${
                                            cardData.isSalonOpened && 'green'
                                        }'></span>
                                        <span>Открыто до ${
                                            cardData.endJobTime
                                        }</span>
                                    </div>`
                                    }
                                </div>
                                ${
                                    cardData.logo
                                        ? `<div class='bt-2g__image'><img src='${
                                              mediaFileHost +
                                              cardData.logo?.fileName
                                          }' alt='${cardData.title}'></div>`
                                        : ''
                                }
                                ${
                                    isAltMode
                                        ? `
                                <div class='bt-2g__right-content'>
                                <span class='bt-2g__requests'>Отзывы (18)</span>
                                <span class='bt-2g__price_sum'>${cardData.price.toLocaleString()} р.</span>
                                <div class='bt-2g__order' data-rc-bs-salon='${
                                    cardData.id
                                }' data-rc-bs-type='button'>Записаться</div>
</div>
                                `
                                        : ''
                                }
                           </div>
                            `;
}

/**
 *Хук используется для инициализации карты на основе геопозиции с бэка (фронтовый сервис useLocationContext).
 * Отрисовывает список салонов из salon/all.
 * Рекомендуется отображать состояние загрузки, так как карта инициализируется долго.
 * @param rootElementId - HTML id элемента в который записать карту
 * @param salonsList - Необязательный параметр. Список салонов которые отображаются на карте (обновление этого списка не перерисует салоны)
 * @param cardsWithOrder - Необязательный параметр. Включение альтернативного вида карточки (для записи с этой самой карточки)
 * @param selectedServices - Список выбранных услуг для подсчета суммы
 * @param handleSelectToOrder - Действие при клике на кнопку записаться (только когда cardsWithOrder: true)
 * @param filterBy - Фильтр салонов на карте
 */
export const useSalonsMap: (
    rootElementId: string,
    salonsList?: ISalon[],
    cardsWithOrder?: boolean,
    selectedServices?: number[],
    handleSelectToOrder?: (salonId: number) => void,
    filterBy?: FilterType
) => [isInited: boolean] = (
    rootElementId,
    salonsList,
    cardsWithOrder,
    selectedServices,
    handleSelectToOrder,
    filterBy
) => {
    const [markers, setMarkers] = useState<Marker[]>([]);
    const [isInited, setIsInited] = useState(false);
    const history = useHistory();

    const { data, isLoaded, isErrorOnLoad } = useLocationContext();
    const [mapState, setMapState] = useState<Map>();

    const redirectToSomePage = useCallback((id: string) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        history.push(id);
    }, []);

    useEffect(() => {
        switch (filterBy) {
            case FilterType.ALL:
                markers.forEach((el) => el.show());
                break;
            case FilterType.OPENED:
                markers.forEach((el) => {
                    if (el.userData.salonOpen) {
                        el.show();
                    } else {
                        el.hide();
                    }
                });
                break;
            default:
                markers.forEach((el) => el.show());
                break;
        }
    }, [filterBy]);

    useEffect(() => {
        const listener = function (this: HTMLElement, ev: any) {
            if (ev.target?.dataset?.rcBsType === 'link') {
                ev.preventDefault();
                if (ev.target?.pathname) {
                    redirectToSomePage(ev.target?.pathname);
                }
            } else if (ev.target?.dataset?.rcBsType === 'button') {
                if (ev.target.dataset.rcBsSalon) {
                    handleSelectToOrder(Number(ev.target.dataset.rcBsSalon));
                }
            }
        };

        document.addEventListener('click', listener);

        return () => {
            document.removeEventListener('click', listener);
        };
    }, []);

    const initMap = useCallback(async (coords: [number, number]) => {
        let map: undefined | Map;

        new Promise<ISalon[]>((resolve) => {
            if (salonsList) {
                resolve(salonsList as any);
            } else {
                CommonSalonsAPI.getAllSalons().then((response) => {
                    // @ts-ignore
                    resolve(response.data.salons as ISalon[]);
                });
            }
        }).then((salonsResponse) => {
            load().then((mapglAPI) => {
                setIsInited(() => true);

                map = new mapglAPI.Map(rootElementId, {
                    center: coords,
                    zoom: 10,
                    key: 'fd6ca95f-8930-4f91-9835-fc784c1707bc',
                    lang: 'ru',
                });

                map.setStyleState({
                    width: '100px',
                });

                setMapState(() => map);

                const currentDate = moment();

                salonsResponse
                    .filter((el) => !!el.latitude)
                    .forEach((salon, i) => {
                        const fillPercentage = 20 * salon.rating;

                        let isSalonOpened = false;

                        if (salon.startJobTime && salon.endJobTime) {
                            if (
                                currentDate.isBetween(
                                    moment().set({
                                        minutes:
                                            salon.startJobTime.split(':')[1],
                                        hours: salon.startJobTime.split(':')[0],
                                    }),
                                    moment().set({
                                        minutes: salon.endJobTime.split(':')[1],
                                        hours: salon.endJobTime.split(':')[0],
                                    })
                                )
                            ) {
                                isSalonOpened = true;
                            }
                        }

                        const marker = new mapgl.Marker(map, {
                            coordinates: [salon.longitude, salon.latitude],
                            icon: isSalonOpened ? circleGreen : circleBlue,
                            userData: {
                                salonOpen: isSalonOpened,
                            },
                        });

                        setMarkers((prev) => [...prev, marker]);

                        let priceBySalon = 0;

                        if (cardsWithOrder && selectedServices) {
                            selectedServices.forEach((serviceId) => {
                                const findedEmployer = salon.employers.find(
                                    (el) =>
                                        !!el.services.find(
                                            (sEl) =>
                                                sEl.service.id === serviceId
                                        )
                                );

                                if (findedEmployer) {
                                    priceBySalon +=
                                        findedEmployer.services.find(
                                            (sEl) =>
                                                sEl.service.id === serviceId
                                        ).priceValue;
                                }
                            });
                        }

                        const popup = new mapgl.HtmlMarker(map, {
                            coordinates: [salon.longitude, salon.latitude],
                            html: getCardHtml(
                                {
                                    title: salon.title,
                                    isSalonOpened,
                                    fillPercentage,
                                    address: salon.address,
                                    id: salon.id,
                                    endJobTime: salon.endJobTime,
                                    logo: salon.logo,
                                    rating: salon.rating,
                                    price: priceBySalon,
                                },
                                !!cardsWithOrder
                            ),
                        });

                        const html = popup.getContent();

                        function hidePopup() {
                            html.style.display = 'none';
                        }

                        document.addEventListener('click', (e) => {
                            if (!popup.getContent().contains(e.target as any)) {
                                hidePopup();
                            }
                        });

                        document.addEventListener('touchend', (e) => {
                            if (!popup.getContent().contains(e.target as any)) {
                                hidePopup();
                            }
                        });

                        hidePopup();

                        marker.on('click', () => {
                            html.style.display = 'flex';
                        });

                        map.on('click', () => hidePopup());
                    });

                const controlContent = `
                        <div class="bt-2g-buttonRoot" id="find-me">
                            <button class="button">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    style='display: block'
                                >
                                    <path
                                        fill="currentColor"
                                        d="M17.89 26.27l-2.7-9.46-9.46-2.7 18.92-6.76zm-5.62-12.38l4.54 1.3 1.3 4.54 3.24-9.08z"
                                    />
                                </svg>
                            </button>
                        </div>
                        <p id="bt-2g-status"></p>
                    `;

                const control = new mapgl.Control(map, controlContent, {
                    position: 'bottomRight',
                });

                const status = control
                    .getContainer()
                    .querySelector('#bt-2g-status');
                let circle;

                function success(pos) {
                    const center = [pos.coords.longitude, pos.coords.latitude];

                    status.textContent = '';
                    if (circle) {
                        circle.destroy();
                    }

                    circle = new mapgl.CircleMarker(map, {
                        coordinates: center,
                        radius: 14,
                        color: '#0088ff',
                        strokeWidth: 4,
                        strokeColor: '#ffffff',
                        stroke2Width: 6,
                        stroke2Color: '#0088ff55',
                    });
                    map.setCenter(center);
                    map.setZoom(16);
                }

                function error() {
                    status.textContent = 'Unable to retrieve your location';
                }

                function geoFindMe() {
                    if (!navigator.geolocation) {
                        status.textContent =
                            'Geolocation is not supported by your browser';
                    } else {
                        status.textContent = 'Locating…';
                        navigator.geolocation.getCurrentPosition(
                            success,
                            error
                        );
                    }
                }

                control
                    .getContainer()
                    .querySelector('#find-me')
                    .addEventListener('click', geoFindMe);

                return map;
            });
        });
    }, []);

    useEffect(() => {
        if (isLoaded && !isErrorOnLoad) {
            void initMap([data.longitude, data.latitude]);
        } else if (isErrorOnLoad) {
            void initMap(MoscowLocation);
        }

        return () => mapState && mapState.destroy();
    }, [isLoaded, isErrorOnLoad]);

    return [isInited];
};
