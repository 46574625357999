import {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';

export enum MediaPoint {
    DESkTOP = 1200,
    TABLET = 768,
    MOBILE = 320,
}

export interface IMedia {
    width: number;
    media: MediaPoint;
}

export const Media = createContext<IMedia>({
    width: 0,
    media: MediaPoint.MOBILE,
});

export const useMediaContext = () => useContext<IMedia>(Media);

export const MediaProvider: FC<PropsWithChildren> = ({ children }) => {
    const [width, setWidth] = useState(0);
    const [media, setMedia] = useState(MediaPoint.MOBILE);

    useEffect(() => {
        const listener = () => {
            setTimeout(() => {
                const width = window.innerWidth;
                let media: MediaPoint;

                if (width >= 1200) {
                    media = MediaPoint.DESkTOP;
                } else if (width >= 768) {
                    media = MediaPoint.TABLET;
                } else {
                    media = MediaPoint.MOBILE;
                }

                setWidth(width);
                setMedia(media);
            }, 0);
        };

        listener();

        window.addEventListener('resize', listener);

        return () => {
            window.removeEventListener('resize', listener);
        };
    }, []);

    return (
        <Media.Provider
            value={{
                width,
                media,
            }}
        >
            {children}
        </Media.Provider>
    );
};
