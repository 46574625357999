import { FC, useCallback, useMemo, useState } from 'react';
import { IEmployer, ISalon } from '../../../../Types/SalonTypes.ts';
import styles from './MasterSchedule.module.scss';
import moment, { Moment } from 'moment';
import { DatePicker, message } from 'antd';
import { TWithoutServicesSchedule } from '../../../../api/common/salons.ts';
import { CatsModalSelect } from '../CatsModalSelect/index.tsx';
import { SalonScheduleAPI } from '../../../../api/schedule/index.ts';
import { IEventStatus } from '../../../../Types/ScheduleTypes.ts';

type TProps = {
    employer?: IEmployer;
    salon?: ISalon;
    scheduleWithoutServices?: Record<string, TWithoutServicesSchedule[]>;
    fetchScheduleWithoutServices: (date: string) => Promise<void>;
    defaultWithoutServices?: TWithoutServicesSchedule[];
    handleScheduleByTime: (
        masterId: string | number,
        dateStart: string
    ) => void;
    clientId?: string;
};

type TServices = {
    title: string;
    id: string | number;
};

function numberToFixed(num: number): string {
    return num.toString().padStart(2, '0');
}

const TagItem: FC<{
    value: string | number;
    checked: boolean;
    onSelect: (value: string) => void;
    selectedDate?: Moment;
}> = ({ value, checked, onSelect, selectedDate }) => {
    const handleSelect = () => {
        if (typeof value !== 'number') {
            if (selectedDate) {
                onSelect(value);
            } else {
                void message.info('Выберите дату');
            }
        }
    };

    return (
        <div
            className={`${styles.tagItem} ${checked ? styles.checked : ''} ${
                typeof value === 'number' ? styles.empty : ''
            }`}
            onClick={handleSelect}
        >
            {typeof value !== 'number' ? value : '|'}
        </div>
    );
};

type TTimeItem = {
    start: number;
    end: number;
};

function isTimeUsed(timeArr: TTimeItem[], value: number) {
    return timeArr.some((item) => value >= item.start && value <= item.end);
}

export const MasterSchedule: FC<TProps> = ({
    employer,
    salon,
    fetchScheduleWithoutServices,
    scheduleWithoutServices = {},
    defaultWithoutServices = [],
    handleScheduleByTime,
    clientId,
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Moment>();
    const [selectedTime, setSelectedTime] = useState<string | undefined>();

    const handleCloseModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    const currentTimes = useMemo(() => {
        const dataSelected = moment(selectedDate)
            .utc()
            .startOf('day')
            .toISOString();

        const res: TTimeItem[] = [];

        if (
            moment(selectedDate).startOf('day').format('DD.MM.YYYY') ===
            moment().startOf('day').format('DD.MM.YYYY')
        ) {
            const [hours, minutes] = moment(selectedDate)
                .format('HH:mm')
                .split(':')
                .map((el) => Number(el));
            const minValue = hours * 60 + minutes;

            res.push({
                start: 0,
                end: minValue,
            });
        }

        const itemsToFindTime =
            scheduleWithoutServices[dataSelected] ?? defaultWithoutServices;

        const scheduleByEmployer = itemsToFindTime.filter(
            (el) => el.employer.id === employer.id
        );

        scheduleByEmployer.forEach((schedule) => {
            const [startHour, startMinute] = moment(schedule.dateStart)
                .format('HH:mm')
                .split(':')
                .map((el) => Number(el));
            const [endHour, endMinute] = moment(schedule.dateEnd)
                .format('HH:mm')
                .split(':')
                .map((el) => Number(el));

            res.push({
                start: startHour * 60 + startMinute,
                end: endHour * 60 + endMinute,
            });
        });

        return res;
    }, [
        defaultWithoutServices,
        scheduleWithoutServices,
        selectedDate,
        employer,
    ]);

    const datesList = useMemo(() => {
        const res: (string | number)[] = [];

        if (!salon) {
            return [];
        }

        const [startHour, startMin] = salon.startJobTime
            .split(':')
            .map((el) => Number(el));
        const [endHour, endMin] = salon.endJobTime
            .split(':')
            .map((el) => Number(el));

        const startHours = startHour * 60 + startMin;
        const endHours = endHour * 60 + endMin;

        for (let i = startHours; i < endHours; i += 15) {
            if (!isTimeUsed(currentTimes, i)) {
                res.push(
                    `${numberToFixed(Math.floor(i / 60))}:${numberToFixed(
                        i % 60
                    )}`
                );
            } else {
                res.push(i);
            }
        }

        return res;
    }, [salon, currentTimes]);

    const services: TServices[] = useMemo(() => {
        if (!employer?.services) {
            return [];
        }
        return employer?.services.reduce((res, acc) => {
            if (!res.some((el) => el.id === acc.service.parent.id)) {
                return [
                    ...res,
                    {
                        title: acc.service.parent.title,
                        id: acc.service.parent.id,
                    },
                ];
            } else {
                return res;
            }
        }, [] as TServices[]);
    }, [employer]);

    const handleSelectTime = useCallback(
        async (date: Moment) => {
            setSelectedTime(undefined);
            setSelectedDate(date);

            const dateToLoad = moment(date).utc().startOf('day').toISOString();

            if (scheduleWithoutServices[dateToLoad]) {
                return;
            }

            const loading = message.loading(
                'Загрузка информации на выбранную дату',
                0
            );

            try {
                await fetchScheduleWithoutServices(dateToLoad);
                loading();
            } catch (e) {
                loading();
                void message.error(
                    'Ошибка при попытке загрузить расписание на выбранный день'
                );
            }
        },
        [fetchScheduleWithoutServices, scheduleWithoutServices]
    );

    const handleSchedule = useCallback(
        (time: string) => {
            setSelectedTime(time);

            setModalOpen(true);
        },
        [handleScheduleByTime, employer, selectedDate]
    );

    const fetchSchedule = useCallback(
        async (services: (string | number)[]) => {
            try {
                if (!clientId) {
                    message.error(
                        'Необходимо авторизоваться. Войдите в аккаунт'
                    );
                    return;
                }

                if (!employer || !salon) {
                    return;
                }

                const servicesDuration = services.reduce((acc: number, cur) => {
                    return (
                        acc +
                        (employer?.services.find((el) => el.service.id === cur)
                            ?.serviceTiming ?? 0)
                    );
                }, 0 as number);

                const [hours, minutes] = selectedTime.split(':');

                const dateToSchedule = moment(selectedDate).set({
                    hours: Number(hours),
                    minutes: Number(minutes),
                });

                await SalonScheduleAPI.addSchedule({
                    status: IEventStatus.PLANNED,
                    dateStart: dateToSchedule.toISOString(),
                    dateEnd: moment(dateToSchedule)
                        .add(servicesDuration, 'minutes')
                        .toISOString(),
                    employerId: employer.id,
                    clientId: clientId,
                    salonId: salon.id,
                    serviceIds: services as number[],
                    statusDescription: '',
                });

                message.success(
                    `Вы успешно записаны на ${moment(dateToSchedule).format(
                        'DD.MM.YYYY HH:mm'
                    )}`
                );
                setModalOpen(false);
            } catch (e) {
                message.error('Ошибка при попытке записаться');
            }
        },
        [selectedTime, employer, clientId, salon, selectedDate]
    );

    if (!employer || !salon) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.servList}>
                {services.map((el) => (
                    <div className={styles.serviceItem}>
                        <span>{el.title}</span>
                    </div>
                ))}
            </div>
            <div className={styles.dateInner}>
                <div className={styles.guide}>
                    Кликните на желаемое время, выберите услугу и запишитесь
                </div>
                <div className={styles.dateWrapper}>
                    <div className={styles.today}>
                        <span>Дата визита</span>
                        <span>
                            (сегодня {moment().format('D MMMM YYYY')} г.)
                        </span>
                    </div>
                    <DatePicker
                        format={'DD.MM.YYYY'}
                        showTime={false}
                        disabledDate={(date) => {
                            return moment()
                                .startOf('day')
                                .isAfter(moment(date));
                        }}
                        placeholder={'ДД.ММ.ГГГГ'}
                        value={selectedDate}
                        onChange={handleSelectTime}
                    />
                </div>
                <div className={styles.timingWrapper}>
                    {datesList.map((el) => (
                        <TagItem
                            value={el}
                            key={el}
                            checked={selectedTime === el}
                            onSelect={handleSchedule}
                            selectedDate={selectedDate}
                        />
                    ))}
                </div>
            </div>
            <CatsModalSelect
                services={employer?.services}
                open={modalOpen}
                onClose={handleCloseModal}
                onOk={fetchSchedule}
            />
        </div>
    );
};
