import { FC, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';

import styles from '../../../Pages/ResultNew/ResulNew.module.scss';

export const FilterItem: FC<
    PropsWithChildren<{
        title: string;
    }>
> = ({ children, title }) => {
    return (
        <div className={styles.filterItem}>
            <span className={styles.filterItemTitle}>{title}</span>
            {children}
        </div>
    );
};

export const FilterGroup: FC = () => {
    return (
        <div className={styles.filterWrapper}>
            <div className={styles.crumb}>
                <NavLink to={'/'}>Главная</NavLink>
                <span>/</span>
                <span>Результаты поиска</span>
            </div>
            <div className={styles.filterTitle}>Фильтры услуг</div>
        </div>
    );
};
