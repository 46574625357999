import './App.scss';
import 'antd/dist/antd.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import JobTimes from './Pages/JobTimes';
import Salon from './Pages/Salon/salon';
import SalonManage from './Pages/SalonManage/salonMan';
import SalonAdmin from './Pages/SalonAdmin';
import Profile from './Pages/Profile/profile';
import Client from './Pages/Client';
import Master from './Pages/Master';
import Action from './Pages/Action';
import Category from './Pages/Category';
import Customer from './Pages/Customer';
import Service from './Pages/Service';
import { ResultNew } from './Pages/ResultNew/index.tsx';
import { NotFound404 } from './Pages/NotFound404/index.tsx';
import { About } from './Pages/About/index.tsx';
import { Privacy } from './Pages/Privacy/index.tsx';
import { Agreement } from './Pages/Agreement/index.tsx';
import { Support } from './Pages/Support/index.tsx';
import { Contacts } from './Pages/Contacts/index.tsx';

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/result=" component={ResultNew} />
                <Route path="/result=:text" component={ResultNew} />
                <Route path="/result" component={ResultNew} />
                <Route path="/job-times" component={JobTimes} />
                <Route path="/salon/cabinet/:sId" component={SalonManage} />
                <Route path="/salon/cabinet" component={SalonManage} />
                <Route path="/salon/:id" component={Salon} />
                <Route path="/salon" component={Salon} />
                <Route path="/salons" component={SalonAdmin} />
                <Route path="/salons/:id" component={SalonAdmin} />
                <Route path="/user/:userId" component={Profile} />
                <Route path="/profile" component={Profile} />
                <Route path="/clients" component={Client} />
                <Route path="/customers" component={Customer} />
                <Route path="/masters" component={Master} />
                <Route path="/actions" component={Action} />
                <Route path="/category" component={Category} />
                <Route path="/service" component={Service} />
                <Route path="/about" component={About} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/agreement" component={Agreement} />
                <Route path="/support" component={Support} />
                <Route path="/contacts" component={Contacts} />
                <Route path="*" component={NotFound404} />
            </Switch>
        </Router>
    );
}

export default App;
