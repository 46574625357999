export const columnSchema = [
    {
        title: 'Название',
        key: 'title',
        dataIndex: 'title',
        type: 'input',
        show: true,
        value: '',
        required: true,
    },
    {
        title: 'Адрес',
        key: 'address',
        dataIndex: 'address',
        type: 'input',
        show: true,
        value: '',
        required: true,
    },
    {
        title: 'Аватар',
        key: 'avatar',
        dataIndex: 'avatar',
        type: 'file',
        show: true,
        value: '',
        required: true,
    },
    {
        title: 'Описание',
        key: 'description',
        dataIndex: 'description',
        type: 'textarea',
        show: true,
        value: '',
        required: true,
    },
    {
        title: 'Почта',
        key: 'email',
        dataIndex: 'email',
        type: 'input',
        show: true,
        value: '',
        required: true,
    },
    {
        title: 'Широта',
        key: 'latitude',
        dataIndex: 'latitude',
        type: 'input-lat',
        show: true,
        value: '',
        required: true,
    },
    {
        title: 'Долгота',
        key: 'longitude',
        dataIndex: 'longitude',
        type: 'input-long',
        show: true,
        value: '',
        required: true,
    },
    {
        title: 'Телефон',
        key: 'phone',
        dataIndex: 'phone',
        type: 'phone',
        show: true,
        value: '',
        required: true,
    },
    {
        title: 'Соц.сети',
        key: 'social',
        dataIndex: 'social',
        type: 'input',
        show: true,
        value: '',
        required: true,
    },
    {
        title: 'Статус',
        key: 'status',
        dataIndex: 'status',
        type: 'enum',
        show: true,
        value: '',
        options: ['В системе', 'Не в системе'],
        required: true,
    },
    {
        title: 'Сайт',
        key: 'website',
        dataIndex: 'website',
        type: 'input',
        show: true,
        value: '',
        required: true,
    },
    {
        title: 'Время работы',
        key: 'jobTime',
        dataIndex: 'jobTime',
        type: 'jobTime',
        show: true,
        value: '',
        options: [],
        required: true,
    },
];
