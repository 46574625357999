// вкладки в salon/cabinet для клиентов и сотрудников
import { Button, Image, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './clientsMasters.scss';
import { apiBase } from '../Const.js';
import axios from 'axios';
import { MasterCard } from '../../Components/Cabinet/Salon/Masters/MasterCard.tsx';
import { AddClientModal } from '../../Modals/AddClientModal.tsx';
import { CommonSalonsAPI } from '../../api/common/salons.ts';
import { EventsList } from '../../Components/Cabinet/Salon/EventsList.tsx';

export default function ClientsMasters({
    mode,
    sa: Sa,
    saveSalon,
    handleSetNeedLoad,
    events,
    selectedDate,
}) {
    const dispatch = useDispatch();
    const SaM = Sa.employers,
        SaC = Sa.clients; // TEST
    const $stor = useSelector((state) => state);
    const pRating = 1.2,
        clieStars =
            pRating || Math.max(0, Math.min(5, Math.random() * 5)).toFixed(1);
    const [search, setSearch] = useState(''),
        [switchSort, setSwitchValue] = useState({
            birthday: 0, // Дата рождения
            rating: 0, // Рейтинг
            visits: 0, // Количество посещений
            visitLatest: 0, // Последнее посещение
            discount: 0, // 0 - no sort, 1 - 'ascending' (возрастание), 2 - 'descending' (убывание)
        }),
        setSearch2 = (x) => {
            setSearch(x.target.value);
        };
    const statMonthCurrent = 'март';
    /**
     * Добавить мастера в список мастеров (кастомером)
     *
     * Чтобы добавить, кастомер заходит в салон-кабинет и во вкладке "Сотрудники" нажимает кнопку "Добавить нового сотрудника",
     *   предварительно введя в окно поиска UID  сотрудника (20-символьный код из 0-9 и a-f с 4 дефисами). Если сотрудник
     *   существует в системе среди мастеров (пользователей с ролью "мастер"), он добавляется в салон как новый сотрудник.
     * Если UID совпал с одним из существующих в салоне мастеров - ничего не происходит. Если UID неправильный или не найден
     *   - ошибка и ничего не происходит.
     * Технически, чтобы добавить сотрудника - взять массив UID-ы мастеров салона, приписать UID сотрудника, выполнить обновление салона.
     */
    const addClieMast = (ev) => {
        const mastNewUid = (
            ev.currentTarget.parentNode.parentNode.querySelector(
                '.peopleSearch'
            ) || {}
        ).value;
        // Обновить салон с добавлением мастера

        if (mastNewUid?.length === 36) {
            // при наличии строки поиска, похожей на UID
            //debugger;
            // Если роль добавляемого - клиент, делаем смену роли до мастера, а затем добавляем, иначе не добавляется
            axios
                .patch(
                    `${apiBase}account/${mastNewUid}`,
                    {
                        role: 'master',
                    },
                    {
                        headers: {
                            accept: '*/*',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${$stor.auth?.accessToken}`,
                        },
                    }
                )
                .then((res0) => {
                    //debugger; // добавляем мастера в салон к списку меющихся
                    saveSalon(ev, {
                        masterIds: (Sa.masters || [])
                            .map((el) => el.id)
                            .concat(mastNewUid),
                    });
                });
        }
    };

    const [addClient, setAddClient] = useState(false);

    const handleDeleteClient = useCallback(
        async (id) => {
            const clientsList = SaC.map((el) => el.id).filter(
                (el) => el !== id
            );

            const res = await CommonSalonsAPI.addClient(clientsList, Sa.id);

            dispatch({
                type: 'PATCH-SALON-DESCR',
                salonDescr: res.data,
            });
        },
        [Sa.id, SaC]
    );

    return (
        <>
            <div className="stackIn bfCabinet salonEvents">
                <div>
                    <div className="salonLogo">
                        <Image.PreviewGroup>Место для лого</Image.PreviewGroup>
                    </div>
                    <EventsList
                        handleSetNeedLoad={handleSetNeedLoad}
                        events={events}
                        selectedDate={selectedDate}
                    />
                </div>
            </div>
            <div className="peopleList">
                {mode === 'clients' ? (
                    <div className="peopleHead clients">
                        <div className="rowStatAdd">
                            <dl>
                                <dt>Всего клиентов:</dt>
                                <dd>{SaC?.length || 0}</dd>
                            </dl>
                            <dl>
                                <dt>Клиентов за {statMonthCurrent}:</dt>
                                <dd>{101}</dd>
                            </dl>
                            <dl>
                                <dt>Новых клиентов за {statMonthCurrent}:</dt>
                                <dd>{12}</dd>
                            </dl>
                            <dl>
                                <dt>
                                    <Button
                                        size="large"
                                        className="masterAdd orange"
                                        onClick={() => {
                                            setAddClient(true);
                                        }}
                                    >
                                        Добавить нового клиента
                                    </Button>
                                </dt>
                            </dl>
                        </div>
                        <div className="rowSortSearch">
                            <div className="label">Отсортировать:</div>
                            <dl>
                                <dt>Дата рождения</dt>
                                <dd className="arrowSwitch">
                                    {switchSort.birthday}
                                </dd>
                            </dl>
                            <dl>
                                <dt>Рейтинг</dt>
                                <dd className="arrowSwitch">
                                    {switchSort.rating}
                                </dd>
                            </dl>
                            <dl>
                                <dt>Количество посещений</dt>
                                <dd className="arrowSwitch">
                                    {switchSort.visits}
                                </dd>
                            </dl>
                            <dl>
                                <dt>Последнее посещение</dt>
                                <dd className="arrowSwitch">
                                    {switchSort.visitLatest}
                                </dd>
                            </dl>
                            <dl>
                                <dt>Скидка</dt>
                                <dd className="arrowSwitch">
                                    {switchSort.discount}
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <input
                                        className="peopleSearch"
                                        value={search}
                                        onChange={setSearch2}
                                        placeholder="Поиск... введите UID"
                                    />
                                </dt>
                            </dl>
                        </div>
                    </div>
                ) : (
                    <div className="peopleHead masters">
                        <input
                            className="peopleSearch"
                            value={search}
                            onChange={setSearch2}
                            placeholder="Поиск... введите UID"
                        />
                        <Button
                            size="large"
                            className="masterAdd orange"
                            onClick={() => {
                                setAddClient(true);
                            }}
                        >
                            Добавить нового сотрудника
                        </Button>
                        <dl style={{ margin: '0 auto 0 0', fontSize: '21px' }}>
                            <dt>Всего сотрудников:</dt>
                            <dd style={{ fontWeight: 'bold' }}>
                                {SaM?.length || 0}
                            </dd>
                        </dl>
                    </div>
                )}
                {mode === 'clients' ? (
                    SaC?.length ? (
                        <div className="peopleClientList">
                            {SaC?.map((X) => (
                                <MasterCard
                                    mode={'client'}
                                    key={X.id}
                                    handleEditEmp={undefined}
                                    X={X}
                                    handleDelete={handleDeleteClient}
                                    salonId={Sa.id}
                                />
                            ))}
                        </div>
                    ) : (
                        <div>
                            <i>В салоне нет клиентов.</i>
                        </div>
                    )
                ) : null}
                {SaC && (
                    <AddClientModal
                        isOpen={addClient}
                        setOpen={setAddClient}
                        salonId={Sa.id}
                        clientsList={SaC.map((el) => el.id)}
                    />
                )}
            </div>
        </>
    );
}
