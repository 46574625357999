import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { Button } from 'antd';
import { SchedullerMasterCol } from './SchedullerMasterCol.tsx';
import {
    EventsByMaster,
    IEventStatus,
    IServerEvent,
} from '../../../../Types/ScheduleTypes.ts';
import { IModalPrevState } from '../SalonSheduleTab.tsx';

import styles from '../SalonShedule.module.scss';
import { IEmployer } from '../../../../Types/SalonTypes';

interface Props {
    events: IServerEvent[];
    handleOpenModal: () => void;
    startTime: [number, number];
    endTime: [number, number];
    setModalPrevState: Dispatch<SetStateAction<undefined | IModalPrevState>>;
    masters: IEmployer[];
    handleCancelEvent: (eventId: string) => Promise<void>;
}

export const Scheduller: FC<Props> = ({
    events,
    handleOpenModal,
    startTime,
    endTime,
    setModalPrevState,
    masters,
    handleCancelEvent,
}) => {
    const hours = useMemo(() => {
        let hours: number[] = [];

        for (let i = startTime[0]; i < endTime[0]; i++) {
            hours.push(i);
        }

        return hours;
    }, [startTime, endTime]);

    const eventsByMaster: EventsByMaster = useMemo(() => {
        let res: EventsByMaster = {};

        events
            .filter((el) => el.status !== IEventStatus.CANCELED)
            .forEach((evt) => {
                const master = evt.employer;

                if (res[master.id]) {
                    res = {
                        ...res,
                        [master.id]: {
                            ...res[master.id],
                            events: [...res[master.id].events, evt],
                        },
                    };
                } else {
                    res = {
                        ...res,
                        [master.id]: {
                            ...master,
                            events: [evt],
                        },
                    };
                }
            });

        masters.forEach((emp) => {
            if (!res[emp.id]) {
                res = {
                    ...res,
                    [emp.id]: {
                        ...emp,
                        events: [],
                    },
                };
            }
        });

        return res;
    }, [events]);

    return (
        <div className={styles.schedulerWrapper}>
            <div className={styles.leftColumn}>
                <div
                    className={`${styles.row} ${styles.rowCentered} ${styles.column}`}
                >
                    <Button className={styles.btn} type={'primary'}>
                        Выходной день
                    </Button>
                    <Button
                        className={styles.btn}
                        type={'primary'}
                        onClick={handleOpenModal}
                    >
                        Создать запись
                    </Button>
                </div>
                {hours.map((el) => (
                    <div className={styles.row} key={el.toString()}>
                        <span className={styles.hTitle}>
                            {(el / 100).toFixed(2).split('.')[1]}
                        </span>
                        <div className={styles.hours}>
                            <div className={styles.hoursItem}>
                                {`${(el / 100).toFixed(2).split('.')[1]}:00`}
                            </div>
                            <div className={styles.hoursItem}>
                                {`${(el / 100).toFixed(2).split('.')[1]}:15`}
                            </div>
                            <div className={styles.hoursItem}>
                                {`${(el / 100).toFixed(2).split('.')[1]}:30`}
                            </div>
                            <div className={styles.hoursItem}>
                                {`${(el / 100).toFixed(2).split('.')[1]}:45`}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.rightColumn}>
                {Object.keys(eventsByMaster).map((el) => (
                    <SchedullerMasterCol
                        eventByMaster={eventsByMaster[el]}
                        key={el}
                        hours={hours}
                        setModalPrevState={setModalPrevState}
                        handleCancelEvent={handleCancelEvent}
                    />
                ))}
            </div>
        </div>
    );
};
