import { baseAPIInstance } from '../index.ts';
import { AxiosResponse } from 'axios';
import {
    IAdvantage,
    ICreateService,
    IEmployer,
    IListedCategory,
    IListedClient,
    IListedPriceList,
    IListedSalon,
    IPatchedService,
    ISalonClient,
} from '../../Types/SalonTypes.ts';

export type TWithoutServicesSchedule = {
    createAt: string;
    dateEnd: string;
    dateStart: string;
    id: string;
    employer: IEmployer;
};

export interface IReviewItem {
    id: number;
    estimation: string;
    description: string | null;
    salonId: number;
    clientId: string;
    type: 'client2salon' | 'salon2client';
    createAt: string;
    updateAt: string;
    client: {
        id: string;
        createAt: string;
        updateAt: string;
        isEdit: boolean;
        firstName: string;
        lastName: string;
        patronymic: string;
        birthDate: string;
        phone: string;
        email: string;
        city: string;
        role: string;
        rating: number;
        discount: string;
        king: string;
        emailStatus: string;
        phoneStatus: string;
        status: string;
        avatar?: {
            id: number;
            fileName: string;
            mimeType: string;
            mediaType: string;
            createAt: string;
            updateAt: string;
        } | null;
    };
}

interface IApi {
    getAllSalons: () => Promise<
        AxiosResponse<{
            salons: IListedSalon[];
        }>
    >;
    getSalonServices: (
        salonId: number
    ) => Promise<AxiosResponse<IListedPriceList>>;
    patchService: (
        service: IPatchedService,
        serviceId: string
    ) => Promise<AxiosResponse<any>>;
    deleteService: (serviceId: string | number) => Promise<AxiosResponse<any>>;
    createService: (service: ICreateService) => Promise<AxiosResponse<any>>;
    getAllCategories: () => Promise<AxiosResponse<IListedCategory>>;
    addClient: (
        clientsIds: string[],
        salonId: number
    ) => Promise<AxiosResponse<IListedSalon>>;
    getClientsList: () => Promise<
        AxiosResponse<{
            clients: IListedClient[];
        }>
    >;
    deleteAdvantage: () => Promise<AxiosResponse<void>>;
    getAllAdvantages: () => Promise<AxiosResponse<IAdvantage[]>>;
    editSalonAdvantages: (
        salonId: string | number,
        id: (string | number)[]
    ) => Promise<AxiosResponse<IListedSalon>>;
    patchSalonClient: (
        clientId: string | number,
        data: Partial<ISalonClient>
    ) => Promise<AxiosResponse>;
    getSalonReviews: (
        salonId: string | number,
        type: 'client2salon' | 'salon2client'
    ) => Promise<
        AxiosResponse<{
            items: IReviewItem[];
            count: number;
        }>
    >;
    getScheduleWithoutServices: (
        salonId: number,
        date: string
    ) => Promise<
        AxiosResponse<{
            items: TWithoutServicesSchedule[];
            count: number;
        }>
    >;
}

export const CommonSalonsAPI: IApi = {
    getAllSalons: async () => {
        return baseAPIInstance.get('salon/all');
    },
    getSalonServices: async (salonId) => {
        return baseAPIInstance.get(`service/by-salon/${salonId}`);
    },
    patchService: async (service, serviceId) => {
        return baseAPIInstance.patch(`service/${serviceId}`, service);
    },
    deleteService: async (serviceId) => {
        return baseAPIInstance.delete(`service/${serviceId}`);
    },
    createService: async (service) => {
        return baseAPIInstance.post('service', service);
    },
    getAllCategories: async () => {
        return baseAPIInstance.get('category');
    },
    addClient: async (clientsIds, salonId) => {
        return baseAPIInstance.patch(`salon/${salonId}`, {
            clientIds: clientsIds,
        });
    },
    getClientsList: async () => {
        return baseAPIInstance.get('/client/all');
    },
    deleteAdvantage: async () => {
        return baseAPIInstance.delete(``);
    },
    getAllAdvantages: async () => {
        return baseAPIInstance.get('advantages/all');
    },
    editSalonAdvantages: async (salonId, ids) => {
        return baseAPIInstance.patch(`salon/${salonId}`, {
            advantagesIds: ids,
        });
    },
    patchSalonClient: async (id, data) => {
        return baseAPIInstance.patch(`client/${id}`, data);
    },
    getSalonReviews: async (salonId, type) => {
        return baseAPIInstance.get(
            `/review/all/by-salon/${salonId}?type=${type}`
        );
    },
    getScheduleWithoutServices: async (salonId, date) => {
        return baseAPIInstance.get(
            `/schedule/by-salon-without-service?salonId=${salonId}&currentDate=${date}`
        );
    },
};
