import React, { FC, useCallback, useState } from 'react';
import { useSalonsMap } from '../../hooks/useSalonsMap.ts';

import styles from './HomeSalonsMap.module.scss';

export enum FilterType {
    OPENED = 'OPENED',
    ALL = 'ALL',
}

export const MoscowLocation: [number, number] = [37.6155, 55.7522];

const MapWrapper = React.memo(
    () => {
        return (
            <div id="map-container" style={{ width: '100%', height: '100%' }} />
        );
    },
    () => true
);

export const HomeSalonsMap: FC = () => {
    const [selectedFilter, setSelectedFilter] = useState<FilterType>(
        FilterType.ALL
    );

    useSalonsMap(
        'map-container',
        undefined,
        undefined,
        undefined,
        undefined,
        selectedFilter
    );

    const handleSelectOpenedFilter = useCallback(() => {
        setSelectedFilter(FilterType.OPENED);
    }, []);

    const handleSelectAllFilter = useCallback(() => {
        setSelectedFilter(FilterType.ALL);
    }, []);

    return (
        <div className="stackBlock bfSearchMap">
            <div className="stackIn bfCarou-hider">
                <h2>Все салоны на карте</h2>
                <div className="mapHead">
                    <div
                        className={`canBeSelected mapHeadEl ${
                            selectedFilter === FilterType.ALL ? 'selected' : ''
                        }`}
                        onClick={handleSelectAllFilter}
                    >
                        <div className="tx allNow">Все салоны</div>
                    </div>
                    <div
                        className={`canBeSelected mapHeadEl ${
                            selectedFilter === FilterType.OPENED
                                ? 'selected'
                                : ''
                        }`}
                        data-as-radio="3 elements"
                        onClick={handleSelectOpenedFilter}
                    >
                        <div className="tx openedNow">Открыты сейчас</div>
                    </div>
                    <div className="mapHeadWrapper">
                        <div className="mapHeadEl" data-color="#03b821">
                            <div className="opened" />
                            <div className="tx openedTx">Открыто</div>
                        </div>
                        <div className="mapHeadEl" data-color="#038bff">
                            <div className="closed" />
                            <div className="tx closedTx">Закрыто</div>
                        </div>
                        <div className="mapHeadEl" data-color="#ff4200">
                            <div className="hasAction" />
                            <div className="tx hasActionTx">Акция</div>
                        </div>
                        <div className="mapHeadEl" data-color="#898989">
                            <div className="notConnected" />
                            <div className="tx notConnectedTx">
                                Не подключен
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mapDummy">
                <div className={styles.wrapper}>
                    <MapWrapper />
                </div>
            </div>
        </div>
    );
};
