import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { IService } from '../../../../Types/SalonTypes';
import { Checkbox, Modal } from 'antd';

type TProps = {
    services?: IService[];
    open: boolean;
    onClose: () => void;
    onOk: (services: (string | number)[]) => void;
};

export const CatsModalSelect: FC<TProps> = memo(
    ({ services = [], onClose, open, onOk }) => {
        const [selectedServices, setSelectedServices] = useState<
            (string | number)[]
        >([]);

        const servicesByCats = useMemo(() => {
            return services.reduce(
                (acc, cur) => {
                    if (acc[cur.service.parent.key]) {
                        acc[cur.service.parent.key].services.push(cur);
                        return acc;
                    } else {
                        return {
                            ...acc,
                            [cur.service.parent.key]: {
                                title: cur.service.parent.title,
                                services: [cur],
                            },
                        };
                    }
                },
                {} as Record<
                    string,
                    {
                        services: IService[];
                        title: string;
                    }
                >
            );
        }, [services]);

        const onServChange = useCallback((key: string | number) => {
            setSelectedServices((prev) =>
                prev.includes(key)
                    ? prev.filter((el) => el !== key)
                    : [...prev, key]
            );
        }, []);

        const handleOk = useCallback(() => {
            onOk(selectedServices);
        }, [onOk, selectedServices]);

        return (
            <Modal
                className="servNoHeadNoFoot"
                maskClosable={true}
                maskStyle={{
                    background: 'rgba(0,0,0,0.7)',
                }}
                footer={null}
                open={open}
                onCancel={onClose}
            >
                {Object.keys(servicesByCats).map((el) => (
                    <div key={el}>
                        <h2 className="servTabCat">
                            Категория: {servicesByCats[el].title}
                        </h2>
                        <div className="servCat">
                            {servicesByCats[el].services.map((serv) => (
                                <div className="serv" key={serv.id}>
                                    <Checkbox
                                        className="servValue"
                                        name={serv.service.title}
                                        onChange={() => {
                                            onServChange(serv.service.id);
                                        }}
                                        checked={selectedServices.includes(
                                            serv.service.id
                                        )}
                                    >
                                        {serv.service.title}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                <div className="srchAction" onClick={handleOk}>
                    <span>OK</span>
                </div>
            </Modal>
        );
    }
);
