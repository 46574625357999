import { Image, Layout } from 'antd';
import React from 'react';
import { PageHead } from '../part/PageParts';
import { Header } from 'antd/es/layout/layout';
import SideMenu from '../../Components/SideMenu';
import ActionDataComponent from './component/ActionDataComponent';

function Home({ match }) {
    const {
        params: { url },
    } = match;
    return (
        <Layout style={{ height: '100vh' }}>
            <PageHead />
            <Layout height="100%">
                <SideMenu />
                <ActionDataComponent />
            </Layout>
        </Layout>
    );
}
export default Home;
