import { FC, useCallback, useState } from 'react';
import { Image } from 'antd';
import { IEmployer, ISalon } from '../../../../Types/SalonTypes.ts';
import { MasterCard } from './MasterCard.tsx';
import { AddMastersModal } from '../../../../Modals/AddMastersModal.tsx';
import { CommonAPI } from '../../../../api/common/common.ts';
import { useDispatch } from 'react-redux';
import { EventsList } from '../EventsList.tsx';

interface Props {
    salon: ISalon;
    handleSetNeedLoad: any;
    events: any;
    selectedDate: any;
}

export const MastersList: FC<Props> = ({
    salon,
    handleSetNeedLoad,
    events,
    selectedDate,
}) => {
    const dispatch = useDispatch();

    // const [addMasterModal, setAddMasterModal] = useState(false);
    const [editMasterModal, setEditMasterModal] = useState(false);

    const [selectedEmployer, setSelectedEmployer] = useState<
        IEmployer | undefined
    >(undefined);

    const handleEditEmp = useCallback((emp: IEmployer) => {
        setSelectedEmployer(() => emp);
        setEditMasterModal(() => true);
    }, []);

    const handleDeleteEmployer = useCallback(async (id: string) => {
        const res = await CommonAPI.deleteEmployer(id);

        dispatch({
            type: 'PATCH-SALON-DESCR',
            salonDescr: res.data,
        });
    }, []);

    return (
        <>
            <div className="stackIn bfCabinet salonEvents">
                <div>
                    <div className="salonLogo">
                        <Image.PreviewGroup>Место для лого</Image.PreviewGroup>
                    </div>
                    <EventsList
                        selectedDate={selectedDate}
                        events={events}
                        handleSetNeedLoad={handleSetNeedLoad}
                    />
                </div>
            </div>
            <div className="peopleList">
                <div className="peopleHead masters">
                    <dl style={{ margin: '0 auto 0 0', fontSize: '21px' }}>
                        <dt>Всего сотрудников:</dt>
                        <dd style={{ fontWeight: 'bold' }}>
                            {salon.employers?.length || 0}
                        </dd>
                    </dl>
                </div>

                {salon.employers?.length ? (
                    <div className="peopleMasterList">
                        {salon.employers.map((X) => (
                            <MasterCard
                                mode={'master'}
                                X={X}
                                key={X.id}
                                handleEditEmp={handleEditEmp}
                                handleDelete={handleDeleteEmployer}
                            />
                        ))}
                    </div>
                ) : (
                    <div>
                        <i>В салоне нет мастеров.</i>
                    </div>
                )}
            </div>
            {/*<AddMastersModal*/}
            {/*    isOpen={addMasterModal}*/}
            {/*    onCancel={setAddMasterModal}*/}
            {/*    mode={'create'}*/}
            {/*    services={salon?.services}*/}
            {/*    salonId={salon.id as any}*/}
            {/*/>*/}
            <AddMastersModal
                isOpen={editMasterModal}
                onCancel={setEditMasterModal}
                mode={'edit'}
                services={salon?.services}
                salonId={salon.id as any}
                employer={selectedEmployer}
            />
        </>
    );
};
