import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    DatePicker,
    Form,
    FormInstance,
    Input,
    InputNumber,
    message,
    Modal,
    Select,
    TreeSelect,
} from 'antd';
import { IEmployer, IMaster, IService } from '../Types/SalonTypes.ts';
import { CommonAPI } from '../api/common/common.ts';
import { useForm } from 'antd/es/form/Form';
import moment, { Moment } from 'moment';

const { TextArea } = Input;
const { SHOW_PARENT } = TreeSelect;

interface Props {
    onCancel: Dispatch<SetStateAction<boolean>>;
    isOpen: boolean;
    mode: 'edit' | 'create';
    services: IService[] | undefined;
    salonId: string;
    employer?: IEmployer;
}

interface IForm {
    descriptions?: string;
    employedDate: Moment;
    priceAspectRatio: number;
    masterId: string;
    serviceIds: string[];
}

type IFormProps = {
    mastersPending: boolean;
    mastersList: IMaster[] | undefined;
    instance: FormInstance<IForm>;
    servicesGroup: ITreeData[];
    salonId: string;
    setFormLoading: Dispatch<SetStateAction<boolean>>;
    mode: 'edit' | 'create';
    employer?: IEmployer;
    handleCancel: () => void;
};

interface ITreeData {
    title: string;
    value: string | number;
    key: string;
    children: {
        title: string;
        value: string;
        key: string;
    }[];
}

const AddMasterForm: FC<IFormProps> = ({
    instance,
    mastersPending,
    mastersList,
    servicesGroup,
    salonId,
    setFormLoading,
    mode,
    handleCancel,
    employer,
}) => {
    const handleFinish = useCallback(
        async (values: IForm) => {
            try {
                setFormLoading(true);

                let servicesList: string[] = [];

                values.serviceIds.forEach((el) => {
                    const parentItem = servicesGroup.find(
                        (servEl) => servEl.value === el
                    );
                    if (parentItem) {
                        servicesList.push(
                            ...parentItem.children.map((parEl) => parEl.value)
                        );
                    } else {
                        servicesList.push(el);
                    }
                });

                if (mode === 'create') {
                    await CommonAPI.createEmp({
                        property: {
                            descriptions: values.descriptions ?? '',
                            employedDate: values.employedDate.toISOString(),
                            priceAspectRatio: values.priceAspectRatio,
                        },
                        masterId: values.masterId,
                        salonIds: [salonId],
                        serviceIds: servicesList,
                    });
                } else {
                    await CommonAPI.editEmp(
                        {
                            property: {
                                descriptions: values.descriptions ?? '',
                                employedDate: values.employedDate.toISOString(),
                                priceAspectRatio: values.priceAspectRatio,
                            },
                            serviceIds: servicesList,
                        },
                        employer?.id
                    );
                }

                handleCancel();

                setFormLoading(() => false);
            } catch (e) {
                setFormLoading(() => false);
                message.error(
                    `Ошибка при ${
                        mode === 'create' ? 'создании' : 'редактировании'
                    } мастера: ${e.message}`
                );
            }
        },
        [salonId, mode, employer, servicesGroup]
    );

    return (
        <Form<IForm>
            form={instance}
            layout={'vertical'}
            onFinish={handleFinish}
        >
            {mode === 'create' && (
                <Form.Item
                    name={'masterId'}
                    label={'Мастер'}
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        options={mastersList.map((el) => ({
                            value: el.id,
                            label: `${el.firstName} ${el.lastName}`,
                        }))}
                        loading={mastersPending}
                        placeholder={'Выберите'}
                    />
                </Form.Item>
            )}
            <Form.Item name={'descriptions'} label={'Описание мастера'}>
                <TextArea placeholder={'Введите'} />
            </Form.Item>
            <Form.Item
                name={'employedDate'}
                label={'Дата выхода на работу'}
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <DatePicker format={'DD.MM.YYYY'} />
            </Form.Item>
            {servicesGroup && (
                <Form.Item
                    name={'serviceIds'}
                    label={'Услуги предоставляемые мастером'}
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                >
                    <TreeSelect
                        showSearch
                        placeholder={'Выберите'}
                        showCheckedStrategy={SHOW_PARENT}
                        treeData={servicesGroup}
                        treeCheckable
                        allowClear
                    />
                </Form.Item>
            )}
            <Form.Item
                name={'priceAspectRatio'}
                label={'Множитель стоимость'}
                tooltip={
                    'Стоимость услуги у данного мастера будет умножена на введенное значение'
                }
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                    {
                        type: 'number',
                        message: 'Введенное значение должно быть числовым',
                    },
                ]}
            >
                <InputNumber
                    placeholder={'Введите'}
                    step={0.1}
                    type={'number'}
                    min={0}
                    width={200}
                />
            </Form.Item>
        </Form>
    );
};

export const AddMastersModal: FC<Props> = ({
    mode,
    isOpen,
    onCancel,
    services,
    salonId,
    employer,
}) => {
    const [form] = useForm<IForm>();

    const [formLoading, setFormLoading] = useState(false);

    const [mastersPending, setMastersPending] = useState(false);
    const [mastersLoaded, setMastersLoaded] = useState(false);

    const [mastersList, setMastersList] = useState<IMaster[] | undefined>(
        undefined
    );

    const handleOk = useCallback(() => {
        form.submit();
    }, []);

    const handleCancel = useCallback(() => {
        onCancel(() => false);
    }, []);

    const handleLoadMastersList = useCallback(async () => {
        setMastersPending(true);
        try {
            const response = await CommonAPI.getMastersList();

            setMastersList(() => response.data);

            setMastersLoaded(() => true);
            setMastersPending(() => false);
        } catch (e) {
            setMastersPending(() => false);
            setMastersLoaded(() => false);
            message.error('Ошибка при загрузке списка мастеров');
        }
    }, []);

    useEffect(() => {
        if (!mastersLoaded && !mastersPending) {
            void handleLoadMastersList();
        }
    }, []);

    useEffect(() => {
        if (mode === 'edit') {
            form.setFields([
                {
                    name: 'serviceIds',
                    value: employer?.services.map((el) => el.id),
                },
                {
                    name: 'descriptions',
                    value: employer?.property?.descriptions,
                },
                {
                    name: 'employedDate',
                    value: employer?.property?.employedDate
                        ? moment(employer?.property?.employedDate)
                        : undefined,
                },
                {
                    name: 'priceAspectRatio',
                    value: employer?.property?.priceAspectRatio,
                },
            ]);
        }
    }, [employer, mode]);

    const servicesGroup: ITreeData[] = useMemo(() => {
        return Object.values(
            services.reduce((res: Record<string, ITreeData>, acc) => {
                if (res[acc.service.parent.id]) {
                    res = {
                        ...res,
                        [acc.service.parent.id]: {
                            ...res[acc.service.parent.id],
                            children: [
                                ...res[acc.service.parent.id].children,
                                {
                                    value: acc.id,
                                    title: acc.service.title,
                                    key: acc.id,
                                },
                            ],
                        },
                    };
                } else {
                    res = {
                        ...res,
                        [acc.service.parent.id]: {
                            title: acc.service.parent.title,
                            value: acc.service.parent.id,
                            key: acc.service.parent.id,
                            children: [
                                {
                                    value: acc.id,
                                    title: acc.service.title,
                                    key: acc.id,
                                },
                            ],
                        },
                    };
                }

                return res;
            }, {} as Record<string, ITreeData>)
        );
    }, []);

    return (
        <Modal
            title={mode === 'create' ? 'Добавить мастера' : 'Изменить мастера'}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={mode === 'create' ? 'Создать' : 'Изменить'}
            cancelText={'Отмена'}
            closable={false}
            maskClosable={false}
            destroyOnClose
            okButtonProps={{
                loading: formLoading,
            }}
        >
            {isOpen && (
                <AddMasterForm
                    mastersPending={mastersPending}
                    mastersList={mastersList}
                    instance={form}
                    servicesGroup={servicesGroup}
                    salonId={salonId}
                    setFormLoading={setFormLoading}
                    mode={mode}
                    employer={employer}
                    handleCancel={handleCancel}
                />
            )}
        </Modal>
    );
};
