import { Checkbox, DatePicker, message, Modal, Radio, Select } from 'antd';
import { MediaPoint, useMediaContext } from '../../Services/Media.tsx';
import locale from 'antd/es/locale/ru_RU';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Splide } from '@splidejs/react-splide';
import axios from 'axios';
import { ResultParamsNames } from '../../Pages/ResultNew/index.tsx';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

export const SearchForm = ({ currentFilters }) => {
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    const $stor = useSelector((state) => state),
        dispatch = useDispatch(),
        [catOnce, setCatOnce] = useState(0);

    moment.locale('ru');
    const { media } = useMediaContext();

    const [servOpen, setServOpen] = useState(0); // видимость нужного попапа (groupServices[].value)

    const [, setCountSite] = useState({
        countSalons: '...',
        countMasters: '...',
        countOrders: '...',
        countOnline: '...',
    });

    const groupServices = useMemo(
        () => [
            {
                value: 1,
                label: 'Ногтевой сервис',
                disabled: true,
                cats: ['Маникюр', 'Педикюр'],
            },
            {
                value: 2,
                label: 'Парикмахерский зал',
                cats: ['Стрижка', 'Окрашивание', 'Укладка', 'Уходы'],
            },
            {
                value: 3,
                label: 'Удаление волос',
                cats: ['Шугаринг', 'Воск', 'Лазер'],
            },
            { value: 4, label: 'Брови и ресницы', cats: ['Ресницы и брови'] },
            {
                value: 5,
                label: 'Макияж',
                cats: ['Макияж', 'Перманентный макияж'],
            },
            {
                value: 11,
                label: <u>Начать поиск</u>,
                className: 'operational',
                disabled: true,
            },
        ],
        []
    );

    useEffect(() => {
        const remoteUrl = `${process.env.REACT_APP_REMOTE_HOST}category/`;
        const S1 = new Splide('.splide', {
            perPage: 4,
        });
        S1.go && S1.go(1); // TODO не выставляется на нужный индекс!
        if (!catOnce) {
            setCatOnce(1);
            axios.get(remoteUrl).then((res) => {
                dispatch({
                    type: 'SOURCE-TABLE',
                    tableSource: res.data,
                });
                axios
                    .get(
                        `${process.env.REACT_APP_REMOTE_HOST}advantages/main/general`
                    )
                    .then((res) => {
                        dispatch({
                            type: 'ADVANTAGES-SITE',
                            countSite: res?.data || {},
                        });
                        setCountSite(res?.data || {});
                    });
            });
        }
    });

    const [tabs, setTabs] = useState(groupServices); // список добавления услуг с блок-й добавленных

    const handleCancel = () => {
        setServOpen(0);
    };

    const servCountMax = 3;

    const servNo = '\\(не выбрано\\)';
    const srchNo = '\\(запрос поиска\\)';

    const [servCount, setServCount] = useState(0); // ограничитель числа услуг (общий из всех категорий)

    const [selServices, setCurServices] = useState([]);

    const chgService = (ev) => {
        const t = ev.target;
        const el = document.querySelector(
            `.servNoHeadNoFoot input[name="${t.name}"]`
        )?.parentNode;
        if (servCount >= servCountMax) {
            setTimeout(() => {
                t.checked = false;
                ev.nativeEvent.srcElement.checked = false;
                if (el.classList) el.classList.remove('ant-checkbox-checked');
            }, 0);
        }
        if (t.checked && servCount >= servCountMax) return;
        if (t.checked) setServCount(Math.min(servCount + 1, servCountMax));
        else setServCount(Math.max(0, servCount - 1));
        const servCat = document.querySelector(
                `.srchForm >[data-cat="${servOpen}"] .srchServiceRequest >div`
            ),
            srchT = document.querySelector(
                '.srchForm >.srchYouFind >.srchFullResult'
            );
        const s = (servCat?.innerHTML || '').replace(new RegExp(servNo), ''),
            sr = (srchT?.innerHTML || '').replace(new RegExp(srchNo), '');
        if (servCat)
            servCat.innerHTML = t.checked
                ? s + (s ? ' + ' : '') + t.name
                : s.replace(
                      new RegExp(
                          '( \\+ )?' + t.name.replace(/([()/+])/g, '\\$1')
                      ),
                      ''
                  ) || servNo.replace(/\\/g, '');
        if (srchT)
            srchT.innerHTML = t.checked
                ? sr + (sr ? ' + ' : '') + t.name
                : sr.replace(
                      new RegExp(
                          '( \\+ )?' + t.name.replace(/([()/+])/g, '\\$1')
                      ),
                      ''
                  ) || srchNo.replace(/\\/g, '');
    };

    const setSelServices = useCallback((id) => {
        setCurServices((prev) => {
            let isExist = prev.indexOf(id) > -1;

            if (isExist) {
                return [...prev.filter((el) => el !== id)];
            } else {
                return [...prev, id];
            }
        });
    }, []);

    const [activeCatTab, setActiveCatTab] = useState(1);
    const [servExtraNext, setServExtraNext] = useState(false);

    const tabsChange = (ev) => {
        setActiveCatTab(ev.target.value);
        setTabs(
            tabs.map((el) => ({
                ...el,
                ...{ disabled: el.value === +ev.target.value },
            }))
        );
    };

    const cities = [
        {
            value: 2,
            label: (
                <div>
                    Москва
                    <br />
                    &nbsp; <i>{$stor?.countSite?.countSalons} салонов</i>
                </div>
            ),
        },
    ];

    const [city, setCity] = useState();

    const dateNow = moment()
        .locale('ru')
        .format(media === MediaPoint.DESkTOP ? 'LL, dddd' : 'DD.MM.YYYY');

    const [visitDate, setVisitDate] = useState(moment(currentFilters.date));

    const chgTimeExact = () => {
        setCheckExact(!checkExact);
    };

    const [checkExact, setCheckExact] = useState(false); // точное время / интервал (ручной выбор)

    const pickerOptions = useMemo(() => {
        let arr = [];

        for (let i = 0; i < 96; i++) {
            const dataItem = moment()
                .set('hours', Math.floor(i / 4))
                .set('minutes', (i % 4) * 15);
            arr.push({
                value: dataItem.toISOString(),
                label: dataItem.format('HH:mm'),
            });
        }

        return arr;
    }, []);

    const [timeStart, setTimeStart] = useState(
        moment()
            .set({
                hours: Number(currentFilters.hourStart.split(':')[0]),
                minutes: Number(currentFilters.hourStart?.split(':')[1]),
            })
            .toISOString()
    );

    const [timeEnd, setTimeEnd] = useState(
        moment()
            .set({
                hours: Number(currentFilters.hourEnd.split(':')[0]),
                minutes: Number(currentFilters.hourEnd?.split(':')[1]),
            })
            .toISOString()
    );

    const [servExtraStop, setServExtraStop] = useState(0); // счётчик выбранных услуг (чекбоксов)

    const [servLines, setServ] = useState([0, 0, 0, 0]); // ⋺ группы услуг (3 "кнопки-линии") из категорий

    const clicLine = (ev) => {
        const t = ev.currentTarget.parentNode;
        const servLine = t.classList.contains('srchServiceExtra')
            ? 1 // на какой линии услуг кликнуто
            : t.classList.contains('srchServiceExtraTwo')
            ? 2
            : 3;
        //debugger;
        setServOpen(
            servLines[servLine].value || tabs.find((el) => el.disabled).value
        ); // открыть нужный попап с услугами
    };

    const [servExtraTwoNext, setServExtraTwoNext] = useState(false);

    const extraTwoChange = (val) => {
        setServExtraTwoNext(true); // команда выполнена (признак)
        setServ(
            (servLines.splice(
                3,
                1,
                tabs.find((el) => el.value === val)
            ),
            servLines)
        ); // -''- 3-й группы выбора
    };

    const handleSearch = useCallback(() => {
        const dateS = () => {
            if (timeStart && timeEnd) {
                return {
                    hourStart: moment(timeStart).format('HH:mm'),
                    hourEnd: moment(timeEnd).format('HH:mm'),
                };
            } else {
                return {
                    hourStart: moment(timeStart).format('HH:mm'),
                };
            }
        };

        const dataToSend = {
            date: visitDate?.startOf('day').format('YYYY-MM-DD'),
            serviceIds: selServices,
            ...dateS(),
        };

        if (
            !dataToSend.date ||
            dataToSend.serviceIds.length === 0 ||
            !dataToSend.hourStart
        ) {
            void message.warn('Вы заполнили не все данные для поиска');
            return;
        }

        searchParams.set(
            ResultParamsNames.date,
            visitDate?.format('YYYY-MM-DD')
        );
        searchParams.set(
            ResultParamsNames.serviceIds,
            JSON.stringify(selServices)
        );
        searchParams.set(ResultParamsNames.hourStart, dataToSend.hourStart);

        if (dataToSend.hourEnd) {
            searchParams.set(ResultParamsNames.hourEnd, dataToSend.hourEnd);
        }

        history.push('/result');
        history.replace({ search: searchParams.toString() });
        window.location.reload();
    }, [city, visitDate, timeStart, timeEnd, selServices]);

    return (
        <div className="srchForm">
            {catOnce &&
                $stor.tableSource &&
                tabs.map((el) =>
                    el.cats ? (
                        <Modal
                            className="servNoHeadNoFoot"
                            maskClosable={true}
                            maskStyle={{
                                background: 'rgba(0,0,0,0.7)',
                            }}
                            key={el.value}
                            footer={null}
                            open={el.value === servOpen}
                            onCancel={handleCancel}
                        >
                            {/*искать по el.cats группы услуг в $stor.tableSource */}
                            {/*<div className="servTabCats">TODO услуги .cats в группе таба</div>*/}
                            {el.cats.map((e2) => (
                                <div key={e2}>
                                    <h2 className="servTabCat">
                                        Категори
                                        {1 ? 'я' : 'и'}: {e2}
                                    </h2>
                                    <div className="servCat">
                                        {/*TODO список услуг с колонками, с регистрацией выбора<br/>*/}
                                        {$stor.tableSource.categories
                                            .find((e3) => e3.title === e2)
                                            ?.children.map((e4) => (
                                                <div
                                                    className="serv"
                                                    key={e4.id}
                                                >
                                                    <Checkbox
                                                        className="servValue"
                                                        name={e4.title}
                                                        onChange={(e) => {
                                                            chgService(e);
                                                            setSelServices(
                                                                e4.id
                                                            );
                                                        }}
                                                    >
                                                        {e4.title}
                                                    </Checkbox>
                                                </div>
                                            ))}
                                        {/*<div className="serv">
                                        <div className="servPrice">1&thinsp;600<sub>р</sub></div>
                                        <Checkbox className="servValue">bbbb</Checkbox>
                                    </div>*/}
                                    </div>
                                </div>
                            ))}
                            <div className="srchAction" onClick={handleCancel}>
                                <span>OK</span>
                            </div>
                        </Modal>
                    ) : null
                )}
            <div className={`tabMask${servExtraNext ? ' down' : ''}`} />
            {(media === MediaPoint.DESkTOP || media === MediaPoint.TABLET) && (
                <Radio.Group
                    defaultValue={1}
                    buttonStyle="solid"
                    className="srchTabs"
                    onChange={tabsChange}
                >
                    {groupServices.map((el) =>
                        el.cats ? (
                            <Radio.Button value={el.value} key={el.value}>
                                {el.label}
                            </Radio.Button>
                        ) : null
                    )}
                </Radio.Group>
            )}
            {media === MediaPoint.MOBILE && (
                <>
                    <Radio.Group
                        defaultValue={1}
                        buttonStyle="solid"
                        className="srchTabs"
                        onChange={tabsChange}
                        value={activeCatTab}
                    >
                        {groupServices.slice(0, 2).map((el) =>
                            el.cats ? (
                                <Radio.Button value={el.value} key={el.value}>
                                    {el.label}
                                </Radio.Button>
                            ) : null
                        )}
                    </Radio.Group>
                    <Radio.Group
                        defaultValue={1}
                        buttonStyle="solid"
                        className="srchTabs"
                        onChange={tabsChange}
                        value={activeCatTab}
                    >
                        {groupServices
                            .slice(2, groupServices.length)
                            .map((el) =>
                                el.cats ? (
                                    <Radio.Button
                                        value={el.value}
                                        key={el.value}
                                    >
                                        {el.label}
                                    </Radio.Button>
                                ) : null
                            )}
                    </Radio.Group>
                </>
            )}
            <div className="srchSalonDateTime">
                <div className="srchSalon">
                    <div className="srchCityTitle">
                        Местоположение или салон
                    </div>
                    <div className="srchCitySelect">
                        <Select
                            options={cities}
                            popupClassName="srchCityOptions"
                            defaultValue={2}
                            onChange={(e) => setCity(e)}
                            size={
                                media === MediaPoint.TABLET ||
                                media === MediaPoint.MOBILE
                                    ? 'small'
                                    : 'middle'
                            }
                        />
                    </div>
                </div>
                <div className="srchDate">
                    <div className="srchDateTitle">
                        Дата визита &nbsp; <i>(сегодня {dateNow})</i>
                    </div>
                    <DatePicker
                        placement="bottomRight"
                        className="srchDateSelect"
                        placeholder="ГГГГ-ММ-ДД"
                        bordered={'false'}
                        clearText="Очистить"
                        locale={locale}
                        onChange={(data) => {
                            setVisitDate(data);
                        }}
                        value={visitDate}
                        size={
                            media === MediaPoint.TABLET ||
                            media === MediaPoint.MOBILE
                                ? 'small'
                                : 'middle'
                        }
                    />
                    {/* clearText="..." не работает - вероятно, требуется clearIcon */}
                    {/*<div className="srchDateSelect">
                                    <div>(выбор даты)</div>
                                </div>*/}
                </div>
                <div className="srchTime noSrch">
                    <div className="srchTimeExact">
                        <Checkbox
                            className="timeExact"
                            onChange={chgTimeExact}
                            size={
                                media === MediaPoint.DESkTOP
                                    ? 'middle'
                                    : 'small'
                            }
                        >
                            {' '}
                            точное время
                        </Checkbox>
                    </div>
                    <div className="srchTimeTitle">Время начала</div>
                    {checkExact ? (
                        <>
                            {media === MediaPoint.TABLET ||
                            media === MediaPoint.MOBILE ? (
                                <select
                                    className="srchTimeSelect"
                                    value={timeStart}
                                    placeholder={'00:00'}
                                    onChange={(value) => {
                                        setTimeStart(value.target.value);

                                        if (
                                            (timeEnd &&
                                                moment(timeEnd).isBefore(
                                                    moment(value.target.value)
                                                )) ||
                                            timeEnd === value.target.value
                                        ) {
                                            setTimeEnd(
                                                moment(value.target.value)
                                                    .add(15, 'minutes')
                                                    .toISOString()
                                            );
                                        }
                                    }}
                                >
                                    {pickerOptions.map((el) => (
                                        <option key={el.value} value={el.value}>
                                            {el.label}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <Select
                                    size={
                                        media === MediaPoint.TABLET ||
                                        media === MediaPoint.MOBILE
                                            ? 'small'
                                            : 'middle'
                                    }
                                    className="srchTimeSelect"
                                    options={pickerOptions}
                                    optionFilterProp={'label'}
                                    optionLabelProp={'label'}
                                    placeholder={'00:00'}
                                    onChange={(value) => {
                                        setTimeStart(value);
                                    }}
                                    value={timeStart}
                                />
                            )}
                        </>
                    ) : (
                        <div className="selects-wrapper">
                            {media === MediaPoint.TABLET ||
                            media === MediaPoint.MOBILE ? (
                                <>
                                    <select
                                        className="srchTimeSelect"
                                        value={timeStart}
                                        placeholder={'00:00'}
                                        onChange={(value) => {
                                            setTimeStart(value.target.value);

                                            if (
                                                (timeEnd &&
                                                    moment(timeEnd).isBefore(
                                                        moment(
                                                            value.target.value
                                                        )
                                                    )) ||
                                                timeEnd === value.target.value
                                            ) {
                                                setTimeEnd(
                                                    moment(value.target.value)
                                                        .add(15, 'minutes')
                                                        .toISOString()
                                                );
                                            }
                                        }}
                                    >
                                        {pickerOptions.map((el) => (
                                            <option
                                                key={el.value}
                                                value={el.value}
                                            >
                                                {el.label}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        className="srchTimeSelect"
                                        value={timeEnd}
                                        onChange={(value) => {
                                            console.log(value);
                                            setTimeEnd(value.target.value);
                                        }}
                                    >
                                        {pickerOptions
                                            .filter((el) => {
                                                return moment(el.value).isAfter(
                                                    moment(timeStart)
                                                );
                                            })
                                            .map((el) => (
                                                <option
                                                    key={el.value}
                                                    value={el.value}
                                                >
                                                    {el.label}
                                                </option>
                                            ))}
                                    </select>
                                </>
                            ) : (
                                <>
                                    <Select
                                        size={
                                            media === MediaPoint.TABLET ||
                                            media === MediaPoint.MOBILE
                                                ? 'small'
                                                : 'middle'
                                        }
                                        className="srchTimeSelect"
                                        options={pickerOptions}
                                        optionFilterProp={'label'}
                                        optionLabelProp={'label'}
                                        placeholder={'00:00'}
                                        value={timeStart}
                                        onChange={(value) => {
                                            setTimeStart(value);
                                        }}
                                    />
                                    <Select
                                        size={
                                            media === MediaPoint.TABLET ||
                                            media === MediaPoint.MOBILE
                                                ? 'small'
                                                : 'middle'
                                        }
                                        className="srchTimeSelect"
                                        options={pickerOptions.filter((el) => {
                                            return moment(el.value).isAfter(
                                                moment(timeStart)
                                            );
                                        })}
                                        optionFilterProp={'label'}
                                        optionLabelProp={'label'}
                                        placeholder={'00:00'}
                                        value={timeEnd}
                                        onChange={(value) => {
                                            setTimeEnd(value);
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {/* при .srchServiceExtra.hasNext открывается видимость следующего блока в CSS */}
            {/* при .stopSelect прекращ-ся выбор чекбок. и показ .srchExtra:not(.srchAction)*/}
            <div
                className={`srchServiceExtra${servExtraNext ? ' hasNext' : ''}${
                    servExtraStop > 3 ? ' stopSelect' : ''
                }`}
                data-cat={
                    servLines[1].value || tabs.find((el) => el.disabled).value
                }
            >
                <div className="srchService" onClick={clicLine}>
                    <div className="srchServiceTitle">
                        Выберите услугу{' '}
                        <i>
                            &nbsp;{' '}
                            {servLines[1]
                                ? '(' + servLines[1].label + ')'
                                : null}
                        </i>
                    </div>
                    <div className="srchServiceRequest">
                        <div>(не выбрано)</div>
                    </div>
                </div>
            </div>
            <div
                className={`srchServiceExtraTwo${
                    servExtraTwoNext ? ' hasNext' : ''
                }`}
                data-cat={servLines[2].value}
            >
                <div className="srchService" onClick={clicLine}>
                    <div className="srchServiceTitle">
                        Выберите услугу{' '}
                        <i>
                            &nbsp;{' '}
                            {servLines[2]
                                ? '(' + servLines[2].label + ')'
                                : null}
                        </i>
                    </div>
                    <div className="srchServiceRequest">
                        <div>(не выбрано)</div>
                    </div>
                </div>
                <Select
                    className="srchExtra"
                    defaultValue={10}
                    options={tabs}
                    popupClassName="srchExtraOptions srchTabs"
                    onChange={extraTwoChange}
                    size={
                        media === MediaPoint.TABLET ||
                        media === MediaPoint.MOBILE
                            ? 'small'
                            : 'middle'
                    }
                />
            </div>
            <div
                className="srchServiceExtraThree"
                data-cat={servLines[3].value}
            >
                <div className="srchService" onClick={clicLine}>
                    <div className="srchServiceTitle">
                        Выберите услугу{' '}
                        <i>
                            &nbsp;{' '}
                            {servLines[3]
                                ? '(' + servLines[3].label + ')'
                                : null}
                        </i>
                    </div>
                    <div className="srchServiceRequest">
                        <div>(не выбрано)</div>
                    </div>
                </div>
                <div className="srchExtra srchAction" onClick={handleSearch}>
                    <span>Начать поиск</span>
                </div>
            </div>

            <div className="srchYouFind">
                <div className="srchServiceTitle">Вы ищете:</div>
                <div className="srchFullResult">(запрос поиска)</div>
            </div>
            <div className="srchAction" onClick={handleSearch}>
                <span>Начать поиск</span>
            </div>
        </div>
    );
};
