import axios from 'axios';

export const mediaFileHost = 'http://storage.beautysputnik.ru/beauty/';

export const baseAPIInstance = axios.create({
    baseURL: process.env.REACT_APP_REMOTE_HOST ?? 'http://77.222.52.193:8008/',
});

baseAPIInstance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
        'accessTokenBTS'
    )}`;
    return config;
});
