import {Button, DatePicker, Input, Select, TimePicker} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import MaskedInput from 'antd-mask-input';

export function FormComponentWithMap(props){
    const [columnSchema, setColumnSchema] = useState([]);
    const data = useSelector(state => state);
    const dispatch = useDispatch();

    const remoteUrl = process.env.REACT_APP_REMOTE_HOST + props.url;

    function getTableData(){
        return dispatch => {
            axios.get(`${remoteUrl}all`)
                .then(res =>
                    dispatch({
                        type: 'SOURCE-TABLE',
                        tableSource: res.data
                    })
                );
        };
    }

    const onSaveClick = () => {

        if(data.isEditForm.flag){
            axios.patch(remoteUrl + data.isEditForm.id, data.formData).then(async item => {
                dispatch(getTableData());
            }).catch(e => {
                console.log(e)
            })
        }else{
            axios.post(remoteUrl, data.formData).then(async item => {
                console.log(item)
                dispatch(getTableData());
            }).catch(e => {
                console.log(e)
            })
        }
    }

    useEffect(() => {
        if(props.columtSchema.length){
            setColumnSchema(props.columtSchema)
        }

    }, [])

    return <div style={{marginBottom: '20px'}}>
        {columnSchema.map((item, key) => {
            switch (item.type){
                case 'input':
                    return(
                        <div style={{marginBottom: 16}}  key={key}>
                            <label>{item.title}</label>
                            <Input
                                type={'text'}
                                name={item.key}
                                placeholder={item.title}
                                ref={el => props.inputRef.current[item.key] = el}
                                required={item.required}
                                value={data.formData[item.key]}
                                onChange={props.handler({item})}
                            autoComplete={'false'}/>
                        </div>
                    )
                case 'phone':
                    return <div style={{marginBottom: 16}}  key={key}>
                        <label>{item.title}</label>
                        <MaskedInput name={item.key} value={data.formData[item.key]}
                          mask={data.formData[item.key] || '7 (999) 999-99-99'}
                          type={'text'}
                          ref={el => props.inputRef.current[item.key] = el}
                        onKeyPress={props.handler({item})}/>
                        {/*<Input*/}
                        {/*    type={'text'}*/}
                        {/*    name={item.key}*/}
                        {/*    placeholder={item.title}*/}
                        {/*    ref={el => props.inputRef.current[item.key] = el}*/}
                        {/*    onChange={props.handler({item})}*/}
                        {/*    required={item.required}*/}
                        {/*    value={data.formData[item.key]}*/}
                        {/*    autoComplete={'false'}*/}
                        {/*/>*/}
                    </div>;
                case 'input-lat':
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <Input placeholder={item.title}
                            type={'text'}
                            name={item.key}
                            // ref={el => inputRef.current[item.key] = el}
                            required={item.required}
                            value={data.formData[item.key]}
                            disabled={true}
                        onChange={props.handler({item})}/>
                    </div>;
                case 'input-long':
                    return <div style={{marginBottom: 16}}  key={key}>
                        <label>{item.title}</label>
                        <Input placeholder={item.title}
                            type={'text'}
                            name={item.key}
                            // ref={el => inputRef.current[item.key] = el}
                            required={item.required}
                            value={data.formData[item.key]}
                            disabled={true}
                       onChange={props.handler({item})}/>
                    </div>;
                case 'textarea':
                    return <div style={{marginBottom: 16}} key={key} >
                        <label>{item.title}</label>
                        <TextArea
                            rows={10}
                            name={item.key}
                            ref={el => props.inputRef.current[item.key] = el}
                            required={item.required}
                            value={data.formData[item.key]}
                        onChange={props.handler({item})}/>
                    </div>;
                case 'datetime':
                    const format = 'YYYY-MM-DD HH:mm';
                    return(
                        <div style={{marginBottom: 16}}  key={key}>
                            <label>{item.title}</label>
                            <DatePicker
                                defaultDate={moment(new Date(), format)}
                                showTime={true}
                                minuteStep={15}
                                format={format}
                                name={item.key}
                                ref={el => props.inputRef.current[item.key] = el}
                                required={item.required}
                                onChange={props.handler({item})}
                            autoComplete={'false'}/>
                        </div>
                    )
                case 'date':
                    const date = 'YYYY-MM-DD';
                    return(
                        <div style={{marginBottom: 16}}  key={key}>
                            <label>{item.title}</label>
                            <br/>
                            <DatePicker
                                defaultDate={moment(new Date(), date)}
                                format={date}
                                name={item.key}
                                ref={el => props.inputRef.current[item.key] = el}
                                required={item.required}
                                onChange={props.handler({item})}
                            autoComplete={'false'}/>
                        </div>
                    )
                case 'startDate':
                    var startDate = 'YYYY-MM-DD';
                    return(
                        <div style={{marginBottom: 16}}  key={key}>
                            <label>{item.title}</label>
                            <br/>
                            <DatePicker
                                defaultDate={moment(new Date(), startDate)}
                                format={startDate}
                                name={item.key}
                                ref={el => props.inputRef.current[item.key] = el}
                                required={item.required}
                                onChange={props.handler({item})}
                            autoComplete={'false'}/>
                        </div>
                    )
                case 'endDate':
                    var endDate = 'YYYY-MM-DD';
                    return(
                        <div style={{marginBottom: 16}}  key={key}>
                            <label>{item.title}</label>
                            <br/>
                            <DatePicker
                                defaultDate={moment(new Date(), endDate)}
                                format={endDate}
                                name={item.key}
                                ref={el => props.inputRef.current[item.key] = el}
                                required={item.required}
                                onChange={props.handler({item})}
                            autoComplete={'false'}/>
                        </div>
                    )
                // case 'file':
                //     return(
                //         <div style={{marginBottom: 16}}  key={key}>
                //             <label>{item.title}</label>
                //             <Input
                //                 type={'file'}
                //                 name={item.key}
                //                 ref={el => props.inputRef.current[item.key] = el}
                //                 onChange={props.handler({item})}
                //                 required={item.required}
                //                 autoComplete={'false'}
                //             />
                //         </div>
                //     )
                case 'number':
                    return(
                        <div style={{marginBottom: 16}}  key={key}>
                            <label>{item.title}</label>
                            <br/>
                            <Input
                                type={'number'}
                                name={item.key}
                                placeholder={item.title}
                                ref={el => props.inputRef.current[item.key] = el}
                                step={item.step}
                                disabled={item.disabled}
                                min={item.min}
                                max={item.max}
                                required={item.required}
                                value={data.formData[item.key]}
                                style={{width: '100%'}}
                                autoComplete={'false'}
                            onChange={props.handler({item})}/>
                        </div>
                    )
                case 'select':
                    return (
                        <div style={{marginBottom: 16}}  key={key}>
                            <label>{item.title}</label>
                            <Select
                                onChange={props.handler({item})}
                                style={{width:'100%'}}
                                // defaultValue={item.options[0]}
                                placeholder="Выбрать"
                                ref={el => props.inputRef.current[item.key] = el}
                                required={item.required}
                                autoComplete={'false'}
                            >
                                {
                                    (props.additionalData[item.key])
                                        ?
                                        props.additionalData[item.key].map((option, index) => {
                                            return(
                                                <Select.Option value={option.id} key={option.id}>{ option.title }</Select.Option>
                                            )
                                        })
                                        :
                                        null
                                }
                            </Select>
                        </div>
                    )
                case 'jobTime':
                    // console.log('DEFAULT', item)
                    return <div style={{marginBottom: 16}}  key={key}>
                        <label>{item.title}</label>
                        <Select placeholder="Выбрать"
                            onChange={props.handler({item})}
                            style={{width:'100%'}}
                            // defaultValue={item.options[0]}
                            ref={el => props.inputRef.current[item.key] = el}
                            required={item.required}
                        autoComplete={'false'}>
                            {
                                props.additionalData[item.key]
                                    ? props.additionalData[item.key].map((option, index) => {
                                        return <Select.Option value={option.id} key={option.id}>{option.startTime} - {option.endTime}</Select.Option>;
                                    })
                                    : null
                            }
                        </Select>
                    </div>;
                case 'enum':
                    return (
                        <div style={{marginBottom: 16}}  key={key}>
                            <label>{item.title}</label>
                            <Select placeholder="Выбрать"
                                onChange={props.handler({item})}
                                style={{width:'100%'}}
                                //defaultValue={item.options[0]}
                                ref={el => props.inputRef.current[item.key] = el}
                                required={item.required}
                            autoComplete={'false'}>
                                {item.options.map((option, index) => {
                                    return(
                                        <Select.Option value={option} key={index}>{option}</Select.Option>
                                    )
                                })}
                            </Select>
                        </div>
                    )
                case 'time':
                    const timeFormat = 'HH:mm';
                    return(
                        <div style={{marginBottom: 16}}  key={key}>
                            <label>{item.title}</label><br/>
                            <TimePicker
                                placeholder={item.title}
                                defaultValue={moment('00:00:00', timeFormat)}
                                name={item.key}
                                format={timeFormat}
                                minuteStep={15}
                                ref={el => props.inputRef.current[item.key] = el}
                                onChange={props.handler({item})}
                                value={moment(data.formData[item.key])}
                            />
                        </div>
                    )
                default:
                    break;
            }
        })}
        <Button type={'primary'} onClick={()=> onSaveClick()}>Сохранить</Button>
        <Button type={'danger'} onClick={()=> {dispatch({
            type: 'TOGGLE-FORM',
            isFormShow: false
        })}} style={{marginLeft: 10}}>Закрыть</Button>
    </div>;
}
