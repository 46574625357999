import React from 'react';
import {Image, Layout} from 'antd';
import {PageHead} from '../part/PageParts';
import SideMenu from '../../Components/SideMenu';
import ClientDataComponent from './component/ClientDataComponent';

function Home({match}){
    const {
        params: { url },
    } = match;
    return <Layout style={{ height: '100vh'}}>
        <PageHead/>
        <Layout height="100%">
            <SideMenu/>
            <ClientDataComponent/>
        </Layout>
    </Layout>;
}
export default Home;
