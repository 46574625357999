import {Image, Layout} from 'antd';
import React from 'react';
import {PageHead} from '../part/PageParts';
import SideMenu from '../../Components/SideMenu';
import CustomerDataComponent from './component/CustomerDataComponent';

function Customer(){
    return <Layout style={{height: '100vh'}}>
        <PageHead/>
        <Layout height="100%">
            <SideMenu/>
            <CustomerDataComponent/>
        </Layout>
    </Layout>;
}
export default Customer;
