import { IEmployer, ISalonClient } from './SalonTypes.ts';
import { IMedia } from './Common.ts';

export enum IEventStatus {
    CANCELED = 'CANCELED', // Отменено
    IN_PROGRESS = 'IN_PROGRESS', // В процессе
    DONE = 'DONE', // Исполнено
    PLANNED = 'PLANNED', // Запись
    DISABLED_MASTER = 'DISABLED_MASTER', // Невозможный для записи слот у мастера
    WAITING_FEEDBACK = 'WAITING_FEEDBACK', // Ожидает оценки
}

export type EventByMaster = IEmployer & {
    events: IServerEvent[];
};

export type EventsByMaster = Record<string, EventByMaster>;

export interface IServerEvent {
    dateEnd: string;
    dateStart: string;
    employer: IEmployer;
    client: ISalonClient;
    id: string;
    salon: {
        logo: IMedia | null;
        address: string;
        city: string;
        description: string;
        email: string;
        id: string;
        isEdit: boolean;
        latitude: string;
        longitude: string;
        metro: string;
        phone: string;
        rating: number;
        social: string;
        status: string;
        title: string;
        website: string;
    };
    service: {
        createAt: string;
        id: number;
        priceValue: number;
        serviceDescription: string;
        serviceTiming: number;
        updateAt: string;
        service: {
            id: number;
            key: string;
            title: string;
            parent: {
                id: number;
                key: string;
                title: string;
            };
        };
    }[];
    status: IEventStatus;
    statusDescription?: string;
    reviews?: {
        createAt: string;
        estimation: number;
        id: number;
        type: 'client2salon' | 'salon2client';
        updateAt: string;
    }[];
}

export interface IEventsResponse {
    count: number;
    items: IServerEvent[];
}
