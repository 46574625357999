import { FC } from 'react';
import { Rate } from 'antd';
import { IReviewItem } from '../../../../api/common/salons.ts';

import styles from './ReviewsStyles.module.scss';

const getEstCountByRate = (reviews: IReviewItem[], est: number) => {
    return reviews.filter((el) => Math.round(Number(el.estimation)) === est)
        .length;
};

export const RatesTable: FC<{
    reviews: IReviewItem[];
    salonRate: string | number;
}> = ({ reviews, salonRate }) => {
    return (
        <div className={styles.estimates}>
            <span className={styles.estimateValue}>
                {(Math.round(Number(salonRate) * 10) / 10).toString()}
            </span>
            <div className={styles.estList}>
                <div className={styles.estItem}>
                    <span className={styles.estText}>5</span>
                    <Rate
                        className={styles.estRate}
                        disabled
                        defaultValue={5}
                    />
                    <span className={styles.estText}>
                        {getEstCountByRate(reviews, 5)} оценок
                    </span>
                </div>
                <div className={styles.estItem}>
                    <span className={styles.estText}>4</span>
                    <Rate
                        className={styles.estRate}
                        disabled
                        defaultValue={4}
                    />
                    <span className={styles.estText}>
                        {getEstCountByRate(reviews, 4)} оценок
                    </span>
                </div>
                <div className={styles.estItem}>
                    <span className={styles.estText}>3</span>
                    <Rate
                        className={styles.estRate}
                        disabled
                        defaultValue={3}
                    />
                    <span className={styles.estText}>
                        {getEstCountByRate(reviews, 3)} оценок
                    </span>
                </div>
                <div className={styles.estItem}>
                    <span className={styles.estText}>2</span>
                    <Rate
                        className={styles.estRate}
                        disabled
                        defaultValue={2}
                    />
                    <span className={styles.estText}>
                        {getEstCountByRate(reviews, 2)} оценок
                    </span>
                </div>
                <div className={styles.estItem}>
                    <span className={styles.estText}>1</span>
                    <Rate
                        className={styles.estRate}
                        disabled
                        defaultValue={1}
                    />
                    <span className={styles.estText}>
                        {getEstCountByRate(reviews, 1)} оценок
                    </span>
                </div>
            </div>
        </div>
    );
};
