import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import { FilterItem } from './FilterGroup.tsx';
import { Button, Checkbox, InputNumber, Slider } from 'antd';
import { MediaPoint } from '../../../Services/Media.tsx';

import styles from '../../../Pages/ResultNew/ResulNew.module.scss';
import { isProduction } from '../../../utils/common.ts';

export interface IResultFilter {
    price: {
        min: number;
        max: number;
    };
    stanchions: string[];
    types: string[];
    purchaseType: string[];
    ratings: string[];
}

interface Props {
    filtersList: IResultFilter;
    setFiltersList: Dispatch<SetStateAction<IResultFilter>>;
    handleResetFilters: () => void;
    hideCrumbs?: boolean;
    hideTitle?: boolean;
    fixButtons?: boolean;
    media: MediaPoint;
}

const FilterWrapperClassName: Record<MediaPoint, string> = {
    [MediaPoint.DESkTOP]: styles.DESkTOP,
    [MediaPoint.TABLET]: styles.TABLET,
    [MediaPoint.MOBILE]: styles.MOBILE,
};

export const Filters: FC<Props> = ({
    filtersList,
    setFiltersList,
    handleResetFilters,
    hideCrumbs,
    hideTitle,
    fixButtons,
    media,
}) => {
    const [isStanchionsOpened, setIsStanchionsOpened] = useState(false);
    const [isTypeOpened, setIsTypeOpened] = useState(false);

    const metroStanchions = useMemo(() => {
        return [
            {
                value: '1',
                label: 'Авиамоторная',
            },
            {
                value: '2',
                label: 'Академическая',
            },
            {
                value: '3',
                label: 'Александровский сад',
            },
            {
                value: '4',
                label: 'Алексеевская',
            },
            {
                value: '5',
                label: 'Алтуфьево',
            },
            {
                value: '6',
                label: 'Аннино',
            },
        ];
    }, []);

    const salonType = useMemo(() => {
        return [
            {
                value: '1',
                label: 'Салон красоты',
            },
            {
                value: '2',
                label: 'Ногтевая студия',
            },
            {
                value: '3',
                label: 'Парикмахерская',
            },
            {
                value: '4',
                label: 'Барбершоп',
            },
        ];
    }, []);

    const purchaseType = useMemo(() => {
        return [
            {
                value: '1',
                label: 'Оплата картой',
            },
            {
                value: '2',
                label: 'Оплата наличными',
            },
        ];
    }, []);

    const ratingList = useMemo(() => {
        return [
            {
                value: '1',
                label: '3.0 и выше',
            },
            {
                value: '2',
                label: '3.5 и выше',
            },
            {
                value: '3',
                label: '4.0 и выше',
            },
            {
                value: '4',
                label: '4.5 и выше',
            },
            {
                value: '5',
                label: '4.9 и выше',
            },
        ];
    }, []);

    const handlePriceChange = useCallback(
        (props: { min?: number; max?: number }) => {
            setFiltersList((prev) => ({
                ...prev,
                price: {
                    ...prev.price,
                    max: props.max !== undefined ? props.max : prev.price.max,
                    min: props.min !== undefined ? props.min : prev.price.min,
                },
            }));
        },
        []
    );

    const handleStanchionsChange = useCallback(
        (props: { id: string; checked: boolean }) => {
            setFiltersList((prev) => {
                let newStanchions = prev.stanchions;

                if (props.checked) {
                    newStanchions.push(props.id);
                } else {
                    newStanchions = newStanchions.filter(
                        (el) => el !== props.id
                    );
                }

                return {
                    ...prev,
                    stanchions: newStanchions,
                };
            });
        },
        []
    );

    const handleTypeChange = useCallback(
        (props: { id: string; checked: boolean }) => {
            setFiltersList((prev) => {
                let newTypes = prev.types;

                if (props.checked) {
                    newTypes.push(props.id);
                } else {
                    newTypes = newTypes.filter((el) => el !== props.id);
                }

                return {
                    ...prev,
                    types: newTypes,
                };
            });
        },
        []
    );

    const handlePurchaseTypeChange = useCallback(
        (props: { id: string; checked: boolean }) => {
            setFiltersList((prev) => {
                let newPurchaseType = prev.purchaseType;

                if (props.checked) {
                    newPurchaseType.push(props.id);
                } else {
                    newPurchaseType = newPurchaseType.filter(
                        (el) => el !== props.id
                    );
                }

                return {
                    ...prev,
                    purchaseType: newPurchaseType,
                };
            });
        },
        []
    );

    const handleRatingChange = useCallback(
        (props: { id: string; checked: boolean }) => {
            setFiltersList((prev) => {
                let newRatings = prev.ratings;

                if (props.checked) {
                    newRatings.push(props.id);
                } else {
                    newRatings = newRatings.filter((el) => el !== props.id);
                }

                return {
                    ...prev,
                    ratings: newRatings,
                };
            });
        },
        []
    );

    if (media !== MediaPoint.DESkTOP || isProduction) {
        return null;
    }

    return (
        <div
            className={`${styles.filterWrapper}${
                fixButtons ? ` ${styles.fixed}` : ''
            }`}
        >
            {!hideCrumbs && (
                <div className={styles.crumb}>
                    <NavLink to={'/'}>Главная</NavLink>
                    <span>/</span>
                    <span>Результаты поиска</span>
                </div>
            )}
            {!hideTitle && (
                <div className={styles.filterTitle}>Фильтры услуг</div>
            )}
            <FilterItem title={'Стоимость'}>
                <Slider
                    range
                    value={[filtersList.price.min, filtersList.price.max]}
                    min={100}
                    max={15000}
                    step={100}
                    style={{
                        width: 250,
                    }}
                    className={styles.filterPrice}
                    tooltip={{
                        formatter: (n) => {
                            return `${n.toLocaleString()} руб.`;
                        },
                    }}
                    onChange={(value) => {
                        handlePriceChange({
                            min: value[0],
                            max: value[1],
                        });
                    }}
                />
                <div className={styles.priceFilterValues}>
                    <InputNumber
                        className={styles.filterInput}
                        placeholder={'Мин.'}
                        value={filtersList.price.min}
                        controls={false}
                        min={100}
                        max={filtersList.price.max}
                        onChange={(e) => {
                            handlePriceChange({
                                min: e,
                            });
                        }}
                    />
                    <span className={styles.divider} />
                    <InputNumber
                        className={styles.filterInput}
                        placeholder={'Макс.'}
                        value={filtersList.price.max}
                        min={filtersList.price.min}
                        max={15000}
                        controls={false}
                        onChange={(e) => {
                            handlePriceChange({
                                max: e,
                            });
                        }}
                    />
                </div>
            </FilterItem>
            <FilterItem title={'Станция метро'}>
                {metroStanchions
                    .slice(0, isStanchionsOpened ? metroStanchions.length : 10)
                    .map((el) => (
                        <div className={styles.filterCheckItem} key={el.value}>
                            <Checkbox
                                checked={
                                    filtersList.stanchions.indexOf(el.value) >
                                    -1
                                }
                                onChange={(e) =>
                                    handleStanchionsChange({
                                        id: el.value,
                                        checked: e.target.checked,
                                    })
                                }
                            >
                                {el.label}
                            </Checkbox>
                        </div>
                    ))}
                {metroStanchions.length > 10 && (
                    <span
                        className={styles.filterShowAll}
                        onClick={() => {
                            setIsStanchionsOpened((prev) => !prev);
                        }}
                    >
                        {isStanchionsOpened ? 'Скрыть' : 'Показать все'}
                    </span>
                )}
            </FilterItem>
            <FilterItem title={'Тип заведения'}>
                {salonType
                    .slice(0, isTypeOpened ? salonType.length : 10)
                    .map((el) => (
                        <div className={styles.filterCheckItem} key={el.value}>
                            <Checkbox
                                checked={
                                    filtersList.types.indexOf(el.value) > -1
                                }
                                onChange={(e) =>
                                    handleTypeChange({
                                        id: el.value,
                                        checked: e.target.checked,
                                    })
                                }
                            >
                                {el.label}
                            </Checkbox>
                        </div>
                    ))}
                {salonType.length > 10 && (
                    <span
                        className={styles.filterShowAll}
                        onClick={() => {
                            setIsTypeOpened((prev) => !prev);
                        }}
                    >
                        {isTypeOpened ? 'Скрыть' : 'Показать все'}
                    </span>
                )}
            </FilterItem>
            <FilterItem title={'Вид оплаты'}>
                {purchaseType.map((el) => (
                    <div className={styles.filterCheckItem} key={el.value}>
                        <Checkbox
                            checked={
                                filtersList.purchaseType.indexOf(el.value) > -1
                            }
                            onChange={(e) =>
                                handlePurchaseTypeChange({
                                    id: el.value,
                                    checked: e.target.checked,
                                })
                            }
                        >
                            {el.label}
                        </Checkbox>
                    </div>
                ))}
            </FilterItem>
            <FilterItem title={'Рейтинг'}>
                {ratingList.map((el) => (
                    <div className={styles.filterCheckItem} key={el.value}>
                        <Checkbox
                            checked={filtersList.ratings.indexOf(el.value) > -1}
                            onChange={(e) =>
                                handleRatingChange({
                                    id: el.value,
                                    checked: e.target.checked,
                                })
                            }
                        >
                            {el.label}
                        </Checkbox>
                    </div>
                ))}
            </FilterItem>
            <div
                className={`${styles.filterButtons}${
                    fixButtons ? ` ${styles.fixed}` : ''
                }`}
            >
                <Button
                    className={`${styles.filterBtn} ${styles.primary}`}
                    type={'primary'}
                >
                    Применить
                </Button>
                <Button
                    className={styles.filterBtn}
                    onClick={handleResetFilters}
                >
                    Сбросить
                </Button>
            </div>
        </div>
    );
};
