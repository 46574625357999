import React, { FC, useCallback, useMemo } from 'react';
import moment, { Moment as IMoment } from 'moment';
import { IEventStatus, IServerEvent } from '../../../Types/ScheduleTypes.ts';
import Moment from 'react-moment';
import { message, Rate, Table } from 'antd';
import styles from './SalonShedule.module.scss';
import { SalonScheduleAPI } from '../../../api/schedule/index.ts';

import 'moment/locale/ru';

moment.locale('ru');

export const enumStatusToText = (status: IEventStatus) => {
    switch (status) {
        case IEventStatus.CANCELED:
            return 'Отменено';
        case IEventStatus.DISABLED_MASTER:
            return 'Запись невозможна';
        case IEventStatus.DONE:
            return 'Исполнено';
        case IEventStatus.IN_PROGRESS:
            return 'В процессе';
        case IEventStatus.PLANNED:
            return 'Запись';
        case IEventStatus.WAITING_FEEDBACK:
            return 'Ожидает оценки';
    }
};

const enumToClassName = (status?: IEventStatus) => {
    switch (status) {
        case IEventStatus.IN_PROGRESS:
            return `ant-row--${IEventStatus.IN_PROGRESS}`;
        case IEventStatus.DONE:
        case IEventStatus.WAITING_FEEDBACK:
            return `ant-row--${IEventStatus.DONE}`;
        case IEventStatus.PLANNED:
            return `ant-row--${IEventStatus.PLANNED}`;
        default:
            return '';
    }
};

interface Props {
    selectedDate: IMoment;
    events: IServerEvent[];
    handleSetNeedLoad: () => void;
}

export const EventsList: FC<Props> = ({
    selectedDate,
    events,
    handleSetNeedLoad,
}) => {
    const currentDate = useMemo(() => moment(), []);

    const handleAddEstimate = useCallback(
        async (event: IServerEvent, estimate: number) => {
            const loading = message.loading('Оценка...', 0);
            try {
                if (event.salon[0]) {
                    await SalonScheduleAPI.addReview({
                        estimation: estimate,
                        type: 'salon2client',
                        salonId: event.salon[0].id,
                        clientId: event.client.id,
                        scheduleId: event.id,
                    });
                } else {
                    throw new Error('Салон не найден в записи');
                }

                loading();
                void message.success('Оценка успешно установлена');
                handleSetNeedLoad();
            } catch (e) {
                loading();
                void message.error('Ошибка при оценке пользователя');
            }
        },
        []
    );

    const dateFormat = useMemo(() => {
        return selectedDate.format('DD.MM.YYYY') ===
            currentDate.format('DD.MM.YYYY')
            ? 'DD MMMM YYYY HH:mm'
            : 'DD MMMM YYYY';
    }, [currentDate, selectedDate]);

    const columns = useMemo(
        () => [
            {
                dataIndex: 'time',
                key: 'time',
            },
            {
                dataIndex: 'masterName',
                key: 'masterName',
            },
            {
                dataIndex: 'serviceName',
                key: 'serviceName',
            },
            {
                dataIndex: 'servicePrice',
                key: 'servicePrice',
            },
            {
                dataIndex: 'status',
                key: 'status',
            },
        ],
        []
    );

    const dataSource = useMemo(() => {
        const sortedEvents = events
            .filter((el) => el.status !== IEventStatus.DISABLED_MASTER)
            .sort(
                (a, b) =>
                    moment(a.dateStart).valueOf() -
                    moment(b.dateStart).valueOf()
            );

        return sortedEvents.map((el) => {
            const handleRate = (estimate: number) => {
                if (el.status === IEventStatus.WAITING_FEEDBACK) {
                    void handleAddEstimate(el, estimate);
                }
            };

            const isRated =
                el.reviews &&
                !!el.reviews.find((el) => el.type === 'salon2client');
            const currentRate: number | undefined = el.reviews?.find(
                (el) => el.type === 'salon2client'
            )?.estimation;

            const waitingText =
                el.status === IEventStatus.WAITING_FEEDBACK && !isRated
                    ? 'Ожидает оценки'
                    : 'Ожидает оценки клиентом';

            return {
                time: moment(el.dateStart).format('HH:mm'),
                masterName: `${el.employer.master.firstName} ${el.employer.master.lastName}`,
                serviceName: el.service
                    .map((el) => el.service.title)
                    .join('; '),
                servicePrice: `${el.service
                    .reduce((res, acc) => res + acc.priceValue, 0)
                    .toLocaleString()} Р.`,
                status: (
                    <>
                        <span>
                            {el.status === IEventStatus.WAITING_FEEDBACK
                                ? waitingText
                                : enumStatusToText(el.status)}
                        </span>
                        {el.status === IEventStatus.WAITING_FEEDBACK && (
                            <Rate
                                className={styles.rateItem}
                                onChange={handleRate}
                                defaultValue={currentRate}
                                disabled={isRated}
                            />
                        )}
                    </>
                ),
                statusEnum: el.status,
            };
        });
    }, [events]);

    return (
        <>
            <span className={styles.subTitle}>События дня</span>
            <div className={styles.eventsWrapper}>
                <div className={styles.eventsHeader}>
                    <Moment format={dateFormat} locale={'ru'}>
                        {selectedDate}
                    </Moment>
                    <span>
                        Количество завершенных записей:{' '}
                        {
                            events.filter(
                                (s) =>
                                    s.status === IEventStatus.DONE ||
                                    s.status === IEventStatus.WAITING_FEEDBACK
                            ).length
                        }
                    </span>
                </div>
                <Table
                    showHeader={false}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    rowClassName={(record) =>
                        enumToClassName(record.statusEnum)
                    }
                />
            </div>
        </>
    );
};
