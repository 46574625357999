export const columnSchema = [
    {
        title: 'Телефон',
        key: 'phone',
        dataIndex: 'phone',
        type: 'input',
        show: true,
        value: ''
    },{
        title: 'Статус Телефона',
        key: 'phoneStatus',
        dataIndex: 'phoneStatus',
        type: 'enum',
        options: [
            'Подтвержден', 'Не подтвержден'
        ],
        show: true,
        value: ''
    },{
        title: 'Почта',
        key: 'email',
        dataIndex: 'email',
        type: 'input',
        show: true,
        value: ''
    },{
        title: 'Статус почты',
        key: 'emailStatus',
        dataIndex: 'emailStatus',
        type: 'enum',
        options: [
            'Подтвержден', 'Не подтвержден'
        ],
        show: true,
        value: ''
    },{
        title: 'Статус',
        key: 'status',
        dataIndex: 'status',
        type: 'enum',
        show: true,
        value: '',
        options: [
            'Активные', 'Отключенный'
        ]
    }
]
