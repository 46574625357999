import { FC } from 'react';
import { Avatar, Rate } from 'antd';

import styles from './ReviewsStyles.module.scss';
import { IReviewItem } from '../../../../api/common/salons.ts';
import moment from 'moment';
import { mediaFileHost } from '../../../../api/index.ts';
import { MediaPoint, useMediaContext } from '../../../../Services/Media.tsx';

type Props = {
    review: IReviewItem;
};

export const CommentItem: FC<Props> = ({
    review: { client, estimation, description, createAt },
}) => {
    const { media } = useMediaContext();

    return (
        <div className={styles.comment}>
            <div className={styles.commentHead}>
                <div className={styles.userData}>
                    <Avatar
                        size={media === MediaPoint.MOBILE ? 20 : 100}
                        src={mediaFileHost + client.avatar?.fileName}
                        className={styles.avatar}
                    >
                        {client.firstName[0]}
                    </Avatar>
                    <span className={styles.name}>
                        {client.firstName} {client.lastName}
                    </span>
                </div>
                <span className={styles.date}>
                    {moment(createAt).format('DD.MM.YYYY')}
                </span>
            </div>
            <div className={styles.row}>
                <span className={styles.commentRateText}>Оценка клиентом</span>
                <Rate
                    className={styles.estRate}
                    disabled
                    defaultValue={Number(estimation)}
                />
                <span className={styles.commentText}>({estimation})</span>
            </div>
            <div className={styles.row}>
                <span className={styles.commentRateText}>Комментарий:</span>
            </div>
            <div className={styles.commentValue}>{description}</div>
        </div>
    );
};
