import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { IAdvantage } from '../Types/SalonTypes.ts';
import { Form, FormInstance, message, Modal, Select } from 'antd';
import { CommonSalonsAPI } from '../api/common/salons.ts';

interface IForm {
    id: (string | number)[];
}

type ByEditType =
    | {
          mode: 'create';
          handleCreate: (advId: (string | number)[]) => Promise<void>;
          advantageId: (string | number)[];
      }
    | {
          mode: 'edit';
          handleCreate: (advId: (string | number)[]) => Promise<void>;
          advantageId: (string | number)[];
      };

type Props = ByEditType & {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    salonId: number;
    isProcessing: boolean;
};

type IFormProps = ByEditType & {
    form: FormInstance<IForm>;
    salonId: Props['salonId'];
    handleClose: () => void;
    advantagesPending: boolean;
    advantageList: IAdvantage[];
};

const AddModalForm: FC<IFormProps> = ({
    form,
    mode,
    salonId,
    handleCreate,
    handleClose,
    advantagesPending,
    advantageList,
}) => {
    const handleFinish = useCallback(
        async (values: IForm) => {
            try {
                await handleCreate(values.id);

                handleClose();
            } catch (e) {
                if (mode === 'create') {
                    void message.error('Ошибка при добавлении преимущества');
                } else {
                    message.error('Ошибка при изменении преимущества');
                }
            }
        },
        [salonId, handleCreate, mode]
    );

    return (
        <Form<IForm> form={form} layout={'vertical'} onFinish={handleFinish}>
            <Form.Item
                name="id"
                label="Преимущество"
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                ]}
            >
                <Select
                    options={advantageList.map((el) => ({
                        label: el.title,
                        value: el.id,
                    }))}
                    placeholder="Выберите"
                    optionFilterProp={'label'}
                    mode={'multiple'}
                    loading={advantagesPending}
                />
            </Form.Item>
        </Form>
    );
};

export const AddAdvantageModal: FC<Props> = ({
    mode,
    isOpen,
    setOpen,
    advantageId,
    salonId,
    handleCreate,
    isProcessing,
}) => {
    const [form] = Form.useForm<IForm>();

    const [advantagesLoaded, setAdvantagesLoaded] = useState(false);
    const [advantagesPending, setAdvantagesPending] = useState(false);
    const [advantagesError, setAdvantagesError] = useState(false);
    const [advantageList, setAdvantageList] = useState<IAdvantage[]>([]);

    const handleLoadAdvantages = useCallback(async () => {
        try {
            setAdvantagesPending(true);

            const response = await CommonSalonsAPI.getAllAdvantages();

            setAdvantageList(response.data);
            setAdvantagesLoaded(true);
            setAdvantagesError(false);
            setAdvantagesPending(false);
        } catch (e) {
            message.error('Ошибка при загрузке списка преимуществ');
            setAdvantagesError(true);
            setAdvantagesPending(false);
        }
    }, []);

    useEffect(() => {
        if (
            isOpen &&
            !advantagesLoaded &&
            !advantagesError &&
            !advantagesPending
        ) {
            void handleLoadAdvantages();
        }
    }, [isOpen, advantagesLoaded, advantagesError, advantagesPending]);

    const handleOk = useCallback(() => {
        form.submit();
    }, [form]);

    const handleCancel = useCallback(() => {
        form.resetFields();
        setOpen(() => false);
    }, []);

    useEffect(() => {
        if (advantageId) {
            form.setFieldValue('id', advantageId);
        }
    }, [advantageId, mode]);

    return (
        <Modal
            title={
                mode === 'create'
                    ? 'Добавить преимущество'
                    : 'Изменить преимущество'
            }
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={mode === 'create' ? 'Создать' : 'Изменить'}
            cancelText={'Отмена'}
            closable={false}
            maskClosable={false}
            destroyOnClose
            okButtonProps={{
                loading: isProcessing,
            }}
        >
            {isOpen && (
                <AddModalForm
                    form={form}
                    handleCreate={handleCreate}
                    mode={mode as any}
                    handleClose={handleCancel}
                    salonId={salonId}
                    advantageId={advantageId}
                    advantagesPending={advantagesPending}
                    advantageList={advantageList}
                />
            )}
        </Modal>
    );
};
