export const serviceColumnSchema = [
    {
        title: 'Название услуги',
        key: 'name',
        dataIndex: 'name',
        type: 'input',
        show: true,
        value: ''
    },
    {
        title: 'Время исполнения',
        key: 'timing',
        dataIndex: 'timing',
        type: 'number',
        step: 15,
        min: 0,
        show: true,
        value: ''
    },{
        title: 'Цена',
        key: 'price',
        dataIndex: 'price',
        type: 'number',
        step: 1.0,
        min: 0,
        show: true,
        value: ''
    },{
        title: 'Активно',
        key: 'enable',
        dataIndex: 'enable',
        type: 'boolean',
        render: item => <input type={'checkbox'} checked={item.enable} />,
        show: true,
        value: ''
    }
]
