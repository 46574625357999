import { FC, useCallback } from 'react';

import styles from './Adventage.module.scss';
import { IAdvantage } from '../../../../Types/SalonTypes.ts';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';

interface Props {
    advantage: IAdvantage;
    handleDeleteAdvantage: (serviceId: string | number) => Promise<void>;
    handleStartEdit: (el: IAdvantage) => void;
}

export const AdventItem: FC<Props> = ({
    advantage,
    handleDeleteAdvantage,
    handleStartEdit,
}) => {
    const handleDelete = useCallback(async () => {
        try {
            void handleDeleteAdvantage(advantage.id);
        } catch (e) {}
    }, [advantage, handleDeleteAdvantage]);

    const handleEdit = useCallback(async () => {
        handleStartEdit({
            ...advantage,
        });
    }, [advantage, handleStartEdit]);

    return (
        <div className={styles.serviceItem}>
            <span className={styles.mods}>
                <span className={styles.edit} onClick={handleEdit}>
                    <EditOutlined size={16} />
                </span>
                <Popconfirm
                    title={`Вы уверены что хотите удалить преимущество "${advantage.title}"?`}
                    onConfirm={handleDelete}
                    okText="Да, удалить"
                    cancelText="Отмена"
                >
                    <span className={styles.delete}>
                        <DeleteOutlined size={16} />
                    </span>
                </Popconfirm>
            </span>
            <h5>{advantage.title}</h5>
            {advantage.description && (
                <span className={styles.serviceItemDesc}>
                    {advantage.description}
                </span>
            )}
            {advantage.digit !== null && advantage.digit !== undefined && (
                <div className={styles.serviceItemContent}>
                    <div>
                        <span>Скидка: {advantage.digit}%</span>
                    </div>
                </div>
            )}
        </div>
    );
};
