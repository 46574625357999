import { FC } from 'react';
import { IServiceByCategory } from '../../../../Types/SalonTypes';
import { ServiceItem } from './ServiceItem.tsx';
import { IEditableCategory } from '../../../../Modals/AddServiceModal.tsx';

import styles from './SalonPriceList.module.scss';

interface Props {
    category: IServiceByCategory;
    handleDeleteService: (serviceId: string | number) => Promise<void>;
    handleStartEdit: (el: IEditableCategory) => void;
}

export const CategoryItem: FC<Props> = ({
    category,
    handleDeleteService,
    handleStartEdit,
}) => {
    return (
        <div className={styles.category}>
            <h2 className={styles.categoryTitle}>{category.title}</h2>
            <div className={styles.serviceList}>
                {category.services.map((el) => (
                    <ServiceItem
                        service={el}
                        key={el.id}
                        handleDeleteService={handleDeleteService}
                        categoryId={category.categoryId}
                        handleStartEdit={handleStartEdit}
                    />
                ))}
            </div>
        </div>
    );
};
