import { Carousel, Button, Image, Layout, Checkbox, message } from 'antd';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ru';
import { PageHead, BanrHead, SideMen2, PageFoot } from '../part/PageParts';
import {
    ContactsOutlined,
    LoadingOutlined,
    TrophyOutlined,
} from '@ant-design/icons';
import './salon.scss';
import star from '../../img/star5pointsRed.png';
import carouArrowRight from '../../img/carouArrowRight.png';
import salonFotoXmp03 from '../../img/salon/salonFotoXmp03.jpg';
import Ant from '../../Components/ant-carousel.js';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/react-splide/dist/css/splide.min.css';
import '@splidejs/react-splide/dist/css/splide-core.min.css';
import { apiBaseMedia, apiBase } from '../Const.js';
import { PriceCurrent } from '../../Components/Cabinet/Salon/PriceCurrent/PriceCurrent.tsx';
import { AddScheduleModal } from '../../Modals/AddScheduleModal.tsx';
import { mediaFileHost } from '../../api/index.ts';
import { SalonOnMapModal } from '../../Modals/SalonOnMapModal.tsx';
import { MediaPoint, useMediaContext } from '../../Services/Media.tsx';
import { useHistory } from 'react-router-dom';
import { useTimeContext } from '../../Services/Time.tsx';
import { ReviewTab } from '../../Components/Cabinet/Salon/Reviews/ReviewTab.tsx';
import { CommonSalonsAPI } from '../../api/common/salons.ts';
import { MasterSchedule } from '../../Components/Cabinet/Salon/MasterSchedule/index.tsx';
import styles from '../../Components/Cabinet/Salon/SalonShedule.module.scss';

function Salon({ match, location: loc }) {
    const history = useHistory();
    const { utcWithDiff } = useTimeContext();
    const { media } = useMediaContext();
    let saId = ''; // для парсинга ид салона в URL до ':'
    const {
            params: { id: sId = '' },
        } = match,
        hashes = [
            'profile',
            'action',
            'master',
            'price',
            'portfolio',
            'advantages',
        ],
        tabs = [
            <>
                <ContactsOutlined /> О нас
            </>,
            (bonus) => (
                <>
                    <span>Акции и Бонусы</span>{' '}
                    {bonus ? <div className="bonus">{bonus}</div> : null}
                </>
            ),
            <>Мастера</>,
            <>Прайс-лист</>,
            <>Портфолио</>,
            <>
                <TrophyOutlined /> Преимущества
            </>,
        ],
        locHash =
            loc.hash || typeof loc.hash === 'string'
                ? hashes[
                      (hashes.indexOf(loc.hash.replace(/^#/, '')) + 1 || 1) - 1
                  ]
                : 'profile',
        [salonId, salonName] = /:/.test(sId || '')
            ? /\[[0-9]+\]/.test(
                  (saId = sId.replace(/:.*/, '').replace(/.*?:(.*)/, '$1'))
              )
                ? [
                      saId,
                      sId.replace(new RegExp(saId), '').replace(/^:|:$/g, ''),
                  ]
                : ['', sId]
            : /\[[0-9]+\]/.test(sId)
            ? [sId, '']
            : ['', sId];

    const clickMenuHoriz = (ev) => {
        // дубликатор меню и табов
        let t0 = ev.currentTarget,
            t = ev.target;
        if (t.tagName !== 'LI') t = t.parentNode;
        if (t.tagName !== 'LI') t = t.parentNode;
        Array.from(t.parentNode.children).forEach((el) =>
            el.classList.remove('active')
        );
        t.classList.add('active');
    };
    const groupServices = [
            //статика: группы услуг (табы и выбор доп. услуг)
            //{value: 1, label:'Ногтевой сервис', cats: ['Маникюр', 'Педикюр']},
            {
                value: 1,
                label: 'Ногтевой сервис',
                cats: [
                    'Маникюр',
                    'Педикюр',
                    'Стрижка волос',
                    'Окрашивание волос',
                    'Укладка',
                    'Шугаринг',
                    'Воск',
                    'Лазер',
                    'Ресницы и брови',
                    'Барбершоп',
                ],
            },
            {
                value: 2,
                label: 'Парикмахерский зал',
                cats: ['Стрижка волос', 'Окрашивание волос', 'Укладка'],
            },
            {
                value: 3,
                label: 'Удаление волос',
                cats: ['Шугаринг', 'Воск', 'Лазер'],
            },
            { value: 4, label: 'Брови и ресницы', cats: ['Ресницы и брови'] },
            { value: 5, label: 'Барбершоп', cats: ['Барбершоп'] }, // для наглядности - items[].title в cats[]
        ],
        cat2id = {
            Маникюр: 1,
            Педикюр: 2,
            'Стрижка волос': 3,
            'Окрашивание волос': 4,
            Укладка: 5,
            Шугаринг: 6,
            Воск: 7,
            Лазер: 8,
            'Ресницы и брови': 9,
            Барбершоп: 10,
        };
    let cIs = Object.keys(cat2id).map((el) => ({ title: el })); // имя категории - в номер таба
    const [tabsCat, setTabsCat] = useState(groupServices), // список выбора услуг с блок-й добавленных
        chgTabCat = (evx) => {
            //setTabsCat(tabsCat.map(el => ({...el, ...{disabled: el.value === +evx.target.value}})));
            const t = evx.target,
                tabs = document.querySelectorAll('.servByCat.tab');
            tabs &&
                Array.from(tabs).forEach((el) => el.classList.remove('active'));
            document
                .querySelector(`.servByCat.tab${t.value}`)
                ?.classList.add('active');
        };
    const [checkExact, setCheckExact] = useState(false), // точное время / интервал (ручной выбор)
        chgTimeExact = () => {
            setCheckExact(!checkExact);
        };
    const $stor = useSelector((state) => {
            $stor?.tableSource &&
                ((s) => {
                    console.log(s.tableSource, s);
                })($stor);
            //debugger;
            return state;
        }),
        dispatch = useDispatch(),
        [salonOnce, setSalonOnce] = useState(0), // получение категорий из Ajax
        [Sa, setSalon] = useState($stor?.salonDescr || {}), // стейт Салона
        salonStars =
            Sa?.rating ??
            Math.max(0, Math.min(5, Math.random() * 5)).toFixed(1), // ===== конст. РЕЙТНИГ салона =====; округление до 0.1
        whStars = (stN, rat = salonStars) => {
            // whiteStars - рейтинг на 5 звёзд и 50 градаций
            const st01 = Math.max(0, Math.min(5, +(+rat).toFixed(1))),
                stFlor = Math.floor(st01);
            return (
                (stFlor < stN ? 'wh' : '') +
                (stN !== stFlor + 1
                    ? ''
                    : ' wh' + Math.floor(10 * (st01 - stFlor) + 0.00001))
            );
        };

    const [demoVoteN, setDemoVoteN] = useState(50), // при 0 - не показываются звёзды
        demoReplyN = 0,
        demoBonus = 16; // TODO из БД - число активных записей клиента

    moment.locale('ru');
    const dateNow = moment().locale('ru').format('LL, dddd');
    useEffect(() => {
        cIs = cIs.map((el) => ({ title: el.title, children: [] })); // сброс мусора в массивах
        if (!salonOnce) {
            setSalonOnce(1); //debugger
            axios
                .get(apiBase + (sId != null ? 'salon/' + sId : 'category/')) // ТЕСТ и реальный ид
                .then((res) => {
                    const cat2idServ = res?.data?.titleCat?.reduce((s, el) => {
                        return { ...s, ...{ [el.id]: el.title } };
                    }, {});
                    dispatch({
                        type: 'SOURCE-TABLE',
                        tableSource: !salonId
                            ? res.data
                            : res.data.services.reduce(
                                  (s, el) => {
                                      const cI =
                                          cat2id[cat2idServ[el.category.id]] -
                                          1;
                                      //if(cI !==0 && cI !==1 && cI !==8) //TEST принудительный непоказ первой категории (.id ==1)
                                      if (s.categories?.[cI]) {
                                          s.categories[cI].id = el.category.id; // серверный ид категории (короткий)
                                          s.categories[cI].key =
                                              el.category.key; // (для хеша категорий)
                                          s.categories[cI].children.push({
                                              servTitle: el.serviceName,
                                              //enable: el.enable,
                                              servPrice: el.priceValue,
                                              servDescr:
                                                  el.serviceDescription || '',
                                              servTiming: el.serviceTiming,
                                              key: el.id, // (для хеша списка)
                                          });
                                      }
                                      return s;
                                  },
                                  { categories: cIs }
                              ),
                    });
                    setSalon(res.data); // для компонента - сохранён салон, передёрнуты данные
                    //if(Sa.id) // формально - показываем id в адресной строке
                    //    hist.push(`/salon/cabinet/id${Sa.id}`);
                    //setTimeout(() => {
                    //document.querySelectorAll('.srchTabs >label')[4].click();
                    //}, 0);
                })
                .catch((er) => {
                    if (er?.response?.status === 404) {
                        history.push('/404');
                    }
                });
        }
        new Ant('.portfolio');
    });
    const chgService = (ev) => {
        // выбрать/снять чекбокс-услугу: 1) записать в список категории; 2) в общий; 3) в парам-ы
        const t = ev.target; // не нативный ev
        const el = document.querySelector(
            `.servNoHeadNoFoot input[name="${t.name}"]`
        )?.parentNode;
        if (servCount >= servCountMax) {
            setTimeout(() => {
                t.checked = false;
                ev.nativeEvent.srcElement.checked = false;
                if (el.classList) el.classList.remove('ant-checkbox-checked');
            }, 0);
        }
        if (t.checked && servCount >= servCountMax)
            // не добавлять услуг больше servCountMax (==3)
            return;
        if (t.checked) setServCount(Math.min(servCount + 1, servCountMax));
        else setServCount(Math.max(0, servCount - 1));
        const servCat = document.querySelector(
                `.srchForm >[data-cat="${servOpen}"] .srchServiceRequest >div`
            ),
            srchT = document.querySelector(
                '.srchForm >.srchYouFind >.srchFullResult'
            );
        const s = (servCat?.innerHTML || '').replace(new RegExp(servNo), ''),
            sr = (srchT?.innerHTML || '').replace(new RegExp(srchNo), '');
        //debugger;
        if (servCat)
            servCat.innerHTML = t.checked
                ? s + (s ? ' + ' : '') + t.name
                : s.replace(
                      new RegExp(
                          '( \\+ )?' + t.name.replace(/([()/+])/g, '\\$1')
                      ),
                      ''
                  ) || servNo.replace(/\\/g, '');
        if (srchT)
            srchT.innerHTML = t.checked
                ? sr + (sr ? ' + ' : '') + t.name
                : sr.replace(
                      new RegExp(
                          '( \\+ )?' + t.name.replace(/([()/+])/g, '\\$1')
                      ),
                      ''
                  ) || srchNo.replace(/\\/g, '');
    };
    const handleCancel = () => {
            setServOpen(0);
        },
        servNo = '\\(не выбрано\\)',
        srchNo = '\\(запрос поиска\\)';

    const [servOpen, setServOpen] = useState(0); // видимость нужного попапа (groupServices[].value)
    const [servCount, setServCount] = useState(0); // ограничитель числа услуг (общий из всех категорий)
    const [splideH, setSplideH] = useState(300); // высота картинки в карусели "Портфолио" TODO при узких экранах - resize
    const servCountMax = 3,
        S1 = new Splide('.splide', { start: 1 });
    S1.mount && S1.mount({ AutoScroll });
    const advaS = [
        { id: 3, title: 'Европейский маникюр' },
        { id: 4, title: 'Обрезной маникюр' },
        { id: 5, title: 'Комбинированный маникюр' },
        { id: 6, title: 'Аппаратный маникюр' },
        { id: 7, title: 'Классический маникюр + гель лак' },
        { id: 8, title: 'Укрепление акрилом' },
    ];

    const [isAddShOpen, setIsAddShOpen] = useState(false);

    const clientId = useMemo(() => {
        const token = JSON.parse(localStorage.getItem('auth'))?.accessToken;

        if (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(
                window
                    .atob(base64)
                    .split('')
                    .map(function (c) {
                        return (
                            '%' +
                            ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                        );
                    })
                    .join('')
            );

            return JSON.parse(jsonPayload).id;
        } else {
            return undefined;
        }
    }, []);

    const handleAddSh = useCallback(() => {
        if (!clientId) {
            void message.warning('Войдите в систему для записи');
        } else {
            setIsAddShOpen(true);
        }
    }, [clientId]);

    const [isOnMapOpened, setIsOnMapOpened] = useState(false);

    const [modalPrevState, setModalPrevState] = useState(undefined);

    const clearPrevState = useCallback(() => {
        setModalPrevState(undefined);
    }, []);

    const handleAddShByMaster = useCallback(
        (masterId) => {
            if (!clientId) {
                void message.warning('Войдите в систему для записи');
            } else {
                setModalPrevState(() => ({
                    employer: masterId,
                    type: 'schedule',
                }));
                setIsAddShOpen(true);
            }
        },
        [clientId]
    );

    const handleScheduleByTime = useCallback(
        (masterId, dateStart) => {
            if (!clientId) {
                void message.warning('Войдите в систему для записи');
            } else {
                setModalPrevState(() => ({
                    employer: masterId,
                    dateStart,
                    type: 'schedule',
                }));
                setIsAddShOpen(true);
            }
        },
        [clientId]
    );

    const salonStartTime = useMemo(() => {
        return (
            $stor.tableSource.startJobTime
                ?.split(':')
                .map((el, index) =>
                    index === 0 ? Number(el) + utcWithDiff : Number(el)
                ) ?? [0, 0]
        );
    }, [$stor.tableSource, utcWithDiff]);

    const salonEndTime = useMemo(() => {
        return (
            $stor.tableSource.endJobTime
                ?.split(':')
                .map((el, index) =>
                    index === 0 ? Number(el) + utcWithDiff : Number(el)
                ) ?? [23, 59]
        );
    }, [$stor.tableSource, utcWithDiff]);

    const actPerPage = useMemo(
        () =>
            media === MediaPoint.DESkTOP
                ? 4
                : media === MediaPoint.MOBILE
                ? 1
                : 2,
        [media]
    );

    const [reviewsOpen, setReviewsOpen] = useState(false);

    const handleReviewClose = useCallback(() => {
        setReviewsOpen(false);
    }, []);

    const handleReviewOpen = useCallback(() => {
        setReviewsOpen(true);
    }, []);

    const [reviews, setReviews] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(false);

    const handleLoad = useCallback(async () => {
        setPending(() => true);

        try {
            const response = await CommonSalonsAPI.getSalonReviews(
                Sa.id,
                'client2salon'
            );

            setReviews(() => response.data.items);

            setLoaded(() => true);
            setPending(() => false);
            setError(() => false);
        } catch (e) {
            setLoaded(() => false);
            setError(() => true);
            setPending(() => false);
        }
    }, [Sa.id]);

    useEffect(() => {
        if (!loaded && !pending && !error && Sa.id !== undefined) {
            void handleLoad();
        }
    }, [loaded, pending, error, Sa.id]);

    const [defaultWithoutServices, setDefaultWithoutServices] = useState();
    const [scheduleWithoutServices, setScheduleWithoutServices] = useState();

    const fetchScheduleWithoutServices = useCallback(
        async (date, isFirst) => {
            try {
                if (Sa.id && date) {
                    const response =
                        await CommonSalonsAPI.getScheduleWithoutServices(
                            Sa.id,
                            date
                        );
                    if (isFirst) {
                        setDefaultWithoutServices(response.data.items);
                    }
                    setScheduleWithoutServices((prev) => ({
                        ...prev,
                        [date]: response.data.items,
                    }));
                }
            } catch (e) {
                message.error(
                    'Ошибка при попытке загрузить информацию о занятых датах'
                );
            }
        },
        [Sa.id]
    );

    useEffect(() => {
        const startDate = moment().utc().startOf('day').toISOString();

        void fetchScheduleWithoutServices(startDate, true);
    }, [fetchScheduleWithoutServices]);

    return (
        <>
            <Layout className="salon">
                <PageHead />
                <SideMen2 />
                <div className="layCont">
                    <BanrHead hide={process.env.REACT_APP_VISUAL_FOR_DEV} />
                    {Sa?.id && (
                        <div className="stackBlock bfCabinet">
                            {media < MediaPoint.DESkTOP && (
                                <h2 className="bs-adapt-title">{Sa?.title}</h2>
                            )}
                            <div className="stackIn bfCabinet salonHeadStars">
                                <h2>
                                    {Sa?.title
                                        ? Sa?.title
                                        : Sa?.title || (
                                              <span>
                                                  [шаблон салона
                                                  <span className="paleError">
                                                      {' '}
                                                      (укажите /salon/id)
                                                  </span>
                                                  ]
                                              </span>
                                          )}{' '}
                                    <span className="test">
                                        {salonId ? salonId : ''}
                                    </span>
                                </h2>
                                {demoVoteN ? (
                                    <div className="salonStars">
                                        <i className={whStars(1)}>
                                            <img src={star} alt="" />
                                        </i>
                                        <i className={whStars(2)}>
                                            <img src={star} alt="" />
                                        </i>
                                        <i className={whStars(3)}>
                                            <img src={star} alt="" />
                                        </i>
                                        <i className={whStars(4)}>
                                            <img src={star} alt="" />
                                        </i>
                                        <i className={whStars(5)}>
                                            <img src={star} alt="" />
                                        </i>
                                        <div>{Sa.rating ?? salonStars}</div>
                                    </div>
                                ) : null}
                                <div className="votesReplies">
                                    <div
                                        className="votes"
                                        onClick={handleReviewOpen}
                                    >
                                        Оценки (
                                        {reviews?.filter(
                                            (el) => el.type === 'client2salon'
                                        )?.length
                                            ? reviews.filter(
                                                  (el) =>
                                                      el.type === 'client2salon'
                                              ).length
                                            : 'Отсутствуют'}
                                        )
                                    </div>
                                    <div
                                        className="replies"
                                        onClick={handleReviewOpen}
                                    >
                                        {reviews?.filter(
                                            (el) =>
                                                el.type === 'client2salon' &&
                                                !!el.description
                                        )?.length
                                            ? `Отзывы (${
                                                  reviews.filter(
                                                      (el) =>
                                                          el.type ===
                                                              'client2salon' &&
                                                          !!el.description
                                                  ).length
                                              })`
                                            : 'Отзывы (Отсутствуют)'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {media === MediaPoint.DESkTOP && (
                        <div className="stackBlock bfCabinet bacVw100 menuHorizOver2">
                            <div className="stackIn bfCabinet">
                                <div className="menuHorizOver">
                                    <ul
                                        className="menuHoriz"
                                        onClick={clickMenuHoriz}
                                    >
                                        {tabs.map((el, i) => (
                                            <li
                                                data-tab={i + 1}
                                                key={'menuHor' + i}
                                                className={
                                                    (hashes.indexOf(locHash) ===
                                                    i
                                                        ? 'active'
                                                        : '') +
                                                    (i === 2 &&
                                                    !Sa?.masters?.length
                                                        ? ' disabled'
                                                        : '')
                                                }
                                                onClick={handleReviewClose}
                                                title={
                                                    i === 2 &&
                                                    !Sa?.masters?.length
                                                        ? 'Мастера не указаны'
                                                        : ''
                                                }
                                            >
                                                <a href={`#${hashes[i]}`}>
                                                    {typeof tabs[i] ===
                                                    'function'
                                                        ? tabs[i](undefined)
                                                        : tabs[i]}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}

                    {!Sa?.id ? (
                        <div className={styles.loaderWrapper}>
                            <LoadingOutlined />
                        </div>
                    ) : (
                        <>
                            {reviewsOpen &&
                            media !== MediaPoint.MOBILE &&
                            !!Sa.id ? (
                                <ReviewTab
                                    handleClose={handleReviewClose}
                                    reviews={reviews}
                                    salonRate={Sa.rating}
                                />
                            ) : (
                                <>
                                    <div
                                        id="profile"
                                        className="stackBlock bfCabinet"
                                    >
                                        <div className="stackIn bfCabinet salonProfile">
                                            <div className="salonPhoto">
                                                <Image.PreviewGroup>
                                                    {!'Одно фото в салоне' ? (
                                                        <img
                                                            src={salonFotoXmp03}
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <Carousel
                                                            effect="fade"
                                                            autoplay
                                                            autoplaySpeed={4000}
                                                            rows={1}
                                                        >
                                                            {(
                                                                Sa.photo || []
                                                            ).map((el) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            el.fileName +
                                                                            el.id
                                                                        }
                                                                    >
                                                                        <Image
                                                                            src={
                                                                                apiBaseMedia +
                                                                                el.fileName
                                                                            }
                                                                            style={{
                                                                                objectFit:
                                                                                    'cover',
                                                                                width: '100%',
                                                                                height: '100%',
                                                                            }}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                        </Carousel>
                                                    )}
                                                </Image.PreviewGroup>
                                            </div>
                                            <div className="salonData">
                                                <div className="rowAddrTransp">
                                                    <div className="rowAddr">
                                                        {Sa.city
                                                            ? 'г. ' +
                                                              Sa.city +
                                                              ', '
                                                            : '--- '}{' '}
                                                        {Sa.address ||
                                                            '[не указан]'}
                                                        <Button
                                                            size="small"
                                                            className="toMap green short"
                                                            onClick={() => {
                                                                setIsOnMapOpened(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <span>
                                                                Посмотреть{' '}
                                                            </span>
                                                            на карте
                                                        </Button>
                                                    </div>
                                                    {Sa.metro ? (
                                                        <div className="rowTransp">
                                                            <div className="label">
                                                                {Sa.metro ||
                                                                    '[не указано]'}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="rowPhoneWeb">
                                                    <div className="rowPhone phones3">
                                                        <span className="phone1">
                                                            {Sa.phone
                                                                ?.replace(
                                                                    /^([^|]+)\|.*/,
                                                                    '$1'
                                                                )
                                                                ?.replace(
                                                                    /^ *| *$/g,
                                                                    ''
                                                                )
                                                                ?.replace(
                                                                    /^7(\d{3})(\d{3})(\d{2})(\d{2})/,
                                                                    '+7($1) $2-$3-$4'
                                                                ) ||
                                                                '8-000-000-0000'}
                                                        </span>
                                                        {/\|/.test(
                                                            Sa.phone || ''
                                                        ) ? (
                                                            <>
                                                                <span className="phoneDelim">
                                                                    /
                                                                </span>
                                                                <span className="phone2">
                                                                    {Sa.phone
                                                                        ?.replace(
                                                                            /^.*?\|([^|]+)\|.*/,
                                                                            '$1'
                                                                        )
                                                                        ?.replace(
                                                                            /^ *| *$/g,
                                                                            ''
                                                                        )
                                                                        ?.replace(
                                                                            /^7(\d{3})(\d{3})(\d{2})(\d{2})/,
                                                                            '+7($1) $2-$3-$4'
                                                                        ) ||
                                                                        '(не указан)'}
                                                                </span>
                                                                <span className="phoneDelim">
                                                                    /
                                                                </span>
                                                                <span className="phone3">
                                                                    {Sa.phone
                                                                        ?.replace(
                                                                            /^.*?\|.*?\|([^|]*).*/,
                                                                            '$1'
                                                                        )
                                                                        ?.replace(
                                                                            /^ *| *$/g,
                                                                            ''
                                                                        )
                                                                        ?.replace(
                                                                            /^7(\d{3})(\d{3})(\d{2})(\d{2})/,
                                                                            '+7($1) $2-$3-$4'
                                                                        ) ||
                                                                        '(не указан)'}
                                                                </span>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                    <div className="rowWeb">
                                                        {Sa.website ? (
                                                            <a
                                                                href={
                                                                    Sa.website
                                                                }
                                                                target="_blank"
                                                            >
                                                                {Sa.website.replace(
                                                                    /^https?:\/\//,
                                                                    ''
                                                                ) || '[нет]'}
                                                            </a>
                                                        ) : (
                                                            '[нет]'
                                                        )}
                                                    </div>
                                                    <div className="rowNet">
                                                        <div
                                                            className={`inView${
                                                                Sa.social
                                                                    ?.replace(
                                                                        /^([^|]+)\|.*/,
                                                                        '$1'
                                                                    )
                                                                    ?.replace(
                                                                        /^ *| *$/g,
                                                                        ''
                                                                    )
                                                                    ? ''
                                                                    : ' disabled'
                                                            }`}
                                                        >
                                                            {Sa.social
                                                                ?.replace(
                                                                    /^([^|]+)\|.*/,
                                                                    '$1'
                                                                )
                                                                ?.replace(
                                                                    /^ *| *$/g,
                                                                    ''
                                                                ) ? (
                                                                <a
                                                                    href={
                                                                        Sa.social
                                                                            ?.replace(
                                                                                /^([^|]+)\|.*/,
                                                                                '$1'
                                                                            )
                                                                            ?.replace(
                                                                                /^ *| *$/g,
                                                                                ''
                                                                            ) ||
                                                                        ''
                                                                    }
                                                                    target="_blank"
                                                                >
                                                                    in
                                                                </a>
                                                            ) : (
                                                                <span title="нет">
                                                                    in
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`vkView${
                                                                Sa.social
                                                                    ?.replace(
                                                                        /^.*?\|([^|]+)\|.*/,
                                                                        '$1'
                                                                    )
                                                                    ?.replace(
                                                                        /^ *| *$/g,
                                                                        ''
                                                                    ) &&
                                                                /\|/.test(
                                                                    Sa.social ||
                                                                        ''
                                                                )
                                                                    ? ''
                                                                    : ' disabled'
                                                            }`}
                                                        >
                                                            {Sa.social
                                                                ?.replace(
                                                                    /^.*?\|([^|]+)\|.*/,
                                                                    '$1'
                                                                )
                                                                ?.replace(
                                                                    /^ *| *$/g,
                                                                    ''
                                                                ) &&
                                                            /\|/.test(
                                                                Sa.social || ''
                                                            ) ? (
                                                                <a
                                                                    href={
                                                                        Sa.social
                                                                            ?.replace(
                                                                                /^.*?\|([^|]+)\|.*/,
                                                                                '$1'
                                                                            )
                                                                            ?.replace(
                                                                                /^ *| *$/g,
                                                                                ''
                                                                            ) ||
                                                                        ''
                                                                    }
                                                                    target="_blank"
                                                                >
                                                                    vk
                                                                </a>
                                                            ) : (
                                                                <span title="нет">
                                                                    vk
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`fbView${
                                                                Sa.social
                                                                    ?.replace(
                                                                        /^.*?\|.*?\|([^|]*).*/,
                                                                        '$1'
                                                                    )
                                                                    ?.replace(
                                                                        /^ *| *$/g,
                                                                        ''
                                                                    ) &&
                                                                /\|/.test(
                                                                    Sa.social ||
                                                                        ''
                                                                )
                                                                    ? ''
                                                                    : ' disabled'
                                                            }`}
                                                        >
                                                            {Sa.social
                                                                ?.replace(
                                                                    /^.*?\|.*?\|([^|]*).*/,
                                                                    '$1'
                                                                )
                                                                ?.replace(
                                                                    /^ *| *$/g,
                                                                    ''
                                                                ) &&
                                                            /\|/.test(
                                                                Sa.social || ''
                                                            ) ? (
                                                                <a
                                                                    href={
                                                                        Sa.social
                                                                            ?.replace(
                                                                                /^.*?\|.*?\|([^|]*).*/,
                                                                                '$1'
                                                                            )
                                                                            ?.replace(
                                                                                /^ *| *$/g,
                                                                                ''
                                                                            ) ||
                                                                        ''
                                                                    }
                                                                    target="_blank"
                                                                >
                                                                    fb
                                                                </a>
                                                            ) : (
                                                                <span title="нет">
                                                                    fb
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="descr"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            Sa.description?.replace(
                                                                /\n/g,
                                                                '<br>\n'
                                                            ) ||
                                                            '[нет описания]',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="fillSpaceVert" />
                                    </div>

                                    <div
                                        id="action"
                                        className="stackBlock bfCarou action"
                                    >
                                        {$stor.tableSource &&
                                            $stor.tableSource.actions &&
                                            $stor.tableSource.actions.length >
                                                0 && (
                                                <div className="stackIn bfCabinet">
                                                    <h2>Акции и Бонусы</h2>
                                                    {actPerPage >
                                                    $stor.tableSource.actions
                                                        .length ? (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                                gap:
                                                                    media ===
                                                                    MediaPoint.DESkTOP
                                                                        ? '5.2em'
                                                                        : '2.9em',
                                                            }}
                                                        >
                                                            {$stor.tableSource.actions.map(
                                                                (act) => (
                                                                    <div
                                                                        key={
                                                                            act.id
                                                                        }
                                                                    >
                                                                        <Image
                                                                            height={
                                                                                360
                                                                            }
                                                                            width={
                                                                                310
                                                                            }
                                                                            src={
                                                                                act.thumbs
                                                                            }
                                                                            alt="1"
                                                                            preview={{
                                                                                src: act.fullImage,
                                                                            }}
                                                                        />
                                                                        <div
                                                                            style={{
                                                                                color: 'white',
                                                                            }}
                                                                        >
                                                                            {
                                                                                act.title
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <Image.PreviewGroup>
                                                            <Splide
                                                                hasTrack={false}
                                                                options={{
                                                                    perPage:
                                                                        actPerPage,
                                                                    start: 0,
                                                                    gap:
                                                                        media ===
                                                                        MediaPoint.DESkTOP
                                                                            ? '5.2em'
                                                                            : '2.9em',
                                                                    height: 400,
                                                                    type: 'slide',
                                                                    rewind: true,
                                                                    autoplay: true,
                                                                    drag: 'free',
                                                                    autoWidth: true,
                                                                    interval: 12000,
                                                                }}
                                                            >
                                                                <SplideTrack>
                                                                    {$stor.tableSource.actions.map(
                                                                        (
                                                                            act
                                                                        ) => (
                                                                            <SplideSlide
                                                                                key={
                                                                                    act.id
                                                                                }
                                                                            >
                                                                                <Image
                                                                                    height={
                                                                                        360
                                                                                    }
                                                                                    width={
                                                                                        310
                                                                                    }
                                                                                    src={
                                                                                        act.thumbs
                                                                                    }
                                                                                    alt="1"
                                                                                    preview={{
                                                                                        src: act.fullImage,
                                                                                    }}
                                                                                />
                                                                                <div>
                                                                                    {
                                                                                        act.title
                                                                                    }
                                                                                </div>
                                                                            </SplideSlide>
                                                                        )
                                                                    )}
                                                                </SplideTrack>
                                                                <div className="splide__arrows">
                                                                    <img
                                                                        src={
                                                                            carouArrowRight
                                                                        }
                                                                        className="splide__arrow splide__arrow--prev carouArrowLeft"
                                                                        alt="%lt;"
                                                                    />
                                                                    <img
                                                                        src={
                                                                            carouArrowRight
                                                                        }
                                                                        className="splide__arrow splide__arrow--next carouArrowRight"
                                                                        alt="%lt;"
                                                                    />
                                                                </div>
                                                                <div className="splide__progress">
                                                                    <div className="splide__progress__bar" />
                                                                </div>
                                                            </Splide>
                                                        </Image.PreviewGroup>
                                                    )}
                                                </div>
                                            )}
                                    </div>

                                    {Sa?.employers?.length ? (
                                        <div
                                            id="master"
                                            className="stackBlock bfCarou master bfCabinet bacVw100"
                                        >
                                            <div className="stackIn bfCabinet">
                                                <h2>Наши мастера</h2>
                                                <div className="center">
                                                    Выберите желаемого мастера,
                                                    и ниже будут доступны только
                                                    те услуги, которые оказывает
                                                    мастер.
                                                </div>
                                                <Image.PreviewGroup>
                                                    <Splide
                                                        hasTrack={false}
                                                        options={{
                                                            perPage:
                                                                media ===
                                                                MediaPoint.DESkTOP
                                                                    ? 4
                                                                    : media ===
                                                                      MediaPoint.MOBILE
                                                                    ? 1
                                                                    : 2,
                                                            start:
                                                                media ===
                                                                MediaPoint.DESkTOP
                                                                    ? Math.min(
                                                                          Sa
                                                                              .employers
                                                                              .length,
                                                                          4
                                                                      ) === 4
                                                                        ? 0
                                                                        : Math.ceil(
                                                                              (Sa
                                                                                  .employers
                                                                                  .length -
                                                                                  1 ??
                                                                                  2) /
                                                                                  2
                                                                          )
                                                                    : media ===
                                                                      MediaPoint.MOBILE
                                                                    ? 0
                                                                    : 0,
                                                            gap:
                                                                media ===
                                                                MediaPoint.DESkTOP
                                                                    ? '5.2em'
                                                                    : '2.9em',
                                                            type: 'slide',
                                                            rewind: true,
                                                            autoplay: true,
                                                            drag: 'free',
                                                            autoWidth: true,
                                                            interval: 12000,
                                                            focus: 'center',
                                                        }}
                                                    >
                                                        <SplideTrack>
                                                            {Sa.employers.map(
                                                                (el) => (
                                                                    <SplideSlide
                                                                        key={
                                                                            el.id
                                                                        }
                                                                    >
                                                                        <Image
                                                                            height={
                                                                                300
                                                                            }
                                                                            width={
                                                                                310
                                                                            }
                                                                            src={
                                                                                el
                                                                                    .master
                                                                                    .avatar
                                                                                    ? `${mediaFileHost}${el.master.avatar.fileName}`
                                                                                    : undefined
                                                                            }
                                                                            alt="1"
                                                                        />
                                                                        <div>
                                                                            <h4>
                                                                                {
                                                                                    el
                                                                                        .master
                                                                                        .firstName
                                                                                }{' '}
                                                                                {
                                                                                    el
                                                                                        .master
                                                                                        .lastName
                                                                                }
                                                                            </h4>
                                                                            {el
                                                                                .master
                                                                                .property && (
                                                                                <>
                                                                                    <hr />
                                                                                    {
                                                                                        el
                                                                                            .master
                                                                                            .property
                                                                                            .descriptions
                                                                                    }
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        {/*<Button*/}
                                                                        {/*    className="orange"*/}
                                                                        {/*    onClick={() => {*/}
                                                                        {/*        handleAddShByMaster(*/}
                                                                        {/*            el.id*/}
                                                                        {/*        );*/}
                                                                        {/*    }}*/}
                                                                        {/*>*/}
                                                                        {/*    Записаться*/}
                                                                        {/*</Button>*/}
                                                                        <MasterSchedule
                                                                            employer={
                                                                                el
                                                                            }
                                                                            salon={
                                                                                Sa
                                                                            }
                                                                            defaultWithoutServices={
                                                                                defaultWithoutServices
                                                                            }
                                                                            fetchScheduleWithoutServices={
                                                                                fetchScheduleWithoutServices
                                                                            }
                                                                            scheduleWithoutServices={
                                                                                scheduleWithoutServices
                                                                            }
                                                                            handleScheduleByTime={
                                                                                handleScheduleByTime
                                                                            }
                                                                            clientId={
                                                                                clientId
                                                                            }
                                                                        />
                                                                    </SplideSlide>
                                                                )
                                                            )}
                                                        </SplideTrack>
                                                        <div className="splide__arrows">
                                                            <img
                                                                src={
                                                                    carouArrowRight
                                                                }
                                                                className="splide__arrow splide__arrow--prev carouArrowLeft"
                                                                alt="%lt;"
                                                            />
                                                            <img
                                                                src={
                                                                    carouArrowRight
                                                                }
                                                                className="splide__arrow splide__arrow--next carouArrowRight"
                                                                alt="%lt;"
                                                            />
                                                        </div>
                                                        <div className="splide__progress">
                                                            <div className="splide__progress__bar" />
                                                        </div>
                                                    </Splide>
                                                </Image.PreviewGroup>
                                            </div>
                                        </div>
                                    ) : null}

                                    <div
                                        id="price"
                                        className="stackBlock bfCarou master bacVw100"
                                    >
                                        <div className="stackIn bfCarou-hider">
                                            <h2>Услуги и стоимость</h2>
                                            <div className="descr">
                                                Добро пожаловать на
                                                интерактивную страницу записи.
                                                <br />
                                                Выберите одну или несколько
                                                услуг, а также обозначьте
                                                удобные дату и время посещения и
                                                нажмите кнопку "Начать поиск".
                                                <br />
                                                Ждем Вас)
                                            </div>
                                            <div className="srchForm srchPriceBts">
                                                <PriceCurrent
                                                    salon={$stor.tableSource}
                                                />
                                            </div>
                                            <div className="servByCat active srchSalonDateTimeOver">
                                                <div className="servNoHeadNoFoot ant-modal-content">
                                                    <div className="ant-modal-body">
                                                        <div
                                                            className="srchAction"
                                                            onClick={
                                                                handleAddSh
                                                            }
                                                        >
                                                            <span>
                                                                Записаться
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        id="portfolio"
                                        className="stackBlock bfCarou portfolio"
                                    >
                                        <div className="stackIn bfCabinet">
                                            <h2>Портфолио работ</h2>
                                            <Image.PreviewGroup>
                                                <Splide
                                                    hasTrack={false}
                                                    options={{
                                                        perPage:
                                                            media ===
                                                            MediaPoint.DESkTOP
                                                                ? 4
                                                                : media ===
                                                                  MediaPoint.MOBILE
                                                                ? 1
                                                                : 2,
                                                        start: 0,
                                                        gap:
                                                            media ===
                                                            MediaPoint.DESkTOP
                                                                ? '5.2em'
                                                                : '2.9em',
                                                        height: 215,
                                                        type: 'slide',
                                                        rewind: true,
                                                        autoplay: true,
                                                        drag: 'free',
                                                        autoWidth: true,
                                                        interval: 12000,
                                                    }}
                                                >
                                                    <SplideTrack>
                                                        {(
                                                            Sa.portfolio || []
                                                        ).map((el) => {
                                                            return (
                                                                <SplideSlide
                                                                    key={
                                                                        el.fileName +
                                                                        el.id
                                                                    }
                                                                >
                                                                    <Image
                                                                        width={
                                                                            310
                                                                        }
                                                                        src={
                                                                            apiBaseMedia +
                                                                            el.fileName
                                                                        }
                                                                        alt={
                                                                            el.id
                                                                        }
                                                                    />
                                                                </SplideSlide>
                                                            );
                                                        })}
                                                    </SplideTrack>
                                                    <div className="splide__arrows">
                                                        <img
                                                            src={
                                                                carouArrowRight
                                                            }
                                                            className="splide__arrow splide__arrow--prev carouArrowLeft"
                                                            alt="%lt;"
                                                        />
                                                        <img
                                                            src={
                                                                carouArrowRight
                                                            }
                                                            className="splide__arrow splide__arrow--next carouArrowRight"
                                                            alt="%lt;"
                                                        />
                                                    </div>
                                                    <div className="splide__progress">
                                                        <div className="splide__progress__bar" />
                                                    </div>
                                                </Splide>
                                            </Image.PreviewGroup>
                                        </div>
                                    </div>
                                    {Sa?.advantages &&
                                        Sa?.advantages.length > 0 && (
                                            <>
                                                <div
                                                    id="advantages"
                                                    className="stackBlock bfCarou advantages"
                                                >
                                                    <div className="stackIn bfCabinet">
                                                        <h2>
                                                            Наши преимущества
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="stackBlock bfCarou master advantages">
                                                    <div className="stackIn bfCabinet">
                                                        <div className="servByCat servNoHeadNoFoot tab active">
                                                            <div className="ant-modal-content">
                                                                <div className="ant-modal-body">
                                                                    <div className="servCat">
                                                                        {Sa?.advantages?.map(
                                                                            (
                                                                                e4
                                                                            ) => (
                                                                                <div
                                                                                    className="serv"
                                                                                    key={`adva${e4.title}`}
                                                                                >
                                                                                    <Checkbox
                                                                                        checked
                                                                                        className="servValue"
                                                                                        name={
                                                                                            e4.title
                                                                                        }
                                                                                        onChange={
                                                                                            chgService
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            e4.title
                                                                                        }
                                                                                    </Checkbox>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    {media === MediaPoint.MOBILE &&
                                        reviewsOpen &&
                                        !!Sa.id && (
                                            <ReviewTab
                                                reviews={reviews}
                                                salonRate={Sa.rating}
                                                handleClose={handleReviewClose}
                                            />
                                        )}
                                </>
                            )}
                        </>
                    )}

                    <AddScheduleModal
                        open={isAddShOpen}
                        setOpen={setIsAddShOpen}
                        salonData={$stor.tableSource}
                        handleSetNeedLoad={() => {}}
                        mode={'client'}
                        clientId={clientId}
                        modalPrevState={modalPrevState}
                        clearPrevState={clearPrevState}
                        startTime={salonStartTime}
                        endTime={salonEndTime}
                    />
                </div>
                <SalonOnMapModal
                    isOpen={isOnMapOpened}
                    setOpen={setIsOnMapOpened}
                    salonCoords={[
                        $stor.tableSource.longitude,
                        $stor.tableSource.latitude,
                    ]}
                />
            </Layout>
            <PageFoot />
        </>
    );
}
export default Salon;
