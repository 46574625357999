import React, { FC, useCallback, useMemo } from 'react';
import { MediaPoint } from '../../../Services/Media.tsx';
import { Button, Rate } from 'antd';
import star from '../../../img/star5pointsRed.png';
import { IEventStatus, IServerEvent } from '../../../Types/ScheduleTypes.ts';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { mediaFileHost } from '../../../api/index.ts';

interface Props {
    media: MediaPoint;
    salonStars: string;
    whStars: any;
    event: IServerEvent;
    handleReOrder: (event: IServerEvent) => void;
    handleDeclineEvent: (event: string) => void;
    handleRate: (salonId: string, estimate: number, scheduleId: string) => void;
    handleEditOrder: (event: IServerEvent) => void;
    handleOpenOnMap: (coords: [number, number]) => void;
}

export const UserIventItem: FC<Props> = ({
    media,
    whStars,
    event,
    handleReOrder,
    handleDeclineEvent,
    handleRate,
    handleEditOrder,
    handleOpenOnMap,
}) => {
    const isRated = useMemo(
        () =>
            event.reviews &&
            !!event.reviews.find((el) => el.type === 'client2salon'),
        [event]
    );

    const currentRate = useMemo(() => {
        if (event.reviews) {
            return event.reviews.find((el) => el.type === 'client2salon')
                ?.estimation;
        }
        return undefined;
    }, []);

    const handleRateClick = useCallback(
        (estimate: number) => {
            if (!isRated) {
                handleRate(event.salon.id, estimate, event.id);
            }
        },
        [isRated]
    );

    const handleOpenMap = useCallback(() => {
        handleOpenOnMap([
            event.salon.longitude as any,
            event.salon.latitude as any,
        ]);
    }, [event]);

    return (
        <div className="appoint">
            {media <= MediaPoint.TABLET && (
                <NavLink to={`salon/${event.salon.id}`} className="appointName">
                    {event.salon.title}
                </NavLink>
            )}
            <div className="appointWrapper act-wrp">
                <div className="salonPhotoStars">
                    <div className="salonPhoto">
                        <div className="photo">
                            <img
                                src={
                                    event.salon.logo
                                        ? `${mediaFileHost}/${event.salon.logo.fileName}`
                                        : undefined
                                }
                                alt={event.salon.title}
                            />
                        </div>
                    </div>
                    <div className="salonStars">
                        <i
                            className={whStars(
                                1,
                                event.salon.rating.toLocaleString()
                            )}
                        >
                            <img src={star} alt="" />
                        </i>
                        <i
                            className={whStars(
                                2,
                                event.salon.rating.toLocaleString()
                            )}
                        >
                            <img src={star} alt="" />
                        </i>
                        <i
                            className={whStars(
                                3,
                                event.salon.rating.toLocaleString()
                            )}
                        >
                            <img src={star} alt="" />
                        </i>
                        <i
                            className={whStars(
                                4,
                                event.salon.rating.toLocaleString()
                            )}
                        >
                            <img src={star} alt="" />
                        </i>
                        <i
                            className={whStars(
                                5,
                                event.salon.rating.toLocaleString()
                            )}
                        >
                            <img src={star} alt="" />
                        </i>
                        <div>{event.salon.rating.toLocaleString()}</div>
                    </div>
                </div>
                <div className="salonData">
                    {media > MediaPoint.TABLET && (
                        <h2 className="name">
                            <NavLink to={`salon/${event.salon.id}`}>
                                {event.salon.title}
                            </NavLink>
                        </h2>
                    )}
                    <div className="dateInPast">
                        <span className="label">
                            {event.status === IEventStatus.DONE ||
                            event.status === IEventStatus.WAITING_FEEDBACK
                                ? 'Вы были: '
                                : 'Вы записаны: '}
                        </span>
                        <span className="date">
                            {moment(event.dateStart).format('D MMMM YYYY')} в{' '}
                            {moment(event.dateStart).format('HH:mm')}
                        </span>
                    </div>
                    <div className="service">
                        <span className="label">Процедура: </span>
                        <span className="date">
                            {event.service
                                .map((el) => el.service?.title)
                                .join(' + ')}
                        </span>
                    </div>
                    <div className="address">
                        <span className="label">Адрес: </span>
                        <span className="date">{event.salon.address}</span>
                        {media > MediaPoint.TABLET && (
                            <Button
                                size="small"
                                className="toMap green"
                                onClick={handleOpenMap}
                            >
                                Посмотреть на карте
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <div className="actions app-act">
                {media <= MediaPoint.TABLET && (
                    <Button
                        size="large"
                        className="appoReappo green pr-act-btn"
                        onClick={handleOpenMap}
                    >
                        Посмотреть на карте
                    </Button>
                )}
                <Button
                    size="large"
                    className="appoReappo orange pr-act-btn"
                    onClick={() => {
                        handleEditOrder(event);
                    }}
                    disabled={
                        event.status === IEventStatus.DONE ||
                        event.status === IEventStatus.WAITING_FEEDBACK
                    }
                >
                    Изменить запись
                </Button>
                <Button
                    size="large"
                    className="appoCancel orange pr-act-btn"
                    disabled={
                        event.status === IEventStatus.DONE ||
                        event.status === IEventStatus.WAITING_FEEDBACK
                    }
                    onClick={() => {
                        handleDeclineEvent(event.id);
                    }}
                >
                    Отменить запись
                </Button>
                <Button
                    size="large"
                    className="appoRank pr-act-rate orange pr-act-btn"
                    disabled={
                        (event.status !== IEventStatus.DONE &&
                            event.status !== IEventStatus.WAITING_FEEDBACK) ||
                        isRated
                    }
                >
                    Оценить{' '}
                    <Rate
                        className={`pr-act-rate-comp${isRated ? ' rated' : ''}`}
                        onChange={handleRateClick}
                        defaultValue={currentRate}
                        disabled={
                            (event.status !== IEventStatus.DONE &&
                                event.status !==
                                    IEventStatus.WAITING_FEEDBACK) ||
                            isRated
                        }
                    />
                </Button>
                <Button
                    size="large"
                    className="appoComment orange pr-act-btn"
                    disabled={
                        event.status !== IEventStatus.DONE &&
                        event.status !== IEventStatus.WAITING_FEEDBACK
                    }
                >
                    Оставить комментарий
                </Button>
                <Button
                    size="large"
                    className="appoNewAppo orange pr-act-btn"
                    onClick={() => {
                        handleReOrder(event);
                    }}
                    disabled={
                        event.status !== IEventStatus.DONE &&
                        event.status !== IEventStatus.WAITING_FEEDBACK
                    }
                >
                    Записаться снова
                </Button>
            </div>
        </div>
    );
};
