import {
    Button,
    Layout,
    Upload,
    Select,
    DatePicker,
    message,
    Empty,
} from 'antd';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PageHead, BanrHead, SideMen2, PageFoot } from '../part/PageParts';
import {
    ContactsOutlined,
    CustomerServiceOutlined,
    EditFilled,
    EyeFilled,
    EyeInvisibleFilled,
    LoadingOutlined,
} from '@ant-design/icons';
import star from '../../img/star5pointsRed.png';

import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import './profile.scss';
import locale from 'antd/es/locale/ru_RU';
import moment from 'moment';
import { apiBaseMedia, apiBase, apiFileUp, avatarDefa } from '../Const.js';
import { MediaPoint, useMediaContext } from '../../Services/Media.tsx';
import { SalonScheduleAPI } from '../../api/schedule/index.ts';
import { UserIventItem } from '../../Components/Cabinet/User/UserIventItem.tsx';
import { IEventStatus } from '../../Types/ScheduleTypes.ts';
import { AddScheduleModal } from '../../Modals/AddScheduleModal.tsx';
import { useTimeContext } from '../../Services/Time.tsx';
import { ScheduleType } from '../../Modals/AddScheduleModalForm.tsx';
import { SalonOnMapModal } from '../../Modals/SalonOnMapModal.tsx';
import { useFormErrorsHandling } from './form-errors-handling.ts';

function Profile({ match, location: loc }) {
    const { utcWithDiff } = useTimeContext();
    const [isAppointsLoading, setAppointsLoading] = useState(false);
    const [isAppointsLoaded, setAppointsLoaded] = useState(false);
    const [isAppointsError, setIsAppointsError] = useState(false);
    const [appointsList, setAppointsList] = useState([]);

    const handleSetNeedLoading = useCallback(() => {
        setAppointsLoaded(false);
    }, []);

    const handleLoadAppoints = useCallback(async () => {
        try {
            setAppointsLoading(true);
            const response = await SalonScheduleAPI.getByProfile();

            setAppointsLoaded(true);
            setAppointsList(() => response.data);
            setAppointsLoading(() => false);
        } catch (e) {
            setAppointsLoading(false);
            setIsAppointsError(true);
            void message.error('Ошибка при загрузке списка записей');
        }
    }, []);

    const { media } = useMediaContext();
    const {
            params: { userId },
        } = match,
        hashes = ['profile', 'active', 'closed', 'bonus', 'support'],
        tabs =
            media <= MediaPoint.TABLET
                ? [
                      <>Профиль</>,
                      (appoActive) => (
                          <>
                              <span>Активные</span>
                              {appoActive ? (
                                  <div className="appoActive">{appoActive}</div>
                              ) : null}
                          </>
                      ),
                      <>Завершённые</>,
                      <>Бонусы</>,
                  ]
                : [
                      <>
                          <ContactsOutlined /> Профиль
                      </>,
                      (appoActive) => (
                          <>
                              <span>Активные записи</span>
                              {appoActive ? (
                                  <div className="appoActive">{appoActive}</div>
                              ) : null}
                          </>
                      ),
                      <>Завершённые записи</>,
                      <>Бонусы</>,
                      <>
                          <CustomerServiceOutlined /> Техподдержка
                      </>,
                  ],
        locHash =
            hashes[(hashes.indexOf(loc.hash.replace(/^#/, '')) + 1 || 1) - 1];
    const $stor = useSelector((state) => state),
        dispatch = useDispatch(),
        testStatusEdit = 0, //TEST - проверять, как при наличии P.isEdit
        hist = useHistory();

    const clickMenuHoriz = (ev) => {
            // дубликатор меню и табов
            let t0 = ev.currentTarget,
                t = ev.target;
            if (t.tagName !== 'LI') t = t.parentNode;
            if (t.tagName !== 'LI') t = t.parentNode;
            Array.from(t.parentNode.children).forEach((el) =>
                el.classList.remove('active')
            );
            t.classList.add('active');
            const tabsIn = document.querySelector('.bfCabinet.tabs .stackIn');
            Array.from(tabsIn.children).forEach((el) =>
                el?.classList.remove('active')
            );
            tabsIn
                ?.querySelector(`.tab${t.getAttribute('data-tab')}`)
                ?.classList.add('active');
        }, // TODO из БД - число активных записей клиента
        [P, setProf] = useState($stor?.meProfile || {}), // профиль из /client/:id
        clieStars =
            P?.rating || Math.max(0, Math.min(5, Math.random() * 5)).toFixed(1), // ===== конст. РЕЙТНИГ клиента =====; округление до 0.1
        salonStars = Math.max(0, Math.min(5, Math.random() * 5)).toFixed(1), // ===== ТЕСТ. РЕЙТНИГ салона =====;
        whStars = (stN, rat = clieStars) => {
            // whiteStars - рейтинг на 5 звёзд и 50 градаций
            const st01 = Math.max(0, Math.min(5, +(+rat).toFixed(1))),
                stFlor = Math.floor(st01);
            return (
                (stFlor < stN ? 'wh' : '') +
                (stN !== stFlor + 1
                    ? ''
                    : ' wh' + Math.floor(10 * (st01 - stFlor) + 0.00001))
            );
        };
    const fields = useMemo(
        () => ({
            firstName: {
                value: P.firstName,
            },
            lastName: {
                value: P.lastName,
            },
            patronymic: {
                value: P.patronymic,
            },
            city: {
                value: P.city,
            },
            email: {
                value: P.email,
            },
            birthDate: {
                value: P.birthDate,
            },
            king: {
                value: P.king,
            },
        }),
        [P]
    );

    const rules = useMemo(
        () => ({
            firstName: {
                required: true,
                message: 'Поле обязательно для заполнения',
            },
            lastName: {
                required: true,
                message: 'Поле обязательно для заполнения',
            },
            patronymic: {
                required: true,
                message: 'Поле обязательно для заполнения',
            },
            city: {
                required: true,
                message: 'Поле обязательно для заполнения',
            },
            email: {
                required: true,
                message: 'Поле обязательно для заполнения',
            },
            king: {
                required: true,
                message: 'Поле обязательно для заполнения',
            },
        }),
        []
    );

    const hookDataProps = useMemo(
        () => ({ fields: fields, rules: rules }),
        [rules, fields]
    );

    const { errors } = useFormErrorsHandling(hookDataProps);
    const [paswA, setPaswA] = useState([0, 0, 0, 0]), // видимости паролей
        [pasw1, setPasw1] = useState(false), // набранные тексты в паролях
        [pasw2, setPasw2] = useState(false),
        [pasw3, setPasw3] = useState(false);
    const chgPasw = (ev) => {
        const t0 = ev.currentTarget,
            t1 = ev.target,
            t = t0.querySelector('input'),
            paswI = +t.getAttribute('data-pasw'),
            pasw =
                paswI === 1 ? !paswA[1] : paswI === 2 ? !paswA[2] : !paswA[3];
        if (
            !t1.classList.contains('anticon') &&
            t1.tagName !== 'svg' &&
            t1.tagName !== 'path'
        )
            return;
        //paswA[paswI] = 1 - paswA[paswI];
        t.type = !pasw ? 'password' : 'text';
        //setPaswA(paswA);
        paswI === 1 && setPaswA([0, 1 - paswA[1], paswA[2], paswA[3]]);
        paswI === 2 && setPaswA([0, paswA[1], 1 - paswA[2], paswA[3]]);
        paswI === 3 && setPaswA([0, paswA[1], paswA[2], 1 - paswA[3]]);
    };
    const [nextSaveDisabled, setNextSaveDisabled] = useState(0);
    const hellip = '…',
        loggedNDefa = <>Как Вас зовут?</>; // как отображается имя в шапке в первый момент

    const saveProfile = (ev) => {
        if (
            '7' + $stor.auth?.userLogin === $stor.meProfile?.phone &&
            Object.keys(errors).length === 0
        )
            // если профиль не свой - отправки нет, хотя и так задизейблена
            // ======= отправка данных в Профиль для изменения допустимых полей (определяется testStatusEdit или P.isEdit) =======
            axios
                .patch(
                    apiBase + (0 && userId ? 'client' + userId : 'account'),
                    {
                        firstName: P.firstName || '', // 'Имя',
                        lastName: P.lastName || '', // 'Фамилия_из_19_букв_',
                        patronymic: P.patronymic || '', //'Отч-во',
                        //    phone: P?.phone ||'',
                        city: P.city || '', // 'city001',
                        email: P.email || '', //'string@somemail.cc',
                        birthDate: P.birthDate || '0-1-1 0:0:0', // это - Sat Jan 01 2000 00:00:00,
                        //    rating: P?.rating ||5, // число
                        //avatar: P?.avatar ||'', // 'string', // TODO на беке - не меняется
                        //    emailStatus: P?.emailStatus ||'', //[ Подтвержден, Не подтвержден ]
                        king: P.king || 'FEMALE', // [ MALE, FEMALE ]
                        //    phoneStatus: P?.phoneStatus ||'Не подтвержден', //[ Подтвержден, Не подтвержден ]
                        //    role: P?.role ||'client', // [ customer, client, admin, master ]
                        //    status: P?.status ||'Отключенный', // [ Активные, Отключенный ]
                        avatarId: P.avatarId || P?.avatar?.id || 0, // новый аввтар || прежний || по умолч. с корректной сущностью 0
                        isEdit: P?.isEdit,
                    },
                    {
                        headers: {
                            accept: '*/*',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${$stor.auth?.accessToken}`,
                        },
                    }
                )
                .then((res) => {
                    //debugger;
                    if (
                        $stor?.auth?.firstNameOld !== res?.data?.firstName ||
                        $stor?.auth?.lastName !== res?.data?.lastName
                    ) {
                        let lN = `${res?.data?.firstName || ''} ${
                            res?.data?.lastName || ''
                        }`;
                        if (lN.length > 23) lN = lN.substr(0, 21) + hellip; // Ограничение длиной 22
                        if (lN.length < 2)
                            lN = $stor?.auth.userLogin || loggedNDefa;
                        dispatch({
                            type: 'AUTH-LOGIN',
                            auth: Object.assign(
                                {},
                                $stor?.auth || {},
                                {
                                    loggedName: lN,
                                    firstNameOld: res.data.firstName,
                                    lastNameOld: res.data.lastName,
                                },
                                P?.salons?.[0]?.id
                                    ? {
                                          salonCustomer: P.salons[0].id, // UUID привязанного к кастомеру салона
                                      }
                                    : {}
                            ),
                        });
                        // Если изменилось Имя или фамилия - подправить стор для хедера (в логине)
                        const d = document.querySelector('.header .nickname');
                        if (d.innerHTML) d.innerHTML = lN;
                    }
                    dispatch({
                        type: 'ME-PROFILE',
                        meProfile: res.data,
                    });
                    setProf(res.data);
                    setNextSaveDisabled(1); // временно выключим кнопку отправки (при новых правках полей - включить)
                    void message.success('Информация изменена');
                })
                .catch((e) => {
                    void message.error('Ошибка при изменении информации');
                });
        // ========= Независимо, по saveProfile изменяем пароль, если условия в полях это обеспечат ================
        if (pasw1 && pasw2 && pasw3 && pasw2 === pasw3)
            axios
                .post(
                    `${apiBase}auth/password/change-by-old-password`,
                    { oldPassword: pasw1, newPassword: pasw2 },
                    {
                        headers: {
                            accept: '*/*',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${$stor.auth?.accessToken}`,
                        },
                    }
                )
                .then((res) => {
                    setNextSaveDisabled(1);
                    setPasw3(''); // ?? надо ли стирать?
                    void message.success('Пароль изменен');
                })
                .catch((e) => {
                    void message.error('Ошибка при изменении пароля');
                }); // и по ошибке
    };
    const setCity = (ev) => {
            setProf({ ...P, ...{ city: ev.target?.value || '' } });
            setNextSaveDisabled(0);
        },
        setFirstName = (ev) => {
            setProf({ ...P, ...{ firstName: ev.target?.value || '' } });
            setNextSaveDisabled(0);
        },
        setLastName = (ev) => {
            setProf({ ...P, ...{ lastName: ev.target?.value || '' } });
            setNextSaveDisabled(0);
        },
        setPatronymic = (ev) => {
            setProf({ ...P, ...{ patronymic: ev.target?.value || '' } });
            setNextSaveDisabled(0);
        },
        setEmail = (ev) => {
            setProf({ ...P, ...{ email: ev.target?.value || '' } });
            setNextSaveDisabled(0);
        },
        setBirthday = (ev) => {
            setProf({
                ...P,
                ...{ birthDate: ev?.target?.value || ev?._d || '' },
            });
            setNextSaveDisabled(0);
        },
        setKing = (ev) => {
            setProf({ ...P, ...{ king: ev.target?.value || ev || '' } });
            setNextSaveDisabled(0);
        },
        chgPasw1 = (ev) => {
            setPasw1(ev.target?.value || '');
            setNextSaveDisabled(0);
        },
        chgPasw2 = (ev) => {
            setPasw2(ev.target?.value || '');
            setNextSaveDisabled(0);
        },
        chgPasw3 = (ev) => {
            setPasw3(ev.target?.value || '');
            setNextSaveDisabled(0);
        };
    //const uploadAction = {
    //    name: P?.avatar?.fileName || avatarDefa, // не исп-ся
    //    status: 'done',
    //    url: apiBaseMedia + (P?.avatar?.fileName || avatarDefa),
    //    thumbUrl: apiBaseMedia + (P?.avatar?.fileName || avatarDefa), // не исп-ся
    //};
    const [fileList, setFileList] = useState([
            // фото (avatar) юзера - история загруженных вариантов
            {
                uid: '-1',
                name: P?.avatar?.fileName || avatarDefa,
                status: 'done',
                url: apiBaseMedia + (P?.avatar?.fileName || avatarDefa),
            },
        ]),
        [waitNewAvatar, setWaitNewAvatar] = useState(-1);
    useEffect(
        (x) => {
            if (waitNewAvatar && waitNewAvatar === P?.avatarId) {
                saveProfile(); // TODO сброс ожидания аватара - в коллбек сейва!
                setWaitNewAvatar(-1);
            }
        },
        [waitNewAvatar]
    );
    const onChangeAvatar = ({ fileList: newFileList }) => {
        if (P?.avatar !== undefined) setFileList(newFileList);
        //debugger;
        if (newFileList?.[newFileList.length - 1]?.response?.id) {
            // отправляем ид аватара на изменение профиля
            const meProfile = {
                ...P,
                avatarId: newFileList[newFileList.length - 1].response.id,
            };
            dispatch({
                type: 'ME-PROFILE',
                meProfile,
                // TODO профиль может быть чужой (при чтении по id)
            });
            //setProf(meProfile, saveProfile); // для компонента - сохранён профиль, передёрнуты данные
            //Но!! index.js:1 Warning: State updates from the useState() and useReducer() Hooks don't support the second
            //   callback argument. To execute a side effect after rendering, declare it in the component body with useEffect()
            //   - не срабатывает коллбек по https://stackoverflow.com/questions/54954091/how-to-use-callback-with-usestate-hook-in-react
            setProf(meProfile); // и затем отслеживаем разницу avatarId старого и нового
            setWaitNewAvatar(meProfile.avatarId);
        }
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new window.Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    // 0&& -- пока что выбираем всегда auth/me, чтобы отображались аватары и фото
    // client/:id нужен, чтобы админом и другими просмотреть профиль юзера (и там не будет аватара)
    const urlProfile =
            apiBase +
            (0 && userId && $stor?.auth?.role !== 'client'
                ? `client/${userId}`
                : 'auth/me'), //TODO && role !=='client'
        [meOnce, setMeOnce] = useState(0), // ======= получение профиля из Ajax =======
        getProfile = (x) => {
            // TODO Auth
            axios
                .get(urlProfile, {
                    headers: {
                        // читаем профиль
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${$stor.auth?.accessToken}`,
                    },
                })
                .then((res) => {
                    //debugger;
                    dispatch({
                        type: 'ME-PROFILE',
                        meProfile: res.data, // TODO профиль может быть чужой (при чтении по id)
                    });
                    setProf(res.data); // для компонента - сохранён профиль, передёрнуты данные
                    if (P.id)
                        // формально - показываем id в адресной строке
                        dispatch({
                            // сохраняем привязку салона
                            type: 'AUTH-LOGIN',
                            auth: {
                                ...($stor?.auth || {}),
                                ...(res?.data?.salons?.[0]?.id
                                    ? {
                                          salonCustomer: res.data.salons[0].id, // UUID привязанного к кастомеру салона
                                      }
                                    : {}),
                            },
                        });
                    // массив из 1 последнего аватара или пустой массив
                    const avaMedia =
                        res?.data?.media &&
                        res.data.media.reduce(
                            (s, el) => (el.mediaType === 'avatar' ? [el] : s),
                            []
                        );
                    setFileList(
                        avaMedia?.length
                            ? avaMedia.map((el) => {
                                  return {
                                      uid: el.fileName + el.id,
                                      name: el.fileName,
                                      status: 'done',
                                      url: apiBaseMedia + el.fileName,
                                  };
                              })
                            : []
                    );
                });
        };
    useEffect(() => {
        if (!meOnce && $stor?.auth?.userLogin) {
            setMeOnce(1);
            getProfile();
        }
        $stor.meProfile &&
            ((s) => {
                //console.log(s.meProfile, s);
            })($stor);
    });

    const starsBlock = useMemo(() => {
        return (
            <div className="userStars">
                <i className={whStars(1)}>
                    <img src={star} alt="" />
                </i>
                <i className={whStars(2)}>
                    <img src={star} alt="" />
                </i>
                <i className={whStars(3)}>
                    <img src={star} alt="" />
                </i>
                <i className={whStars(4)}>
                    <img src={star} alt="" />
                </i>
                <i className={whStars(5)}>
                    <img src={star} alt="" />
                </i>
                <div>{P?.rating ?? clieStars}</div>
            </div>
        );
    }, [whStars, clieStars, P]);

    const userProfileAvatar = useMemo(
        () => (
            <div className="userPhotoStars">
                <div className="userPhoto">
                    <div className="photo">
                        <img
                            src={
                                apiBaseMedia +
                                (P?.avatar?.fileName || avatarDefa)
                            }
                            alt=""
                        />
                    </div>
                    <div
                        className="editOver"
                        title="Загрузить новое фото"
                        onClick={(ev) =>
                            document
                                .querySelector(
                                    '.ant-upload-select-picture-card >.ant-upload'
                                )
                                ?.click()
                        }
                    >
                        <EditFilled className="edit" />
                    </div>
                </div>
                {media >= MediaPoint.DESkTOP ? starsBlock : null}
                <ImgCrop
                    rotate
                    quality="0.6"
                    minZoom={0.5}
                    maxZoom="5"
                    shape="round"
                    grid
                    modalTitle="Редактировать изображение"
                    modalWidth="640px"
                    modalOk="Отправить"
                    modalCancel="Отмена"
                    modalClassName="pictureEdit"
                    cropperProps={{ zoomSpeed: 0.05 }}
                >
                    <Upload
                        action={apiFileUp}
                        listType="picture-card"
                        headers={{
                            Authorization: `Bearer ${$stor.auth?.accessToken}`,
                        }}
                        data={{ mediaType: 'avatar' }}
                        fileList={fileList}
                        accept="image/png, image/jpeg"
                        onChange={(pr) => {
                            if (pr.file.status === 'done') {
                                void message.success('Аватар загружен');
                            } else if (pr.file.status === 'error') {
                                void message.error('Ошибка загрузки аватара');
                            }
                            onChangeAvatar(pr);
                        }}
                        onPreview={onPreview}
                    >
                        Загрузить новое фото
                    </Upload>
                </ImgCrop>
            </div>
        ),
        [
            P,
            avatarDefa,
            onChangeAvatar,
            onPreview,
            fileList,
            $stor,
            media,
            starsBlock,
        ]
    );

    useEffect(() => {
        if (!isAppointsLoading && !isAppointsLoaded && !isAppointsError) {
            void handleLoadAppoints();
        }
    }, [isAppointsLoading, isAppointsLoaded, isAppointsError]);

    const [addShModalOpen, setAddShModalOpen] = useState(false);
    const [modalPrevState, setModalPrevState] = useState(undefined);
    const [selectedSalon, setSelectedSalon] = useState(undefined);

    const handleCloseSelectedModal = useCallback(() => {
        setAddShModalOpen(false);
    }, []);

    const clearPrevState = useCallback(() => {
        setModalPrevState(undefined);
    }, []);

    const clientId = useMemo(() => {
        const token = JSON.parse(localStorage.getItem('auth'))?.accessToken;

        if (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(
                window
                    .atob(base64)
                    .split('')
                    .map(function (c) {
                        return (
                            '%' +
                            ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                        );
                    })
                    .join('')
            );

            return JSON.parse(jsonPayload).id;
        } else {
            return undefined;
        }
    }, []);

    const handleReOrder = useCallback(
        (event) => {
            setSelectedSalon(event.salon);
            setModalPrevState({
                type: ScheduleType.schedule,
                employer: event.employer.id,
                servicesIds: event.service.map((el) => el.id),
                clientId: clientId,
            });
            setAddShModalOpen(true);
        },
        [clientId]
    );

    const handleEditOrder = useCallback(
        (event) => {
            setSelectedSalon(event.salon);
            setModalPrevState({
                type: ScheduleType.schedule,
                employer: event.employer.id,
                servicesIds: event.service.map((el) => el.id),
                clientId: clientId,
                existTimeSlotId: event.id,
                dateStart: moment(event.dateStart),
            });
            setAddShModalOpen(true);
        },
        [clientId]
    );

    const handleDeclineEvent = useCallback(async (eventId) => {
        const loading = message.loading('Отмена записи...', 0);

        try {
            await SalonScheduleAPI.editSchedule(
                {
                    status: IEventStatus.CANCELED,
                },
                eventId
            );
            loading();
            void message.success('Запись отменена');
            handleSetNeedLoading();
        } catch (e) {
            loading();
            void message.error('Ошибка при удалении');
        }
    }, []);

    const handleRate = useCallback(
        async (salonId, estimate, scheduleId) => {
            const loading = message.loading('Оценка...', 0);

            try {
                await SalonScheduleAPI.addReview({
                    type: 'client2salon',
                    clientId: clientId,
                    salonId: salonId,
                    estimation: estimate,
                    scheduleId: scheduleId,
                });
                loading();
                void message.success('Оценка добавлена');
                handleSetNeedLoading();
            } catch (e) {
                loading();
                void message.error('Ошибка при добавлении оценки');
            }
        },
        [clientId]
    );

    const [salonOnMapCoords, setSalonOnMapCoords] = useState(undefined);

    const [salonOnMapOpen, setSalonOnMapOpen] = useState(false);

    const handleOpenOnMap = useCallback((salonCoords) => {
        setSalonOnMapCoords(() => salonCoords);
        setSalonOnMapOpen(true);
    }, []);

    return (
        <>
            <Layout className="profile">
                <PageHead />
                <SideMen2 />
                <div className="layCont">
                    <BanrHead hide={process.env.REACT_APP_VISUAL_FOR_DEV} />
                    {media >= MediaPoint.DESkTOP && (
                        <div className="stackBlock bfCabinet">
                            <div className="stackIn bfCabinet">
                                <h2>
                                    Личный кабинет{' '}
                                    <span className="pale">
                                        {0 && 'TEST' && userId
                                            ? ' : ' + userId
                                            : ''}
                                    </span>
                                </h2>
                                <div className="srchDescr">
                                    Есть много вариантов личных кабинетов, но
                                    большинство из них недоступны для посещения
                                    неавторизованными пользователями.
                                </div>
                            </div>
                        </div>
                    )}
                    <div
                        className="stackBlock bfCabinet bacVw100"
                        style={{
                            marginTop:
                                media <= MediaPoint.TABLET ? '-13px' : '',
                        }}
                    >
                        <div className="stackIn bfCabinet">
                            <div className="menuHorizOver">
                                <ul
                                    className="menuHoriz"
                                    onClick={clickMenuHoriz}
                                >
                                    {tabs.map((el, i) => {
                                        return (
                                            <li
                                                data-tab={i + 1}
                                                key={'menuHor' + i}
                                                className={
                                                    hashes.indexOf(locHash) ===
                                                    i
                                                        ? 'active'
                                                        : ''
                                                }
                                            >
                                                <div>
                                                    {typeof tabs[i] ===
                                                    'function'
                                                        ? tabs[i](
                                                              appointsList.filter(
                                                                  (el) =>
                                                                      el.status !==
                                                                          IEventStatus.WAITING_FEEDBACK &&
                                                                      el.status !==
                                                                          IEventStatus.DONE &&
                                                                      el.status !==
                                                                          IEventStatus.CANCELED
                                                              ).length ??
                                                                  undefined
                                                          )
                                                        : tabs[i]}
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="stackBlock bfCabinet tabs">
                        <div className="stackIn bfCabinet">
                            <div
                                className={`tab tab1 profile${
                                    hashes.indexOf(locHash) === 0
                                        ? ' active'
                                        : ''
                                }`}
                            >
                                <div className="userAccount">
                                    {media === MediaPoint.DESkTOP
                                        ? userProfileAvatar
                                        : null}
                                    <div className="userData">
                                        <div className="fio">
                                            <div className="email editable">
                                                <div className="label">Имя</div>
                                                <EditFilled className="toRight" />
                                                <input
                                                    className="emailValue"
                                                    value={P?.firstName || ''}
                                                    placeholder="Введите"
                                                    onChange={setFirstName}
                                                />
                                                {errors && errors.firstName && (
                                                    <span className="bs_inp_error_item">
                                                        {errors.firstName}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="email editable">
                                                <div className="label">
                                                    Фамилия
                                                </div>
                                                <EditFilled className="toRight" />
                                                <input
                                                    className="emailValue"
                                                    value={P?.lastName || ''}
                                                    placeholder="Введите"
                                                    onChange={setLastName}
                                                />
                                                {errors && errors.lastName && (
                                                    <span className="bs_inp_error_item">
                                                        {errors.lastName}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="email editable">
                                                <div className="label">
                                                    Отчество
                                                </div>
                                                <EditFilled className="toRight" />
                                                <input
                                                    className="emailValue"
                                                    value={P?.patronymic || ''}
                                                    placeholder="Введите"
                                                    onChange={setPatronymic}
                                                />
                                                {errors &&
                                                    errors.patronymic && (
                                                        <span className="bs_inp_error_item">
                                                            {errors.patronymic}
                                                        </span>
                                                    )}
                                            </div>
                                            {/*<span>*/}
                                            {/*    {(P?.lastName || '') +*/}
                                            {/*        ' ' +*/}
                                            {/*        (P?.firstName || '[Имя]') +*/}
                                            {/*        (' ' +*/}
                                            {/*            (P?.patronymic || ''))}*/}
                                            {/*</span>*/}
                                            {media <= MediaPoint.TABLET && (
                                                <>
                                                    <span>
                                                        {P?.phone?.replace(
                                                            /^7(\d{3})(\d{3})(\d{2})(\d{2})/,
                                                            '8-$1-$2-$3-$4'
                                                        ) || ''}
                                                    </span>
                                                    {starsBlock}
                                                </>
                                            )}
                                        </div>
                                        {media <= MediaPoint.TABLET
                                            ? userProfileAvatar
                                            : null}
                                        {media <= MediaPoint.TABLET ? null : (
                                            <div className="city editable">
                                                <div className="label">
                                                    Город
                                                </div>
                                                <EditFilled className="toRight" />
                                                <input
                                                    className="cityValue"
                                                    placeholder="[Город]"
                                                    value={P?.city || ''}
                                                    onChange={setCity}
                                                />
                                                {errors && errors.city && (
                                                    <span className="bs_inp_error_item">
                                                        {errors.city}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                        <div className="rowPersData">
                                            {media <= MediaPoint.TABLET ? (
                                                <div className="city editable">
                                                    <div className="label">
                                                        Город
                                                    </div>
                                                    <EditFilled className="toRight" />
                                                    <input
                                                        className="cityValue"
                                                        placeholder="[Город]"
                                                        value={P?.city || ''}
                                                        onChange={setCity}
                                                    />
                                                    {errors && errors.city && (
                                                        <span className="bs_inp_error_item">
                                                            {errors.city}
                                                        </span>
                                                    )}
                                                </div>
                                            ) : null}
                                            <div className="sex editable">
                                                <div className="label">Пол</div>
                                                {testStatusEdit || P?.isEdit ? (
                                                    <>
                                                        <EditFilled className="toRight" />
                                                        {/*<><input className="sexValue" value={P?.king ||''} placeholder="(не указан)" onChange={setKing}/></>*/}
                                                        <Select
                                                            popupClassName="srchCityOptions"
                                                            value={
                                                                P?.king || ''
                                                            }
                                                            onChange={setKing}
                                                            options={[
                                                                {
                                                                    value: 'MALE',
                                                                    label: '(не указан)',
                                                                },
                                                                {
                                                                    value: 'FEMALE',
                                                                    label: 'Женский',
                                                                },
                                                                {
                                                                    value: 'MALE',
                                                                    label: 'Мужской',
                                                                },
                                                            ]}
                                                        />
                                                    </>
                                                ) : (
                                                    <div className="value">
                                                        {(P?.king &&
                                                            {
                                                                MALE: 'Мужской',
                                                                FEMALE: 'Женский',
                                                            }[P.king]) ||
                                                            ''}
                                                    </div>
                                                )}
                                                {errors && errors.king && (
                                                    <span className="bs_inp_error_item">
                                                        {errors.king}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="birthday editable">
                                                <div className="label">
                                                    День рождения
                                                </div>
                                                {testStatusEdit || P?.isEdit ? (
                                                    <>
                                                        <EditFilled className="toRight" />
                                                        {/*<input className="birthdayValue" value={moment(P?.birthDate).local().format('yyyy-MM-DD') ||''} placeholder="(не указан)" onChange={setBirthday}/>*/}
                                                        <DatePicker
                                                            placement="bottomRight"
                                                            className="srchDateSelect"
                                                            placeholder="ГГГГ-ММ-ДД"
                                                            bordered={'false'}
                                                            clearText="Очистить"
                                                            locale={locale}
                                                            value={
                                                                P?.birthDate
                                                                    ? moment(
                                                                          P?.birthDate
                                                                      ).local()
                                                                    : undefined
                                                            }
                                                            onChange={
                                                                setBirthday
                                                            }
                                                        />
                                                    </>
                                                ) : (
                                                    <div className="value">
                                                        {(P?.birthDate &&
                                                            new Date(
                                                                P.birthDate
                                                            ).toLocaleDateString()) ||
                                                            ''}
                                                    </div>
                                                )}
                                                {errors && errors.birthDate && (
                                                    <span className="bs_inp_error_item">
                                                        {errors.birthDate}
                                                    </span>
                                                )}
                                            </div>
                                            {media >= MediaPoint.DESkTOP && (
                                                <div className="phone">
                                                    <div className="label">
                                                        Телефон
                                                    </div>
                                                    <div className="value">
                                                        {P?.phone?.replace(
                                                            /^7(\d{3})(\d{3})(\d{2})(\d{2})/,
                                                            '+7($1) $2-$3-$4'
                                                        ) || ''}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="email editable">
                                                <div className="label">
                                                    E-mail
                                                </div>
                                                <EditFilled className="toRight" />
                                                <input
                                                    className="emailValue"
                                                    value={P?.email || ''}
                                                    placeholder="(не указан)"
                                                    onChange={setEmail}
                                                />
                                                {errors && errors.email && (
                                                    <span className="bs_inp_error_item">
                                                        {errors.email}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="paswTitle">
                                            Пароль{' '}
                                            <input
                                                type="text"
                                                className="dummy-prev-pasw"
                                                size="1"
                                            />{' '}
                                            <input
                                                type="password"
                                                className="dummy-prev-pasw"
                                                size="1"
                                            />
                                        </div>
                                        <div className="rowPasw">
                                            <div
                                                className="pasw edited defense"
                                                onClick={chgPasw}
                                            >
                                                <div className="label">
                                                    Старый пароль
                                                </div>
                                                {paswA[1] ? (
                                                    <EyeFilled className="toRight" />
                                                ) : (
                                                    <EyeInvisibleFilled className="toRight" />
                                                )}
                                                <input
                                                    type="password"
                                                    value={pasw1 || ''}
                                                    data-pasw="1"
                                                    onChange={chgPasw1}
                                                />
                                            </div>
                                            <div
                                                className="paswNew edited defense"
                                                onClick={chgPasw}
                                            >
                                                <div className="label">
                                                    Новый пароль
                                                </div>
                                                {paswA[2] ? (
                                                    <EyeFilled className="toRight" />
                                                ) : (
                                                    <EyeInvisibleFilled className="toRight" />
                                                )}
                                                <input
                                                    type="password"
                                                    value={pasw2 || ''}
                                                    data-pasw="2"
                                                    onChange={chgPasw2}
                                                />
                                            </div>
                                            <div
                                                className="paswNewRepeat edited defense"
                                                onClick={chgPasw}
                                            >
                                                <div className="label">
                                                    Новый пароль ещё раз
                                                </div>
                                                {paswA[3] ? (
                                                    <EyeFilled className="toRight" />
                                                ) : (
                                                    <EyeInvisibleFilled className="toRight" />
                                                )}
                                                <input
                                                    type="password"
                                                    value={pasw3 || ''}
                                                    data-pasw="3"
                                                    onChange={chgPasw3}
                                                />
                                            </div>
                                            {media !== MediaPoint.MOBILE && (
                                                <Button
                                                    size="large"
                                                    className="orange"
                                                    onClick={saveProfile}
                                                    disabled={
                                                        nextSaveDisabled ||
                                                        '7' +
                                                            $stor.auth
                                                                ?.userLogin !==
                                                            $stor.meProfile
                                                                ?.phone ||
                                                        Object.keys(errors)
                                                            .length !== 0
                                                    }
                                                >
                                                    Сохранить
                                                </Button>
                                            )}
                                        </div>
                                        {media !== MediaPoint.MOBILE ? (
                                            <div className="editNote">
                                                Примечание: информация будет
                                                изменена после проверки вашим
                                                менеджером.
                                            </div>
                                        ) : (
                                            <>
                                                <div className="editNote">
                                                    Примечание: информация будет
                                                    изменена после проверки
                                                    вашим менеджером.
                                                </div>
                                                <Button
                                                    size="large"
                                                    className="orange"
                                                    onClick={saveProfile}
                                                    disabled={
                                                        nextSaveDisabled ||
                                                        '7' +
                                                            $stor.auth
                                                                ?.userLogin !==
                                                            $stor.meProfile
                                                                ?.phone ||
                                                        Object.keys(errors)
                                                            .length !== 0
                                                    }
                                                >
                                                    Сохранить
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="fillSpaceVert" />
                            </div>
                            <div
                                className={`tab tab2 appointActual${
                                    hashes.indexOf(locHash) === 1
                                        ? ' active'
                                        : ''
                                }`}
                            >
                                {media === MediaPoint.DESkTOP && (
                                    <div className="userAccount brief">
                                        <div className="userPhotoStars">
                                            <div className="userPhoto">
                                                <div className="photo">
                                                    <img
                                                        src={
                                                            apiBaseMedia +
                                                            (P?.avatar
                                                                ?.fileName ||
                                                                avatarDefa)
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="userStars">
                                                <i className={whStars(1)}>
                                                    <img src={star} alt="" />
                                                </i>
                                                <i className={whStars(2)}>
                                                    <img src={star} alt="" />
                                                </i>
                                                <i className={whStars(3)}>
                                                    <img src={star} alt="" />
                                                </i>
                                                <i className={whStars(4)}>
                                                    <img src={star} alt="" />
                                                </i>
                                                <i className={whStars(5)}>
                                                    <img src={star} alt="" />
                                                </i>
                                                <div>
                                                    {P?.rating ?? clieStars}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="userData">
                                            <div className="fio">
                                                {(P?.lastName || '') +
                                                    ' ' +
                                                    (P?.firstName || '[Имя]') +
                                                    (' ' +
                                                        (P?.patronymic || ''))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={`appointListActive${
                                        isAppointsLoading ? ' loading' : ''
                                    }`}
                                >
                                    {isAppointsLoading && <LoadingOutlined />}
                                    {isAppointsLoaded &&
                                    appointsList.filter(
                                        (el) =>
                                            el.status !==
                                                IEventStatus.WAITING_FEEDBACK &&
                                            el.status !== IEventStatus.DONE &&
                                            el.status !== IEventStatus.CANCELED
                                    ).length > 0 ? (
                                        <>
                                            {appointsList
                                                .filter(
                                                    (el) =>
                                                        el.status !==
                                                            IEventStatus.WAITING_FEEDBACK &&
                                                        el.status !==
                                                            IEventStatus.DONE &&
                                                        el.status !==
                                                            IEventStatus.CANCELED
                                                )
                                                .map((el) => (
                                                    <UserIventItem
                                                        media={media}
                                                        salonStars={salonStars}
                                                        whStars={whStars}
                                                        event={el}
                                                        key={el.id}
                                                        handleReOrder={
                                                            handleReOrder
                                                        }
                                                        handleEditOrder={
                                                            handleEditOrder
                                                        }
                                                        handleDeclineEvent={
                                                            handleDeclineEvent
                                                        }
                                                        handleRate={handleRate}
                                                        handleOpenOnMap={
                                                            handleOpenOnMap
                                                        }
                                                    />
                                                ))}
                                        </>
                                    ) : (
                                        <>
                                            {!isAppointsLoading && (
                                                <Empty
                                                    description={
                                                        'Записи отсутствуют'
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`tab tab3 appointActual${
                                    hashes.indexOf(locHash) === 2
                                        ? ' active'
                                        : ''
                                }`}
                            >
                                {media === MediaPoint.DESkTOP && (
                                    <div className="userAccount brief">
                                        <div className="userPhotoStars">
                                            <div className="userPhoto">
                                                <div className="photo">
                                                    <img
                                                        src={
                                                            apiBaseMedia +
                                                            (P?.avatar
                                                                ?.fileName ||
                                                                avatarDefa)
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="userStars">
                                                <i className={whStars(1)}>
                                                    <img src={star} alt="" />
                                                </i>
                                                <i className={whStars(2)}>
                                                    <img src={star} alt="" />
                                                </i>
                                                <i className={whStars(3)}>
                                                    <img src={star} alt="" />
                                                </i>
                                                <i className={whStars(4)}>
                                                    <img src={star} alt="" />
                                                </i>
                                                <i className={whStars(5)}>
                                                    <img src={star} alt="" />
                                                </i>
                                                <div>
                                                    {P?.rating ?? clieStars}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="userData">
                                            <div className="fio">
                                                {(P?.lastName || '') +
                                                    ' ' +
                                                    (P?.firstName || '[Имя]') +
                                                    (' ' +
                                                        (P?.patronymic || ''))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={`appointListActive${
                                        isAppointsLoading ? ' loading' : ''
                                    }`}
                                >
                                    {isAppointsLoading && <LoadingOutlined />}
                                    {isAppointsLoaded &&
                                    appointsList.filter(
                                        (el) =>
                                            el.status ===
                                                IEventStatus.WAITING_FEEDBACK ||
                                            el.status === IEventStatus.DONE
                                    ).length > 0 ? (
                                        <>
                                            {appointsList
                                                .filter(
                                                    (el) =>
                                                        el.status ===
                                                            IEventStatus.WAITING_FEEDBACK ||
                                                        el.status ===
                                                            IEventStatus.DONE
                                                )
                                                .map((el) => (
                                                    <UserIventItem
                                                        media={media}
                                                        salonStars={salonStars}
                                                        whStars={whStars}
                                                        event={el}
                                                        key={el.id}
                                                        handleReOrder={
                                                            handleReOrder
                                                        }
                                                        handleEditOrder={
                                                            handleEditOrder
                                                        }
                                                        handleDeclineEvent={
                                                            handleDeclineEvent
                                                        }
                                                        handleRate={handleRate}
                                                        handleOpenOnMap={
                                                            handleOpenOnMap
                                                        }
                                                    />
                                                ))}
                                        </>
                                    ) : (
                                        <>
                                            {!isAppointsLoading && (
                                                <Empty
                                                    description={
                                                        'Записи отсутствуют'
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`tab tab4 bonus${
                                    hashes.indexOf(locHash) === 3
                                        ? ' active'
                                        : ''
                                }`}
                            >
                                4
                            </div>
                            <div
                                className={`tab tab5 support${
                                    hashes.indexOf(locHash) === 4
                                        ? ' active'
                                        : ''
                                }`}
                            >
                                5 - Support dialogs
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            {selectedSalon && (
                <AddScheduleModal
                    open={addShModalOpen}
                    setOpen={setAddShModalOpen}
                    handleSetNeedLoad={handleSetNeedLoading}
                    salonData={selectedSalon}
                    mode={'client'}
                    clientId={clientId}
                    modalPrevState={modalPrevState}
                    clearPrevState={clearPrevState}
                    startTime={
                        selectedSalon.startJobTime
                            ?.split(':')
                            .map((el, index) =>
                                index === 0
                                    ? Number(el) + utcWithDiff
                                    : Number(el)
                            ) ?? [0, 0]
                    }
                    endTime={
                        selectedSalon.endJobTime
                            ?.split(':')
                            .map((el, index) =>
                                index === 0
                                    ? Number(el) + utcWithDiff
                                    : Number(el)
                            ) ?? [23, 59]
                    }
                />
            )}
            {salonOnMapCoords && (
                <SalonOnMapModal
                    isOpen={salonOnMapOpen}
                    setOpen={setSalonOnMapOpen}
                    salonCoords={salonOnMapCoords}
                />
            )}
            <PageFoot />
        </>
    );
}
export default Profile;
