import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { Form, message, Modal, Select } from 'antd';
import { CommonSalonsAPI } from '../api/common/salons.ts';
import { useLoadAvailableClients } from '../hooks/use-load-available-clients.ts';

interface Props {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    salonId: number;
    clientsList: string[];
}

interface IForm {
    userId: string;
}

export const AddClientModal: FC<Props> = ({
    isOpen,
    setOpen,
    salonId,
    clientsList,
}) => {
    const {
        loaded: clientsLoaded,
        pending: clientsPending,
        handleLoadClients,
        clientsAvailable,
    } = useLoadAvailableClients(clientsList);

    const [pending, setPending] = useState(false);

    const [form] = Form.useForm<IForm>();

    const handleCancel = useCallback(() => {
        if (!pending) {
            setOpen(false);
        }
    }, [pending]);

    const handleFinish = useCallback(
        async (values: IForm) => {
            try {
                setPending(true);
                await CommonSalonsAPI.addClient(
                    [...clientsList, values.userId],
                    salonId
                );

                setOpen(false);

                form.resetFields();

                message.success('Клиент добавлен');

                setPending(false);
            } catch (e) {
                setPending(false);
                message.error('Ошибка при добавлении клиента');
            }
        },
        [salonId, handleCancel, setOpen]
    );

    useEffect(() => {
        if (!clientsLoaded) {
            void handleLoadClients();
        }
    }, []);

    return (
        <Modal
            open={isOpen}
            onCancel={handleCancel}
            title={'Добавить клиента в салон'}
            okText={'Создать'}
            cancelText={'Отмена'}
            onOk={form.submit}
            okButtonProps={{
                loading: pending,
            }}
        >
            <Form<IForm>
                onFinish={handleFinish}
                layout={'vertical'}
                form={form}
            >
                <Form.Item
                    label={'Пользователь'}
                    name={'userId'}
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                >
                    <Select
                        loading={clientsPending}
                        placeholder={'Выберите'}
                        optionFilterProp={'label'}
                        showSearch
                        options={
                            clientsAvailable?.map((el) => ({
                                label: `${el.firstName} ${el.lastName} | ${el.phone}`,
                                value: el.id,
                            })) ?? []
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
