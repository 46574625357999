import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {columnSchema} from '../data/columnSchema';
import {Table, Card, Space, Row, Col, Button, Input, DatePicker, Modal, Select} from 'antd';
import {Content} from 'antd/es/layout/layout';
import Breadcrumbs from '../../../Components/Breadcrumbs';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import {DeleteOutlined, EditOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {Option} from 'antd/es/mentions';
import {useDispatch, useSelector} from 'react-redux';
import {FormComponent} from '../../../Components/FormComponent';

const {confirm} = Modal;

export default function ClientDataComponent ({url}){
    const $stor = useSelector(state => state);
    const dispatch = useDispatch();

    const inputRef = useRef([]);
    const [needAuth, setNeedAuth] = useState(false);
    const handler = data => ev => {
        if(ev){
            switch (data.item.type){
                case 'enum':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            [data.item.key]: ev,
                        }
                    });
                    // setFormData({
                    //     ...formData,
                    //     [data.item.key]: ev
                    // })
                    break;
                case 'input':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            [ev.target.name]: ev.target.value,
                        }
                    });
                    // setFormData({
                    //     ...formData,
                    //     [ev.target.name]: ev.target.value
                    // })
                    break;
                case 'textarea':
                    // setFormData({
                    //     ...formData,
                    //     [ev.target.name]: ev.target.value
                    // })
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            [ev.target.name]: ev.target.value,
                        }
                    });
                    break;
                case 'number':
                    // setFormData({
                    //     ...formData,
                    //     [ev.target.name]: ev.target.value
                    // })
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            [ev.target.name]: ev.target.value,
                        }
                    });
                    break;
                case 'date':
                    // setFormData({
                    //     ...formData,
                    //     'birthday': ev.format()
                    // })

                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            'birthday': ev.format(),
                        }
                    });
                    break;
                case 'select':
                    // setFormData({
                    //     ...formData,
                    //     [data.item.key]: ev
                    // })
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            [data.item.key]: ev,
                        }
                    });
                    break;
                case 'select-salons':
                    // setFormData({
                    //     ...formData,
                    //     [data.item.key]: ev
                    // })
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            salonIds: ev,
                        }
                    });
                    break;
                case 'file':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            [data.item.key]: ev.target.value,
                        }
                    });
            }
        }

        const next = inputRef.current[ev];
        if(next){
            next.focus()
        }
    };
    // const handler = data => ev => {
    //     if(ev){
    //         switch (data.item.type){
    //             case 'enum':
    //                 setFormData({
    //                     ...formData,
    //                     [data.item.key]: ev
    //                 })
    //                 break;
    //             case 'input':
    //                 setFormData({
    //                     ...formData,
    //                     [ev.target.name]: ev.target.value
    //                 })
    //                 break;
    //             case 'number':
    //                 setFormData({
    //                     ...formData,
    //                     [ev.target.name]: ev.target.value
    //                 })
    //                 break;
    //             case 'date':
    //                 setFormData({
    //                     ...formData,
    //                     'birthday': ev.format()
    //                 })
    //                 break;
    //             default:
    //                 break;
    //         }
    //     }
    //
    //     const next = inputRef.current[ev];
    //     if(next){
    //         next.focus()
    //     }
    // };

    let title='Клиенты', parent='Главная'
    const remoteUrl = `${process.env.REACT_APP_REMOTE_HOST}client/`;

    function getTableData(){
        return dispatch => {
            setNeedAuth(!$stor.auth?.accessToken);
            axios.get(`${remoteUrl}all`, {headers:{
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${$stor.auth?.accessToken}`,
                }}).then(res =>
                dispatch({
                    type: 'SOURCE-TABLE',
                    tableSource: res.data,
                })
            );
        };
    }

    function getAdditionalData(){
        return dispatch => {
            axios.get(`${process.env.REACT_APP_REMOTE_HOST}salon/all`).then(res => {
                dispatch({
                    type: 'ADDITIONAL-DATA-SALON',
                    salon: res.data,
                });
            });
        }
    }

    function getTableColumn(){
        return dispatch => {
            if(columnSchema.some(item => {
                return item.key == 'action';
            })){
                ;
            }else{
                columnSchema.push({
                    title: 'Action',
                    key: 'action',
                    fixed: 'right',
                    width: 80,
                    render: (text, record) => (
                        <Space size="middle">
                            <a onClick={() => onDeleteClick(record.id)}><DeleteOutlined/></a>
                            <a onClick={()=> onEditClick(text)}><EditOutlined/></a>
                        </Space>
                    ),
                });
            }
            dispatch({
                type: 'COLUMN-TABLE',
                tableColumn: columnSchema,
            });
        }
    }

    function deleteDateFromUrl(url, id){
        axios.delete(remoteUrl + id).then(async response => {
            dispatch(getAdditionalData());
            dispatch(getTableData());
            dispatch(getTableColumn());
        }).catch(er => {
            console.log(er);
        })
    }
    let onEditClick = data => {
        let obj = {}
        columnSchema.forEach(item => {
            obj = {
                ...obj,
                [item.key]: data[item.key],
            };
        });
        dispatch({
            type: 'TOGGLE-FORM',
            isFormShow: false,
        });
        dispatch({
            type: 'FORM-IS-EDIT',
            isEditForm: {
                flag: true,
                id: data.id,
            }
        });
        dispatch({
            type: 'FORM-DATA',
            formDData: obj,
        });
    }

    let onDeleteClick = id => {
        confirm({
            title: 'Вы действительно хотите удалить этот элемент?',
            icon: <ExclamationCircleOutlined />,
            content: 'При удалении этот элемент удалится навсегда',
            okText:'Удалить',
            cancelText:'Отменить',
            onOk(){
                deleteDateFromUrl(remoteUrl, id);
            },
            onCancel(){
                console.log('Cancel');
            },
        })
    }

    let onNewClick = () => {
        dispatch({
            type: 'TOGGLE-FORM',
            isFormShow: false,
        });
        dispatch({
            type: 'FORM-IS-EDIT',
            isEditForm: {
                flag: false,
                id: 0,
            }
        });
    }

    useEffect( () => {
        dispatch(getAdditionalData());
        dispatch(getTableData());
        dispatch(getTableColumn());

        // TODO При переходе с другой страницы - форма по умолчанию в состоянии "закрыта"
    }, [])

    console.log('==/client/all', $stor);
    return <Content style={{margin: 20}}>
        <Card style={{marginBottom: '20px'}}>
            <Row gutter={24}>
                <Col span={18}>
                    <Breadcrumbs parent={parent} title={title} style={{margin:0}}/>
                </Col>
                <Col span={6} style={{display: 'flex', justifyContent: 'flex-end', alignItems:'center'}}>
                    <Row>
                        <Button type={'primary'} onClick={() => onNewClick()}>
                            Новая запись
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Card>
        <FormComponent columtSchema={columnSchema} inputRef={inputRef} additionalData={$stor.additionalData}
            salonsInOptions={$stor.salons} handler={handler} formData={$stor.formData} url={'client/'}/>
        <Card>
            <Table locale={{emptyText: (<span>
                {!needAuth ?<>Нет данных</>:<>Данные недоступны (статус 401), требуется <b>авторизация</b></>}
            </span>)}} columns={$stor.tableColumn}
                 dataSource={$stor.tableSource.clients} scroll={{x: 1500, y: null}}/>
        </Card>
    </Content>;
}
