import {Button, Card, DatePicker, Input, Select, TimePicker} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import MaskedInput from 'antd-mask-input';

export function FormComponent(props){
    const [columnSchema, setColumnSchema] = useState([]);
    const data = useSelector(state => state);
    const dispatch = useDispatch();

    const remoteUrl = process.env.REACT_APP_REMOTE_HOST + props.url;
    function getTableData() {
        return dispatch => {
            axios.get(`${remoteUrl}${/category/.test(remoteUrl) ?'':'all'}`).then(res =>
                dispatch({
                    type: 'SOURCE-TABLE',
                    tableSource: res.data,
                })
            );
        };
    }

    const onSaveClick = () => {
        console.log('++onSaveClick')
        if(data.isEditForm.flag){
            axios.patch(remoteUrl + data.isEditForm.id, data.formData, {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${data.auth?.accessToken}`,
            }).then(async item => {
                dispatch(getTableData());
            }).catch(er => {
                console.log(er);
            })
        }else{
            axios.post(remoteUrl, data.formData, {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${data.auth?.accessToken}`,
            }).then(async item => {
                dispatch(getTableData());
            }).catch(er => {
                console.log(er);
            })
        }
    }
    useEffect(() => {
        if(props.columtSchema.length)
            setColumnSchema(props.columtSchema);
    }, []);
    return data.isFormShow ? <Card style={{marginBottom: '20px'}}>
        {columnSchema.map((item, key) => {
            switch (item.type){
                case 'input':
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <Input value={data.formData[item.key]}
                            type={'text'}
                            name={item.key}
                            placeholder={item.title}
                            ref={el => props.inputRef.current[item.key] = el}
                            required={item.required}
                            onChange={props.handler({item})}
                        autoComplete="false"/>
                    </div>;
                case 'phone':
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <MaskedInput mask={'7(111)111-11-11'} name={item.key} value={data.formData[item.key]}
                            type="text"
                            placeholder={item.title}
                            ref={el => props.inputRef.current[item.key] = el}
                            required={item.required}
                            onChange={props.handler({item})}
                        autoComplete="false"/>
                  </div>;
                case 'textarea':
                    return <div style={{marginBottom: 16}} key={key} >
                        <label>{item.title}</label>
                        <TextArea
                            rows={10}
                            name={item.key}
                            ref={el => props.inputRef.current[item.key] = el}
                            onChange={props.handler({item})}
                            required={item.required}
                            value={data.formData[item.key]}
                        />
                    </div>;
                case 'datetime':
                    const format = 'YYYY-MM-DD HH:mm';
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <DatePicker defaultDate={moment(new Date(), format)}
                            showTime={true}
                            minuteStep={15}
                            format={format}
                            name={item.key}
                            ref={el => props.inputRef.current[item.key] = el}
                            onChange={props.handler({item})}
                            required={item.required}
                        autoComplete="false"/>
                    </div>;
                case 'date':
                    const date = 'YYYY-MM-DD';
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <br/>
                        <DatePicker defaultDate={moment(new Date(), date)}
                            format={date}
                            name={item.key}
                            ref={el => props.inputRef.current[item.key] = el}
                            onChange={props.handler({item})}
                            required={item.required}
                        autoComplete="false"/>
                    </div>;
                case 'startDate':
                    var startDate = 'YYYY-MM-DD';
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <br/>
                        <DatePicker defaultDate={moment(new Date(), startDate)}
                            format={startDate}
                            name={item.key}
                            ref={el => props.inputRef.current[item.key] = el}
                            onChange={props.handler({item})}
                            required={item.required}
                        autoComplete="false"/>
                    </div>;
                case 'endDate':
                    var endDate = 'YYYY-MM-DD';
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <br/>
                        <DatePicker defaultDate={moment(new Date(), endDate)}
                            format={endDate}
                            name={item.key}
                            ref={el => props.inputRef.current[item.key] = el}
                            required={item.required}
                            onChange={props.handler({item})}
                        autoComplete="false"/>
                    </div>;
                case 'file':
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <Input type={'file'}
                            name={item.key}
                            ref={el => props.inputRef.current[item.key] = el}
                            onChange={props.handler({item})}
                            required={item.required}
                        autoComplete="false"/>
                    </div>;
                case 'number':
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <br/>
                        <Input type={'number'} name={item.key}
                            placeholder={item.title}
                            ref={el => props.inputRef.current[item.key] = el}
                            onChange={props.handler({item})}
                            step={item.step}
                            disabled={item.disabled}
                            min={item.min}
                            max={item.max}
                            required={item.required}
                            value={data.formData[item.key]}
                            style={{width: '100%'}}
                        autoComplete="false"/>
                    </div>;
                case 'select':
                    return <div style={{marginBottom: 16}}  key={key}>
                        <label>{item.title}</label>
                        <Select onChange={props.handler({item})}
                            style={{width:'100%'}}
                            // defaultValue={item.options[0]}
                            placeholder="Выбрать"
                            ref={el => props.inputRef.current[item.key] = el}
                            required={item.required}
                        autoComplete="false">
                            {(props.additionalData[item.key])
                                ? props.additionalData[item.key].map((option, index) => {
                                    return (option?.children?.length
                                        ? <Select.Option value={option.id} key={option.id}>{ option.title }</Select.Option>
                                        : option.title)
                                })
                            : null}
                        </Select>
                    </div>;
                case 'select-salons':
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <Select onChange={props.handler({item})}
                            style={{width:'100%'}}
                            // defaultValue={item.options[0]}
                            placeholder="Выбрать"
                            ref={el => props.inputRef.current['salons'] = el}
                            required={item.required}
                        autoComplete="false"> {/* ?ERR key={"option.id"} - без key?*/}
                            <Select.Option value="" key={"option.id"}> Без привязки к салону </Select.Option>
                            {(props.salonsInOptions)
                                ? props.salonsInOptions.map((option, index) => {
                                    return <Select.Option value={option.id} key={option.id}>{ option.title }</Select.Option>;
                            }) : null}
                        </Select>
                    </div>;
                case 'multiselect':
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <Select
                            onChange={props.handler({item})}
                            style={{width:'100%'}}
                            mode="multiple"
                            // defaultValue={item.options[0]}
                            placeholder="Выбрать"
                            ref={el => props.inputRef.current[item.key] = el}
                            required={item.required}
                            autoComplete={"false"}
                            // autoFocus={false}
                            // optionFilterProp="children"
                            // filterOption={(input, option) =>
                            //     {
                            //         console.log(option)
                            //         console.log(input)
                            //         //option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            //     }
                            // }
                            // filterSort={(optionA, optionB) =>
                            //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            // }
                        >
                            {
                                (props.serviceList)
                                    ?
                                    props.serviceList.map((option, index) => {
                                        if(option.parentId !== null) {
                                            if(option.parentId == 1) {
                                                return(
                                                    <Select.Option value={option.id} key={option.id} disabled={true}> <strong>{ option.title } </strong></Select.Option>
                                                )
                                            }else{
                                                return(
                                                    <Select.Option value={option.id} key={option.id} style={{marginLeft: 10}}>{ option.title }</Select.Option>
                                                )
                                            }
                                        }
                                    })
                                    :
                                    null
                            }
                        </Select>
                    </div>;
                case 'multiselectService':
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <Select placeholder="Выбрать" onChange={props.handler({item})}
                            mode="multiple"
                            style={{width:'100%'}}
                            // defaultValue={item.options[0]}
                            ref={el => props.inputRef.current[item.key] = el}
                            maxTagCount={2}
                            //required={item.required}
                        autoComplete="false">
                            {/*// autoFocus={false}
                            // optionFilterProp="children"
                            // filterOption={(input, option) =>
                            //     {
                            //         console.log(option)
                            //         console.log(input)
                            //         //option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            //     }
                            // }
                            // filterSort={(optionA, optionB) =>
                            //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            // } */}
                            {
                                (props.serviceList)
                                    ?
                                    props.serviceList.map((option, index) => {
                                        return (
                                            option.children.map((subOption, index) => {
                                                return(
                                                    <Select.Option value={subOption.id} key={subOption.id} disabled={false}> <strong>{ subOption.name } </strong></Select.Option>
                                                )
                                            })
                                        )

                                        // if(option.parentId !== null) {
                                        //     if(option.parentId == 1) {
                                        //         return(
                                        //             <Select.Option value={option.id} key={option.id} disabled={true}> <strong>{ option.title } </strong></Select.Option>
                                        //         )
                                        //     }else{
                                        //         return(
                                        //             <Select.Option value={option.id} key={option.id} style={{marginLeft: 10}}>{ option.title }</Select.Option>
                                        //         )
                                        //     }
                                        // }
                                    })
                                    :
                                    null
                            }
                        </Select>
                    </div>;
                case 'selectClient':
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <Select placeholder="Выбрать" onChange={props.handler({item})}
                            style={{width:'100%'}}
                            //defaultValue={item.options[0]}
                            ref={el => props.inputRef.current[item.key] = el}
                            //required={item.required}
                        autoComplete="false">
                            {props.clients.map((option, index) => {
                                return <Select.Option value={option.id} key={index}>{option.firstname +' '+ option.lastname+' ('+option.email+')'}</Select.Option>;
                            })}
                        </Select>
                    </div>
                case 'selectMaster':
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <Select placeholder="Выбрать" onChange={props.handler({item})}
                            style={{width:'100%'}}
                            //defaultValue={item.options[0]}
                            ref={el => props.inputRef.current[item.key] = el}
                            //required={item.required}
                        autoComplete="false">
                            {props.clients.map((option, index) => {
                                // console.log(option)
                                return(
                                    <Select.Option value={option.id} key={index}>{option.firstname+' '+option.lastname+' '+option.patronymic}</Select.Option>
                                )
                            })}
                        </Select>
                    </div>
                case 'selectSalon':
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <Select placeholder="Выбрать" onChange={props.handler({item})}
                            style={{width:'100%'}}
                            //defaultValue={item.options[0]}
                            ref={el => props.inputRef.current[item.key] = el}
                            //required={item.required}
                        autoComplete="false">
                            {props.clients.map((option, index) => {
                                console.log(option)
                                // return(
                                //     <Select.Option value={option.id} key={index}>{option.firstname+' '+option.lastname+' '+option.patronymic}</Select.Option>
                                // )
                            })}
                        </Select>
                    </div>;
                case 'enum':
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label>
                        <Select placeholder="Выбрать" onChange={props.handler({item})}
                            style={{width:'100%'}}
                            //defaultValue={item.options[0]}
                            ref={el => props.inputRef.current[item.key] = el}
                            required={item.required}
                        autoComplete="false">
                            {item.options.map((option, index) => {
                                return <Select.Option value={option} key={index}>{option}</Select.Option>;
                            })}
                        </Select>
                    </div>;
                case 'time':
                    const timeFormat = 'HH:mm';
                    return <div style={{marginBottom: 16}} key={key}>
                        <label>{item.title}</label><br/>
                        <TimePicker placeholder={item.title} name={item.key} format={timeFormat}
                            value={moment(data.formData[item.key])}
                            minuteStep={15}
                            defaultValue={moment('00:00:00', timeFormat)}
                            ref={el => props.inputRef.current[item.key] = el}
                        onChange={props.handler({item})}/>
                    </div>;
                default:
                    break;
            }
        })}
        <Button type={'primary'} onClick={() => onSaveClick()}>Сохранить</Button>
        <Button type={'danger'} onClick={() => {dispatch({
            type: 'TOGGLE-FORM',
            isFormShow: false
        })}} style={{marginLeft: 10}}>Закрыть</Button>
    </Card> : null;
}
