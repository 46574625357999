import { FC, useCallback } from 'react';
import { IListedService } from '../../../../Types/SalonTypes.ts';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { IEditableCategory } from '../../../../Modals/AddServiceModal.tsx';
import { Popconfirm } from 'antd';

import styles from './SalonPriceList.module.scss';

interface Props {
    service: IListedService;
    handleDeleteService: (serviceId: string | number) => Promise<void>;
    categoryId: number;
    handleStartEdit: (el: IEditableCategory) => void;
}

export const ServiceItem: FC<Props> = ({
    service,
    handleDeleteService,
    handleStartEdit,
    categoryId,
}) => {
    const handleDelete = useCallback(async () => {
        try {
            void handleDeleteService(service.id);
        } catch (e) {}
    }, [service, handleDeleteService]);

    const handleEdit = useCallback(async () => {
        handleStartEdit({
            ...service,
            categoryId,
        });
    }, [service, categoryId, handleStartEdit]);

    return (
        <div className={styles.serviceItem}>
            <span className={styles.mods}>
                <span className={styles.edit} onClick={handleEdit}>
                    <EditOutlined size={16} />
                </span>
                <Popconfirm
                    title={`Вы уверены что хотите удалить услугу "${service.serviceName}"?`}
                    onConfirm={handleDelete}
                    okText="Да, удалить"
                    cancelText="Отмена"
                >
                    <span className={styles.delete}>
                        <DeleteOutlined size={16} />
                    </span>
                </Popconfirm>
            </span>
            <h5>{service.serviceName}</h5>
            <span className={styles.serviceItemDesc}>
                {service.serviceDescription}
            </span>
            <div className={styles.serviceItemContent}>
                <div>
                    <span>{service.serviceTiming} минут</span>
                </div>
                <div>
                    <span>{service.priceValue} РУБ.</span>
                </div>
            </div>
        </div>
    );
};
