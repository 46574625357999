export const columnSchema = [
    {
        title: 'Название категории',
        key: 'id',
        dataIndex: 'title',
        type: 'input',
        show: true,
        value: ''
    },{
        title: 'Тайминг',
        key: 'defaultTiming',
        dataIndex: 'defaultTiming',
        type: 'number',
        step: 15,
        disabled: false,
        show: true,
        value: ''
    },{
        title: 'Родительский элемент',
        key: 'category',
        dataIndex: 'category',
        type: 'select',
        show: true,
        value: '',
        options: [
            {id: null, title: 'Без родительского элемента', defaultTiming: 0, key: 0}
        ]
    }
]
