import { FC, useCallback, useState } from 'react';
import { Button, Input, InputNumber, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { CommonSalonsAPI } from '../../../../api/common/salons.ts';

import styles from './PersonalSale.module.scss';

type Props = {
    sale: number;
    clientId: string | number;
    salonId: string | number;
};

export const PersonalSale: FC<Props> = ({ sale, clientId }) => {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState<number>(sale);
    const [editMode, setEditMode] = useState(false);

    const handleGoToEdit = useCallback(() => {
        setEditMode(true);
    }, []);

    const handleView = useCallback(() => {
        setEditMode(false);
        setValue(sale);
    }, []);

    const handleChangeDiscount = useCallback(
        async (value: string) => {
            const loading = message.loading('Изменение скидки клиента...');
            setLoading(true);

            try {
                await CommonSalonsAPI.patchSalonClient(clientId, {
                    discount: value,
                });

                setEditMode(false);
                loading();
                message.success('Скидка клиента изменена');
                setLoading(false);
            } catch (e) {
                loading();
                message.error('Ошибка при изменении скидки');
                setLoading(false);
            }
        },
        [clientId]
    );

    const handleEdit = useCallback(() => {
        if (value === undefined || value === null) {
            void message.warning('Введите 0 если скидка отсутствует');
        } else if (value < 0 || value > 100) {
            void message.warning('Допустимо значение от 0 до 100');
        } else {
            void handleChangeDiscount(value.toString());
        }
    }, [value, handleChangeDiscount]);

    return (
        <>
            {editMode ? (
                <div>
                    <Input.Group compact>
                        <InputNumber
                            value={value}
                            onChange={(val) => {
                                setValue(val);
                            }}
                            disabled={loading}
                            min={0}
                            max={100}
                        />
                        <Button
                            shape={'round'}
                            onClick={handleView}
                            disabled={loading}
                        >
                            <CloseOutlined />
                        </Button>
                        <Button
                            type="primary"
                            shape={'round'}
                            onClick={handleEdit}
                            loading={loading}
                            className={styles.btnConfirm}
                        >
                            <CheckOutlined />
                        </Button>
                    </Input.Group>
                </div>
            ) : (
                <div className={styles.personalFee}>
                    <span>{value}%</span>
                    <span
                        className={styles.personalFeeButton}
                        onClick={handleGoToEdit}
                    >
                        Изменить скидку
                    </span>
                </div>
            )}
        </>
    );
};
