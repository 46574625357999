import { baseAPIInstance } from '../index.ts';
import { AxiosResponse } from 'axios';
import { ISalon } from '../../Types/SalonTypes';

interface IApi {
    getResultByParams: (params: {
        serviceIds: number[];
        date: string;
        hourStart: string;
        hourEnd?: string;
    }) => Promise<
        AxiosResponse<{
            full: ISalon[];
            less_found_end_time: ISalon[];
            less_found_start_time: ISalon[];
        }>
    >;
}

export const SearchAPI: IApi = {
    getResultByParams: async (params) => {
        return baseAPIInstance.post('search', params);
    },
};
