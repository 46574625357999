import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { CloseOutlined } from '@ant-design/icons';

import { load } from '@2gis/mapgl';
import { Map } from '@2gis/mapgl/global';
import circleGreen from '../img/icon/circleGreen.svg';

interface Props {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    salonCoords: [number, number];
}

const SalonMap: FC<{
    salonCoords: [number, number];
}> = ({ salonCoords }) => {
    const [mapState, setMapState] = useState<Map>();

    const initMap = useCallback(async () => {
        let map: undefined | Map;
        load().then((mapglAPI) => {
            map = new mapglAPI.Map('bt-salon-map-container', {
                center: salonCoords,
                zoom: 13,
                key: 'fd6ca95f-8930-4f91-9835-fc784c1707bc',
                lang: 'ru',
            });

            setMapState(() => map);

            new mapgl.Marker(map, {
                coordinates: salonCoords,
                icon: circleGreen,
            });

            return map;
        });
    }, []);

    useEffect(() => {
        void initMap();

        return () => mapState && mapState.destroy();
    }, []);

    return <div id={'bt-salon-map-container'} />;
};

export const SalonOnMapModal: FC<Props> = ({
    isOpen,
    salonCoords,
    setOpen,
}) => {
    return (
        <div
            className={'bt-salon-on-map'}
            style={{
                display: isOpen ? 'flex' : 'none',
            }}
        >
            <div className={'bt-salon-on-map__inner'}>
                <div
                    className="close"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    <CloseOutlined />
                </div>
                {isOpen && <SalonMap salonCoords={salonCoords} />}
            </div>
        </div>
    );
};
