import React, { FC, useMemo } from 'react';
import { ISalon, IService } from '../../../../Types/SalonTypes.ts';

interface Props {
    salon?: ISalon;
}

export const PriceCurrent: FC<Props> = ({ salon }) => {
    const servicesByCategory:
        | undefined
        | {
              id: number;
              key: string;
              title: string;
              services: IService[];
          }[] = useMemo(() => {
        if (!salon?.services) {
            return undefined;
        }
        return Object.values(
            salon?.services.reduce((res, acc) => {
                if (acc?.service?.parent) {
                    if (res[acc.service.parent.id]) {
                        res = {
                            ...res,
                            [acc.service.parent.id]: {
                                ...res[acc.service.parent.id],
                                services: [
                                    ...res[acc.service.parent.id].services,
                                    {
                                        ...acc,
                                    },
                                ],
                            },
                        };
                    } else {
                        res = {
                            ...res,
                            [acc.service.parent.id]: {
                                ...acc.service.parent,
                                services: [acc],
                            },
                        };
                    }
                }

                return res;
            }, {})
        );
    }, [salon]);

    return (
        <div>
            {servicesByCategory?.length > 0 &&
                servicesByCategory.map((el) => (
                    <div className={`servByCat servNoHeadNoFoot`} key={el.key}>
                        <div className="ant-modal-content">
                            <div className="ant-modal-body">
                                <div>
                                    <h2 className="servTabCat">{el.title}</h2>
                                    <div className="servCat">
                                        {el.services.map((serv) => (
                                            <div className="serv" key={serv.id}>
                                                {serv.serviceTiming && (
                                                    <div className="servTiming">
                                                        {serv.serviceTiming}
                                                        <sub>м</sub>
                                                    </div>
                                                )}
                                                {serv.priceValue && (
                                                    <div className="servPrice">
                                                        {serv.priceValue}
                                                        <sub>р</sub>
                                                    </div>
                                                )}
                                                <div
                                                    title={
                                                        serv.serviceDescription ??
                                                        ''
                                                    }
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    {serv.service.title}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            {!servicesByCategory && (
                <div className="servByCat servNoHeadNoFoot tab tab9 active">
                    <div className="ant-modal-content">
                        (загрузка списка услуг...)
                    </div>
                </div>
            )}
            {servicesByCategory && servicesByCategory.length === 0 && (
                <div className="servByCat servNoHeadNoFoot tab tab9 active">
                    <div className="ant-modal-content">Услуги не найдены</div>
                </div>
            )}
        </div>
    );
};
