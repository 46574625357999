import { FC, useCallback, useMemo, useState } from 'react';
import { Scheduller } from './Schedule/Scheduller.tsx';
import * as moment from 'moment';
import { Moment } from 'moment';
import { CalendarItem } from './CalendarItem.tsx';
import { EventsList } from './EventsList.tsx';
import { IEventStatus } from '../../../Types/ScheduleTypes.ts';
import { LoadingOutlined } from '@ant-design/icons';
import { SalonScheduleAPI } from '../../../api/schedule/index.ts';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { ISalon } from '../../../Types/SalonTypes.ts';
import { AddScheduleModal } from '../../../Modals/AddScheduleModal.tsx';
import { ScheduleType } from '../../../Modals/AddScheduleModalForm.tsx';
import { useTimeContext } from '../../../Services/Time.tsx';

import styles from './SalonShedule.module.scss';

export interface IModalPrevState {
    type?: ScheduleType;
    employer?: string;
    dateStart?: Moment;
    employerOff?: string;
    existTimeSlotId?: string | number;
    servicesIds?: Array<string | number>;
    status?: IEventStatus;
    statusDescription?: string;
    dateEnd?: string;
    clientId?: string;
}

export const SalonSheduleTab: FC<{
    loading: any;
    handleFetchSchedule: any;
    handlePrevDate: any;
    handleSelectDate: any;
    selectedDate: any;
    handleNextDate: any;
    scheduleData: any;
    handleSetNeedLoad: any;
}> = ({
    loading,
    handleFetchSchedule,
    handlePrevDate,
    handleSelectDate,
    selectedDate,
    handleNextDate,
    scheduleData,
    handleSetNeedLoad,
}) => {
    const { utcWithDiff } = useTimeContext();

    const salonData: ISalon | undefined = useSelector(
        (state: any) => state.salonDescr
    );

    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = useCallback(() => {
        setModalOpen(true);
    }, []);

    const startTime: [number, number] = useMemo(() => {
        if (salonData?.startJobTime) {
            return [
                Number(salonData.startJobTime.split(':')[0]) + utcWithDiff,
                Number(salonData.startJobTime.split(':')[1]),
            ];
        } else return [0, 0];
    }, [salonData, utcWithDiff]);

    const endTime: [number, number] = useMemo(
        () =>
            salonData?.endJobTime
                ? [
                      Number(salonData.endJobTime.split(':')[0]) + utcWithDiff,
                      Number(salonData.endJobTime.split(':')[1]),
                  ]
                : [23, 59],
        [salonData, utcWithDiff]
    );

    const [modalPrevState, changeModalPrevState] = useState<
        IModalPrevState | undefined
    >(undefined);

    const setModalPrevState = useCallback((props: IModalPrevState) => {
        changeModalPrevState(() => props);
        setModalOpen(true);
    }, []);

    const clearPrevState = useCallback(() => {
        changeModalPrevState(undefined);
    }, []);

    const handleCancelEvent = useCallback(
        async (eventId: string) => {
            const loading = message.loading('Отмена записи', 0);
            try {
                await SalonScheduleAPI.editSchedule(
                    {
                        status: IEventStatus.CANCELED,
                    },
                    eventId
                );
                loading();
                void handleFetchSchedule();
            } catch (e) {
                loading();
                void message.error('Ошибка при отмене записи');
            }
        },
        [handleFetchSchedule]
    );

    if (loading || !salonData) {
        return (
            <div className={styles.loaderWrapper}>
                <LoadingOutlined />
            </div>
        );
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.calendarWrapper}>
                    <div
                        className={`${styles.arrow} ${styles.arrowLeft}`}
                        onClick={handlePrevDate}
                    />
                    <CalendarItem
                        onSelect={handleSelectDate}
                        selectedDate={moment(selectedDate).add(-1, 'months')}
                        calendarClassName={styles.disabledCalendar}
                    />
                    <CalendarItem
                        onSelect={handleSelectDate}
                        selectedDate={selectedDate}
                    />
                    <CalendarItem
                        onSelect={handleSelectDate}
                        selectedDate={moment(selectedDate).add(1, 'months')}
                        calendarClassName={styles.disabledCalendar}
                    />
                    <div
                        className={`${styles.arrow} ${styles.arrowRight}`}
                        onClick={handleNextDate}
                    />
                </div>
            </div>
            <div className={styles.wrapper_colored}>
                {scheduleData && (
                    <>
                        <EventsList
                            selectedDate={selectedDate}
                            events={scheduleData}
                            handleSetNeedLoad={handleSetNeedLoad}
                        />
                        <span className={styles.subTitle}>Журнал записи</span>
                        <Scheduller
                            events={scheduleData}
                            masters={salonData.employers}
                            handleOpenModal={handleOpenModal}
                            startTime={startTime}
                            endTime={endTime}
                            setModalPrevState={setModalPrevState}
                            handleCancelEvent={handleCancelEvent}
                        />
                    </>
                )}
            </div>
            {salonData && (
                <AddScheduleModal
                    salonData={salonData}
                    open={modalOpen}
                    setOpen={setModalOpen}
                    handleSetNeedLoad={handleSetNeedLoad}
                    startTime={startTime}
                    endTime={endTime}
                    modalPrevState={modalPrevState}
                    clearPrevState={clearPrevState}
                />
            )}
        </div>
    );
};
