import {Image, Layout} from 'antd';
import React from 'react';
import {PageHead} from '../part/PageParts';
import SideMenu from '../../Components/SideMenu';
import ServiceDataComponent from './component/ServiceDataComponent';

function Service({match}){
    const {
        params: { url },
    } = match;
    return <Layout style={{height: '100vh'}}>
        <PageHead/>
        <Layout height="100%">
            <SideMenu/>
            <ServiceDataComponent/>
        </Layout>
    </Layout>
}
export default Service;
