import React from 'react'
import {Menu, Layout} from 'antd'
import {Link} from 'react-router-dom';
import {HomeTwoTone, FileDoneOutlined, IdcardTwoTone, PictureTwoTone, AppstoreOutlined,
    TableOutlined, UsergroupAddOutlined, ClockCircleOutlined, GiftTwoTone, ClusterOutlined} from '@ant-design/icons';
const {Sider} = Layout; let x;

export default function SideMenu(){return <Sider>
    <Menu items={[{
        key: 'root',
        label: <Link to={'/'}>Главная</Link>,
        icon: <HomeTwoTone />,
    },{
        key: x='result',
        label: <Link to={`/${x}=`}>Результат поиска</Link>,
        icon: <FileDoneOutlined />,
    },{
        key: x='profile',
        label: <Link to={`/${x}`}>Мой профиль</Link>,
        icon: <IdcardTwoTone />,
    },{
        key: x='salon',
        label: <Link to={`/${x}`}>Салон-карточка</Link>,
        icon: <PictureTwoTone />,
    },{
        key: 'salonManage',
        label: <Link to={'/salon/cabinet'}>Салон/кабинет</Link>,
        icon: <AppstoreOutlined />,
    },{
        key: x='clients',
        label: <Link to={`/${x}`}>Клиенты</Link>,
        icon: <UsergroupAddOutlined />,
    },{
        key: x='customers',
        label: <Link to={`/${x}`}>Пользователи</Link>,
        icon: <UsergroupAddOutlined />,
    },{
        key: 'times',
        label: <Link to={'/job-times'}>Время работы</Link>,
        icon: <ClockCircleOutlined />,
    },{
        key: x='salons',
        label: <Link to={`/${x}`}>Салоны</Link>,
        icon: <TableOutlined />,
    },{
        key: x='masters',
        label: <Link to={`/${x}`}>Мастера</Link>,
        icon: <TableOutlined />,
    },{
        key: x='actions',
        label: <Link to={`/${x}`}>Акции</Link>,
        icon: <GiftTwoTone />,
    },{
        key: x='category',
        label: <Link to={`/${x}`}>Категории услуг</Link>,
        icon: <ClusterOutlined />,
    //},{
    //    key: x='service',
    //    label: <Link to={`/${x}`}>Стоимость услуг</Link>,
    //    icon: <ClusterOutlined />,
    }]}/>
</Sider>;}
