import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { Form, FormInstance, Input, message, Modal, TreeSelect } from 'antd';
import {
    ICategoryNode,
    ICreateService,
    IListedCategories,
    IListedService,
    IPatchedService,
} from '../Types/SalonTypes.ts';

interface IForm {
    priceValue: string;
    serviceDescription?: string;
    serviceTiming: string;
    categoryId: number;
}

type ByEditType =
    | {
          mode: 'create';
          handleCreate: (service: ICreateService) => Promise<void>;
          service: undefined;
      }
    | {
          mode: 'edit';
          handleCreate: (
              service: IPatchedService,
              serviceId: string | number
          ) => Promise<void>;
          service: IEditableCategory;
      };

type Props = ByEditType & {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    isAddServicePending: boolean;
    salonId: number;
    categoriesPending: boolean;
    categories: IListedCategories;
};

type FormProps = ByEditType & {
    form: FormInstance<IForm>;
    categories: IListedCategories;
    categoriesPending: boolean;
    salonId: number;
    handleClose: () => void;
};

interface ITreeSelect {
    value: number;
    title: string;
    children?: ITreeSelect[];
}

export interface IEditableCategory extends IListedService {
    categoryId: number;
}

const treeMapper: (item: ICategoryNode) => ITreeSelect = (
    item: ICategoryNode
) => {
    const dataToPush: ITreeSelect = {
        value: item.id,
        title: item.title,
        children:
            Array.isArray(item.children) && item.children.length > 0
                ? item.children.map(treeMapper)
                : undefined,
    };

    return dataToPush;
};

const AddModalForm: FC<FormProps> = ({
    form,
    categories,
    categoriesPending,
    handleCreate,
    mode,
    salonId,
    handleClose,
    service,
}) => {
    const treeData = useMemo(() => categories.map(treeMapper), [categories]);

    const handleFinish = useCallback(
        async (values: IForm) => {
            if (mode === 'create') {
                try {
                    await handleCreate({
                        priceValue: Number(values.priceValue),
                        serviceDescription: values.serviceDescription,
                        serviceName: '',
                        serviceTiming: Number(values.serviceTiming),
                        categoryId: values.categoryId,
                        salonId,
                    });
                    handleClose();
                } catch (e) {
                    message.error('Ошибка при создании услуги: ' + e.message);
                }
            } else {
                try {
                    await handleCreate(
                        {
                            priceValue: Number(values.priceValue),
                            serviceDescription: values.serviceDescription,
                            serviceTiming: Number(values.serviceTiming),
                        },
                        service.id
                    );
                    handleClose();
                } catch (e) {
                    message.error('Ошибка при изменении услуги: ' + e.message);
                }
            }
        },
        [salonId]
    );

    return (
        <Form<IForm> form={form} layout={'vertical'} onFinish={handleFinish}>
            {mode === 'create' && (
                <Form.Item
                    name={'categoryId'}
                    label={'Услуга'}
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                >
                    <TreeSelect
                        loading={categoriesPending}
                        treeDefaultExpandAll
                        treeData={treeData}
                        showSearch
                        treeNodeFilterProp={'title'}
                    />
                </Form.Item>
            )}
            <Form.Item name={'serviceDescription'} label={'Описание услуги'}>
                <Input placeholder={'Введите'} />
            </Form.Item>
            <Form.Item
                name={'serviceTiming'}
                label={'Длительность услуги'}
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                    {
                        pattern: /^\d+$/,
                        whitespace: false,
                        message: 'Используйте число',
                    },
                ]}
            >
                <Input
                    placeholder={'Введите'}
                    type={'number'}
                    suffix={'минут'}
                    step={5}
                />
            </Form.Item>
            <Form.Item
                name={'priceValue'}
                label={'Стоимость услуги'}
                rules={[
                    {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                    },
                    {
                        pattern: /^\d+$/,
                        whitespace: false,
                        message: 'Используйте число',
                    },
                ]}
            >
                <Input
                    placeholder={'Введите'}
                    type={'number'}
                    suffix={'руб.'}
                    step={100}
                />
            </Form.Item>
        </Form>
    );
};

export const AddServiceModal: FC<Props> = ({
    isOpen,
    setOpen,
    isAddServicePending,
    handleCreate,
    mode,
    salonId,
    service,
    categoriesPending,
    categories,
}) => {
    const [form] = Form.useForm<IForm>();

    const handleCancel = useCallback(() => {
        form.resetFields();
        setOpen(() => false);
    }, []);

    const handleOk = useCallback(() => {
        form.submit();
    }, [form]);

    useEffect(() => {
        if (mode === 'edit') {
            form.setFields([
                { name: 'priceValue', value: service?.priceValue },
                {
                    name: 'serviceDescription',
                    value: service?.serviceDescription,
                },
                { name: 'serviceTiming', value: service?.serviceTiming },
            ]);
        }
    }, [mode, service]);

    return (
        <Modal
            title={mode === 'create' ? 'Добавить услугу' : 'Изменить услугу'}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={mode === 'create' ? 'Создать' : 'Изменить'}
            cancelText={'Отмена'}
            closable={false}
            maskClosable={false}
            destroyOnClose
            okButtonProps={{
                loading: isAddServicePending,
            }}
        >
            {isOpen && (
                <AddModalForm
                    categoriesPending={categoriesPending}
                    categories={categories}
                    form={form}
                    handleCreate={handleCreate}
                    mode={mode as any}
                    salonId={salonId}
                    handleClose={handleCancel}
                    service={service}
                />
            )}
        </Modal>
    );
};
