import React, { FC } from 'react';
import { Layout } from 'antd';
import { PageHead, BanrHead, PageFoot } from '../part/PageParts';
import clientsList from '../../img/icon/clients-list.png';
import journal from '../../img/icon/journal.png';
import list from '../../img/icon/list.png';
import loyality from '../../img/icon/loyality.png';
import miniCite from '../../img/icon/mini-cite.png';
import price from '../../img/icon/price.png';
import rating from '../../img/icon/rating.png';
import reviews from '../../img/icon/reviews.png';
import search from '../../img/icon/search.png';

import styles from './About.module.scss';

export const About: FC = () => {
    return (
        <>
            <Layout className={styles.wrapper}>
                <PageHead />
                <div className="layCont">
                    <BanrHead hide={process.env.REACT_APP_VISUAL_FOR_DEV} />
                    <div className={styles.inner}>
                        <div className={styles.title}>О нас</div>
                        <p className={styles.paragraph}>
                            <mark>BEAUTYSPUTNIK</mark> - это первая
                            бьюти-платформа, которая делает процесс поиска,
                            записи и обслуживания клиента максимально удобным и
                            комфортным.
                        </p>
                        <p className={styles.paragraph}>
                            Забудьте про такие архаизмы, как раздача листовок
                            или программы лояльности на визитках с оттисками.
                            Забудьте про нерадивых мастеров! Забудьте про
                            “токсичных” клиентов! Забудьте про купонаторы и
                            сомнительные сайты-агрегаторы!
                        </p>
                        <p className={styles.paragraph}>
                            Теперь Ваш салон напрямую встречается с
                            потенциальными клиентами на единой
                            специализированной платформе!
                        </p>
                        <p className={styles.paragraph}>
                            Широчайший функционал и возможности, маскимальная
                            безопасность, фильтрация нежелательных клиентов и
                            ботов, удобный и понятный интерфейс, радушная
                            техподдержка,все это - <mark>BEAUTYSPUTNIK</mark>!
                        </p>
                    </div>
                    <div className={styles.lightBg}>
                        <div className={styles.inner}>
                            <div className={styles.title}>
                                ВАШЕМУ САЛОНУ ПРЕДОСТАВЛЯЕТСЯ
                            </div>
                            <div className={styles.list}>
                                <div className={styles.listItem}>
                                    <div className={styles.ico}>
                                        <img src={miniCite} alt="" />
                                    </div>
                                    <div className={styles.content}>
                                        <span className={styles.contentTitle}>
                                            1. КАРТОЧКА САЛОНА (Мини-сайт)
                                        </span>
                                        <span className={styles.contentText}>
                                            Максимально подробная и
                                            конструктивная карточка салона с
                                            описанием, портфолио, актуальным
                                            прайсом, описанием мастеров и
                                            акциями для клиентов! Разве нужно
                                            что-то еще?
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.listItem}>
                                    <div className={styles.ico}>
                                        <img src={journal} alt="" />
                                    </div>
                                    <div className={styles.content}>
                                        <span className={styles.contentTitle}>
                                            2. ЖУРНАЛ ЗАПИСИ
                                        </span>
                                        <span className={styles.contentText}>
                                            Пожалуй, самый удобный и простой в
                                            использовании журнал записи!
                                            Хронология статуса записи: <br />
                                            ЗАПИСЬ-В РАБОТЕ-ОЖИДАЕТ
                                            ОЦЕНКИ-ИСПОЛНЕНО <br />
                                            Возможность редактирования и
                                            переноса записи в режиме реального
                                            времени.
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.listItem}>
                                    <div className={styles.ico}>
                                        <img src={search} alt="" />
                                    </div>
                                    <div className={styles.content}>
                                        <span className={styles.contentTitle}>
                                            3. ПОИСК И РЕЗУЛЬТАТЫ ПОИСКА
                                            (списком, на карте)
                                        </span>
                                        <span className={styles.contentText}>
                                            Клиент выбирает КАТЕГОРИЮ, ДЕНЬ,
                                            ВРЕМЯ и УСЛУГУ. Все остальное за
                                            него сделает{' '}
                                            <mark>BEAUTYSPUTNIK</mark>! <br />
                                            Каждый вариант предоставления
                                            результатов поиска прекрасен
                                            по-своему. Но их объединяет одно -
                                            максимальное качество, комфорт,
                                            удобство выбора и ортировки, и
                                            фоинальный процесс записи в салон.
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.listItem}>
                                    <div className={styles.ico}>
                                        <img src={list} alt="" />
                                    </div>
                                    <div className={styles.content}>
                                        <span className={styles.contentTitle}>
                                            4. СПИСОК МАСТЕРОВ
                                        </span>
                                        <span className={styles.contentText}>
                                            У каждого мастера свой список услуг!
                                            Свой коэффициент, если это
                                            ТОП-мастер или стажер. История
                                            профессионального роста и общий стаж
                                            тоже есть. А если мастером был
                                            недоволен предыдущий салон, вы тоже
                                            об этом узнаете.
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.listItem}>
                                    <div className={styles.ico}>
                                        <img src={clientsList} alt="" />
                                    </div>
                                    <div className={styles.content}>
                                        <span className={styles.contentTitle}>
                                            5. СПИСОК КЛИЕНТОВ
                                        </span>
                                        <span className={styles.contentText}>
                                            О, ДА! Наконец-то взаимодействие с
                                            клиентом стало максимально простым и
                                            удобным! Вы видите последнее время
                                            посещения, популярную услугу,
                                            количество посещений, персональную
                                            скидку и программу лояльности! А еще
                                            Вы можете видеть рейтинг клиента.
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.listItem}>
                                    <div className={styles.ico}>
                                        <img src={price} alt="" />
                                    </div>
                                    <div className={styles.content}>
                                        <span className={styles.contentTitle}>
                                            6. ПРАЙС-ЛИСТ
                                        </span>
                                        <span className={styles.contentText}>
                                            Добавляйте новые услуги или
                                            категории в свой салон из
                                            стандартизированного списка. Меняйте
                                            цены хоть 100 раз в день! <br />
                                            Корректируйте продолжительность
                                            услуги, если у вас трудятся
                                            выдающиеся мастера.
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.listItem}>
                                    <div className={styles.ico}>
                                        <img src={rating} alt="" />
                                    </div>
                                    <div className={styles.content}>
                                        <span className={styles.contentTitle}>
                                            7. РЕЙТИНГ КЛИЕНТОВ
                                        </span>
                                        <span className={styles.contentText}>
                                            Пожалуй, самая востребованная и
                                            недостающая информация в нашем
                                            удивительном мире! <br /> А у нас
                                            она есть!
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.listItem}>
                                    <div className={styles.ico}>
                                        <img src={reviews} alt="" />
                                    </div>
                                    <div className={styles.content}>
                                        <span className={styles.contentTitle}>
                                            8. САМЫЕ ЧЕСТНЫЕ ОТЗЫВЫ
                                        </span>
                                        <span className={styles.contentText}>
                                            Рейтинги салонов и мастеров
                                            считаются платформой автоматически и
                                            мы никак на них не влияем. Отзывы же
                                            проверяются нами вручную и только
                                            после этого публикуются. Если оценка
                                            или отзыв слишком низкие, мы
                                            разбираемся что не устроило клиента.
                                            В случае, если отзыв достоверен, мы
                                            его публикуем вне зависимости от его
                                            оценки.
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.listItem}>
                                    <div className={styles.ico}>
                                        <img src={loyality} alt="" />
                                    </div>
                                    <div className={styles.content}>
                                        <span className={styles.contentTitle}>
                                            9. ПРОГРАММЫ ЛОЯЛЬНОСТИ И АКЦИИ
                                        </span>
                                        <span className={styles.contentText}>
                                            Когда придет время, система сама
                                            напомнит дать клиенту скидку или
                                            подарок! А еще скидку можно всегда
                                            изменить. Программа лояльности
                                            считает количество посещений
                                            автоматически, что исключает подлог
                                            со стороны клиента или
                                            администратора. Безусловно, каждый
                                            гость на вашей карточке салона видит
                                            все акции!
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.inner}>
                        <div className={styles.subTitle}>
                            УЖЕ ХОТИТЕ РАЗМЕСТИТЬ САЛОН ИЛИ ОСТАЛИСЬ ЕЩЕ
                            ВОПРОСЫ?
                        </div>
                        <a href="tel:+79671085108" className={styles.mainLink}>
                            8 <br />
                            967 <br />
                            108-5-108
                        </a>
                        <div className={`${styles.subTitle} ${styles.smallMg}`}>
                            (ТЕЛ/WHATSAPP/TELEGRAM) <br />
                            ЗВОНИТЕ И ПИШИТЕ - МЫ ОТВЕТИМ НА ВСЕ ВОПРОСЫ!
                        </div>
                    </div>
                </div>
            </Layout>
            {/*@ts-ignore*/}
            <PageFoot />
        </>
    );
};
