import { Header } from 'antd/es/layout/layout';
import React, {
    useEffect,
    useState,
    useRef,
    useMemo,
    useCallback,
} from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import {
    Button,
    Dropdown,
    Tooltip,
    Input,
    Modal,
    Image,
    Carousel,
    Checkbox,
    Popover,
} from 'antd';
import SideMenu from '../../Components/SideMenu';
import './PageParts.scss';
import { UserOutlined } from '@ant-design/icons';
import MaskedInput from 'react-maskedinput';

import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { apiBaseMedia, apiBase } from '../Const.js';
import { useLocationContext } from '../../Services/Location.tsx';
import { isProduction } from '../../utils/common.ts';
import { MediaPoint, useMediaContext } from '../../Services/Media.tsx';
import { Footer } from '../../Components/Common/footer.tsx';

export function PageHead(x) {
    const { media } = useMediaContext();

    const { data } = useLocationContext();
    // =========== Заголовок страницы, "шапка" ===========
    const ttOpen = (ev) => {
            //debugger;
        },
        inMouse = (ev) => {
            //debugger;
            ev.stopPropagation();
        },
        $stor = useSelector((state) => {
            $stor?.tableSource &&
                ((s) => {
                    //console.log(s.tableSource, s);
                })($stor);
            return state;
        }),
        hellip = '…', // троеточие
        loggedNDefa = <>Как Вас зовут?</>, // как отображается имя в шапке в первый момент
        nameInHeader = (s) => {
            let lN = $stor?.auth.loggedName || ''; // Ограничение длиной 22
            if (lN.length < 2) lN = s;
            return lN;
        },
        urlLogin = `${process.env.REACT_APP_REMOTE_HOST}auth/signin`,
        [loginVisi, setLoginVisi] = useState(),
        login = (ev, name, pasw) => {
            // если !ev, то вход по логину и паролю
            if (
                ev &&
                ev.type === 'keypress' &&
                (ev.code !== 'Enter' || ev.currentTarget.id !== 'inp4_2')
            )
                return; // вход по Enter и отсечка не-Enter клавиш
            //TODO навигацию по кл. Tab
            //TODO блокировать клик Tab и Enter на 1-м поле
            axios
                .post(
                    urlLogin,
                    {
                        publicKey: ev
                            ? `7${(
                                  ev.currentTarget.parentNode.parentNode.querySelector(
                                      '#inp4_1'
                                  )?.value ?? ''
                              ).replace(/\D*/g, '')}`
                            : name, // '7' в начале и без пробелов, скобок и дефисов - формат логина
                        secretKey: ev
                            ? ev.currentTarget.parentNode.parentNode.querySelector(
                                  '#inp4_2'
                              )?.value
                            : pasw,
                    },
                    {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }
                )
                .then((res) => {
                    // TODO БЕК Нужна обработка ошибок, когда 2-й сервис недоступен или дал ошибку
                    const userLogin = (
                        (JSON.parse(res.config.data) || {}).publicKey || ''
                    ).substr(1);
                    // читаем профиль ради показа имени в хедере страницы
                    const remoteUrlP =
                        process.env.REACT_APP_REMOTE_HOST +
                        (0 ? `client/${0}` : 'auth/me'); //TODO && role !=='client'
                    axios
                        .get(remoteUrlP, {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${res.data?.accessToken}`,
                            },
                        })
                        .then((res2) => {
                            //debugger;
                            localStorage.setItem(
                                'accessTokenBTS',
                                res.data.accessToken
                            );
                            dispatch({
                                type: 'ME-PROFILE',
                                meProfile: res2.data, // TODO профиль может быть чужой (при чтении по id)
                            });
                            //setProf(res2.data); // для компонента - сохранён профиль, передёрнуты данные
                            let lN = `${res2.data?.firstName || ''} ${
                                res2.data?.lastName || ''
                            }`;
                            if (lN.length > 23) lN = lN.substr(0, 21) + hellip; // Ограничение длиной 22
                            if (lN.length < 2) lN = userLogin || loggedNDefa;
                            setLoggedName(lN);
                            setLogged(true);
                            dispatch({
                                type: 'AUTH-LOGIN',
                                auth: {
                                    userLogin,
                                    accessToken: res.data.accessToken,
                                    refreshToken: res.data.refreshToken,
                                    loggedName: lN,
                                    firstNameOld: res2.data?.firstName || '',
                                    lastNameOld: res2.data?.lastName || '',
                                    role: res2.data?.role || '',
                                    salonCustomer:
                                        res2.data?.salons &&
                                        res2.data?.salons[0]
                                            ? res2.data?.salons[0].id
                                            : [],
                                },
                            });
                            //закрыть окно логина при успехе логина в случае нажатия мышью "Войти в"
                            setLoginVisi(false);
                        });
                });
        },
        logout = (ev) => {
            setLogged(false); // включение видимости кнопок логина
            dispatch({ type: 'AUTH-LOGOUT' }); // выход из стора (и прописывание в сторадже)
            hist.push('/');
            setLoginVisi(true);
        };

    const loginForm = useMemo(
        () => [
            {
                key: '4',
                label: (
                    <ul
                        className="loginList"
                        onMouseUp={inMouse}
                        onClick={inMouse}
                    >
                        <li>
                            <label htmlFor="inp4_1">Логин:</label>
                            <Input id="inp4_1" />
                        </li>
                        <li>
                            <label htmlFor="inp4_2">Пароль:</label>
                            <Input
                                type="password"
                                id="inp4_2"
                                onKeyPress={login}
                            />
                        </li>
                        <li>
                            <Button onClick={login} className="loginAction">
                                Войти в систему
                            </Button>
                        </li>
                    </ul>
                ),
            },
            {
                key: '5',
                label: (
                    <span
                        onClick={(ev) => {
                            registerAsClientVosst(ev);
                        }}
                    >
                        Забыли пароль?
                    </span>
                ),
            },
        ],
        []
    );
    const hist = useHistory();
    //debugger
    const urlPhoneReq = `${process.env.REACT_APP_REMOTE_HOST}auth/phone/request`,
        urlPhoneCode = `${process.env.REACT_APP_REMOTE_HOST}auth/phone/code`,
        urlPhonePasw = `${process.env.REACT_APP_REMOTE_HOST}auth/password/change-by-token`,
        dispatch = useDispatch();
    const authPhoneReq = (phone) => {
        axios
            .post(
                urlPhoneReq,
                {
                    phone: `7${phone.replace(/[ ()+-]/g, '')}`, //переформат из (123) 456-78-90 в 71234567890
                },
                {
                    headers: {
                        accept: '*/*',
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((res) => {
                // TODO БЕК Нужна обработка ошибок, когда 2-й сервис недоступен или дал ошибку
                dispatch({
                    type: 'AUTH-PHONE-REQUEST',
                    userPhone: res.data,
                });
            });
    };
    const authPhoneCode = (code, phone) => {
        // TODO Auth
        axios
            .post(
                urlPhoneCode,
                {
                    code,
                    phone: `7${phone.replace(/[ ()+-]/g, '')}`,
                },
                {
                    headers: {
                        accept: '*/*',
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((res) => {
                setStep2token(res?.data?.accessToken);
                dispatch({
                    type: 'AUTH-PHONE-CODE',
                    userCode: res.data,
                });
                if (res.status < 400) nextStep();
                return res.status; // Проверка успешности команды стороннего сервера
            })
            .catch((er) => {
                console.log(
                    '==erStep2',
                    er?.[0] === '{' && JSON.stringify(er),
                    er
                );
                if (er.status < 400)
                    // с ошибкой по статусу
                    nextStep();
                return er.status;
            });
    };
    const authPasw = (password) => {
        // TODO Auth
        axios
            .post(
                urlPhonePasw,
                { password },
                {
                    headers: {
                        accept: '*/*',
                        Authorization: `Bearer ${step2token}`, //вставить токен из ответа предыдущего шага
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((res) => {
                //debugger;
                login(null, `7${(telVal ?? '').replace(/\D*/g, '')}`, password);
                setLogged(true); // в шапке отмечается переход в залогиненное состояние в случае успеха логина
                dispatch({
                    type: 'AUTH-PHONE-PASW',
                    userToken: res.data,
                });
            });
    };
    useEffect(() => {
        const tx = document.querySelector('.ttLocation .ant-tooltip-inner');
        if (tx?.innerHTML)
            tx.innerHTML = tx.innerHTML.replace(
                /\*\*\*/,
                '<button>Да</button> <button>Нет</button>'
            );

        setLogged(!!$stor.auth?.accessToken); // видимость кнопок логина-регистрации в шапке
    });

    const [vosst, setVosst] = useState(false); // режим диалога: регистрация или восстановление
    const [asSalon, setAsSalon] = useState(false); // TODO режим регистрации клиент или менеджер салона

    const modalCancel = () => {
        setModalOpen(false);
    };
    const [modalOpen, setModalOpen] = useState(false); // видимость нужного попапа (groupServices[].value)
    const registerAsClientVosst = (ev) => {
        setLoginVisi(false);
        registerAsClient(ev, true);
    };
    const registerAsClient = (ev, vossta) => {
            setAsSalon(false);
            setVosst(!!vossta); // показывает заголовок регистрации при false, или восстановления
            const regEl = document.querySelector('.register');
            regEl?.classList.add('asSalon');
            if (stepCount > 4 || !stepCount) {
                // восстановить пустые поля формы регистрации (клиента)
                setTelVal('');
                setFourDigitVal('');
                setPas('');
                setChkPas();
                setStepCount(1);
                focusInp(0);
            } else focusInp(stepCount - 1);
            setModalOpen(true);
        },
        registerAsSalon = (ev) => {
            setAsSalon(true);
            setVosst(false);
            const regEl = document.querySelector('.register');
            regEl?.classList.add('asSalon');
            if (stepCount > 4) {
                setTelVal('');
                setFourDigitVal('');
                setPas('');
                setChkPas();
                setStepCount(0);
                focusInp(-1);
            } else focusInp(stepCount - 1);
            setModalOpen(true);
        };
    // registerPopup = [
    //     {
    //         key: '6',
    //         label: <div onClick={registerAsClient}>Зарегистрироваться</div>,
    //     },
    //     {
    //         key: '7',
    //         label: (
    //             <div onClick={registerAsSalon}>
    //                 Зарегистрироваться как салон
    //             </div>
    //         ),
    //     },
    // ]
    const [telHtml, setTelHtml] = useState(
        '<i>9</i><i>6</i><i>6</i>-<i>8</i><i>5</i><i>&nbsp;</i>-<i>&nbsp;</i><i' +
            '>&nbsp;</i><i>&nbsp;</i><i>&nbsp;</i>'
    ); // шаблон телефона
    const [digHtml, setDigHtml] = useState(
        '<i>2</i><i>&nbsp;</i><i>&nbsp;</i><i>&nbsp;</i>'
    ); // шаблон 4 цифр
    const [stepCount, setStepCount] = useState(0); // шаг мастера диалога регистрации
    const editChange = (ev) => {
            // TODO менять формат под правильный и ставить курсор
            //для <span className="ed" contentEditable onInput={editChange} dangerouslySetInnerHTML={{__html: telHtml}} />
            const eN = ev.nativeEvent;
            if (eN.inputType === 'insertText' && isNaN(+eN.data)) {
                ev.target.innerHTML = telHtml;
                return telHtml;
            }
            const s = ev.target.innerText
                .split('\n')
                .map((el) => {
                    return el === '-'
                        ? el
                        : `<i>${el.length ? el[el.length - 1] : '&nbsp;'}</i>`;
                })
                .join(''); // TODO научить курсор передвигаться вправо; парсить удаления
            ev.target.innerHTML = s;
            setTelHtml(s);
        },
        editChange4dig = (ev) => {
            const eN = ev.nativeEvent;
            if (eN.inputType === 'insertText' && isNaN(+eN.data)) {
                ev.target.innerHTML = digHtml;
                return digHtml;
            }
            const s = ev.target.innerText
                .split('\n')
                .map((el) => {
                    return el === '-'
                        ? el
                        : `<i>${el.length ? el[el.length - 1] : '&nbsp;'}</i>`;
                })
                .join('\n'); // TODO научить курсор передвигаться вправо; парсить удаления
            ev.target.innerHTML = s;
            setDigHtml(s);
        },
        step1to2 = (x) => {
            authPhoneReq(telVal);
            nextStep();
        },
        step2to3 = (x) => {
            const statusTemp = authPhoneCode(+fourDigitVal, telVal); // нет nextStep(); , если 4xx-5xx
            console.log('==statusStep2', statusTemp);
        },
        step3to4 = (x) => {
            authPasw(pas); // логинится в случае успеха
            nextStep();
        },
        nextStep = (x) => {
            setStepCount(stepCount + 1);
            focusInp(stepCount);
        },
        toStep1 = (x) => {
            setStepCount(1);
        };
    const [telVal, setTelVal] = useState(''), // номер тел. при регистрации, без +7 и только цифры
        chgTelVal = (ev) => {
            setTelVal(ev.target.value);
        };
    const [fourDigitVal, setFourDigitVal] = useState(''),
        chgFourDigitVal = (ev) => {
            setFourDigitVal(ev.target.value);
        },
        [step2token, setStep2token] = useState(''), // токен Bearer временной авторизации
        [pas, setPas] = useState(''), // первый пароль при регистрации
        [chkPas, setChkPas] = useState(''),
        chgPas = (ev) => {
            const v = ev.target.value;
            console.log(
                '==Pasw.valid',
                v.length < 7,
                v !== chkPas,
                !/^[\x00-\x7F]+$/.test(v),
                !/[A-Z$]/.test(v),
                !/\d/.test(v)
            );
            setPas(v);
        },
        inpPas = (ev) => {
            setChkPas(ev.target.value);
        },
        focusInp = (x) => {
            setTimeout(
                () =>
                    (
                        document.querySelector(
                            '.dialog.step' + (x + 1) + ' input'
                        ) ||
                        document.querySelector(
                            '.dialog.step' + (x + 1) + ' .srchAction'
                        )
                    )?.focus(),
                0
            );
        };
    const [logged, setLogged] = useState(false); // показ имени или телефона юзера вместо кнопок логина и регистрации
    const [loggedName, setLoggedName] = useState(
        <>{nameInHeader(loggedNDefa)}</>
    );
    const ddownRef = useRef(null);
    const urlAddSalonId = (x) => {
        // const a = x.domEvent.currentTarget.querySelector('a');
        if (/customer|admin/.test($stor.auth.role) && $stor.auth.salonCustomer)
            hist.push('/salon/cabinet/' + $stor.auth.salonCustomer); // дорисовали ид салона
    };
    const userLinks = [
        {
            key: 'u1',
            label: <Link to="/profile">Мой профиль</Link>,
        },
        {
            key: 'u2',
            label: (
                <Link to={`/salon/cabinet/${$stor?.auth?.salonCustomer ?? ''}`}>
                    Мой салон
                </Link>
            ),
            onClick: urlAddSalonId,
        },
    ];

    const mobileHeaderMenu = useMemo(() => {
        let result = [];

        if ($stor?.auth?.accessToken) {
            if (
                $stor?.auth?.role === 'customer' ||
                $stor?.auth?.role === 'selfEmployer'
            ) {
                result.push(
                    {
                        key: 'profile',
                        label: (
                            <Link to="/profile" title="Мой профиль">
                                {loggedName}
                            </Link>
                        ),
                    },
                    {
                        key: 'u2',
                        label: (
                            <Link
                                to={`/salon/cabinet/${
                                    $stor?.auth?.salonCustomer ?? ''
                                }`}
                            >
                                Мой салон
                            </Link>
                        ),
                        onClick: urlAddSalonId,
                    }
                );
            } else {
                result.push({
                    key: 'profile',
                    label: (
                        <Link to="/profile" title="Мой профиль">
                            {loggedName}
                        </Link>
                    ),
                });
            }

            result.push({
                key: 'logout',
                label: <span onClick={logout}>Выйти</span>,
            });
        }

        if (!$stor?.auth?.accessToken) {
            result.push(
                {
                    key: 'register',
                    label: (
                        <span onClick={registerAsClient}>
                            Зарегистрироваться
                        </span>
                    ),
                },
                {
                    key: 'login',
                    label: (
                        <Dropdown
                            menu={{ items: loginForm }}
                            trigger={['click']}
                            placement="bottomLeft"
                            overlayClassName="header-ant-dropdown login"
                            open={loginVisi}
                        >
                            <span>Войти</span>
                        </Dropdown>
                    ),
                }
            );
        }

        return result;
    }, [
        loginForm,
        loginVisi,
        loggedName,
        urlAddSalonId,
        registerAsClient,
        data,
        logout,
        $stor,
    ]);

    const [isPrivacyChecked, setPrivacyChecked] = useState(false);

    const onPrivacyChange = useCallback((value) => {
        setPrivacyChecked(value.target.checked);
    }, []);

    return (
        <Header className={`header${logged ? ' logged' : ''}`}>
            <div className="headerIn">
                {media === MediaPoint.MOBILE ? (
                    <Dropdown
                        menu={{
                            items: mobileHeaderMenu,
                        }}
                        trigger={['click']}
                        overlayClassName="header-ant-dropdown bt-burg"
                    >
                        <div className="bt-header__burger">
                            <span />
                            <span />
                            <span />
                        </div>
                    </Dropdown>
                ) : (
                    <>
                        {$stor?.auth?.accessToken ? (
                            <Button
                                className="logout right"
                                type="default"
                                shape="round"
                                onClick={logout}
                            >
                                Выйти
                            </Button>
                        ) : null}
                        {!$stor?.auth?.accessToken ? (
                            <>
                                <div className="location right forLogReg">
                                    <div>
                                        <Button
                                            className="bfButtReg"
                                            type="default"
                                            shape="round"
                                            onClick={registerAsClient}
                                        >
                                            Зарегистрироваться
                                        </Button>
                                    </div>
                                </div>
                                <Popover
                                    placement="topLeft"
                                    trigger={'click'}
                                    overlayClassName={'bs_login_popover'}
                                    open={loginVisi}
                                    onOpenChange={setLoginVisi}
                                    content={
                                        <div>
                                            <ul
                                                className="loginList"
                                                onMouseUp={inMouse}
                                                onClick={inMouse}
                                            >
                                                <li>
                                                    <label htmlFor="inp4_1">
                                                        Логин:
                                                    </label>
                                                    <Input id="inp4_1" />
                                                </li>
                                                <li>
                                                    <label htmlFor="inp4_2">
                                                        Пароль:
                                                    </label>
                                                    <Input
                                                        type="password"
                                                        id="inp4_2"
                                                        onKeyPress={login}
                                                    />
                                                </li>
                                                <li>
                                                    <Button
                                                        onClick={login}
                                                        className="loginAction"
                                                    >
                                                        Войти в систему
                                                    </Button>
                                                </li>
                                            </ul>
                                            <span
                                                onClick={(ev) => {
                                                    registerAsClientVosst(ev);
                                                }}
                                                className="forgot_pswd_log_popover"
                                            >
                                                Забыли пароль?
                                            </span>
                                        </div>
                                    }
                                >
                                    <Button
                                        className="bfButtLogin"
                                        type="primary"
                                        shape="round"
                                    >
                                        Войти
                                    </Button>
                                </Popover>
                            </>
                        ) : (
                            <div className="nickname right">
                                <Dropdown
                                    menu={{
                                        items:
                                            $stor?.auth?.role === 'customer' ||
                                            $stor?.auth?.role === 'selfEmployer'
                                                ? userLinks
                                                : [userLinks[0]],
                                    }}
                                    className="location right dropdown"
                                    onChange2={urlAddSalonId}
                                    trigger={['click']}
                                    overlayClassName="header-ant-dropdown region"
                                >
                                    <div>
                                        <UserOutlined />{' '}
                                        <Link to="/profile" title="Мой профиль">
                                            {loggedName}
                                        </Link>
                                    </div>
                                </Dropdown>
                            </div>
                        )}
                        <div className="location right">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div className="geoloc right" />
                                <div ref={ddownRef}>
                                    {media === MediaPoint.DESkTOP
                                        ? 'Ваш регион: '
                                        : ''}
                                    {data?.ruName}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="siteLogoTxt">
                    <Link to="/" className="logoImg" />
                </div>
                <Modal
                    className="register"
                    forceRender={true}
                    maskClosable={true}
                    maskStyle={{ background: 'rgba(0,0,0,0.7)' }}
                    footer={null}
                    open={modalOpen}
                    onCancel={modalCancel}
                    width={345}
                >
                    <div
                        className={`dialog step0${
                            stepCount === 0 ? '' : ' hide'
                        }`}
                    >
                        <div className="dialogHead">
                            {vosst ? 'Восстановление' : 'Регистрация'}
                        </div>
                        <div className="dialogBody">
                            <div className="dialogBodyIn">
                                Форма 4 + 4 шагов регистрации/восстановления и
                                того же для менеджера салона
                            </div>
                        </div>
                        <div className="action">
                            <Button className="srchAction" onClick={nextStep}>
                                Далее
                            </Button>
                        </div>
                    </div>

                    <div
                        className={`dialog step1${
                            stepCount === 1 ? '' : ' hide'
                        }`}
                    >
                        <div className="dialogHead">
                            {vosst ? 'Восстановление' : 'Регистрация'}
                        </div>
                        <div className="dialogBody">
                            <dl className="dialogBodyIn">
                                <dt>Введите Ваш номер телефона</dt>
                                <dd className="phone">
                                    <span className="phoneCountry">+7 </span>
                                    <MaskedInput
                                        className="phoneNumber"
                                        value={telVal}
                                        mask="(111) 111-11-11"
                                        placeholder="(000) 000-00-00"
                                        size="16"
                                        onChange={chgTelVal}
                                    />
                                </dd>
                            </dl>
                            <dl className="dialogBodyIn">
                                <dd className="acceptPrivacy">
                                    <Checkbox
                                        checked={isPrivacyChecked}
                                        onChange={onPrivacyChange}
                                    >
                                        Согласен на обработку персональных
                                        данных
                                    </Checkbox>
                                </dd>
                                <NavLink to={'#'} className="acceptPrivacyLink">
                                    Пользовательское соглашение
                                </NavLink>
                            </dl>
                        </div>
                        <div className="action">
                            <Button
                                className="srchAction"
                                disabled={
                                    /_|^$/.test(telVal) || !isPrivacyChecked
                                }
                                onClick={step1to2}
                            >
                                Далее
                            </Button>
                        </div>
                    </div>
                    <div
                        className={`dialog step2${
                            stepCount === 2 ? '' : ' hide'
                        }`}
                    >
                        <div className="dialogHead">
                            {vosst ? 'Восстановление' : 'Регистрация'}
                        </div>
                        <div className="dialogBody">
                            <dl className="dialogBodyIn">
                                <div>Сейчас Вам поступит от нас звонок.</div>
                                <div>Введите последние 4 цифры.</div>
                                <dt>Отвечать на звонок не нужно.</dt>
                                <dd className="digits">
                                    <MaskedInput
                                        className="phoneDigits4"
                                        value={fourDigitVal}
                                        mask="1111"
                                        placeholder="0000"
                                        size="6"
                                        onChange={chgFourDigitVal}
                                    />
                                </dd>
                            </dl>
                            <Button
                                className="srchAction"
                                disabled={/_|^$/.test(fourDigitVal)}
                                onClick={step2to3}
                            >
                                Далее
                            </Button>
                        </div>

                        <div className="action">
                            <Button
                                type="text"
                                className="srchAction"
                                onClick={toStep1}
                            >
                                Не получили код?
                            </Button>
                        </div>
                    </div>

                    <div
                        className={`dialog step3${
                            stepCount === 3 ? '' : ' hide'
                        }`}
                    >
                        <div className="dialogHead">
                            {vosst ? 'Восстановление' : 'Регистрация'}
                        </div>
                        <div className="dialogBody">
                            <dl className="dialogBodyIn">
                                <div>Придумайте свой пароль.</div>
                                <div>
                                    <span
                                        className={`lat${
                                            !/^[\x00-\x7F]+$/.test(pas)
                                                ? ' note'
                                                : ''
                                        }`}
                                    >
                                        Латинские буквы.{' '}
                                    </span>
                                    <span
                                        className={`ge7${
                                            pas.length < 7 ? ' note' : ''
                                        }`}
                                    >
                                        Не менее 7 символов.
                                    </span>
                                </div>
                                <dt>
                                    <span
                                        className={`cap${
                                            !/[A-Z$]/.test(pas) ? ' note' : ''
                                        }`}
                                    >
                                        1 заглавная буква.{' '}
                                    </span>
                                    <span
                                        className={`dig${
                                            !/\d/.test(pas) ? ' note' : ''
                                        }`}
                                    >
                                        1 цифра.
                                    </span>
                                </dt>
                                <dd className="pwds">
                                    <input value={pas} onInput={chgPas} />
                                    <br />
                                    <input
                                        placeholder="Повторите пароль"
                                        onInput={inpPas}
                                    />
                                </dd>
                            </dl>
                        </div>
                        <div
                            className={`action${
                                pas.length < 7 ||
                                pas !== chkPas ||
                                !/^[\x00-\x7F]+$/.test(pas) ||
                                !/[A-Z$]/.test(pas) ||
                                !/\d/.test(pas)
                                    ? ' hide'
                                    : ''
                            }`}
                        >
                            <Button
                                className="srchAction"
                                onClick={step3to4}
                                disabled={
                                    pas.length < 7 ||
                                    pas !== chkPas ||
                                    !/^[\x00-\x7F]+$/.test(pas) ||
                                    !/[A-Z$]/.test(pas) ||
                                    !/\d/.test(pas)
                                }
                            >
                                Далее
                            </Button>
                        </div>
                    </div>

                    <div
                        className={`dialog step4${
                            stepCount === 4 ? '' : ' hide'
                        }`}
                    >
                        <div className="dialogHead">
                            {vosst ? 'Восстановление' : 'Регистрация'}
                        </div>
                        <div className="dialogBody">
                            <div>Благодарим Вас за регистрацию!</div>
                            <dl className="dialogBodyIn">
                                <dt>
                                    Ваш логин{' '}
                                    <small>(номер телефона без +7)</small>:
                                </dt>
                                <dd className="phone">
                                    <span>{telVal.replace(/\D/g, '')}</span>
                                </dd>
                            </dl>
                            <dl className="dialogBodyIn">
                                <dt>Пароль:</dt>
                                <dd className="pasw">
                                    <span>{pas}</span>
                                </dd>
                            </dl>
                            <Button
                                className="srchAction"
                                onClick={() => {
                                    setStepCount(99);
                                    modalCancel();
                                }}
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Header>
    );
}

export const SideMen2 = () => {
    const { media } = useMediaContext();

    if (isProduction || media !== MediaPoint.DESkTOP) {
        return null;
    }

    return (
        <div className="ant-layout-has-sider">
            <SideMenu />
        </div>
    );
};

// ======= показ баннеров (задаются админом в /media/file-upload (тип 'banner') и POST /banner) =======
export async function BanrHeadData(props) {
    return await axios.get(`${apiBase}banner/all`);
}
export function BanrHead(x) {
    const [banr, setBanr] = useState([]);
    const [banrOnce, setBanrOnce] = useState(0); // TODO проскакивает 2 раза !

    useEffect(() => {
        if (!banrOnce) {
            setBanrOnce(() => 1);

            BanrHeadData().then((res) => {
                // eslint-disable-line no-unused-expressions
                setBanr(res.data);
            });
        }
    }, []);

    return (
        <div className="stackBlock banr">
            <div className="stackIn">
                {x.hide ? (
                    <>
                        &lt;BanrHead
                        hide=process.env.REACT_APP_VISUAL_FOR_DEV&gt;
                    </>
                ) : (
                    <Carousel autoplay autoplaySpeed={4000} rows={1}>
                        {banr.map((el) => {
                            return (
                                <div key={el.media?.fileName + el.mediaId}>
                                    <a
                                        target="_blank"
                                        href={el.url}
                                        key={el.mediaId}
                                        data-id={el.mediaId}
                                        data-oid={el.ownerId}
                                    >
                                        <Image
                                            src={
                                                apiBaseMedia +
                                                el.media?.fileName
                                            }
                                            alt={el.mediaId}
                                            preview={false}
                                        />
                                    </a>
                                </div>
                            );
                        })}
                    </Carousel>
                )}
            </div>
        </div>
    );
}

export const PageFoot = class extends React.Component {
    render() {
        return <Footer />;
    }
};

export const SetStar = class extends React.Component {
    render() {
        return (
            <div className="setStar">
                <span className="footBlock">1</span>
                <span className="footBlock">2</span>
                <span className="footBlock">3</span>
                <span className="footBlock">4</span>
                <span className="footBlock">5</span>
            </div>
        );
    }
};

export const splideOpts = {
    type: 'slide',
    rewind: true,
    autoplay: true,
    drag: 'free',
    perPage: 4,
    autoWidth: true, // TODO max-width картинки (?)
    height: 250,
    //autoScroll: {speed: 1}, // TODO для плагина - не работает для reacr-splide (?)
    interval: 12000,
    start: 0,
};
