import { Layout } from 'antd';
import React from 'react';
import { PageHead } from '../part/PageParts';
import SideMenu from '../../Components/SideMenu';
import SalonDataComponent from './component/SalonDataComponent';

function Salon({ match }) {
    const {
        params: { url },
    } = match;
    return (
        <Layout>
            <PageHead />
            <Layout>
                <SideMenu />
                <SalonDataComponent />
            </Layout>
        </Layout>
    );
}
export default Salon;
