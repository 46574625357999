import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    EventByMaster,
    IEventStatus,
    IServerEvent,
} from '../../../../Types/ScheduleTypes.ts';
import moment from 'moment';
import { enumStatusToText } from '../EventsList.tsx';
import { Popconfirm, Tooltip } from 'antd';
import { IModalPrevState } from '../SalonSheduleTab.tsx';
import {
    DISABLED_MASTER_STATUS,
    ScheduleType,
} from '../../../../Modals/AddScheduleModalForm.tsx';
import { DeleteOutlined } from '@ant-design/icons';

import styles from '../SalonShedule.module.scss';

interface Props {
    hours: number[];
    eventByMaster: EventByMaster;
    setModalPrevState: Dispatch<SetStateAction<undefined | IModalPrevState>>;
    handleCancelEvent: (eventId: string) => Promise<void>;
}

interface EventProps {
    event: IServerEvent;
    hours: number[];
    setModalPrevState: Dispatch<SetStateAction<undefined | IModalPrevState>>;
    handleCancelEvent: (eventId: string) => Promise<void>;
}

const ScheduleEvent: FC<EventProps> = ({
    event,
    hours,
    setModalPrevState,
    handleCancelEvent,
}) => {
    const elemRef = useRef<HTMLDivElement>();
    const [haveAccord, setHaveAccord] = useState(false);

    useEffect(() => {
        if (elemRef && elemRef.current) {
            if (elemRef.current.clientHeight < elemRef.current.scrollHeight) {
                setHaveAccord(true);
            } else {
                setHaveAccord(false);
            }
        }
    }, []);

    const topPos = useMemo(
        () =>
            100 +
            (moment(event.dateStart).hours() - hours[0]) * 100 +
            (100 / 60) * moment(event.dateStart).minutes(),
        [event, hours]
    );

    const elemHeight = useMemo(() => {
        const endsToday =
            moment(event.dateEnd).format('DD-MM-YYYY') ===
            moment(event.dateStart).format('DD-MM-YYYY');

        const dateEnd = endsToday ? event.dateEnd : moment().endOf('day');

        const hoursBetween = moment
            .duration(moment(dateEnd).diff(event.dateStart))
            .asHours();

        return 100 * hoursBetween - (moment(dateEnd).minutes() === 0 ? 4 : 0);
    }, [event]);

    const bgColor = useMemo(() => {
        switch (event.status) {
            case IEventStatus.IN_PROGRESS:
                return '#009800';
            case IEventStatus.PLANNED:
                return '#038bff';
            case IEventStatus.CANCELED:
            case IEventStatus.WAITING_FEEDBACK:
            case IEventStatus.DONE:
                return '#293C4D';
            case IEventStatus.DISABLED_MASTER:
                return '#898989';
            default:
                return '#293C4D';
        }
    }, [event.status]);

    const statusColor = useMemo(() => {
        switch (event.status) {
            case IEventStatus.DONE:
                return '#009800';
            case IEventStatus.WAITING_FEEDBACK:
                return '#FF4200';
            case IEventStatus.DISABLED_MASTER:
                return '#898989';
            default:
                return '#ffffff';
        }
    }, [event.status]);

    const handleEdit = useCallback(() => {
        let prevStateData: IModalPrevState = {
            dateStart: moment(event.dateStart),
            employer: event.employer.id,
            servicesIds: event.service.map((el) => el.id),
            existTimeSlotId: event.id,
            employerOff: event.employer.id,
            status: event.status,
        };

        switch (event.status) {
            case IEventStatus.CANCELED:
            case IEventStatus.DONE:
            case IEventStatus.WAITING_FEEDBACK:
                return;
            case IEventStatus.IN_PROGRESS:
            case IEventStatus.PLANNED:
                prevStateData = {
                    dateStart: moment(event.dateStart),
                    dateEnd: moment(event.dateEnd),
                    employer: event.employer.id,
                    servicesIds: event.service.map((el) => el.id),
                    existTimeSlotId: event.id,
                    status: event.status,
                    type: ScheduleType.schedule,
                    clientId: event.client.id,
                };
                break;
            case IEventStatus.DISABLED_MASTER:
                prevStateData = {
                    dateStart: moment(event.dateStart),
                    employerOff: event.employer.id,
                    existTimeSlotId: event.id,
                    status: event.status,
                    type:
                        event.statusDescription === DISABLED_MASTER_STATUS
                            ? ScheduleType.day_off
                            : ScheduleType.time_off,
                    dateEnd: event.dateEnd,
                    statusDescription: event.statusDescription,
                };
        }

        setModalPrevState(prevStateData);
    }, [event]);

    const handleDelete = useCallback(() => {
        void handleCancelEvent(event.id);
    }, [event]);

    const content = useMemo(
        () => (
            <div
                style={{
                    position: 'relative',
                }}
            >
                {event.status !== IEventStatus.CANCELED &&
                    event.status !== IEventStatus.DONE &&
                    event.status !== IEventStatus.WAITING_FEEDBACK && (
                        <div
                            className={styles.eventEdit}
                            onClick={handleEdit}
                        ></div>
                    )}
                {event.status !== IEventStatus.CANCELED &&
                    event.status !== IEventStatus.DONE &&
                    event.status !== IEventStatus.WAITING_FEEDBACK && (
                        <Popconfirm
                            title="Вы уверены что хотите отменить запись?"
                            onConfirm={handleDelete}
                            okText="Да"
                            cancelText="Нет"
                        >
                            <div
                                className={`${styles.eventEdit} ${styles.delete}`}
                            >
                                <DeleteOutlined />
                            </div>
                        </Popconfirm>
                    )}
                {event.client?.rating <= 3.0 && (
                    <Tooltip title="Низкий рейтинг у клиента">
                        <div className={styles.clientAlarm}>!</div>
                    </Tooltip>
                )}
                {event.status === IEventStatus.DISABLED_MASTER ? (
                    <div className={styles.evDisabledText}>
                        {event.statusDescription}
                    </div>
                ) : (
                    <>
                        <div>
                            {moment(event.dateStart).format('HH:mm')} -{' '}
                            {moment(event.dateEnd).format('HH:mm')}
                        </div>
                        <div>
                            {event.client?.firstName} {event.client?.lastName} (
                            {event.client?.rating.toLocaleString()})
                        </div>
                        <div>{event.client?.phone}</div>
                        <div>
                            {event.service
                                .reduce((res, acc) => res + acc.priceValue, 0)
                                .toLocaleString()}
                            р.
                        </div>
                        <div className={styles.eventPt}>
                            {event.service
                                .map((el) => el.service.title)
                                .join(' + ')}
                        </div>
                        <div className={styles.eventPt}>
                            Статус:{' '}
                            <span
                                className={styles.b}
                                style={{
                                    color: statusColor,
                                }}
                            >
                                {enumStatusToText(event.status)}
                            </span>
                        </div>
                    </>
                )}
            </div>
        ),
        [event, statusColor]
    );

    return (
        <div
            className={`${styles.event}${
                haveAccord ? ` ${styles.haveAccord}` : ''
            }`}
            style={{
                top: topPos,
                height: elemHeight,
                backgroundColor: bgColor,
            }}
            ref={elemRef}
        >
            {content}
        </div>
    );
};

export const SchedullerMasterCol: FC<Props> = ({
    hours,
    eventByMaster,
    setModalPrevState,
    handleCancelEvent,
}) => {
    const services: string[] = useMemo(
        () =>
            Object.values(
                eventByMaster.services.reduce((res, acc) => {
                    if (!res[acc.service.parent.id]) {
                        res = {
                            ...res,
                            [acc.service.parent.id]: acc.service.parent.title,
                        };
                    }

                    return res;
                }, {})
            ),
        []
    );

    return (
        <div className={styles.rightRow}>
            <div className={`${styles.rightRHead} ${styles.rightCol}`}>
                <div className={styles.colHead}>
                    <span className={styles.masterName}>
                        {eventByMaster.master.firstName}{' '}
                        {eventByMaster.master.lastName}
                    </span>
                    <div className={styles.rowButtons}>
                        <span
                            className={styles.rowBtn}
                            onClick={() => {
                                setModalPrevState({
                                    type: ScheduleType.schedule,
                                    employer: eventByMaster.id,
                                });
                            }}
                        >
                            Запись
                        </span>
                        <span
                            className={styles.rowBtn}
                            onClick={() => {
                                setModalPrevState({
                                    type: ScheduleType.time_off,
                                    employerOff: eventByMaster.id,
                                });
                            }}
                        >
                            Занят
                        </span>
                        <span
                            className={styles.rowBtn}
                            onClick={() => {
                                setModalPrevState({
                                    type: ScheduleType.day_off,
                                    employerOff: eventByMaster.id,
                                });
                            }}
                        >
                            Выходной
                        </span>
                    </div>
                </div>
                <div className={styles.serviceName}>
                    {services.map((el) => (
                        <span
                            style={{
                                flexBasis: `${100 / services.length}%`,
                            }}
                            key={el}
                        >
                            {el}
                        </span>
                    ))}
                </div>
            </div>
            {hours.map((el) => (
                <div className={styles.rightCol} key={el}>
                    {[0, 15, 30, 45].map((el) => (
                        <div key={el} className={styles.rightRowWrapper}>
                            {services.map((el) => (
                                <div
                                    key={el}
                                    className={styles.rightColRow}
                                    style={{
                                        flexBasis: `${100 / services.length}%`,
                                    }}
                                ></div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
            <div className={styles.eventsList}>
                {eventByMaster.events.map((el) => (
                    <ScheduleEvent
                        key={el.id}
                        event={el}
                        hours={hours}
                        setModalPrevState={setModalPrevState}
                        handleCancelEvent={handleCancelEvent}
                    />
                ))}
            </div>
        </div>
    );
};
