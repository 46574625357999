import 'react-app-polyfill/ie11'; // This must be the first line in src/salonMan.js
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import { LocationProvider } from './Services/Location.tsx';
import reducer from './store.js';
import { MediaProvider } from './Services/Media.tsx';
import { TimeService } from './Services/Time.tsx';

const store = createStore(reducer, applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <ConfigProvider locale={ruRU}>
                <MediaProvider>
                    <LocationProvider>
                        <TimeService>
                            <App />
                        </TimeService>
                    </LocationProvider>
                </MediaProvider>
            </ConfigProvider>
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
