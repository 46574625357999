import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, message } from 'antd';
import {
    ICreateService,
    IListedCategories,
    IPatchedService,
    IService,
    IServiceByCategory,
} from '../../../../Types/SalonTypes.ts';
import { CommonSalonsAPI } from '../../../../api/common/salons.ts';
import { useParams } from 'react-router-dom';
import { CategoryItem } from './CategoryItem.tsx';
import {
    AddServiceModal,
    IEditableCategory,
} from '../../../../Modals/AddServiceModal.tsx';

import styles from './SalonPriceList.module.scss';
import { EventsList } from '../EventsList.tsx';

export const SalonPriceList: FC<{
    selectedDate?: any;
    events?: any;
    handleSetNeedLoad?: any;
}> = ({ selectedDate, events, handleSetNeedLoad }) => {
    const { sId } = useParams();

    const [addModalOpened, setAddModalOpened] = useState(false);
    const [editModalOpened, setEditModalOpened] = useState(false);

    const [editableService, setEditableService] = useState<
        IEditableCategory | undefined
    >(undefined);

    const [createLoading, setCreateLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [servicesLoaded, setServicesLoaded] = useState(false);
    const [servicesPending, setServicesPending] = useState(false);
    const [currentLoadedSalon, setCurrentLoadedSalon] = useState<
        number | undefined
    >(undefined);
    const [servicesData, setServicesData] = useState<
        IServiceByCategory[] | undefined
    >(undefined);

    const handleOpenAddModal = useCallback(() => {
        setAddModalOpened(true);
    }, []);

    const handleStartEdit = useCallback((editable: IEditableCategory) => {
        setEditModalOpened(true);
        setEditableService(editable);
    }, []);

    const [categoriesLoaded, setCategoriesLoaded] = useState(false);
    const [categoriesPending, setCategoriesPending] = useState(false);

    const [categories, setCategories] = useState<IListedCategories>([]);

    const handleLoadCategories = useCallback(async () => {
        try {
            setCategoriesPending(true);
            const response = await CommonSalonsAPI.getAllCategories();

            setCategories(response.data.categories);
            setCategoriesLoaded(true);
            setCategoriesPending(false);
        } catch (e) {
            setCategoriesPending(false);
            setCategoriesLoaded(false);
            message.error(
                'Ошибка при загрузке категорий услуг. Попробуйте позже'
            );
        }
    }, []);

    useEffect(() => {
        if (!categoriesLoaded && !categoriesPending) {
            void handleLoadCategories();
        }
    }, [categoriesLoaded, categoriesPending]);

    const handleLoadServices = useCallback(async () => {
        try {
            setServicesPending(true);
            const response = await CommonSalonsAPI.getSalonServices(sId);

            setServicesData(() =>
                Object.values(
                    response.data.services.reduce((res, acc: IService) => {
                        const categoryId = acc.service.parent.id;

                        if (res[categoryId]) {
                            res[categoryId] = {
                                ...res[categoryId],
                                services: [
                                    ...res[categoryId].services,
                                    {
                                        id: acc.id,
                                        priceValue: acc.priceValue,
                                        serviceDescription:
                                            acc.serviceDescription,
                                        serviceName: acc.service.title,
                                        serviceTiming: acc.serviceTiming,
                                    },
                                ],
                            };
                        } else {
                            res = {
                                ...res,
                                [categoryId]: {
                                    categoryId,
                                    key: acc.service.parent.key,
                                    title: acc.service.parent.title,
                                    services: [
                                        {
                                            id: acc.id,
                                            priceValue: acc.priceValue,
                                            serviceDescription:
                                                acc.serviceDescription,
                                            serviceName: acc.service.title,
                                            serviceTiming: acc.serviceTiming,
                                        },
                                    ],
                                },
                            };
                        }

                        return res;
                    }, {} as Record<string, IServiceByCategory>)
                )
            );
            setCurrentLoadedSalon(() => sId);
            setServicesLoaded(() => true);
            setServicesPending(() => false);
        } catch (e) {
            message.error('Ошибка при загрузке услуг, попробуйте позже');
            setServicesPending(() => false);
            setServicesLoaded(() => false);
        }
    }, [sId]);

    const handleCreateService = useCallback(
        async (service: ICreateService) => {
            try {
                let serviceName = '';

                const category = categories.find(
                    (el) => el.id === service.categoryId
                );

                if (category) {
                    serviceName = category.title;
                } else {
                    categories.forEach((el) => {
                        if (
                            Array.isArray(el.children) &&
                            el.children.length > 0
                        ) {
                            el.children.forEach((nodeEl) => {
                                if (nodeEl.id === service.categoryId) {
                                    serviceName = nodeEl.title;
                                }
                            });
                        }
                    });
                }

                setCreateLoading(true);
                void (await CommonSalonsAPI.createService({
                    ...service,
                    serviceName: serviceName,
                }));
                message.success('Услуга добавлена');
                setCreateLoading(false);
                setServicesLoaded(false);
            } catch (e) {
                message.error('Ошибка при создании услуги');
                setCreateLoading(false);
                throw new Error(e);
            }
        },
        [categories]
    );

    const handleEditService = useCallback(
        async (service: IPatchedService, serviceId: string) => {
            try {
                setEditLoading(true);
                void (await CommonSalonsAPI.patchService(service, serviceId));
                message.success('Услуга обновлена');
                setEditLoading(false);
                setServicesLoaded(false);
            } catch (e) {
                message.error('Ошибка при изменении услуги');
                setEditLoading(false);
                throw new Error(e);
            }
        },
        []
    );

    const handleDeleteService = useCallback(
        async (serviceId: string | number) => {
            try {
                setDeleteLoading(true);
                void (await CommonSalonsAPI.deleteService(serviceId));
                message.success('Услуга удалена');
                setDeleteLoading(false);
                setServicesLoaded(false);
            } catch (e) {
                message.error('Ошибка при удалении услуги');
                setDeleteLoading(false);
                throw new Error(e);
            }
        },
        []
    );

    useEffect(() => {
        if (
            (!servicesLoaded && !servicesPending) ||
            (servicesLoaded && !servicesPending && currentLoadedSalon !== sId)
        ) {
            void handleLoadServices();
        }
    }, [servicesLoaded, servicesPending, sId, currentLoadedSalon]);

    return (
        <div className={styles.wrapper}>
            {selectedDate && events && handleSetNeedLoad && (
                <EventsList
                    selectedDate={selectedDate}
                    events={events}
                    handleSetNeedLoad={handleSetNeedLoad}
                />
            )}
            <div className={styles.header}>
                <span className={styles.pageTitle}>Услуги по категориям</span>
                <Button
                    onClick={handleOpenAddModal}
                    className={styles.headerAdd}
                >
                    Добавить услугу
                </Button>
            </div>
            <div>
                {servicesLoaded &&
                    servicesData &&
                    servicesData.map((el) => (
                        <CategoryItem
                            handleDeleteService={handleDeleteService}
                            handleStartEdit={handleStartEdit}
                            category={el}
                            key={el.categoryId}
                        />
                    ))}
            </div>
            <AddServiceModal
                isOpen={addModalOpened}
                setOpen={setAddModalOpened}
                isAddServicePending={createLoading}
                handleCreate={handleCreateService}
                mode={'create'}
                service={undefined}
                salonId={Number(sId)}
                categoriesPending={categoriesPending}
                categories={categories}
            />
            <AddServiceModal
                isOpen={editModalOpened}
                setOpen={setEditModalOpened}
                isAddServicePending={editLoading}
                handleCreate={handleEditService}
                mode={'edit'}
                salonId={Number(sId)}
                service={editableService}
                categoriesPending={categoriesPending}
                categories={categories}
            />
        </div>
    );
};
