import { Button, Layout, Upload, Input, message } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PageHead, BanrHead, SideMen2, PageFoot } from '../part/PageParts';
import {
    ContactsOutlined,
    CustomerServiceOutlined,
    EditFilled,
    EyeFilled,
    EyeInvisibleFilled,
    LoadingOutlined,
} from '@ant-design/icons';
import star from '../../img/star5pointsRed.png';
import ImgCrop from 'antd-img-crop'; // options https://github.com/nanxiaobei/antd-img-crop
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import './salonMan.scss';
import { apiBaseMedia, apiBase, apiFileUp, salonLogoDefa } from '../Const.js';
import ClientsMasters from '../ClieMast/ClientsMasters.js';
import { SalonSheduleTab } from '../../Components/Cabinet/Salon/SalonSheduleTab.tsx';
import { SalonPriceList } from '../../Components/Cabinet/Salon/SalonPriceList/index.tsx';
import { MastersList } from '../../Components/Cabinet/Salon/Masters/MastersList.tsx';
import { PriceCurrent } from '../../Components/Cabinet/Salon/PriceCurrent/PriceCurrent.tsx';
import { EventsList } from '../../Components/Cabinet/Salon/EventsList.tsx';
import * as moment from 'moment/moment';
import { SalonScheduleAPI } from '../../api/schedule/index.ts';
import { AdvantagesTab } from '../../Components/Cabinet/Salon/Adventages/AdvantagesTab.tsx';
import styles from '../../Components/Cabinet/Salon/SalonShedule.module.scss';

function SalonMan({ match, location: loc }) {
    let saId = ''; // для парсинга ид салона в URL до ':'
    const {
            url,
            params: { userId, sIdNam = '', sId = '' }, // , sIdNam - id салона явно из URL и после ":" - возможное имя салона явно из URL
        } = match,
        { TextArea } = Input,
        hashes = [
            'profile',
            'scheduler',
            'clients',
            'masters',
            'bonus',
            'priceEdit',
            'analythics',
            'support',
        ],
        tabs = [
            <>
                <ContactsOutlined /> Профиль
            </>,
            (appoActive) => (
                <>
                    <span>Журнал записи</span>
                    {appoActive ? (
                        <div className="appoActive">{appoActive}</div>
                    ) : null}
                </>
            ),
            <>Клиенты</>,
            <>Сотрудники</>,
            <>Акции и бонусы</>,
            <>Прайс-лист</>,
            <>Аналитика</>,
            <>
                <CustomerServiceOutlined /> Отзывы
            </>,
        ],
        locHash =
            hashes[(hashes.indexOf(loc.hash.replace(/^#/, '')) + 1 || 1) - 1];
    const [salIdUrl, salNameUrl] = /:/.test(sIdNam || sId || '') // ид салона из URL и
        ? /\[[0-9]+\]/.test(
              (saId = (sIdNam || sId)
                  .replace(/:.*/, '')
                  .replace(/.*?:(.*)/, '$1'))
          )
            ? [
                  saId,
                  (sIdNam || sId)
                      .replace(new RegExp(saId), '')
                      .replace(/^:|:$/g, ''),
              ]
            : ['', sIdNam || sId]
        : /\[[0-9]+\]/.test(sIdNam || sId)
        ? [sIdNam || sId, '']
        : ['', sIdNam || sId];
    const $stor = useSelector((state) => {
            $stor?.tableSource &&
                ((s) => {
                    // console.log(s.tableSource, s);
                })($stor);
            return state;
        }),
        dispatch = useDispatch(),
        testStatusEdit = 0; //TEST - проверять, как при наличии Sa.isEdit
    //debugger
    const hist = useHistory();

    const clickMenuHoriz = (ev) => {
            // дубликатор меню и табов
            let t0 = ev.currentTarget,
                t = ev.target;
            if (t.tagName !== 'LI') t = t.parentNode;
            if (t.tagName !== 'LI') t = t.parentNode;
            Array.from(t.parentNode.children).forEach((el) =>
                el.classList.remove('active')
            );
            t.classList.add('active');
            const tabsIn = document.querySelector('.bfCabinet.tabs .stackIn');
            Array.from(tabsIn.children).forEach((el) =>
                el?.classList.remove('active')
            );
            tabsIn
                ?.querySelector(
                    `.tab:not(.servByCat).tab${t.getAttribute('data-tab')}`
                )
                ?.classList.add('active');
            hist.push('#' + hashes[+t.getAttribute('data-tab') - 1]);
        },
        [appoActive, setAppoActive] = useState(0), // ?? TODO из БД - число активных записей клиентов
        [Sa, setSalon] = useState($stor?.salonDescr || {}), // стейт Салона
        clieStars =
            Sa.rating ?? Math.max(0, Math.min(5, Math.random() * 5)).toFixed(1), // ===== конст. РЕЙТНИГ клиента =====; округление до 0.1
        salonStars = Math.max(0, Math.min(5, Math.random() * 5)).toFixed(1), // ===== ТЕСТ. РЕЙТНИГ салона =====;
        whStars = (stN, rat = clieStars) => {
            // whiteStars - рейтинг на 5 звёзд и 50 градаций
            const st01 = Math.max(0, Math.min(5, +(+rat).toFixed(1))),
                stFlor = Math.floor(st01);
            return (
                (stFlor < stN ? 'wh' : '') +
                (stN !== stFlor + 1
                    ? ''
                    : ' wh' + Math.floor(10 * (st01 - stFlor) + 0.00001))
            );
        };
    const [paswA, setPaswA] = useState([0, 0, 0, 0]), // видимости паролей
        [pasw1, setPasw1] = useState(false), // набранные тексты в паролях
        [pasw2, setPasw2] = useState(false),
        [pasw3, setPasw3] = useState(false);
    const chgPasw = (ev) => {
        const t0 = ev.currentTarget,
            t1 = ev.target,
            tA = t0.parentNode.querySelectorAll('input'),
            pasw = !paswA[1];
        if (
            !t1.classList.contains('anticon') &&
            t1.tagName !== 'svg' &&
            t1.tagName !== 'path'
        )
            return;
        Array.from(tA).forEach((el) => (el.type = !pasw ? 'password' : 'text'));
        //setPaswA(paswA);
        setPaswA([0, 1 - paswA[1], 1 - paswA[2], 1 - paswA[3]]);
    };
    const [nextSaveDisabled, setNextSaveDisabled] = useState(0);
    const saveSalon = (ev, extraSaveObj) => {
        // ======= пишем поля салона (может привязанный кастомер или админ) =======
        Sa.id &&
            axios
                .patch(
                    `${apiBase}salon/${Sa.id}`,
                    {
                        ...{
                            id: Sa.id, //  "cc7e106a-2a03-4d96-ad8e-ff8d25c61321",
                            city: Sa.city || '', // 'string' * город
                            metro: Sa.metro || '', //'string' * Метро, transport
                            address: Sa.address || '', // * Адрес
                            description: Sa.description || '', // * Описание (descr) - многострочное поле
                            email: Sa.email || '', // * email
                            //latitude: "stringI", // координаты салона (по карте)
                            //longitude: "stringI",
                            phone: Sa.phone || '', // 0, 1, 2 или 3 телефона, разделённые '|' * телефоны, до 3
                            social: Sa.social || '', // instagram|ВК|Facebook - url-ы. разделённые '|'
                            title: Sa.title || '',
                            website: Sa.website || '', // _обязательное поле_
                            isEdit: Sa.isEdit, // _оказывается обязательным - без него - ошибка 400_

                            //salonPhotoIds:[0], // * массив id-s фото салона
                            //portfolioIds: [0], // * массив id-s фото портфолио (загруженные ранее через другой интерфейс)
                            //  ; не будем использовать механизм массива, а показываем всегда N последних
                            //servicesIds: [0],
                            //categoryIds: [0],
                            //clientIds: ["0"],clientIds: ["0"],
                            //masterIds: ["0"],

                            latitude: Sa.latitude || '',
                            longitude: Sa.longitude || '',
                            status: Sa.status || 'Не в системе',
                            rating: Sa.rating || 0,
                            employedDate: Sa.employedDate || '', //TODO потом убрать, когда станет необязательным

                            logoId: Sa.logoId || Sa.logo?.id || 0, // ид лого-картинки: если передаётся новая || если была прежняя || по умолч
                        },
                        ...($stor?.auth?.role === 'admin'
                            ? { ownerId: Sa.ownerId || Sa.owner?.id || '' }
                            : {}), //отправляется АДМИНОМ для привязки салона к кастомеру
                        ...(extraSaveObj ?? {}), // сохранять доп. параметры или массивы (стирают прежние параметры !)
                        // * пароли - через другой интерфейс
                    },
                    {
                        headers: {
                            accept: '*/*',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${$stor.auth?.accessToken}`,
                        },
                    }
                )
                .then((res) => {
                    if (res?.data) {
                        dispatch({
                            // пишем то, чем реально ответил сервер (Какие-то данные могут не измениться)
                            type: 'PATCH-SALON-DESCR',
                            salonDescr: res.data,
                        });
                        setSalon(res.data);
                        setNextSaveDisabled(1); // временно выключим кнопку отправки (при новых правках полей - включить)
                    }
                });
        // ======= Независимо, по saveProfile изменяем пароль, если условия в полях это обеспечат ========
        if (pasw1 && pasw2 && pasw3 && pasw2 === pasw3)
            axios
                .post(
                    `${apiBase}auth/password/change-by-old-password`,
                    { oldPassword: pasw1, newPassword: pasw2 },
                    {
                        headers: {
                            accept: '*/*',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${$stor.auth?.accessToken}`,
                        },
                    }
                )
                .then((res) => {
                    //TODO что-то поделать, по реакции
                    setNextSaveDisabled(1);
                    setPasw3(''); // ?? надо ли стирать?
                }); // и по ошибке
    };
    const setSalonTitle = (ev) => {
            setSalon({ ...Sa, ...{ title: ev.target?.value || '' } });
            setNextSaveDisabled(0);
        },
        setCity = (ev) => {
            setSalon({ ...Sa, ...{ city: ev.target?.value || '' } });
            setNextSaveDisabled(0);
        },
        setTransp = (ev) => {
            setSalon({ ...Sa, ...{ metro: ev.target?.value || '' } });
            setNextSaveDisabled(0);
        },
        setAddress = (ev) => {
            setSalon({ ...Sa, ...{ address: ev.target?.value || '' } });
            setNextSaveDisabled(0);
        },
        setPhone = (ev) => {
            const phA = document.querySelectorAll('.phoneValue');
            setSalon({
                ...Sa,
                ...{
                    phone: `${phA[0]?.value || ''} | ${phA[1]?.value || ''} | ${
                        phA[2]?.value || ''
                    }`,
                },
            });
            setNextSaveDisabled(0);
        },
        setSite = (ev) => {
            setSalon({ ...Sa, ...{ website: ev.target?.value || '' } });
            setNextSaveDisabled(0);
        },
        setEmail = (ev) => {
            setSalon({ ...Sa, ...{ email: ev.target?.value || '' } });
            setNextSaveDisabled(0);
        },
        setSocial = (ev) => {
            const insta =
                    document.querySelector('.instagramValue')?.value || '',
                vk = document.querySelector('.vkValue')?.value || '',
                fb = document.querySelector('.fbValue')?.value || '';
            setSalon({
                ...Sa,
                ...{ social: `${insta || ''} | ${vk || ''} | ${fb || ''}` },
            });
            setNextSaveDisabled(0);
        },
        //для роли админа
        setOwner = (ev) => {
            setSalon({ ...Sa, ownerId: ev.target?.value || '' });
            setNextSaveDisabled(0);
        },
        setDescr = (ev) => {
            setSalon({ ...Sa, ...{ description: ev.target?.value || '' } });
            setNextSaveDisabled(0);
        },
        chgPasw1 = (ev) => {
            setPasw1(ev.target?.value || '');
            setNextSaveDisabled(0);
        },
        chgPasw2 = (ev) => {
            setPasw2(ev.target?.value || '');
            setNextSaveDisabled(0);
        },
        chgPasw3 = (ev) => {
            setPasw3(ev.target?.value || '');
            setNextSaveDisabled(0);
        };
    const [fListSalonLogo, setFListSalonLogo] = useState([
        // логотипы салона - история загруженных вариантов
        {
            uid: '-1',
            name: Sa.logo?.fileName || salonLogoDefa,
            status: 'done',
            url: apiBaseMedia + (Sa.logo?.fileName || salonLogoDefa),
        },
    ]);
    const [fileListSalon, setFListSalon] = useState([]);
    const [fileListPortf, setFListPortf] = useState([]);
    const [waitNewLogo, setWaitNewLogo] = useState(-1); // для ожидания показа нового лого
    useEffect(
        (x) => {
            if (waitNewLogo && waitNewLogo === Sa?.logoId) {
                saveSalon(); // TODO сброс ожидания аватара - в коллбек сейва!
                setWaitNewLogo(-1);
            }
        },
        [waitNewLogo]
    );
    const onChangeSalonLogo = (x) => {
        const newFileList = x.fileList;
        //debugger;
        setFListSalonLogo(newFileList);
        if (Sa?.logo !== undefined) setFListSalonLogo(newFileList);
        //debugger
        if (newFileList?.[newFileList.length - 1]?.response?.id) {
            // отправляем ид лого на изменение данных салона
            const logoSa = {
                ...Sa,
                logoId: newFileList[newFileList.length - 1].response.id,
            };
            dispatch({
                type: 'PATCH-SALON-DESCR',
                logoSa,
            });
            //setProf(logoSa, saveProfile); // для компонента - сохранён профиль, передёрнуты данные
            //Но!! index.js:1 Warning: State updates from the useState() and useReducer() Hooks don't support the second
            //   callback argument. To execute a side effect after rendering, declare it in the component body with useEffect()
            //   - не срабатывает коллбек по https://stackoverflow.com/questions/54954091/how-to-use-callback-with-usestate-hook-in-react
            setSalon(logoSa); // и затем отслеживаем разницу avatarId старого и нового
            setWaitNewLogo(logoSa.logoId);
        }
    };

    const showPhoto = (allMedia, fileNMax, fileGroup, wasDel) => {
        // обрезка всего массива нужным типом и количеством fileNMax
        if (allMedia?.fileList) allMedia = allMedia.fileList;

        // массив из 18 (fileNMax) последних фото портфолио или пустой массив
        const briefMedia =
            allMedia &&
            allMedia.reduce(
                (s, el) =>
                    el.mediaType === fileGroup || el.mediaType == null
                        ? (s.length >= fileNMax
                              ? s.splice(1, fileNMax - 1)
                              : s
                          ).concat(el)
                        : s,
                []
            );
        let fList;
        !wasDel &&
            (fileGroup === 'portfolio'
                ? setFListPortf
                : fileGroup === 'logo'
                ? setFListSalonLogo
                : setFListSalon)(
                (fList = briefMedia?.length
                    ? briefMedia.map((el) => {
                          return {
                              uid: el.fileName
                                  ? el.fileName + el.id
                                  : el.response
                                  ? el.response.fileName + el.response.id
                                  : el.uid,
                              name: el.fileName
                                  ? el.fileName
                                  : el.response
                                  ? el.response.fileName
                                  : el.name,
                              status: 'done',
                              url:
                                  apiBaseMedia +
                                  (el.fileName
                                      ? el.fileName
                                      : el?.response
                                      ? el.response.fileName
                                      : el.name),
                          };
                      })
                    : [])
            );
        //debugger;
        if (briefMedia.length && !briefMedia[briefMedia.length - 1].response)
            return fList || [];
        fileGroup !== 'logo' &&
            saveSalon(null, {
                [fileGroup === 'portfolio' ? 'portfolioIds' : 'salonPhotoIds']:
                    fList.map((el) => {
                        return el.uid.replace(new RegExp('^' + el.name), '');
                    }) || [],
            });
        return fList || [];
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new window.Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    const onRemovePhoto = (file, fileNMax, fileGroup) => {
        // ======= удаление файла =======
        //debugger;
        const fileId = file.uid.replace(new RegExp('^' + file.name), '');
        axios
            .delete(apiFileUp.replace(/file-upload/, '') + fileId, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${$stor.auth?.accessToken}`,
                },
            })
            .then((res) => {
                // переписать список файлов в новый список идов (чисел)
                let aa = (
                    showPhoto(
                        $stor.meProfile?.media,
                        fileNMax,
                        fileGroup,
                        'wasDel'
                    ) || []
                ) // массив с неудалённым элементом
                    .map((el) => {
                        return el.uid.replace(new RegExp('^' + el.name), '');
                    });
                aa.splice(
                    aa.indexOf(res.config.url.replace(/.*?(\d+)$/, '$1')),
                    1
                ); // удалили; используем
                console.log('==salonPhoto', aa);
                saveSalon(null, {
                    [fileGroup === 'portfolio'
                        ? 'portfolioIds'
                        : 'salonPhotoIds']: aa,
                });
            });
    };
    const urlProfile =
            apiBase +
            (userId && $stor?.auth?.role !== 'client'
                ? `client/${userId}`
                : 'auth/me'), //TODO && role !=='client'
        //[meOnce, setMeOnce] = useState(0), // получение профиля из Ajax
        getProfile = (cb) => {
            setLoading(true);
            // ========== читаем профиль ==========
            axios
                .get(urlProfile, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${$stor.auth?.accessToken}`,
                    },
                })
                .then((res) => {
                    //debugger;
                    dispatch({
                        type: 'ME-PROFILE',
                        meProfile: res.data, // TODO профиль может быть чужой (при чтении по id)
                    });
                    dispatch({
                        // сохраняем привязку салона
                        type: 'AUTH-LOGIN',
                        auth: {
                            ...($stor?.auth || {}),
                            ...(res?.data?.salons?.[0]?.id
                                ? {
                                      salonCustomer: res.data.salons[0].id, // UUID привязанного к кастомеру салона
                                  }
                                : {}),
                        },
                    });
                    //if(res.data.id) // формально - показываем id в адресной строке
                    //    hist.push(`/user/${res.data.id}`);

                    // массив из 1 последнего аватара или пустой массив TODO разрыв источников данных по фото: в салон - от кастомера
                    showPhoto(res?.data?.media, 1, 'logo');

                    // массив из 3 последних фото салона или пустой массив
                    showPhoto(res?.data?.media, 3, 'salonPhoto');

                    // массив из 18 последних фото портфолио или пустой массив
                    showPhoto(res?.data?.media, 18, 'portfolio');

                    cb && cb(); // (читаем салон)
                    setLoading(false);
                });
        };
    // ========== получение салона из Ajax ==========
    const [salonOnce, setSalonOnce] = useState(0),
        [salonNoData, setSalonNoData] = useState(0),
        //TODO && role !=='client'
        getSalon = (x) => {
            setLoading(true);
            //debugger // читаем параметры салона (может менять - только привязанный к нему кастомер)
            (salIdUrl || $stor?.auth?.salonCustomer) &&
                axios
                    .get(
                        `${apiBase}salon/${
                            salIdUrl || $stor?.auth?.salonCustomer
                        }`,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${$stor.auth?.accessToken}`, // хотя пока не обязателен
                            },
                        }
                    )
                    .then((res) => {
                        //debugger;
                        if (!res.data) setSalonNoData('Нет данных');
                        dispatch({
                            type: 'GET-SALON-DESCR',
                            salonDescr: res.data,
                        });
                        setSalon(res.data); // для компонента - сохранён салон, передёрнуты данные
                        if (res.data.id)
                            // формально - показываем id в адресной строке
                            hist.push(
                                `/salon/cabinet/${res.data.id}` +
                                    `${
                                        loc.hash.replace(/^#/, '')
                                            ? `#${loc.hash.replace(/^#/, '')}`
                                            : ''
                                    }`
                            );
                        setLoading(false);
                    });
        };
    useEffect(() => {
        // TODO зачем тут профиль и салон? Профиль нужен, салон - нет
        if (!salonOnce) {
            setSalonOnce(1);
            getProfile(getSalon);
            $stor.salonDescr &&
                ((s) => {
                    console.log('==salon', s.salonDescr, s);
                })($stor);
        } // TODO надо ли это читать после getSalon?
    });
    const groupServices = [
            //статика: группы услуг (табы и выбор доп. услуг)
            {
                value: 1,
                label: 'Ногтевой сервис',
                disabled: true,
                cats: ['Маникюр', 'Педикюр'],
            },
            {
                value: 2,
                label: 'Парикмахерский зал',
                cats: ['Стрижка волос', 'Окрашивание волос', 'Укладка'],
            },
            {
                value: 3,
                label: 'Удаление волос',
                cats: ['Шугаринг', 'Воск', 'Лазер'],
            },
            { value: 4, label: 'Брови и ресницы', cats: ['Ресницы и брови'] },
            { value: 5, label: 'Барбершоп', cats: ['Барбершоп'] }, // для наглядности - items[].title в cats[]
        ],
        cat2id = {
            Маникюр: 1,
            Педикюр: 2,
            'Стрижка волос': 3,
            'Окрашивание волос': 4,
            Укладка: 5,
            Шугаринг: 6,
            Воск: 7,
            Лазер: 8,
            'Ресницы и брови': 9,
            Барбершоп: 10,
        };
    let cIs = Object.keys(cat2id).map((el) => ({ title: el })); // имя категории - в номер таба
    // ========== получение категорий или сервисов салонов из Ajax ==========
    const [getServicesOnce, setGetServicesOnce] = useState(0);
    useEffect(() => {
        // в сервисах нужно всего лишь найти категорию; ищем по key, получаем id
        const salonId =
                (salIdUrl != null
                    ? salIdUrl
                    : $stor?.salonDescr?.salons?.[0]?.id) || null,
            urlServices =
                apiBase + (salonId != null ? 'salon/' + salonId : 'category/'); // TODO ! category/ было в целях теста
        cIs = cIs.map((el) => ({ title: el.title, children: [] })); // сброс мусора в массивах
        if (!getServicesOnce) {
            setGetServicesOnce(1);
            setLoading(true);
            axios.get(urlServices).then((res) => {
                const cat2idServ = res?.data?.titleCat?.reduce((s, el) => {
                    return { ...s, ...{ [el.id]: el.title } };
                }, {});
                setLoading(false);
                return dispatch({
                    type: 'SOURCE-TABLE',
                    tableSource: !salonId
                        ? res.data
                        : res.data.services.reduce(
                              (s, el) => {
                                  const cI =
                                      cat2id[cat2idServ[el.category.id]] - 1;
                                  //if(cI) //TEST принудительный непоказ первой категории (.id ==1)
                                  if (s.categories?.[cI]) {
                                      s.categories[cI].id = el.category.id; // серверный ид категории (короткий)
                                      s.categories[cI].key = el.category.key; // (для хеша категорий)
                                      s.categories[cI].children.push({
                                          servTitle: el.serviceName,
                                          //enable: el.enable,
                                          servPrice: el.priceValue,
                                          servDescr:
                                              el.serviceDescription || '',
                                          servTiming: el.serviceTiming,
                                          //key: el.id, // (для хеша списка)
                                      });
                                  }
                                  return s;
                              },
                              { categories: cIs }
                          ),
                });
            });
        }
    });
    const [selectedDate, setSelectedDate] = useState(moment());

    const [loaded, setLoaded] = useState(false);

    const handleSetNeedLoad = useCallback(() => {
        setLoaded(false);
    }, []);

    const [loading, setLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [loadedSalon, setLoadedSalon] = useState(undefined);
    const [scheduleData, setScheduleData] = useState([]);

    const [dateLoaded, setDateLoaded] = useState(undefined);

    const handleFetchSchedule = useCallback(async () => {
        try {
            setLoading(true);

            const data = await SalonScheduleAPI.getScheduleBySalon(
                sId,
                selectedDate.format('YYYY-MM-DD')
            );

            setAppoActive(data.data.items.length);

            setScheduleData(() => data.data.items);
            setLoadedSalon(() => sId);
            setErrorLoading(() => false);
            setLoaded(() => true);
            setDateLoaded(() => selectedDate.format('DD.MM.YYYY'));
            setLoading(() => false);
        } catch (e) {
            setErrorLoading(() => true);
            setLoaded(() => false);
            setLoading(() => false);
            message.error('Ошибка при загрузке записей салона');
        }
    }, [sId, selectedDate]);

    useEffect(() => {
        if (
            sId &&
            !loading &&
            !errorLoading &&
            (loadedSalon !== sId ||
                !loaded ||
                dateLoaded !== selectedDate.format('DD.MM.YYYY'))
        ) {
            void handleFetchSchedule();
        }
    }, [
        sId,
        loaded,
        errorLoading,
        handleFetchSchedule,
        dateLoaded,
        selectedDate,
        loading,
    ]);

    const handleSelectDate = useCallback((date) => {
        setSelectedDate(() => date);
    }, []);

    const handleNextDate = () => {
        handleSelectDate(moment(selectedDate).add(1, 'months'));
    };

    const handlePrevDate = () => {
        handleSelectDate(moment(selectedDate).add(-1, 'months'));
    };

    return (
        <>
            <Layout className="salonMan">
                <PageHead />
                <SideMen2 />
                <div className="layCont">
                    <BanrHead hide={process.env.REACT_APP_VISUAL_FOR_DEV} />
                    <div className="stackBlock bfCabinet">
                        <div className="stackIn bfCabinet">
                            <h2>
                                <span className="pale">
                                    {salIdUrl ||
                                    $stor?.salonDescr?.salons?.[0]?.id
                                        ? ' : ' + salIdUrl ||
                                          $stor?.salonDescr?.salons?.[0]?.id
                                        : ''}
                                </span>{' '}
                                <span className="paleError">
                                    {salonNoData ? ' : ' + salonNoData : ''}
                                </span>{' '}
                                {Sa.title || ''}
                            </h2>
                        </div>
                    </div>
                    <div className="stackBlock bfCabinet bacVw100">
                        <div className="stackIn bfCabinet">
                            <div className="menuHorizOver">
                                <ul
                                    className="menuHoriz"
                                    onClick={clickMenuHoriz}
                                >
                                    {tabs.map((el, i) => {
                                        return (
                                            <li
                                                data-tab={i + 1}
                                                key={'menuHor' + i}
                                                className={
                                                    hashes.indexOf(locHash) ===
                                                    i
                                                        ? 'active'
                                                        : ''
                                                }
                                            >
                                                <div>
                                                    {typeof tabs[i] ===
                                                    'function'
                                                        ? tabs[i](appoActive)
                                                        : tabs[i]}
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="stackBlock bfCabinet tabs">
                        <div className="stackIn bfCabinet">
                            <div
                                className={`tab tab1 profile${
                                    hashes.indexOf(locHash) === 0
                                        ? ' active'
                                        : ''
                                }`}
                                id="profile"
                            >
                                {loading || !$stor?.salonDescr ? (
                                    <div className={styles.loaderWrapper}>
                                        <LoadingOutlined />
                                    </div>
                                ) : (
                                    <>
                                        <div className="salonAccount">
                                            <div className="salonPhotoStars">
                                                <div className="salonPhoto">
                                                    <div className="photo">
                                                        <img
                                                            src={
                                                                apiBaseMedia +
                                                                (Sa.logo
                                                                    ?.fileName ||
                                                                    salonLogoDefa)
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div
                                                        className="editOver"
                                                        title="Загрузить новое фото"
                                                        onClick={(ev) =>
                                                            document
                                                                .querySelector(
                                                                    '.ant-upload-select-picture-card >.ant-upload'
                                                                )
                                                                ?.click()
                                                        }
                                                    >
                                                        <EditFilled className="edit" />
                                                    </div>
                                                </div>
                                                <div className="userStars">
                                                    <i className={whStars(1)}>
                                                        <img
                                                            src={star}
                                                            alt=""
                                                        />
                                                    </i>
                                                    <i className={whStars(2)}>
                                                        <img
                                                            src={star}
                                                            alt=""
                                                        />
                                                    </i>
                                                    <i className={whStars(3)}>
                                                        <img
                                                            src={star}
                                                            alt=""
                                                        />
                                                    </i>
                                                    <i className={whStars(4)}>
                                                        <img
                                                            src={star}
                                                            alt=""
                                                        />
                                                    </i>
                                                    <i className={whStars(5)}>
                                                        <img
                                                            src={star}
                                                            alt=""
                                                        />
                                                    </i>
                                                    <div>
                                                        {Sa.rating ?? clieStars}
                                                    </div>
                                                </div>
                                                <ImgCrop
                                                    rotate
                                                    quality="0.6"
                                                    minZoom={0.5}
                                                    maxZoom="5"
                                                    shape="round"
                                                    grid
                                                    modalTitle="Редактировать изображение"
                                                    modalWidth="640px"
                                                    modalOk="Отправить"
                                                    modalCancel="Отмена"
                                                    modalClassName="pictureEdit"
                                                    cropperProps={{
                                                        zoomSpeed: 0.05,
                                                    }}
                                                >
                                                    <Upload
                                                        action={apiFileUp}
                                                        listType="picture-card"
                                                        headers={{
                                                            Authorization: `Bearer ${$stor.auth?.accessToken}`,
                                                        }}
                                                        data={{
                                                            mediaType: 'logo',
                                                        }}
                                                        fileList={
                                                            fListSalonLogo
                                                        }
                                                        accept="image/png, image/jpeg"
                                                        onChange={(x) => {
                                                            onChangeSalonLogo(
                                                                x
                                                            );
                                                        }}
                                                        onPreview={onPreview}
                                                    >
                                                        Загрузить новое фото
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                            <div className="salonManData">
                                                {testStatusEdit || Sa.isEdit ? (
                                                    <div className="salonTitle editable">
                                                        <EditFilled className="toRight" />
                                                        <input
                                                            className="salonTitleValue"
                                                            value={
                                                                Sa.title || ''
                                                            }
                                                            placeholder="(не указано)"
                                                            onChange={
                                                                setSalonTitle
                                                            }
                                                            title="Название салона"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="salonTitle">
                                                        {Sa.title || ''}
                                                    </div>
                                                )}
                                                <div className="rowCityTranspAddrTel">
                                                    <div className="city editable">
                                                        <div className="label">
                                                            Город
                                                        </div>
                                                        <EditFilled className="toRight" />
                                                        <input
                                                            className="cityValue"
                                                            value={
                                                                Sa.city || ''
                                                            }
                                                            placeholder="(не указан)"
                                                            onChange={setCity}
                                                        />
                                                    </div>
                                                    <div className="transp editable">
                                                        <div className="label">
                                                            Метро
                                                        </div>
                                                        <EditFilled className="toRight" />
                                                        <input
                                                            className="transpValue"
                                                            value={
                                                                Sa.metro || ''
                                                            }
                                                            placeholder="(не указано)"
                                                            onChange={setTransp}
                                                        />
                                                    </div>
                                                    <div className="address editable">
                                                        <Button
                                                            size="small"
                                                            type="link"
                                                            className="toMap"
                                                        >
                                                            Указать на карте
                                                        </Button>
                                                        <div className="label">
                                                            Адрес
                                                        </div>
                                                        <EditFilled className="toRight" />
                                                        <input
                                                            className="addressValue"
                                                            value={
                                                                Sa.address || ''
                                                            }
                                                            placeholder="(не указан)"
                                                            onChange={
                                                                setAddress
                                                            }
                                                        />
                                                    </div>
                                                    {testStatusEdit ||
                                                    Sa.isEdit ? (
                                                        <>
                                                            <div className="phone editable">
                                                                <div className="label">
                                                                    Номер
                                                                    телефона 1
                                                                </div>
                                                                <EditFilled className="toRight" />
                                                                <input
                                                                    className="phoneValue"
                                                                    value={
                                                                        Sa.phone
                                                                            ?.replace(
                                                                                /^([^|]+)\|.*/,
                                                                                '$1'
                                                                            )
                                                                            ?.replace(
                                                                                /^ *| *$/g,
                                                                                ''
                                                                            )
                                                                            ?.replace(
                                                                                /^7(\d{3})(\d{3})(\d{2})(\d{2})/,
                                                                                '+7($1) $2-$3-$4'
                                                                            ) ||
                                                                        ''
                                                                    }
                                                                    placeholder="(не указан)"
                                                                    onChange={
                                                                        setPhone
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="phone editable">
                                                                <div className="label">
                                                                    Номер
                                                                    телефона 2
                                                                </div>
                                                                <EditFilled className="toRight" />
                                                                <input
                                                                    className="phoneValue"
                                                                    value={
                                                                        (/\|/.test(
                                                                            Sa.phone ||
                                                                                ''
                                                                        ) &&
                                                                            Sa.phone
                                                                                ?.replace(
                                                                                    /^.*?\|([^|]+)\|.*/,
                                                                                    '$1'
                                                                                )
                                                                                ?.replace(
                                                                                    /^ *| *$/g,
                                                                                    ''
                                                                                )
                                                                                ?.replace(
                                                                                    /^7(\d{3})(\d{3})(\d{2})(\d{2})/,
                                                                                    '+7($1) $2-$3-$4'
                                                                                )) ||
                                                                        ''
                                                                    }
                                                                    placeholder="(не указан)"
                                                                    onChange={
                                                                        setPhone
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="phone editable">
                                                                <div className="label">
                                                                    Номер
                                                                    телефона 3
                                                                </div>
                                                                <EditFilled className="toRight" />
                                                                <input
                                                                    className="phoneValue"
                                                                    value={
                                                                        (/\|/.test(
                                                                            Sa.phone ||
                                                                                ''
                                                                        ) &&
                                                                            Sa.phone
                                                                                ?.replace(
                                                                                    /^.*?\|.*?\|([^|]*).*/,
                                                                                    '$1'
                                                                                )
                                                                                ?.replace(
                                                                                    /^ *| *$/g,
                                                                                    ''
                                                                                )
                                                                                ?.replace(
                                                                                    /^7(\d{3})(\d{3})(\d{2})(\d{2})/,
                                                                                    '+7($1) $2-$3-$4'
                                                                                )) ||
                                                                        ''
                                                                    }
                                                                    placeholder="(не указан)"
                                                                    onChange={
                                                                        setPhone
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="phone">
                                                                <div className="label">
                                                                    Номер
                                                                    телефона 1
                                                                </div>
                                                                <div className="value">
                                                                    {Sa.phone
                                                                        ?.replace(
                                                                            /^([^|]+)\|.*/,
                                                                            '$1'
                                                                        )
                                                                        ?.replace(
                                                                            /^ *| *$/g,
                                                                            ''
                                                                        )
                                                                        ?.replace(
                                                                            /^7(\d{3})(\d{3})(\d{2})(\d{2})/,
                                                                            '+7($1) $2-$3-$4'
                                                                        ) || ''}
                                                                </div>
                                                            </div>
                                                            <div className="phone">
                                                                <div className="label">
                                                                    Номер
                                                                    телефона 2
                                                                </div>
                                                                <div className="value">
                                                                    {(/\|/.test(
                                                                        Sa.phone ||
                                                                            ''
                                                                    ) &&
                                                                        Sa.phone
                                                                            ?.replace(
                                                                                /^.*?\|([^|]+)\|.*/,
                                                                                '$1'
                                                                            )
                                                                            ?.replace(
                                                                                /^ *| *$/g,
                                                                                ''
                                                                            )
                                                                            ?.replace(
                                                                                /^7(\d{3})(\d{3})(\d{2})(\d{2})/,
                                                                                '+7($1) $2-$3-$4'
                                                                            )) ||
                                                                        '(не указан)'}
                                                                </div>
                                                            </div>
                                                            <div className="phone">
                                                                <div className="label">
                                                                    Номер
                                                                    телефона 3
                                                                </div>
                                                                <div className="value">
                                                                    {(/\|/.test(
                                                                        Sa.phone ||
                                                                            ''
                                                                    ) &&
                                                                        Sa.phone
                                                                            ?.replace(
                                                                                /^.*?\|.*?\|([^|]*).*/,
                                                                                '$1'
                                                                            )
                                                                            ?.replace(
                                                                                /^ *| *$/g,
                                                                                ''
                                                                            )
                                                                            ?.replace(
                                                                                /^7(\d{3})(\d{3})(\d{2})(\d{2})/,
                                                                                '+7($1) $2-$3-$4'
                                                                            )) ||
                                                                        '(не указан)'}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="rowPersData">
                                                    <div className="site editable">
                                                        <div className="label">
                                                            Сайт
                                                        </div>
                                                        <EditFilled className="toRight" />
                                                        <input
                                                            className="siteValue"
                                                            value={
                                                                Sa.website || ''
                                                            }
                                                            placeholder="(не указан)"
                                                            onChange={setSite}
                                                        />
                                                    </div>
                                                    <div className="email editable">
                                                        <div className="label">
                                                            Электронная почта
                                                        </div>
                                                        <EditFilled className="toRight" />
                                                        <input
                                                            className="emailValue"
                                                            value={
                                                                Sa.email || ''
                                                            }
                                                            placeholder="(не указанa)"
                                                            onChange={setEmail}
                                                        />
                                                    </div>
                                                    {testStatusEdit ||
                                                    Sa.isEdit ? (
                                                        <>
                                                            <div className="instagram editable">
                                                                <div className="label">
                                                                    Instagram
                                                                </div>
                                                                <EditFilled className="toRight" />
                                                                <input
                                                                    className="instagramValue"
                                                                    value={
                                                                        Sa.social
                                                                            ?.replace(
                                                                                /^([^|]+)\|.*/,
                                                                                '$1'
                                                                            )
                                                                            ?.replace(
                                                                                /^ *| *$/g,
                                                                                ''
                                                                            ) ||
                                                                        ''
                                                                    }
                                                                    placeholder="(не указан)"
                                                                    onChange={
                                                                        setSocial
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="vk editable">
                                                                <div className="label">
                                                                    ВКонтакте
                                                                </div>
                                                                <EditFilled className="toRight" />
                                                                <input
                                                                    className="vkValue"
                                                                    value={
                                                                        (/\|/.test(
                                                                            Sa.social ||
                                                                                ''
                                                                        ) &&
                                                                            Sa.social
                                                                                ?.replace(
                                                                                    /^.*?\|([^|]+)\|.*/,
                                                                                    '$1'
                                                                                )
                                                                                ?.replace(
                                                                                    /^ *| *$/g,
                                                                                    ''
                                                                                )) ||
                                                                        ''
                                                                    }
                                                                    placeholder="(не указан)"
                                                                    onChange={
                                                                        setSocial
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="fb editable">
                                                                <div className="label">
                                                                    Facebook
                                                                </div>
                                                                <EditFilled className="toRight" />
                                                                <input
                                                                    className="fbValue"
                                                                    value={
                                                                        (/\|/.test(
                                                                            Sa.social ||
                                                                                ''
                                                                        ) &&
                                                                            Sa.social
                                                                                ?.replace(
                                                                                    /^.*?\|.*?\|([^|]*).*/,
                                                                                    '$1'
                                                                                )
                                                                                ?.replace(
                                                                                    /^ *| *$/g,
                                                                                    ''
                                                                                )) ||
                                                                        ''
                                                                    }
                                                                    placeholder="(не указан)"
                                                                    onChange={
                                                                        setSocial
                                                                    }
                                                                />
                                                            </div>
                                                            {$stor?.auth
                                                                ?.role ===
                                                            'admin' ? (
                                                                <div className="owner editable">
                                                                    <div className="label">
                                                                        UID
                                                                        кастомера
                                                                        (менеджера)
                                                                    </div>
                                                                    <EditFilled className="toRight" />
                                                                    <input
                                                                        className="ownerValue"
                                                                        value={
                                                                            Sa.ownerId ||
                                                                            Sa
                                                                                .owner
                                                                                ?.id ||
                                                                            ''
                                                                        }
                                                                        placeholder="(не указан)"
                                                                        onChange={
                                                                            setOwner
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="instagram">
                                                                <div className="label">
                                                                    Instagram
                                                                </div>
                                                                <div className="value">
                                                                    {Sa.social
                                                                        ?.replace(
                                                                            /^([^|]+)\|.*/,
                                                                            '$1'
                                                                        )
                                                                        ?.replace(
                                                                            /^ *| *$/g,
                                                                            ''
                                                                        ) ||
                                                                        '(не указан)'}
                                                                </div>
                                                            </div>
                                                            <div className="vk">
                                                                <div className="label">
                                                                    ВКонтакте
                                                                </div>
                                                                <div className="value">
                                                                    {(/\|/.test(
                                                                        Sa.social ||
                                                                            ''
                                                                    ) &&
                                                                        Sa.social
                                                                            ?.replace(
                                                                                /^.*?\|([^|]+)\|.*/,
                                                                                '$1'
                                                                            )
                                                                            ?.replace(
                                                                                /^ *| *$/g,
                                                                                ''
                                                                            )) ||
                                                                        '(не указан)'}
                                                                </div>
                                                            </div>
                                                            <div className="fb">
                                                                <div className="label">
                                                                    Facebook
                                                                </div>
                                                                <div className="value">
                                                                    {(/\|/.test(
                                                                        Sa.social ||
                                                                            ''
                                                                    ) &&
                                                                        Sa.social
                                                                            ?.replace(
                                                                                /^.*?\|.*?\|([^|]*).*/,
                                                                                '$1'
                                                                            )
                                                                            ?.replace(
                                                                                /^ *| *$/g,
                                                                                ''
                                                                            )) ||
                                                                        '(не указан)'}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="descrTitle">
                                                    Описание
                                                </div>
                                                <div className="rowDescr editable">
                                                    <EditFilled className="toRight" />
                                                    <div className="descr">
                                                        <TextArea
                                                            rows={4}
                                                            value={
                                                                Sa.description ||
                                                                ''
                                                            }
                                                            placeholder="(не указано)"
                                                            onChange={setDescr}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="paswTitle"
                                                    data-aspect={1.44}
                                                >
                                                    Фотографии салона
                                                </div>
                                                <ImgCrop
                                                    rotate
                                                    quality="0.6"
                                                    minZoom={0.5}
                                                    maxZoom="5"
                                                    shape="rect"
                                                    aspect={1.44}
                                                    grid
                                                    modalTitle="Редактировать изображение"
                                                    modalWidth="640px"
                                                    modalOk="Отправить"
                                                    modalCancel="Отмена"
                                                    modalClassName="pictureEdit"
                                                    cropperProps={{
                                                        zoomSpeed: 0.05,
                                                        restrictPosition: false,
                                                        cropSize: {
                                                            width: 602,
                                                            height: 416,
                                                        },
                                                    }}
                                                >
                                                    <Upload
                                                        action={apiFileUp}
                                                        listType="picture-card"
                                                        headers={{
                                                            Authorization: `Bearer ${$stor.auth?.accessToken}`,
                                                        }}
                                                        data={{
                                                            mediaType:
                                                                'salonPhoto',
                                                        }}
                                                        fileList={fileListSalon}
                                                        onRemove={(x) =>
                                                            onRemovePhoto(
                                                                x,
                                                                3,
                                                                'salonPhoto'
                                                            )
                                                        }
                                                        onChange={(x) =>
                                                            showPhoto(
                                                                x,
                                                                3,
                                                                'salonPhoto'
                                                            )
                                                        }
                                                        onPreview={onPreview}
                                                    >
                                                        {fileListSalon.length <=
                                                            3 && '+ Загрузить'}
                                                    </Upload>
                                                </ImgCrop>
                                                <div className="paswTitle">
                                                    Пароль{' '}
                                                    <input
                                                        type="text"
                                                        className="dummy-prev-pasw"
                                                        size="1"
                                                    />{' '}
                                                    <input
                                                        type="password"
                                                        className="dummy-prev-pasw"
                                                        size="1"
                                                    />
                                                </div>
                                                <div className="rowPasw">
                                                    <div
                                                        className="pasw edited defense"
                                                        onClick={chgPasw}
                                                    >
                                                        <div className="label">
                                                            Старый пароль
                                                        </div>
                                                        {paswA[1] ? (
                                                            <EyeFilled className="toRight" />
                                                        ) : (
                                                            <EyeInvisibleFilled className="toRight" />
                                                        )}
                                                        <input
                                                            type="password"
                                                            value={pasw1 || ''}
                                                            data-pasw="1"
                                                            onChange={chgPasw1}
                                                        />
                                                    </div>
                                                    <div
                                                        className="paswNew edited defense"
                                                        onClick={chgPasw}
                                                    >
                                                        <div className="label">
                                                            Новый пароль
                                                        </div>
                                                        {paswA[2] ? (
                                                            <EyeFilled className="toRight" />
                                                        ) : (
                                                            <EyeInvisibleFilled className="toRight" />
                                                        )}
                                                        <input
                                                            type="password"
                                                            value={pasw2 || ''}
                                                            data-pasw="2"
                                                            onChange={chgPasw2}
                                                        />
                                                    </div>
                                                    <div
                                                        className="paswNewRepeat edited defense"
                                                        onClick={chgPasw}
                                                    >
                                                        <div className="label">
                                                            Новый пароль ещё раз
                                                        </div>
                                                        {paswA[3] ? (
                                                            <EyeFilled className="toRight" />
                                                        ) : (
                                                            <EyeInvisibleFilled className="toRight" />
                                                        )}
                                                        <input
                                                            type="password"
                                                            value={pasw3 || ''}
                                                            data-pasw="3"
                                                            onChange={chgPasw3}
                                                        />
                                                    </div>
                                                    <Button
                                                        size="large"
                                                        className="orange"
                                                        onClick={saveSalon}
                                                        disabled={
                                                            nextSaveDisabled ||
                                                            '7' +
                                                                $stor.auth
                                                                    ?.userLogin !==
                                                                $stor.meProfile
                                                                    ?.phone
                                                        }
                                                    >
                                                        Сохранить
                                                    </Button>
                                                </div>
                                                <div className="editNote">
                                                    Примечание: информация будет
                                                    изменена после проверки
                                                    вашим менеджером.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="stackBlock bfCabinet pricelist">
                                            <div className="stackIn2 bfCarou-hider">
                                                <h2>Действующий прайс-лист</h2>
                                                <div className="descr">
                                                    <PriceCurrent
                                                        salon={$stor.salonDescr}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stackBlock">
                                            <div>
                                                <h2>Портфолио работ</h2>
                                            </div>
                                        </div>
                                        <div className="salonManPortfolio">
                                            <ImgCrop
                                                rotate
                                                quality="0.6"
                                                minZoom={0.5}
                                                maxZoom="5"
                                                shape="rect"
                                                aspect={1.44}
                                                grid
                                                modalTitle="Редактировать изображение"
                                                modalWidth="640px"
                                                modalOk="Отправить"
                                                modalCancel="Отмена"
                                                modalClassName="pictureEdit"
                                                cropperProps={{
                                                    zoomSpeed: 0.05,
                                                    restrictPosition: false,
                                                    cropSize: {
                                                        width: 602,
                                                        height: 416,
                                                    },
                                                }}
                                            >
                                                <Upload
                                                    action={apiFileUp}
                                                    listType="picture-card"
                                                    headers={{
                                                        Authorization: `Bearer ${$stor.auth?.accessToken}`,
                                                    }}
                                                    data={{
                                                        mediaType: 'portfolio',
                                                    }}
                                                    fileList={fileListPortf}
                                                    onRemove={(x) =>
                                                        onRemovePhoto(
                                                            x,
                                                            18,
                                                            'portfolio'
                                                        )
                                                    }
                                                    onChange={(x) =>
                                                        showPhoto(
                                                            x,
                                                            18,
                                                            'portfolio'
                                                        )
                                                    }
                                                    onPreview={onPreview}
                                                >
                                                    {fileListPortf.length <=
                                                        18 && '+ Загрузить'}
                                                </Upload>
                                            </ImgCrop>
                                        </div>
                                        <div className="stackBlock">
                                            <div>
                                                <h2>Преимущества салона</h2>
                                            </div>

                                            <AdvantagesTab
                                                advantages={
                                                    $stor?.salonDescr &&
                                                    'advantages' in
                                                        $stor?.salonDescr
                                                        ? $stor?.salonDescr
                                                              .advantages
                                                        : undefined
                                                }
                                                salonId={Sa.id}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div
                                className={`tab tab2 scheduler${
                                    hashes.indexOf(locHash) === 1
                                        ? ' active'
                                        : ''
                                }`}
                                id="scheduler"
                                data-tab="2"
                            >
                                {hashes.indexOf(locHash) === 1 && (
                                    <SalonSheduleTab
                                        loading={loading}
                                        handleFetchSchedule={
                                            handleFetchSchedule
                                        }
                                        handlePrevDate={handlePrevDate}
                                        handleSelectDate={handleSelectDate}
                                        selectedDate={selectedDate}
                                        handleNextDate={handleNextDate}
                                        scheduleData={scheduleData}
                                        handleSetNeedLoad={handleSetNeedLoad}
                                    />
                                )}
                            </div>
                            <div
                                className={`tab tab3 clients${
                                    hashes.indexOf(locHash) === 2
                                        ? ' active'
                                        : ''
                                }`}
                                id="clients"
                                data-tab="3"
                            >
                                {Sa?.clients?.length != null ? (
                                    <ClientsMasters
                                        mode="clients"
                                        sa={Sa}
                                        selectedDate={selectedDate}
                                        events={scheduleData}
                                        handleSetNeedLoad={handleSetNeedLoad}
                                    />
                                ) : (
                                    <div>
                                        <i>Загрузка клиентов...</i>
                                    </div>
                                )}
                            </div>
                            <div
                                className={`tab tab4 clients${
                                    hashes.indexOf(locHash) === 3
                                        ? ' active'
                                        : ''
                                }`}
                                id="clients"
                                data-tab="4"
                            >
                                {Sa?.employers?.length >= 0 ? (
                                    <MastersList
                                        salon={Sa}
                                        selectedDate={selectedDate}
                                        events={scheduleData}
                                        handleSetNeedLoad={handleSetNeedLoad}
                                    />
                                ) : (
                                    <div>
                                        <i>Загрузка мастеров...</i>
                                    </div>
                                )}
                            </div>
                            <div
                                className={`tab tab5 bonus${
                                    hashes.indexOf(locHash) === 4
                                        ? ' active'
                                        : ''
                                }`}
                                id="bonus"
                                data-tab="5"
                            >
                                <EventsList
                                    selectedDate={selectedDate}
                                    events={scheduleData}
                                    handleSetNeedLoad={handleSetNeedLoad}
                                />
                                5 - Акции и бонусы
                            </div>
                            <div
                                className={`tab tab6 priceEdit${
                                    hashes.indexOf(locHash) === 5
                                        ? ' active'
                                        : ''
                                }`}
                                id="priceEdit"
                                data-tab="6"
                            >
                                {hashes.indexOf(locHash) === 5 && (
                                    <SalonPriceList
                                        selectedDate={selectedDate}
                                        events={scheduleData}
                                        handleSetNeedLoad={handleSetNeedLoad}
                                    />
                                )}
                            </div>
                            <div
                                className={`tab tab7 analythics${
                                    hashes.indexOf(locHash) === 6
                                        ? ' active'
                                        : ''
                                }`}
                                id="analythics"
                                data-tab="7"
                            >
                                7 - Аналитика
                                {hashes.indexOf(locHash) === 6 && (
                                    <SalonPriceList
                                        selectedDate={selectedDate}
                                        events={scheduleData}
                                        handleSetNeedLoad={handleSetNeedLoad}
                                    />
                                )}
                            </div>
                            <div
                                className={`tab tab8 support${
                                    hashes.indexOf(locHash) === 7
                                        ? ' active'
                                        : ''
                                }`}
                                id="support"
                                data-tab="8"
                            >
                                8 - Support dialogs
                                {hashes.indexOf(locHash) === 7 && (
                                    <SalonPriceList
                                        selectedDate={selectedDate}
                                        events={scheduleData}
                                        handleSetNeedLoad={handleSetNeedLoad}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            <PageFoot />
        </>
    );
}

export default SalonMan;
