import { Table, Tag, Space } from 'antd';
export const columnSchema = [
    /*{
        title: 'Салон',
        key: 'salonId',
        dataIndex: ['salon','title'],
        type: 'select',
        show: true,
        required: true,
        value: '',
        options: [

        ],
    },*/{
        title: 'Имя',
        key: 'name',
        dataIndex: 'name',
        type: 'input',
        show: true,
        required: true,
        value: ''
    },{
        title: 'Телефон',
        key: 'phone',
        dataIndex: 'phone',
        type: 'phone',
        show: true,
        required: true,
        value: ''
    },{
        title: 'Аватар',
        key: 'avatar',
        dataIndex: 'avatar',
        type: 'file',
        show: true,
        required: false,
        value: ''
    },{
        title: 'День рождения',
        key: 'birthday',
        dataIndex: 'birthday',
        type: 'date',
        show: true,
        required: true,
        value: ''
    },{
        title: 'Подробнее',
        key: 'description',
        dataIndex: 'description',
        type: 'textarea',
        show: true,
        required: true,
        value: ''
    },{
        title: 'Наценка',
        key: 'priceAspectRatio',
        dataIndex: 'priceAspectRatio',
        type: 'number',
        step: 0.1,
        min: 0,
        max: 2,
        show: true,
        required: true,
        value: ''
    },{
        title: 'Рейтинг',
        key: 'rating',
        dataIndex: 'rating',
        type: 'number',
        step: 1,
        min: 1,
        max: 5,
        show: true,
        required: true,
        value: ''
    },{
        title: 'Услуги',
        key: 'serviceTypeIds',
        dataIndex: 'serviceTypeIds',
        // render: (text, record) => (
        //
        //     record.serviceType.map(item => {
        //         if(item){
        //             return(
        //                 <Tag color={'green'} key={item.id} style={{margin: 2}}>{item.title}</Tag>
        //             )
        //         }else{
        //             return null
        //         }
        //
        //     })
        //
        //
        // ),
        type: 'multiselect',
        show: true,
        required: true,
        value: '',
        options: [
        ]
    },{
        title: 'Статус',
        key: 'status',
        dataIndex: 'status',
        type: 'enum',
        show: true,
        required: true,
        value: '',
        options: [
            'Активная', 'Отключена'
        ]
    }
]
