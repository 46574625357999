import { FC } from 'react';
import { Layout } from 'antd';
import { PageHead, PageFoot } from '../part/PageParts';
import styles from './NotFound.module.scss';
import { NavLink } from 'react-router-dom';

export const NotFound404: FC = () => {
    return (
        <>
            <Layout className="home">
                <PageHead />
                <div className={styles.wrapper}>
                    <div className={styles.inner}>
                        <div className={styles.content}>
                            <h2>Ошибка</h2>
                            <h1>404</h1>
                            <h4>Пу-пу-пу...</h4>
                            <p>
                                А вот такой страницы у нас еще не было. Проверь
                                адрес страницы, пожалуйста. Или начни все
                                сначала)
                            </p>
                            <NavLink to={'/'} className={styles.link}>
                                Главная страница
                            </NavLink>
                        </div>
                    </div>
                </div>
            </Layout>
            {/*@ts-ignore*/}
            <PageFoot />
        </>
    );
};
