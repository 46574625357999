export const columnSchema = [
    {
        title: 'Имя',
        key: 'firstName',
        dataIndex: 'firstName',
        type: 'input',
        show: true,
        value: ''
    },{
        title: 'Фамилия',
        key: 'lastName',
        dataIndex: 'lastName',
        type: 'input',
        show: true,
        value: ''
    },{
        title: 'Отчество',
        key: 'patronymic',
        dataIndex: 'patronymic',
        type: 'input',
        show: true,
        value: ''
    }, {
        title: 'День рождения',
        key: 'birthDate',
        dataIndex: 'birthDate',
        type: 'date',
        show: true,
        required: true,
        value: ''
    },{
        title: 'Телефон',
        key: 'phone',
        dataIndex: 'phone',
        type: 'phone',
        show: true,
        value: ''
    },{
        title: 'Почта',
        key: 'email',
        dataIndex: 'email',
        type: 'input',
        show: true,
        value: ''
    },{
        title: 'Город',
        key: 'city',
        dataIndex: 'city',
        type: 'input',
        show: true,
        value: ''
    },{
        title: 'Рейтинг',
        key: 'clientRating',
        dataIndex: 'clientRating',
        type: 'number',
        step: 1,
        min: 1,
        max: 5,
        show: true,
        value: ''
    },{
        title: 'Статус',
        key: 'status',
        dataIndex: 'status',
        type: 'enum',
        show: true,
        value: '',
        options: [
            'Активные', 'Отключенный'
        ]
    },{
        title: 'Салон',
        key: 'salon',
        dataIndex: 'salon',
        type: 'select-salons',
        show: true,
        value: '',
        options: [

        ]
    }
]
