import { useCallback, useEffect, useRef, useState } from 'react';
import { IListedClient } from '../Types/SalonTypes.ts';
import { CommonSalonsAPI } from '../api/common/salons.ts';
import { message } from 'antd';

export function useLoadAvailableClients(clientsList: string[]) {
    const [pending, setPending] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const loadRef = useRef(false);

    const [clientsAvailable, setClientsAvailable] = useState<IListedClient[]>(
        []
    );

    const handleLoadClients: () => Promise<IListedClient[]> =
        useCallback(async () => {
            loadRef.current = true;
            try {
                setPending(true);

                const response = await CommonSalonsAPI.getClientsList();

                const newAvailableClients: IListedClient[] =
                    response.data.clients.filter(
                        (el) => clientsList.indexOf(el.id) === -1
                    );

                setClientsAvailable(() => newAvailableClients);
                setLoaded(() => true);

                return newAvailableClients;
            } catch (e) {
                message.error('Ошибка при загрузке списка клиентов');
            } finally {
                setPending(() => false);
                loadRef.current = false;
            }
        }, [clientsList]);

    useEffect(() => {
        if (!loaded && !loadRef.current) {
            loadRef.current = true;
            void handleLoadClients();
        }
    }, []);

    return { loaded, pending, handleLoadClients, clientsAvailable };
}
