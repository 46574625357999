import { FC } from 'react';
import { SearchForm } from '../../Home/search-form';

import styles from './change-search.module.scss';
import { CloseOutlined } from '@ant-design/icons';
import { ISearchQueryFilters } from '../../../Pages/ResultNew/index.tsx';

interface Props {
    opened: boolean;
    onClose: () => void;
    currentFilters: ISearchQueryFilters;
}

export const ChangeSearch: FC<Props> = ({
    opened,
    onClose,
    currentFilters,
}) => {
    return (
        <div
            style={{
                display: opened ? 'flex' : 'none',
            }}
            className={styles.wrapper}
        >
            <div className={styles.inner}>
                <div className={styles.close} onClick={onClose}>
                    <CloseOutlined />
                </div>
                <span className={styles.title}>
                    Внесите изменения в запрос и нажмите НАЧАТЬ ПОИСК
                </span>
                {opened && <SearchForm currentFilters={currentFilters} />}
            </div>
        </div>
    );
};
