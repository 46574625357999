import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Layout, message, Pagination, Select } from 'antd';
import { BanrHead, PageFoot, PageHead, SideMen2 } from '../part/PageParts';
import {
    Filters,
    IResultFilter,
} from '../../Components/Result/Filters/index.tsx';
import { ResultCard } from '../../Components/Result/Card/index.tsx';
import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { ResultOnMap } from '../../Components/Result/OnMap/index.tsx';
import { SearchAPI } from '../../api/search/index.ts';
import moment from 'moment/moment';
import { IListedCategories, ISalon } from '../../Types/SalonTypes.ts';
import { IModalPrevState } from '../../Components/Cabinet/Salon/SalonSheduleTab.tsx';
import { AddScheduleModal } from '../../Modals/AddScheduleModal.tsx';
import { MediaPoint, useMediaContext } from '../../Services/Media.tsx';
import { ScheduleType } from '../../Modals/AddScheduleModalForm.tsx';
import { useTimeContext } from '../../Services/Time.tsx';

import styles from './ResulNew.module.scss';
import { CommonSalonsAPI } from '../../api/common/salons.ts';
import { ChangeSearch } from '../../Components/Result/change-search/index.tsx';

const IS_ON_MAP_PARAM = 'onMap';
const IS_ON_MAP_VALUE = 'true';

const initialFilters: IResultFilter = {
    price: {
        min: 500,
        max: 10000,
    },
    stanchions: [],
    types: [],
    purchaseType: [],
    ratings: [],
};

export const ResultParamsNames = {
    date: 'date' as const,
    serviceIds: 'serviceIds' as const,
    hourStart: 'hourStart' as const,
    hourEnd: 'hourEnd' as const,
};

export type ISearchQueryFilters =
    | Record<keyof typeof ResultParamsNames, string | any>
    | undefined;

export const ResultNew: FC = () => {
    const { utcWithDiff } = useTimeContext();
    const { media } = useMediaContext();
    const history = useHistory();
    const { search } = useLocation();

    const [currentFilters, setCurrentFilters] =
        useState<ISearchQueryFilters>(undefined);

    const [categoriesPending, setCategoriesPending] = useState(false);
    const [categoriesError, setCategoriesError] = useState(false);
    const [categories, setCategories] = useState<IListedCategories>([]);

    const handleFetchCategories = useCallback(async () => {
        try {
            setCategoriesPending(true);
            const response = await CommonSalonsAPI.getAllCategories();

            setCategories(response.data.categories);
            setCategoriesError(false);
            setCategoriesPending(false);
        } catch (e) {
            void message.error('Ошибка при загрузке списка услуг');
            setCategoriesPending(false);
            setCategoriesError(true);
        }
    }, []);

    useEffect(() => {
        void handleFetchCategories();
    }, []);

    const servicesList = useMemo(() => {
        const result: {
            id: string | number;
            title: string;
        }[] = [];

        categories.forEach((cat) => {
            result.push({
                id: cat.id,
                title: cat.title,
            });

            if (cat.children) {
                cat.children.forEach((catChild) => {
                    result.push({
                        id: catChild.id,
                        title: catChild.title,
                    });
                });
            }
        });

        return result;
    }, [categories]);

    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    const [isPending, setIsPending] = useState(false);

    const [salonsFullList, setSalonsFullList] = useState<ISalon[]>([]);
    const [salonsLEFoundList, setSalonsLEFoundList] = useState<ISalon[]>([]);
    const [salonsLSFoundList, setSalonsLSFoundList] = useState<ISalon[]>([]);

    const handleLoadBySelectedFilters = useCallback(
        async (data: Record<keyof typeof ResultParamsNames, any>) => {
            try {
                setIsPending(true);
                const response = await SearchAPI.getResultByParams(data);

                setSalonsFullList(() => response.data.full);
                setSalonsLEFoundList(() => response.data.less_found_end_time);
                setSalonsLSFoundList(() => response.data.less_found_start_time);
                setIsPending(false);
            } catch (e) {
                message.error(
                    'Ошибка при поиске результатов, попробуйте позже'
                );
                setIsPending(false);
            }
        },
        []
    );

    useEffect(() => {
        const hourEnd = searchParams.get(ResultParamsNames.hourEnd);

        let dataToFetch: Record<keyof typeof ResultParamsNames | string, any> =
            {
                [ResultParamsNames.date]: searchParams.get(
                    ResultParamsNames.date
                ),
                [ResultParamsNames.serviceIds]: JSON.parse(
                    searchParams.get(ResultParamsNames.serviceIds)
                ),
                [ResultParamsNames.hourStart]: searchParams.get(
                    ResultParamsNames.hourStart
                ),
            };

        if (hourEnd) {
            dataToFetch = {
                ...dataToFetch,
                [ResultParamsNames.hourEnd]: hourEnd,
            };
        }

        if (
            dataToFetch.serviceIds &&
            dataToFetch.date &&
            dataToFetch.hourStart
        ) {
            setCurrentFilters(dataToFetch);
            void handleLoadBySelectedFilters(dataToFetch);
        }
    }, []);

    const isOnMap = useMemo(() => {
        return searchParams.get(IS_ON_MAP_PARAM) === IS_ON_MAP_VALUE;
    }, [searchParams]);

    const [filtersList, setFiltersList] = useState<IResultFilter>(
        JSON.parse(JSON.stringify(initialFilters))
    );

    const handleResetFilters = useCallback(() => {
        setFiltersList(JSON.parse(JSON.stringify(initialFilters)));
    }, []);

    const absAllSalonsList = useMemo(() => {
        return [...salonsFullList, ...salonsLEFoundList, ...salonsLSFoundList];
    }, [salonsFullList, salonsLEFoundList, salonsLSFoundList]);

    const handleGoToMap = useCallback(() => {
        if (!absAllSalonsList || absAllSalonsList.length === 0) {
            void message.warning('Нет салонов для отображения на карте');
        } else {
            searchParams.set(IS_ON_MAP_PARAM, IS_ON_MAP_VALUE);
            history.replace({ search: searchParams.toString() });
        }
    }, [searchParams, history, absAllSalonsList]);

    const handleGoToList = useCallback(() => {
        searchParams.delete(IS_ON_MAP_PARAM);
        history.replace({ search: searchParams.toString() });
    }, [searchParams, history]);

    const [sheduleOpen, setSheduleOpen] = useState(false);
    const [selectedSalon, setSelectedSalon] = useState<ISalon | undefined>(
        undefined
    );
    const [shPrevState, setShPrevState] = useState<IModalPrevState | undefined>(
        undefined
    );

    const clientId = useMemo(() => {
        const token = JSON.parse(localStorage.getItem('auth'))?.accessToken;

        if (token) {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                window
                    .atob(base64)
                    .split('')
                    .map(function (c) {
                        return (
                            '%' +
                            ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                        );
                    })
                    .join('')
            );

            return JSON.parse(jsonPayload).id;
        } else {
            return undefined;
        }
    }, []);

    const handleSheduleToSalon = useCallback(
        (salon: ISalon) => {
            const selectedServices = [];

            const allServicesIds = JSON.parse(
                searchParams.get(ResultParamsNames.serviceIds)
            ).map((el) => Number(el));

            salon.services.forEach((el) => {
                if (allServicesIds.indexOf(el.service.id) > -1) {
                    selectedServices.push(el.id);
                }
            });

            setSelectedSalon(() => salon);
            setShPrevState(() => ({
                servicesIds: selectedServices,
                type: ScheduleType.schedule,
                dateStart: moment(searchParams.get(ResultParamsNames.date)).set(
                    {
                        hours: Number(
                            searchParams
                                .get(ResultParamsNames.hourStart)
                                .split(':')[0]
                        ),
                        minutes: Number(
                            searchParams
                                .get(ResultParamsNames.hourStart)
                                .split(':')[1]
                        ),
                    }
                ),
            }));
            setSheduleOpen(true);
        },
        [searchParams]
    );

    const clearPrevState = useCallback(() => {
        setShPrevState(undefined);
    }, []);

    const handleSelectOrderToSalon = useCallback(
        (salonId: number) => {
            const selectedSalon = absAllSalonsList.find(
                (el) => el.id === salonId
            );

            if (selectedSalon) {
                handleSheduleToSalon(selectedSalon);
            }
        },
        [handleSheduleToSalon, absAllSalonsList]
    );

    const currentServicesSelected = useMemo(() => {
        const result: string[] = [];

        if (servicesList.length === 0) {
            return '';
        }

        (currentFilters.serviceIds as (string | number)[]).forEach(
            (service) => {
                if (servicesList.find((el) => el.id === service)) {
                    result.push(
                        servicesList.find((el) => el.id === service).title
                    );
                }
            }
        );

        return result.join(', ');
    }, [servicesList, currentFilters]);

    const [changeFiltersOpened, setChangeFiltersOpened] = useState(false);

    const handleOpenChangeFilters = useCallback(() => {
        setChangeFiltersOpened(true);
    }, []);

    const handleChangeFiltersClose = useCallback(() => {
        setChangeFiltersOpened(false);
    }, []);

    return (
        <>
            <Layout>
                <PageHead />
                {/*@ts-ignore*/}
                <SideMen2 />
                <div className="layCont">
                    <BanrHead hide={process.env.REACT_APP_VISUAL_FOR_DEV} />
                    <div className={styles.wrapper}>
                        <Filters
                            filtersList={filtersList}
                            handleResetFilters={handleResetFilters}
                            setFiltersList={setFiltersList}
                            media={media}
                        />
                        <div
                            className={`${styles.resultContent}${
                                isPending ? ` ${styles.pending}` : ''
                            }`}
                        >
                            {isPending ? (
                                <LoadingOutlined />
                            ) : (
                                <>
                                    {media === MediaPoint.MOBILE && (
                                        <div className={styles.resultHistory}>
                                            <span
                                                className={
                                                    styles.resultHistoryItem
                                                }
                                            >
                                                {moment(
                                                    currentFilters?.date
                                                ).format('D MMMM YYYY')}
                                                г. с {currentFilters?.hourStart}{' '}
                                                {currentFilters?.hourEnd
                                                    ? `до ${currentFilters?.hourEnd}`
                                                    : ''}
                                            </span>
                                            <span
                                                className={styles.resultChange}
                                                onClick={
                                                    handleOpenChangeFilters
                                                }
                                            >
                                                Изменить
                                            </span>
                                            <br />
                                            <span
                                                className={
                                                    styles.resultHistoryItem
                                                }
                                            >
                                                Классический маникюр
                                            </span>
                                        </div>
                                    )}
                                    <span className={styles.resultTitle}>
                                        Результаты поиска
                                        {media === MediaPoint.TABLET && (
                                            <span
                                                className={
                                                    styles.resultShowFilters
                                                }
                                            >
                                                Фильтры
                                            </span>
                                        )}
                                        {media === MediaPoint.MOBILE && (
                                            <Button
                                                type={'primary'}
                                                className={styles.btn}
                                                size={'small'}
                                                onClick={handleGoToMap}
                                            >
                                                Смотреть на карте
                                            </Button>
                                        )}
                                    </span>
                                    {media !== MediaPoint.MOBILE && (
                                        <div className={styles.resultHistory}>
                                            <span>Вы искали:</span>
                                            <span
                                                className={
                                                    styles.resultHistoryItem
                                                }
                                            >
                                                {currentServicesSelected} в г.
                                                Москва{' '}
                                                {moment(
                                                    currentFilters?.date
                                                ).format('D MMMM')}{' '}
                                                с {currentFilters?.hourStart}{' '}
                                                {currentFilters?.hourEnd
                                                    ? `до ${currentFilters?.hourEnd}`
                                                    : ''}
                                            </span>
                                            <span
                                                className={styles.resultChange}
                                                onClick={
                                                    handleOpenChangeFilters
                                                }
                                            >
                                                Изменить
                                            </span>
                                        </div>
                                    )}
                                    {media === MediaPoint.MOBILE ? (
                                        <div className={styles.mobileFilters}>
                                            <div className={styles.mobRow}>
                                                <span
                                                    className={styles.mobFilter}
                                                >
                                                    <Checkbox
                                                        className={
                                                            styles.checkBox
                                                        }
                                                    >
                                                        Акции
                                                    </Checkbox>
                                                </span>
                                                <span
                                                    className={`${styles.mobFilter} ${styles.mobBtn}`}
                                                >
                                                    Фильтры
                                                </span>
                                            </div>
                                            <div className={styles.mobRow}>
                                                <span
                                                    className={styles.mobSort}
                                                >
                                                    Сортировка:
                                                </span>
                                            </div>
                                            <div className={styles.mobRow}>
                                                <span
                                                    className={styles.mobFilter}
                                                >
                                                    Рекомендуем
                                                </span>
                                                <span
                                                    className={`${styles.mobFilter} ${styles.selected}`}
                                                >
                                                    Время
                                                </span>
                                                <span
                                                    className={styles.mobFilter}
                                                >
                                                    Стоимость
                                                </span>
                                                <span
                                                    className={styles.mobFilter}
                                                >
                                                    Рейтинг
                                                </span>
                                                <span
                                                    className={styles.mobFilter}
                                                >
                                                    Удаленность
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles.resultControls}>
                                            <Button
                                                type={'primary'}
                                                className={styles.btn}
                                                onClick={handleGoToMap}
                                            >
                                                Посмотреть на карте
                                            </Button>
                                            <div className={styles.resultSort}>
                                                <span className={styles.name}>
                                                    Отсортировать:
                                                </span>
                                                <span
                                                    className={styles.sortItem}
                                                >
                                                    Мы рекомендуем
                                                </span>
                                                <Select
                                                    bordered={false}
                                                    className={styles.select}
                                                    placeholder={'Стоимость'}
                                                    dropdownMatchSelectWidth={
                                                        false
                                                    }
                                                    size={
                                                        media <=
                                                        MediaPoint.TABLET
                                                            ? 'small'
                                                            : 'middle'
                                                    }
                                                    options={[
                                                        {
                                                            value: '1',
                                                            label: 'По возрастанию',
                                                        },
                                                        {
                                                            value: '2',
                                                            label: 'По убыванию',
                                                        },
                                                    ]}
                                                />
                                                <span
                                                    className={styles.sortItem}
                                                >
                                                    Акции
                                                </span>
                                                <Select
                                                    bordered={false}
                                                    className={styles.select}
                                                    placeholder={'Рейтинг'}
                                                    dropdownMatchSelectWidth={
                                                        false
                                                    }
                                                    size={
                                                        media <=
                                                        MediaPoint.TABLET
                                                            ? 'small'
                                                            : 'middle'
                                                    }
                                                    options={[
                                                        {
                                                            value: '1',
                                                            label: 'По возрастанию',
                                                        },
                                                        {
                                                            value: '2',
                                                            label: 'По убыванию',
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className={styles.resultList}>
                                        {/*{salonsFullList.map((el) => (*/}
                                        {absAllSalonsList.map((el) => (
                                            <ResultCard
                                                salon={el}
                                                key={el.id}
                                                handleShedule={() => {
                                                    handleSheduleToSalon(el);
                                                }}
                                                media={media}
                                                queryFilters={currentFilters}
                                            />
                                        ))}
                                    </div>
                                    <div className={styles.paginationWrapper}>
                                        <Pagination
                                            className={'bs-res-pagination'}
                                            defaultCurrent={1}
                                            total={50}
                                        />
                                        <Button
                                            type={'primary'}
                                            className={styles.btn}
                                        >
                                            <RedoOutlined />
                                            Загрузить еще
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
            {/*@ts-ignore*/}
            <PageFoot />
            {selectedSalon && (
                <AddScheduleModal
                    salonData={selectedSalon}
                    open={sheduleOpen}
                    setOpen={setSheduleOpen}
                    mode={'client'}
                    clientId={clientId}
                    handleSetNeedLoad={() => {}}
                    startTime={
                        selectedSalon.startJobTime
                            .split(':')
                            .map((el, index) =>
                                index === 0
                                    ? Number(el) + utcWithDiff
                                    : Number(el)
                            ) as [number, number]
                    }
                    endTime={
                        selectedSalon.endJobTime
                            .split(':')
                            .map((el, index) =>
                                index === 0
                                    ? Number(el) + utcWithDiff
                                    : Number(el)
                            ) as [number, number]
                    }
                    modalPrevState={shPrevState}
                    clearPrevState={clearPrevState}
                />
            )}
            {isOnMap && absAllSalonsList && absAllSalonsList.length > 0 && (
                <ResultOnMap
                    filtersList={filtersList}
                    handleResetFilters={handleResetFilters}
                    setFiltersList={setFiltersList}
                    handleGoToList={handleGoToList}
                    salonsList={absAllSalonsList}
                    selectedServices={JSON.parse(
                        searchParams.get(ResultParamsNames.serviceIds)
                    ).map((el) => Number(el))}
                    handleSelectOrderToSalon={handleSelectOrderToSalon}
                />
            )}
            <ChangeSearch
                opened={changeFiltersOpened}
                onClose={handleChangeFiltersClose}
                currentFilters={currentFilters}
            />
        </>
    );
};
