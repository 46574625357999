export const columnSchema = [
    {
        title: 'Салон',
        key: 'salonId',
        dataIndex: 'salonId',
        type: 'select',
        show: true,
        required: true,
        value: '',
        options: [

        ]
    },{
        title: 'Заголовок',
        key: 'title',
        dataIndex: 'title',
        type: 'input',
        show: true,
        value: ''
    },{
        title: 'Описание',
        key: 'description',
        dataIndex: 'description',
        type: 'textarea',
        show: true,
        value: ''
    },{
        title: 'Условия',
        key: 'terms',
        dataIndex: 'terms',
        type: 'input',
        show: true,
        value: ''
    },{
        title: 'Миниатюра',
        key: 'thumbs',
        dataIndex: 'thumbs',
        type: 'file',
        show: true,
        value: ''
    },{
        title: 'Статус услуги',
        key: 'serviceStatus',
        dataIndex: 'serviceStatus',
        type: 'enum',
        show: true,
        required: true,
        value: '',
        options: [
            'Активная', 'Отключена'
        ]
    },{
        title: 'Статус акции',
        key: 'status',
        dataIndex: 'status',
        type: 'enum',
        show: true,
        required: true,
        value: '',
        options: [
            'Активная', 'Отключена'
        ]
    },{
        title: 'Дата начала',
        key: 'startDate',
        dataIndex: 'startDate',
        type: 'startDate',
        show: true,
        value: ''
    },{
        title: 'Дата окончания',
        key: 'endDate',
        dataIndex: 'endDate',
        type: 'endDate',
        show: true,
        value: ''
    }
]
