import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Footer.module.scss';

export const Footer: FC = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.inner}>
                <span>©2021-2023 BEAUTYSPUTNIK.RU Все права защищены.</span>
                <div className={styles.menu}>
                    <NavLink to={'/about'}>О нас</NavLink>
                    <NavLink to={'/privacy'}>
                        Политика конфиденциальности
                    </NavLink>
                    <NavLink to={'/agreement'}>
                        Пользовательское соглашение
                    </NavLink>
                    <NavLink to={'/support'}>Техподдержка</NavLink>
                    <NavLink to={'/contacts'}>Контакты</NavLink>
                </div>
            </div>
            <div className={styles.metric}>
                <a
                    href="https://metrika.yandex.ru/stat/?id=94436758&amp;from=informer"
                    target="_blank"
                    rel="nofollow"
                >
                    <img
                        src="https://informer.yandex.ru/informer/94436758/3_1_3A4855FF_1A2835FF_1_pageviews"
                        style={{
                            width: 88,
                            height: 31,
                            border: 0,
                        }}
                        alt="Яндекс.Метрика"
                        title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)"
                        className="ym-advanced-informer"
                        data-cid="94436758"
                        data-lang="ru"
                    />
                </a>
            </div>
        </div>
    );
};
