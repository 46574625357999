import { baseAPIInstance } from '../index.ts';
import { AxiosResponse } from 'axios';
import { ILocation } from '../../Services/Location.tsx';
import { IGeneralAction, IPromptItem } from '../../Types/Common.ts';
import {
    ICreateEmployer,
    IMaster,
    IPatchEmployer,
} from '../../Types/SalonTypes.ts';

interface ICommonApi {
    getCurrentLocation: () => Promise<AxiosResponse<ILocation['data']>>;
    getPromptList: () => Promise<
        AxiosResponse<{
            actions: IPromptItem[];
        }>
    >;
    getMainPromptList: () => Promise<AxiosResponse<IGeneralAction[]>>;
    getMastersList: () => Promise<AxiosResponse<IMaster[]>>;
    createEmp: (data: ICreateEmployer) => Promise<AxiosResponse>;
    editEmp: (data: IPatchEmployer, id: string) => Promise<AxiosResponse>;
    deleteEmployer: (id: string) => Promise<AxiosResponse>;
    createClient: (phone: string) => Promise<
        AxiosResponse<{
            birthDate: null;
            city: null;
            createAt: string;
            discount: string;
            email: null;
            emailStatus: 'Не подтвержден';
            firstName: null;
            id: string;
            isEdit: true;
            king: string;
            lastName: null;
            patronymic: null;
            phone: null;
            phoneStatus: 'Не подтвержден';
            rating: 4.5;
            role: 'client';
            status: 'Отключенный';
            updateAt: string;
        }>
    >;
}

export const CommonAPI: ICommonApi = {
    getCurrentLocation: async () => {
        return baseAPIInstance.get('/dadata/city/by-ip');
    },
    getPromptList: async () => {
        return baseAPIInstance.get('/action/all');
    },
    getMastersList: async () => {
        return baseAPIInstance.get('/account/masters');
    },
    createEmp: async (data) => {
        return baseAPIInstance.post('/employer', data);
    },
    editEmp: async (data, id) => {
        return baseAPIInstance.patch(`employer/${id}`, data);
    },
    deleteEmployer: async (id) => {
        return baseAPIInstance.delete(`employer/${id}`);
    },
    getMainPromptList: async () => {
        return baseAPIInstance.get('/action/general');
    },
    createClient: async (phone) => {
        return baseAPIInstance.post('/auth/phone/request', {
            phone: phone,
            isSalon: true,
        });
    },
};
