import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {columnSchema} from '../data/columnSchema';
import {Table, Card, Space, Row, Col, Button, Modal} from 'antd';
import {Content} from 'antd/es/layout/layout';
import Breadcrumbs from '../../../Components/Breadcrumbs';
import {DeleteOutlined, EditOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {FormComponent} from '../../../Components/FormComponent';
import {useDispatch, useSelector} from 'react-redux';

const {confirm} = Modal;

export default function CustomerDataComponent (){
    const $stor = useSelector(state => state);
    const dispatch = useDispatch();

    const inputRef = useRef([]);
    const handler = data => ev => {
        if(ev){
            switch(data.item.type){
                case 'enum':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            [data.item.key]: ev,
                        },
                    })
                    // setFormData({
                    //     ...formData,
                    //     [data.item.key]: ev
                    // })
                    break;
                case 'input':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            [ev.target.name]: ev.target.value,
                        },
                    })
                    // setFormData({
                    //     ...formData,
                    //     [ev.target.name]: ev.target.value
                    // })
                    break;
                case 'textarea':
                    // setFormData({
                    //     ...formData,
                    //     [ev.target.name]: ev.target.value
                    // })
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            [ev.target.name]: ev.target.value,
                        },
                    })
                    break;
                case 'number':
                    // setFormData({
                    //     ...formData,
                    //     [ev.target.name]: ev.target.value
                    // })
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            [ev.target.name]: ev.target.value,
                        },
                    })
                    break;
                case 'date':
                    // setFormData({
                    //     ...formData,
                    //     'birthday': ev.format()
                    // })
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            'birthday': ev.format(),
                        },
                    })
                    break;
                case 'select':
                    // setFormData({
                    //     ...formData,
                    //     [data.item.key]: ev
                    // })
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            [data.item.key]: ev,
                        },
                    })
                    break;
                case 'file':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...$stor.formData,
                            [data.item.key]: ev.target.value,
                        },
                    })
            }
        }
        const next = inputRef.current[ev];
        next && next.focus();
    };

    let title='Пользователи', parent='Главная'
    const remoteUrl = `${process.env.REACT_APP_REMOTE_HOST}customer/`;

    function getTableData(){
        return dispatch => {
            axios.get(`${remoteUrl}all`)
                .then(res =>
                    dispatch({
                        type: 'SOURCE-TABLE',
                        tableSource: res.data,
                    })
                );
        };
    }

    function getAdditionalData(){
        return dispatch => {
            axios.get(`${process.env.REACT_APP_REMOTE_HOST}salon/all`).then(res => {
                dispatch({
                    type: 'ADDITIONAL-DATA',
                    salonId: res.data,
                })
            })
        };
    }

    function getTableColumn(){
        return dispatch => {
            if(columnSchema.some(item => {
                return item.key === 'action';
            })){
            }else{
                columnSchema.push({
                    title: 'Action',
                    key: 'action',
                    width: 80,
                    render: (text, record) => <Space size='middle'>
                        <a onClick={() => onDeleteClick(record.id)}><DeleteOutlined /></a>
                        <a onClick={()=> onEditClick(text)}><EditOutlined /></a>
                    </Space>,
                })
            }
            dispatch({
                type: 'COLUMN-TABLE',
                tableColumn: columnSchema,
            })
        };
    }

    function deleteDateFromUrl(url, id){
        axios.delete(remoteUrl + id).then(async response => {
            dispatch(getAdditionalData());
            dispatch(getTableData());
            dispatch(getTableColumn());
        }).catch(er => {
            console.log(er);
        });
    }

    let onEditClick = data => {

        let obj = {}
        columnSchema.forEach(item => {
            obj = {
                ...obj,
                [item.key]: data[item.key],
            };
        })
        dispatch({
            type: 'TOGGLE-FORM',
            isFormShow: false,
        });

        dispatch({
            type: 'FORM-IS-EDIT',
            isEditForm: {
                flag: true,
                id: data.id,
            },
        })
        dispatch({
            type: 'FORM-DATA',
            formDData: obj,
        });
    }

    let onDeleteClick = id => {
        confirm({
            title: 'Вы действительно хотите удалить этот элемент?',
            icon: <ExclamationCircleOutlined />,
            content: 'При удалении этот элемент удалится навсегда',
            okText:'Удалить',
            cancelText:'Отменить',
            onOk(){
                deleteDateFromUrl(remoteUrl, id)
            },
            onCancel(){
                console.log('Cancel');
            },
        });
    }

    let onNewClick = () => {
        dispatch({
            type: 'TOGGLE-FORM',
            isFormShow: false,
        });
        dispatch({
            type: 'FORM-IS-EDIT',
            isEditForm: {
                flag: false,
                id: 0,
            },
        });
    }

    useEffect( () => {
        dispatch(getAdditionalData());
        dispatch(getTableData());
        dispatch(getTableColumn());
        // TODO Когда переходим с другой страницы, надо сделать, чтобы форма автоматом была в состоянии "закрыто"
    }, []);

    return <Content style={{margin: 20}}>
        <Card style={{marginBottom:'20px'}}>
            <Row gutter={24}>
                <Col span={18}>
                    <Breadcrumbs parent={parent} title={title} style={{margin:0}}/>
                </Col>
                <Col span={6} style={{display: 'flex', justifyContent: 'flex-end', alignItems:'center'}}>
                    <Row>
                        <Button type={'primary'} onClick={() => onNewClick()}>
                            Новая запись
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Card>
        <FormComponent columtSchema={columnSchema} inputRef={inputRef} additionalData={$stor.additionalData}
            handler={handler} formData={$stor.formData} url={'customer/'}/>
        <Card>
            <Table locale={{emptyText: (<span>Нет данных</span>)}} columns={$stor.tableColumn}
                dataSource={$stor.tableSource.items} scroll={{x: null, y: null}}/>
        </Card>
    </Content>
}
