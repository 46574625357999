import {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useMemo,
} from 'react';
import moment from 'moment';

interface ITimeContext {
    utcInc: number;
    serverDiff: 3;
    utcWithDiff: number;
}

const TimeContext = createContext<ITimeContext>({
    utcInc: 0,
    serverDiff: 3,
    utcWithDiff: 0,
});

export const useTimeContext = () => useContext<ITimeContext>(TimeContext);

export const TimeService: FC<PropsWithChildren> = ({ children }) => {
    const currentUTC = useMemo(() => {
        return moment().utcOffset() / 60;
    }, []);

    return (
        <TimeContext.Provider
            value={{
                utcInc: currentUTC,
                serverDiff: 3,
                utcWithDiff: currentUTC - 3,
            }}
        >
            {children}
        </TimeContext.Provider>
    );
};
