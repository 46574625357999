import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
function Breadcrumbs(props){
    if(props.parent !== null){
        return (
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>
                    <Link to="/">Главная</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.title}</Breadcrumb.Item>
            </Breadcrumb>
        )
    } else if(props?.parents?.length){
        return (
            <Breadcrumb style={{ margin: '16px 0' }}>
                {props.parents.map((e) => {
                    if(e.link){
                        return (
                            <Breadcrumb.Item key={e.link}>
                                <Link to={e.link}>{e.title}</Link>
                            </Breadcrumb.Item>
                        )
                    }
                    return <Breadcrumb.Item>{e.title}</Breadcrumb.Item>
                })}
            </Breadcrumb>
        )
    } else {
        return (
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>
                    <Link to="/">Главная</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{props.title}</Breadcrumb.Item>
            </Breadcrumb>
        )
    }
}
export default Breadcrumbs
