import { Card } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';

export default function ScheduleComponent(props) {
    useEffect(() => {
        drawSchedule(props.schedule);
    }, [props]);

    const dispatch = useDispatch();

    function setSchedule(x, y, numOfCells, json) {
        // const target = document.getElementById(x.toString()+y.toString());
        const target = document.getElementById(x.toString() + y.toString());
        console.log(target);
        target.style.height = 72 + 'px';

        const Elem = React.createElement(
            'div',
            {
                style: {
                    height: (70 + 2) * numOfCells + 'px',
                    width: 148 + 'px',
                    background: '#ddd',
                    flex: 1,
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 999,
                },
                onClick: (event) => {
                    console.log(json);
                    dispatch({
                        type: 'SCHEDULE-DETAIL',
                        scheduleDetail: json,
                    });
                    dispatch({
                        type: 'SCHEDULE-VIEW-DETAIL',
                        scheduleDetailView: true,
                    });
                },
                className: 'schedule-item',
            },
            React.createElement(
                'div',
                {
                    style: {
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                },
                'Подробнее'
            )
            //<Card style="display: flex; justify-content: center; align-items: center; height: 100%" onClick="123"><a data-json="${json}">Подробнее</Button></Card>
        );

        if (target) {
            ReactDOM.render(Elem, target);
        }
    }

    function clearScheduleItem() {
        var elem = document.querySelector('.schedule-item');
        if (elem) {
            elem.remove();
        }
    }

    function drawSchedule(items) {
        // clearScheduleItem();

        if (items.length > 0) {
            const currData = new Date();
            const time15Min = 15;

            props.time.forEach((_time, y) => {
                items.forEach((schedule, x) => {
                    currData.setHours(_time.client_time.split(':')[0]);
                    currData.setMinutes(_time.client_time.split(':')[1]);
                    const timeStart = moment(currData).format('HH:mm');

                    currData.setHours(_time.client_time_end.split(':')[0]);
                    currData.setMinutes(_time.client_time_end.split(':')[1]);
                    const timeEnd = moment(currData).format('HH:mm');

                    const scheduleTimeStart = moment(
                        schedule.scheduleDatetime
                    ).format('HH:mm');

                    if (
                        scheduleTimeStart >= timeStart &&
                        scheduleTimeStart < timeEnd
                    ) {
                        let sum = 0;
                        if (schedule.service.length > 0) {
                            schedule.service.forEach((item) => {
                                sum += item.timing;
                            });
                        }

                        const numOfCells = sum / time15Min;

                        setSchedule(
                            schedule.masterId,
                            y,
                            numOfCells == 0 ? 1 : numOfCells,
                            schedule
                        );
                    }
                });
            });
        }
    }

    if (props.schedule.length > 0) {
        return (
            <Card style={{ width: '100%' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: 100 }}>
                        <Card>Время</Card>
                        {props.time.map((item) => {
                            let date = new Date();
                            date.setHours(item.client_time.split(':')[0]);
                            date.setMinutes(item.client_time.split(':')[1]);
                            return (
                                <Card
                                    style={{ alignItems: 'center' }}
                                    className="timeContent"
                                >
                                    {moment(date).format('HH:mm')}
                                </Card>
                            );
                        })}
                    </div>
                    {props.masters
                        ? props.masters.map((master, x) => {
                              return (
                                  <div style={{ width: 150 }} key={x}>
                                      <Card style={{ textAlign: 'center' }}>
                                          {master.name}
                                      </Card>
                                      {props.time.map((item, y) => {
                                          return (
                                              <Card
                                                  style={{
                                                      alignItems: 'center',
                                                  }}
                                                  id={master.id + y.toString()}
                                                  key={x + y}
                                              >
                                                  &nbsp;
                                              </Card>
                                          );
                                      })}
                                  </div>
                              );
                          })
                        : null}
                </div>
            </Card>
        );
    } else {
        return <p>Нет записей</p>;
    }
}
