import { FC, useCallback, useMemo } from 'react';
import { IEmployer, ISalonClient } from '../../../../Types/SalonTypes.ts';
import moment from 'moment';
import { Button, message, Popconfirm } from 'antd';
import { mediaFileHost } from '../../../../api/index.ts';
import { PersonalSale } from './PersonalSale.tsx';

import styles from './MasterCard.module.scss';

type Props =
    | {
          X: IEmployer;
          handleEditEmp: (data: IEmployer) => void;
          mode: 'master';
          handleDelete: (id: string) => Promise<void>;
          salonId: string | number;
      }
    | {
          X: ISalonClient;
          mode: 'client';
          handleDelete: (id: string) => Promise<void>;
          handleEditEmp: undefined;
          salonId: string | number;
      };

export const MasterCard: FC<Props> = ({
    X,
    handleEditEmp,
    mode,
    handleDelete,
    salonId,
}) => {
    const onEdit = useCallback(() => {
        if (mode === 'master') {
            handleEditEmp(X);
        }
    }, [X, handleEditEmp]);

    const onDelete = useCallback(async () => {
        if (mode === 'master') {
            try {
                await handleDelete(X.id);
                message.success('Сотрудник удален');
            } catch (e) {
                message.error('Ошибка при удалении. Попробуйте позже');
            }
        } else {
            try {
                await handleDelete(X.id);
                message.success('Клиент удален');
            } catch (e) {
                message.error('Ошибка при удалении. Попробуйте позже');
            }
        }
    }, []);

    const categories:
        | {
              id: number;
              key: string;
              title: string;
          }[]
        | undefined = useMemo(() => {
        if (mode === 'master') {
            return Object.values(
                X.services.reduce((res, acc) => {
                    if (!res[acc.service.parent.id]) {
                        res = {
                            ...res,
                            [acc.service.parent.id]: {
                                ...acc.service.parent,
                            },
                        };
                    }

                    return res;
                }, {})
            );
        } else return undefined;
    }, []);

    const aboutList: {
        item: string;
        value: string | JSX.Element;
        key: string;
    }[] = useMemo(() => {
        if (mode === 'master') {
            return [
                {
                    item: 'ФИО',
                    value: `${X.master.firstName} ${X.master.lastName}`,
                    key: 'name',
                },
                {
                    item: 'Дата рождения (возраст)',
                    value: X.master.birthDate
                        ? `${moment(X.master.birthDate).format(
                              'DD MMMM YYYY'
                          )} (${moment().diff(
                              moment(X.master.birthDate),
                              'years'
                          )})`
                        : 'Не указана',
                    key: 'birthdate',
                },
                {
                    item: 'Категории',
                    value: categories.map((el) => el.title).join(' / '),
                    key: 'categories',
                },
                {
                    item: 'Дата приема на работу',
                    value:
                        X.property && X.property.employedDate
                            ? moment(X.property.employedDate).format(
                                  'DD.MM.YYYY'
                              )
                            : 'Не указана',
                    key: 'employedDate',
                },
                {
                    item: 'Личный телефон',
                    value: X.master.phone,
                    key: 'phone',
                },
                {
                    item: 'Коэффициент сотрудника',
                    value: X.property?.priceAspectRatio.toString(),
                    key: 'priceAspectRatio',
                },
            ];
        } else {
            return [
                {
                    item: 'ФИО',
                    value: `${X.firstName} ${X.lastName}`,
                    key: 'name',
                },
                {
                    item: 'Дата рождения (возраст)',
                    value: X.birthDate
                        ? `${moment(X.birthDate).format(
                              'DD MMMM YYYY'
                          )} (${moment().diff(moment(X.birthDate), 'years')})`
                        : 'Не указана',
                    key: 'birthdate',
                },
                {
                    item: 'Телефон',
                    value: X.phone,
                    key: 'phone',
                },
                {
                    item: 'Программа лояльности 1',
                    value: (
                        <div className={styles.loyality}>
                            <div className={styles.loyalityItems}>
                                <div
                                    className={`${styles.loyalityItem} ${styles.selected}`}
                                />
                                <div
                                    className={`${styles.loyalityItem} ${styles.selected}`}
                                />
                                <div
                                    className={`${styles.loyalityItem} ${styles.selected}`}
                                />
                                <div className={`${styles.loyalityItem}`} />
                            </div>
                            <span className={styles.loyalityText}>Подарок</span>
                        </div>
                    ),
                    key: 'phone',
                },
                {
                    item: 'Первое посещение',
                    value: X.serviceFirstSchedule
                        ? moment(X.serviceFirstSchedule).format('DD.MM.YYYY')
                        : 'Не посещал',
                    key: 'firstVisit',
                },
                {
                    item: 'Последнее посещение',
                    value: X.serviceLastSchedule
                        ? moment(X.serviceLastSchedule).format('DD.MM.YYYY')
                        : 'Не посещал',
                    key: 'lastVisit',
                },
                {
                    item: 'Всего посещений',
                    value: X.serviceCount,
                    key: 'visitsCount',
                },
                {
                    item: 'Программа лояльности 2',
                    value: (
                        <div className={styles.loyality}>
                            <div className={styles.loyalityItems}>
                                <div
                                    className={`${styles.loyalityItem} ${styles.selected}`}
                                />
                                <div
                                    className={`${styles.loyalityItem} ${styles.selected}`}
                                />
                                <div
                                    className={`${styles.loyalityItem} ${styles.selected}`}
                                />
                                <div className={`${styles.loyalityItem}`} />
                            </div>
                            <span className={styles.loyalityText}>Подарок</span>
                        </div>
                    ),
                    key: 'phone',
                },
                {
                    item: 'Персональная скидка',
                    value: (
                        <PersonalSale
                            sale={Number(X.discount)}
                            clientId={X.id}
                            salonId={salonId}
                        />
                    ),
                    key: 'popularService',
                },
                {
                    item: 'Последняя услуга',
                    value: X.lastService
                        ? X.lastService.service.title
                        : 'Не посещал',
                    key: 'lastService',
                },
                {
                    item: 'Рейтинг',
                    value: Math.round(X.rating * 10) / 10,
                    key: 'rating',
                },
            ];
        }
    }, []);

    return (
        <div className={styles.wrapper}>
            <div
                className={`${styles.logo} ${
                    mode === 'master' ? ` ${styles.contentMaster}` : ''
                }`}
            >
                {mode === 'master' ? (
                    <img
                        src={
                            X.master?.avatar?.fileName
                                ? `${mediaFileHost}${X.master?.avatar?.fileName}`
                                : undefined
                        }
                        alt={`Аватар ${X.master.firstName} ${X.master.lastName}`}
                        title={`Аватар ${X.master.firstName} ${X.master.lastName}`}
                    />
                ) : (
                    <img
                        src={
                            X.avatar?.fileName
                                ? `${mediaFileHost}${X.avatar?.fileName}`
                                : undefined
                        }
                        alt={`Аватар ${X.firstName} ${X.lastName}`}
                        title={`Аватар ${X.firstName} ${X.lastName}`}
                    />
                )}
            </div>
            <div
                className={`${styles.content}${
                    mode === 'master' ? ` ${styles.contentMaster}` : ''
                }`}
            >
                {aboutList && (
                    <div className={styles.aboutList}>
                        {aboutList.map((el) => (
                            <div className={styles.aboutItem} key={el.key}>
                                <span className={styles.aboutText}>
                                    {el.item}
                                </span>{' '}
                                <span className={styles.aboutValue}>
                                    {el.value}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
                {mode === 'master' && (
                    <>
                        <div className={styles.descWrapper}>
                            <span className={styles.descTitle}>Услуги</span>
                            <span className={styles.descValue}>
                                {X.services
                                    .map((el) => el.service.title)
                                    .join(' / ')}
                            </span>
                        </div>
                        {X.property.descriptions && (
                            <div
                                className={`${styles.descWrapper} ${styles.descShort}`}
                            >
                                <span className={styles.descTitle}>
                                    О сотруднике
                                </span>
                                <span className={styles.descValue}>
                                    {X.property.descriptions}
                                </span>
                            </div>
                        )}
                    </>
                )}
                <div className={styles.btnWrapper}>
                    {handleEditEmp && (
                        <Button
                            type={'primary'}
                            onClick={onEdit}
                            className={`${styles.btn} ${styles.edit}`}
                        >
                            Редактировать
                        </Button>
                    )}
                    <Popconfirm
                        title="Вы уверены что хотите удалить мастера?"
                        onConfirm={onDelete}
                        okText="Да, удалить"
                        cancelText="Отмена"
                        placement={'right'}
                        okButtonProps={{
                            className: styles.btnConfirm,
                        }}
                    >
                        <Button
                            type={'primary'}
                            className={`${styles.btn} ${styles.delete}`}
                        >
                            Удалить
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        </div>
    );
};
