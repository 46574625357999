import styles from '../../../Pages/ResultNew/ResulNew.module.scss';
import { FC, useMemo } from 'react';
import { Button, Rate } from 'antd';
import { ISalon } from '../../../Types/SalonTypes.ts';
import { mediaFileHost } from '../../../api/index.ts';
import { MediaPoint } from '../../../Services/Media.tsx';
import { ISearchQueryFilters } from '../../../Pages/ResultNew/index.tsx';

interface Props {
    salon: ISalon;
    handleShedule: () => void;
    media: MediaPoint;
    queryFilters: ISearchQueryFilters;
}

export const ResultCard: FC<Props> = ({
    salon,
    handleShedule,
    media,
    queryFilters,
}) => {
    const selectedServices = useMemo(() => {
        const result: ISalon['services'] = [];

        if (queryFilters && queryFilters.serviceIds) {
            (queryFilters.serviceIds as number[]).map((el) => {
                const findService = salon.services.find(
                    (item) => item.service.id === el
                );

                if (findService) {
                    result.push(findService);
                }
            });
        }

        return result;
    }, []);

    if (media === MediaPoint.MOBILE) {
        return (
            <div className={styles.resultCardMobile}>
                <a
                    href={`/salon/${salon.id}`}
                    target={'_blank'}
                    className={styles.cardName}
                >
                    Салон красоты "{salon.title}"
                </a>
                <div className={styles.cardContent}>
                    <div className={styles.cardLeft}>
                        <div className={styles.img}>
                            {salon.photo && salon.photo[0] ? (
                                <img
                                    src={`${mediaFileHost}/${salon.photo[0].fileName}`}
                                    alt=""
                                />
                            ) : null}
                        </div>
                        <div className={styles.rateWrapper}>
                            <Rate
                                value={salon.rating}
                                className={styles.rate}
                            />
                            <span className={styles.rateText}>
                                {salon.rating.toLocaleString()}
                            </span>
                        </div>
                        <div className={styles.cardSBtn}>Оценки (12)</div>
                        <div
                            className={styles.cardSBtn}
                            style={{
                                textDecoration: 'underline',
                            }}
                        >
                            Отзывы (42)
                        </div>
                    </div>
                    <div className={styles.cardRight}>
                        <div className={styles.cardFeatList}>
                            <div className={styles.cardFeat}>
                                <span className={styles.name}>Описание:</span>
                                <span className={styles.value}>
                                    {salon.description}
                                </span>
                            </div>
                            {selectedServices &&
                                selectedServices.length > 0 && (
                                    <div className={styles.cardFeat}>
                                        <span className={styles.name}>
                                            Процедура:
                                        </span>
                                        <span className={styles.value}>
                                            {selectedServices
                                                .map((el) => el.service.title)
                                                .join(' + ')}
                                        </span>
                                    </div>
                                )}
                            <div className={styles.cardFeat}>
                                <span className={styles.name}>Адрес:</span>
                                <span className={styles.valueListed}>
                                    <div className={styles.cardLocationItem}>
                                        <div className={styles.ico}>I</div>
                                        <span>
                                            г. {salon.city}, ул. {salon.address}
                                        </span>
                                    </div>
                                    <div className={styles.cardLocationItem}>
                                        <div className={styles.ico}>M</div>
                                        <span>м. {salon.metro}</span>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className={styles.cardActions}>
                            <span className={styles.price}>
                                {selectedServices.reduce((res, acc) => {
                                    return res + acc.priceValue;
                                }, 0)}{' '}
                                <span>руб.</span>
                            </span>
                            <Button
                                type={'primary'}
                                className={styles.btn}
                                onClick={handleShedule}
                                size={'small'}
                            >
                                Записаться
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.resultCard}>
            <div className={styles.cardImg}>
                {salon.photo && salon.photo[0] ? (
                    <img
                        src={`${mediaFileHost}/${salon.photo[0].fileName}`}
                        alt=""
                    />
                ) : null}
            </div>
            <div className={styles.cardContent}>
                <div className={styles.cardInfo}>
                    <span className={styles.cardDesc}>Салон красоты</span>
                    <a
                        href={`/salon/${salon.id}`}
                        className={styles.cardSalonName}
                        target={'_blank'}
                    >
                        {salon.title}
                    </a>
                    <span className={styles.cardDescFull}>
                        {salon.description}
                    </span>
                    <div className={styles.cardLocations}>
                        <div className={styles.cardLocationItem}>
                            <div className={styles.ico}>I</div>
                            <span>
                                г. {salon.city}, ул. {salon.address}
                            </span>
                        </div>
                        <div className={styles.cardLocationItem}>
                            <div className={styles.ico}>M</div>
                            <span>м. {salon.metro}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.cardStats}>
                    <div className={styles.cardRating}>
                        <span className={styles['bt-2g-rate']}>
                            <div className={styles['bt-2g-rateMain']}>
                                <svg
                                    viewBox="0 0 32 30"
                                    className={styles['bt-2g-star']}
                                >
                                    <use xlinkHref="#star"></use>
                                </svg>
                                <svg
                                    viewBox="0 0 32 30"
                                    className={styles['bt-2g-star']}
                                >
                                    <use xlinkHref="#star"></use>
                                </svg>
                                <svg
                                    viewBox="0 0 32 30"
                                    className={styles['bt-2g-star']}
                                >
                                    <use xlinkHref="#star"></use>
                                </svg>
                                <svg
                                    viewBox="0 0 32 30"
                                    className={styles['bt-2g-star']}
                                >
                                    <use xlinkHref="#star"></use>
                                </svg>
                                <svg
                                    viewBox="0 0 32 30"
                                    className={styles['bt-2g-star']}
                                >
                                    <use xlinkHref="#star"></use>
                                </svg>
                            </div>
                            <div
                                className={styles['bt-2g-rateSecondary']}
                                style={{
                                    width: `${20 * salon.rating}%`,
                                }}
                            >
                                <svg
                                    viewBox="0 0 32 30"
                                    className={`${styles['bt-2g-star']} ${styles['bt-2g-star-shrink']}`}
                                >
                                    <use xlinkHref="#star"></use>
                                </svg>
                                <svg
                                    viewBox="0 0 32 30"
                                    className={`${styles['bt-2g-star']} ${styles['bt-2g-star-shrink']}`}
                                >
                                    <use xlinkHref="#star"></use>
                                </svg>
                                <svg
                                    viewBox="0 0 32 30"
                                    className={`${styles['bt-2g-star']} ${styles['bt-2g-star-shrink']}`}
                                >
                                    <use xlinkHref="#star"></use>
                                </svg>
                                <svg
                                    viewBox="0 0 32 30"
                                    className={`${styles['bt-2g-star']} ${styles['bt-2g-star-shrink']}`}
                                >
                                    <use xlinkHref="#star"></use>
                                </svg>
                                <svg
                                    viewBox="0 0 32 30"
                                    className={`${styles['bt-2g-star']} ${styles['bt-2g-star-shrink']}`}
                                >
                                    <use xlinkHref="#star"></use>
                                </svg>
                            </div>
                        </span>
                        <span className={styles.cardRateText}>
                            {salon.rating.toLocaleString()}
                        </span>
                    </div>
                    <div className={styles.cardRateButtons}>
                        <span>Оценки (96)</span>
                        <span className={styles.clickable}>Отзывы (17)</span>
                    </div>
                    <div className={styles.price}>
                        {selectedServices.reduce((res, acc) => {
                            return res + acc.priceValue;
                        }, 0)}{' '}
                        <span>руб.</span>
                    </div>
                    <div className={styles.btnWrapper}>
                        <Button
                            type={'primary'}
                            className={styles.btn}
                            onClick={handleShedule}
                        >
                            Записаться
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
