import { useCallback, useEffect, useState } from 'react';

type Props<T extends Record<string, object>, K = keyof T> = {
    fields: Record<string, any>;
    rules: {
        [key in keyof T]: {
            required: boolean;
            message: string;
        };
    };
};

export function useFormErrorsHandling<
    T extends Record<string, any>,
    K = keyof T
>(props: Props<T>) {
    const [fieldsData, setFieldsData] = useState<
        Record<
            string,
            any & {
                touched?: boolean;
                value: string;
            }
        >
    >(props.fields);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        let newFieldsData = {
            ...fieldsData,
        };

        let haveChanges = false;

        Object.keys(props.fields).forEach((key) => {
            if (newFieldsData[key].value !== props.fields[key].value) {
                newFieldsData[key] = {
                    ...props.fields[key],
                    touched: true,
                };

                haveChanges = true;
            } else {
                newFieldsData[key] = {
                    ...newFieldsData[key],
                    touched: false,
                };
            }
        });

        if (haveChanges) {
            console.log(newFieldsData);
            setFieldsData(newFieldsData);
        }
    }, [props.fields, fieldsData]);

    useEffect(() => {
        setErrors((prev) => {
            let newErrors = { ...prev };
            Object.keys(fieldsData).forEach((key) => {
                if (fieldsData[key].touched) {
                    if (props.rules[key]) {
                        if (
                            props.rules[key].required &&
                            props.rules[key].message &&
                            !fieldsData[key].value
                        ) {
                            newErrors = {
                                ...newErrors,
                                [key]: props.rules[key].message,
                            };
                        } else if (
                            props.rules[key].required &&
                            props.rules[key].message &&
                            fieldsData[key].value
                        ) {
                            delete newErrors[key];
                        }
                    }
                }
            });

            return newErrors;
        });
    }, [fieldsData, props.rules]);

    return {
        errors,
    };
}
