import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { CommonAPI } from '../api/common/common.ts';

export interface ILocation {
    isLoading: boolean;
    isLoaded: boolean;
    isErrorOnLoad: boolean;
    data?: {
        createAt: string;
        id: string;
        latitude: number;
        longitude: number;
        name: string;
        ruName: string;
        updateAt: string;
    };
}

export const Location = createContext<ILocation>({
    isLoading: false,
    isErrorOnLoad: false,
    isLoaded: false,
    data: undefined,
});

export const useLocationContext = () => useContext<ILocation>(Location);

export const LocationProvider: FC<PropsWithChildren> = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isErrorOnLoad, setIsErrorOnLoad] = useState(false);

    const [data, setData] = useState<ILocation['data']>(undefined);

    const getCurrentLocation = useCallback(async () => {
        try {
            setLoading(true);

            const response = await CommonAPI.getCurrentLocation();

            setData(() => response.data);
            setIsLoaded(() => true);
            setLoading(() => false);
            setIsErrorOnLoad(() => false);
        } catch (e) {
            setIsErrorOnLoad(() => true);
            setIsLoaded(() => false);
        }
    }, []);

    useEffect(() => {
        if (!isLoaded && !isErrorOnLoad) {
            void getCurrentLocation();
        }
    }, []);

    return (
        <Location.Provider
            value={{
                isLoading: loading,
                isErrorOnLoad: isErrorOnLoad,
                isLoaded: isLoaded,
                data: data,
            }}
        >
            {children}
        </Location.Provider>
    );
};
