import React, {useEffect, useState, useRef} from "react";
import {Checkbox, Collapse} from "antd";
import {useDispatch, useSelector} from "react-redux";
import Text from "antd/es/typography/Text";

const { Panel } = Collapse;

export default function ServiceListComponent (props){

  //const content = useSelector(state => state);
  const dispatch = useDispatch();

  let inputRef = useRef([]);

  let [service, setService] = useState([])

  useEffect(() => {
    props.data.forEach(item => {
      //console.log(item)
      item.children.forEach(children => {
        props.selected.forEach(selected => {
          //console.log(selected)
          if(selected.id === children.id){
            console.log(selected)
            //Object.assign(children, {isChecked: true})
            children.isChecked = true;
          }
        })
        // if(props.selected.indexOf(children.id) ){
        //   console.log(props.selected.indexOf(children.id))
        //   //Object.assign(children, {isChecked: true})
        // }
        //console.log(children)
      })
    })
    console.log(props.data);
    setService(props.data)
  })

  return (
    props.data.map((item, key) => {
      return <Collapse>
        <Panel header={<Text>{item.name}</Text>} key={key}>
          <Checkbox index={item.id} checked={item.isChecked}><Text style={{fontWeight:'bold'}}>Выбрать все</Text></Checkbox>
          <ul style={{flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
            {item.children.map((children, k) => {
              return (
                <Checkbox
                  rootIndex={item.id}
                  childrenIndex={children.id}
                  key={k}
                  onChange={ev => {
                    props.data.filter(item => item.id === ev.target.rootIndex)[0].children.filter(item => item.id === ev.target.childrenIndex).forEach(item => {
                      if(item.isChecked){
                        item.isChecked = false
                      }else {
                        item.isChecked = true;
                      }
                    })
                    setService({...service, service})
                  }}
                  ref={el => inputRef.current['service'] = el}
                defaultChecked={children.isChecked}>
                  <li>{children.name}</li>
                </Checkbox>
              )
            })}
          </ul>
        </Panel>
      </Collapse>;
    })


  )

}
