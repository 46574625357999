import {
    Radio,
    Checkbox,
    Layout,
    Modal,
    Select,
    DatePicker,
    TimePicker,
    Image,
    Button,
    message,
} from 'antd';
import locale from 'antd/es/locale/ru_RU';
import moment from 'moment';
import 'moment/locale/ru';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './index.scss';
import carouArrowRight from '../../img/carouArrowRight.png';
import { useDispatch, useSelector } from 'react-redux';
import { PageHead, SideMen2, BanrHead, PageFoot } from '../part/PageParts';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/dist/css/splide.min.css';
import '@splidejs/react-splide/dist/css/splide-core.min.css';
import axios from 'axios';
import { HomeSalonsMap } from '../../Components/Home/HomeSalonsMap.tsx';
import { CommonAPI } from '../../api/common/common.ts';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { isProduction } from '../../utils/common.ts';
import { MediaPoint, useMediaContext } from '../../Services/Media.tsx';
import { ResultParamsNames } from '../ResultNew/index.tsx';

function Home({ match }) {
    const $stor = useSelector((state) => state),
        dispatch = useDispatch(),
        [catOnce, setCatOnce] = useState(0); // получение категорий из Ajax
    const history = useHistory();
    const { search } = useLocation();

    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    const {
        url,
        params: { url2 },
    } = match;
    const cities = [
            {
                value: 2,
                label: (
                    <div>
                        Москва
                        <br />
                        &nbsp; <i>{$stor?.countSite?.countSalons} салонов</i>
                    </div>
                ),
            },
        ],
        servNo = '\\(не выбрано\\)',
        srchNo = '\\(запрос поиска\\)';
    let groupServices = [
        //статика: группы услуг (табы и выбор доп. услуг)
        {
            value: 1,
            label: 'Ногтевой сервис',
            disabled: true,
            cats: ['Маникюр', 'Педикюр'],
        },
        {
            value: 2,
            label: 'Парикмахерский зал',
            cats: ['Стрижка', 'Окрашивание', 'Укладка', 'Уходы'],
        },
        {
            value: 3,
            label: 'Удаление волос',
            cats: ['Шугаринг', 'Воск', 'Лазер'],
        },
        { value: 4, label: 'Брови и ресницы', cats: ['Ресницы и брови'] },
        { value: 5, label: 'Макияж', cats: ['Макияж', 'Перманентный макияж'] },
        {
            value: 11,
            label: <u>Начать поиск</u>,
            className: 'operational',
            disabled: true,
        },
    ];
    const [activeCatTab, setActiveCatTab] = useState(1);
    const tabsChange = (ev) => {
        setActiveCatTab(ev.target.value);
        setTabs(
            tabs.map((el) => ({
                ...el,
                ...{ disabled: el.value === +ev.target.value },
            }))
        );
    };
    const extraChange = (val) => {
        setServExtraNext(true); // команда выполнена (признак)
        setTabs(
            tabs.map((el) => ({
                ...el,
                ...{ disabled: el.value === val || el.disabled },
            }))
        );
        const pops1 = tabs.filter((el) => el.disabled);
        setServ(
            (servLines.splice(
                1,
                1,
                pops1[0].value !== val ? pops1[0] : pops1[1]
            ),
            servLines)
        ); //фиксируют категорию услуг 1-й группы выбора
        setServ(
            (servLines.splice(
                2,
                1,
                tabs.find((el) => el.value === val)
            ),
            servLines)
        ); // -''- 2-й группы выбора
    };
    const extraTwoChange = (val) => {
        setServExtraTwoNext(true); // команда выполнена (признак)
        setServ(
            (servLines.splice(
                3,
                1,
                tabs.find((el) => el.value === val)
            ),
            servLines)
        ); // -''- 3-й группы выбора
    };
    const chgTimeExact = () => {
        setCheckExact(!checkExact);
    };
    const chgService = (ev) => {
        // выбрать/снять чекбокс-услугу: 1) записать в список категории; 2) в общий; 3) в парам-ы
        const t = ev.target; // не нативный ev
        const el = document.querySelector(
            `.servNoHeadNoFoot input[name="${t.name}"]`
        )?.parentNode;
        if (servCount >= servCountMax) {
            setTimeout(() => {
                t.checked = false;
                ev.nativeEvent.srcElement.checked = false;
                if (el.classList) el.classList.remove('ant-checkbox-checked');
            }, 0);
        }
        if (t.checked && servCount >= servCountMax)
            // не добавлять услуг больше servCountMax (==3)
            return;
        if (t.checked) setServCount(Math.min(servCount + 1, servCountMax));
        else setServCount(Math.max(0, servCount - 1));
        const servCat = document.querySelector(
                `.srchForm >[data-cat="${servOpen}"] .srchServiceRequest >div`
            ),
            srchT = document.querySelector(
                '.srchForm >.srchYouFind >.srchFullResult'
            );
        const s = (servCat?.innerHTML || '').replace(new RegExp(servNo), ''),
            sr = (srchT?.innerHTML || '').replace(new RegExp(srchNo), '');
        //debugger;
        if (servCat)
            servCat.innerHTML = t.checked
                ? s + (s ? ' + ' : '') + t.name
                : s.replace(
                      new RegExp(
                          '( \\+ )?' + t.name.replace(/([()/+])/g, '\\$1')
                      ),
                      ''
                  ) || servNo.replace(/\\/g, '');
        if (srchT)
            srchT.innerHTML = t.checked
                ? sr + (sr ? ' + ' : '') + t.name
                : sr.replace(
                      new RegExp(
                          '( \\+ )?' + t.name.replace(/([()/+])/g, '\\$1')
                      ),
                      ''
                  ) || srchNo.replace(/\\/g, '');
        // servCat.parentNode.parentNode.classList.add('yesSrch');
        //const cc = checkStack.concat([]);
        //if(cc.indexOf(t.name) >=0)
        //    cc.splice(cc.indexOf(t.name), 1); // на случай удаления
        //setCheckStack(t.checked ? (checkStack.push(t.name), checkStack) : cc);
    };
    const handleCancel = () => {
        setServOpen(0);
    };
    const clicLine = (ev) => {
        const t = ev.currentTarget.parentNode;
        const servLine = t.classList.contains('srchServiceExtra')
            ? 1 // на какой линии услуг кликнуто
            : t.classList.contains('srchServiceExtraTwo')
            ? 2
            : 3;
        //debugger;
        setServOpen(
            servLines[servLine].value || tabs.find((el) => el.disabled).value
        ); // открыть нужный попап с услугами
    };
    const [countSite, setCountSite] = useState({
        countSalons: '...',
        countMasters: '...',
        countOrders: '...',
        countOnline: '...',
    });
    useEffect(() => {
        const remoteUrl = `${process.env.REACT_APP_REMOTE_HOST}category/`;
        const S1 = new Splide('.splide', {
            perPage: 4,
        });
        S1.go && S1.go(1); // TODO не выставляется на нужный индекс!
        if (!catOnce) {
            setCatOnce(1);
            axios.get(remoteUrl).then((res) => {
                dispatch({
                    type: 'SOURCE-TABLE',
                    tableSource: res.data,
                });
                axios
                    .get(
                        `${process.env.REACT_APP_REMOTE_HOST}advantages/main/general`
                    )
                    .then((res) => {
                        dispatch({
                            type: 'ADVANTAGES-SITE',
                            countSite: res?.data || {},
                        });
                        setCountSite(res?.data || {});
                    });
            });
        }
        //$stor.tableSource && (s => {console.log(s.tableSource); debugger})($stor);
    });
    moment.locale('ru');
    const { media } = useMediaContext();

    const dateNow = moment()
        .locale('ru')
        .format(media === MediaPoint.DESkTOP ? 'LL, dddd' : 'DD.MM.YYYY');
    const [tabs, setTabs] = useState(groupServices); // список добавления услуг с блок-й добавленных
    const [servExtraNext, setServExtraNext] = useState(false);
    const [servExtraTwoNext, setServExtraTwoNext] = useState(false);
    const [servExtraStop, setServExtraStop] = useState(0); // счётчик выбранных услуг (чекбоксов)
    const [checkExact, setCheckExact] = useState(false); // точное время / интервал (ручной выбор)
    const [servLines, setServ] = useState([0, 0, 0, 0]); // ⋺ группы услуг (3 "кнопки-линии") из категорий
    const [servOpen, setServOpen] = useState(0); // видимость нужного попапа (groupServices[].value)
    //const [checkStack, setCheckStack] = useState([]); // стек чекбоксов на случай отмены (!не испо-ся)
    const [servCount, setServCount] = useState(0); // ограничитель числа услуг (общий из всех категорий)
    const servCountMax = 3;

    const [selectedSlide, setSelectedSlide] = useState(undefined);
    const [isSlideOpened, setIsSlideOpened] = useState(false);

    const [promptLoaded, setPromptLoaded] = useState(false);
    const [promptErrorOnLoad, setPromptErrorOnLoad] = useState(false);
    const [promptList, setPromptList] = useState(undefined);

    const handleClickSlide = useCallback((slide) => {
        console.log(slide);
        setSelectedSlide(() => slide);
        setIsSlideOpened(() => true);
    }, []);

    const handleCloseSlide = useCallback(() => {
        setSelectedSlide(() => undefined);
        setIsSlideOpened(() => false);
    }, []);

    const fetchSlides = useCallback(async () => {
        try {
            const response = await CommonAPI.getMainPromptList();

            setPromptList(
                response.data.filter((el) => el.actions && !!el.actions[0])
            );

            setPromptLoaded(() => true);
            setPromptErrorOnLoad(() => false);
        } catch (e) {
            setPromptErrorOnLoad(() => true);
            setPromptLoaded(() => false);
        }
    }, []);

    useEffect(() => {
        if (!promptLoaded && !promptErrorOnLoad) {
            void fetchSlides();
        }
    }, [promptLoaded, promptErrorOnLoad]);

    const [city, setCity] = useState();
    const [visitDate, setVisitDate] = useState();
    const [timeStart, setTimeStart] = useState();
    const [timeEnd, setTimeEnd] = useState();
    const [selServices, setCurServices] = useState([]);

    const setSelServices = useCallback((id) => {
        setCurServices((prev) => {
            let isExist = prev.indexOf(id) > -1;

            if (isExist) {
                return [...prev.filter((el) => el !== id)];
            } else {
                return [...prev, id];
            }
        });
    }, []);

    const handleSearch = useCallback(() => {
        const date = () => {
            if (timeStart && timeEnd) {
                return {
                    hourStart: moment(timeStart).format('HH:mm'),
                    hourEnd: moment(timeEnd).format('HH:mm'),
                };
            } else {
                return {
                    hourStart: moment(timeStart).format('HH:mm'),
                };
            }
        };

        const dataToSend = {
            date: visitDate?.startOf('day').format('YYYY-MM-DD'),
            serviceIds: selServices,
            ...date(),
        };

        if (
            !dataToSend.date ||
            dataToSend.serviceIds.length === 0 ||
            !dataToSend.hourStart
        ) {
            void message.warn('Вы заполнили не все данные для поиска');
            return;
        }

        searchParams.set(
            ResultParamsNames.date,
            visitDate?.format('YYYY-MM-DD')
        );
        searchParams.set(
            ResultParamsNames.serviceIds,
            JSON.stringify(selServices)
        );
        searchParams.set(ResultParamsNames.hourStart, dataToSend.hourStart);

        if (dataToSend.hourEnd) {
            searchParams.set(ResultParamsNames.hourEnd, dataToSend.hourEnd);
        }

        history.push('/result');
        history.replace({ search: searchParams.toString() });
    }, [city, visitDate, timeStart, timeEnd, selServices]);

    const pickerOptions = useMemo(() => {
        let arr = [];

        for (let i = 0; i < 96; i++) {
            const dataItem = moment()
                .set('hours', Math.floor(i / 4))
                .set('minutes', (i % 4) * 15);
            arr.push({
                value: dataItem.toISOString(),
                label: dataItem.format('HH:mm'),
            });
        }

        return arr;
    }, []);

    return (
        <>
            <Layout className="home">
                <PageHead />
                {!isProduction && <SideMen2 />}
                <div className="layCont">
                    <BanrHead hide={process.env.REACT_APP_VISUAL_FOR_DEV} />
                    <div className="stackBlock bfSearch">
                        <div className="stackIn bfCarou-hider">
                            <h2>Что будем искать?</h2>
                            <div className="srchDescr">
                                Все очень просто: 1. Выбираем нужную категорию
                                2. Выбираем день и время оказания услуги 3.
                                Выбираем нужную услугу и жмем НАЧАТЬ ПОИСК!
                            </div>
                            {/* при смене табов - дизейблить выбранный таб в groupServices ! */}
                            <div className="srchForm">
                                {catOnce &&
                                    $stor.tableSource &&
                                    tabs.map((el) =>
                                        el.cats ? (
                                            <Modal
                                                className="servNoHeadNoFoot"
                                                maskClosable={true}
                                                maskStyle={{
                                                    background:
                                                        'rgba(0,0,0,0.7)',
                                                }}
                                                key={el.value}
                                                footer={null}
                                                open={el.value === servOpen}
                                                onCancel={handleCancel}
                                            >
                                                {/*искать по el.cats группы услуг в $stor.tableSource */}
                                                {/*<div className="servTabCats">TODO услуги .cats в группе таба</div>*/}
                                                {el.cats.map((e2) => (
                                                    <div key={e2}>
                                                        <h2 className="servTabCat">
                                                            Категори
                                                            {1
                                                                ? 'я'
                                                                : 'и'}: {e2}
                                                        </h2>
                                                        <div className="servCat">
                                                            {/*TODO список услуг с колонками, с регистрацией выбора<br/>*/}
                                                            {$stor.tableSource.categories
                                                                .find(
                                                                    (e3) =>
                                                                        e3.title ===
                                                                        e2
                                                                )
                                                                ?.children.map(
                                                                    (e4) => (
                                                                        <div
                                                                            className="serv"
                                                                            key={
                                                                                e4.id
                                                                            }
                                                                        >
                                                                            <Checkbox
                                                                                className="servValue"
                                                                                name={
                                                                                    e4.title
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) => {
                                                                                    chgService(
                                                                                        e
                                                                                    );
                                                                                    setSelServices(
                                                                                        e4.id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {
                                                                                    e4.title
                                                                                }
                                                                            </Checkbox>
                                                                        </div>
                                                                    )
                                                                )}
                                                            {/*<div className="serv">
                                        <div className="servPrice">1&thinsp;600<sub>р</sub></div>
                                        <Checkbox className="servValue">bbbb</Checkbox>
                                    </div>*/}
                                                        </div>
                                                    </div>
                                                ))}
                                                <div
                                                    className="srchAction"
                                                    onClick={handleCancel}
                                                >
                                                    <span>OK</span>
                                                </div>
                                            </Modal>
                                        ) : null
                                    )}
                                <div
                                    className={`tabMask${
                                        servExtraNext ? ' down' : ''
                                    }`}
                                />
                                {(media === MediaPoint.DESkTOP ||
                                    media === MediaPoint.TABLET) && (
                                    <Radio.Group
                                        defaultValue={1}
                                        buttonStyle="solid"
                                        className="srchTabs"
                                        onChange={tabsChange}
                                    >
                                        {groupServices.map((el) =>
                                            el.cats ? (
                                                <Radio.Button
                                                    value={el.value}
                                                    key={el.value}
                                                >
                                                    {el.label}
                                                </Radio.Button>
                                            ) : null
                                        )}
                                    </Radio.Group>
                                )}
                                {media === MediaPoint.MOBILE && (
                                    <>
                                        <Radio.Group
                                            defaultValue={1}
                                            buttonStyle="solid"
                                            className="srchTabs"
                                            onChange={tabsChange}
                                            value={activeCatTab}
                                        >
                                            {groupServices
                                                .slice(0, 2)
                                                .map((el) =>
                                                    el.cats ? (
                                                        <Radio.Button
                                                            value={el.value}
                                                            key={el.value}
                                                        >
                                                            {el.label}
                                                        </Radio.Button>
                                                    ) : null
                                                )}
                                        </Radio.Group>
                                        <Radio.Group
                                            defaultValue={1}
                                            buttonStyle="solid"
                                            className="srchTabs"
                                            onChange={tabsChange}
                                            value={activeCatTab}
                                        >
                                            {groupServices
                                                .slice(2, groupServices.length)
                                                .map((el) =>
                                                    el.cats ? (
                                                        <Radio.Button
                                                            value={el.value}
                                                            key={el.value}
                                                        >
                                                            {el.label}
                                                        </Radio.Button>
                                                    ) : null
                                                )}
                                        </Radio.Group>
                                    </>
                                )}
                                <div className="srchSalonDateTime">
                                    <div className="srchSalon">
                                        <div className="srchCityTitle">
                                            Местоположение или салон
                                        </div>
                                        <div className="srchCitySelect">
                                            <Select
                                                options={cities}
                                                popupClassName="srchCityOptions"
                                                defaultValue={2}
                                                onChange={(e) => setCity(e)}
                                                size={
                                                    media ===
                                                        MediaPoint.TABLET ||
                                                    media === MediaPoint.MOBILE
                                                        ? 'small'
                                                        : 'middle'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="srchDate">
                                        <div className="srchDateTitle">
                                            Дата визита &nbsp;{' '}
                                            <i>(сегодня {dateNow})</i>
                                        </div>
                                        <DatePicker
                                            placement="bottomRight"
                                            className="srchDateSelect"
                                            placeholder="ГГГГ-ММ-ДД"
                                            bordered={'false'}
                                            clearText="Очистить"
                                            locale={locale}
                                            onChange={setVisitDate}
                                            size={
                                                media === MediaPoint.TABLET ||
                                                media === MediaPoint.MOBILE
                                                    ? 'small'
                                                    : 'middle'
                                            }
                                        />
                                        {/* clearText="..." не работает - вероятно, требуется clearIcon */}
                                        {/*<div className="srchDateSelect">
                                    <div>(выбор даты)</div>
                                </div>*/}
                                    </div>
                                    <div className="srchTime noSrch">
                                        <div className="srchTimeExact">
                                            <Checkbox
                                                className="timeExact"
                                                onChange={chgTimeExact}
                                                size={
                                                    media === MediaPoint.DESkTOP
                                                        ? 'middle'
                                                        : 'small'
                                                }
                                            >
                                                {' '}
                                                точное время
                                            </Checkbox>
                                        </div>
                                        <div className="srchTimeTitle">
                                            Время начала
                                        </div>
                                        {checkExact ? (
                                            <>
                                                {media === MediaPoint.MOBILE ||
                                                media === MediaPoint.TABLET ? (
                                                    <select
                                                        className="srchTimeSelect"
                                                        value={timeStart}
                                                        placeholder={'00:00'}
                                                        onChange={(value) => {
                                                            setTimeStart(
                                                                value.target
                                                                    .value
                                                            );

                                                            if (
                                                                (timeEnd &&
                                                                    moment(
                                                                        timeEnd
                                                                    ).isBefore(
                                                                        moment(
                                                                            value
                                                                                .target
                                                                                .value
                                                                        )
                                                                    )) ||
                                                                timeEnd ===
                                                                    value.target
                                                                        .value
                                                            ) {
                                                                setTimeEnd(
                                                                    moment(
                                                                        value
                                                                            .target
                                                                            .value
                                                                    )
                                                                        .add(
                                                                            15,
                                                                            'minutes'
                                                                        )
                                                                        .toISOString()
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {pickerOptions.map(
                                                            (el) => (
                                                                <option
                                                                    key={
                                                                        el.value
                                                                    }
                                                                    value={
                                                                        el.value
                                                                    }
                                                                >
                                                                    {el.label}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                ) : (
                                                    <Select
                                                        size={
                                                            media ===
                                                                MediaPoint.TABLET ||
                                                            media ===
                                                                MediaPoint.MOBILE
                                                                ? 'small'
                                                                : 'middle'
                                                        }
                                                        className="srchTimeSelect"
                                                        options={pickerOptions}
                                                        optionFilterProp={
                                                            'label'
                                                        }
                                                        optionLabelProp={
                                                            'label'
                                                        }
                                                        placeholder={'00:00'}
                                                        onChange={(value) => {
                                                            setTimeStart(value);
                                                        }}
                                                        value={timeStart}
                                                        getPopupContainer={(
                                                            triggerNode
                                                        ) =>
                                                            triggerNode.parentElement
                                                        }
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <div className="selects-wrapper">
                                                {media === MediaPoint.MOBILE ||
                                                media === MediaPoint.TABLET ? (
                                                    <>
                                                        <select
                                                            className="srchTimeSelect"
                                                            value={timeStart}
                                                            placeholder={
                                                                '00:00'
                                                            }
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setTimeStart(
                                                                    value.target
                                                                        .value
                                                                );

                                                                if (
                                                                    (timeEnd &&
                                                                        moment(
                                                                            timeEnd
                                                                        ).isBefore(
                                                                            moment(
                                                                                value
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        )) ||
                                                                    timeEnd ===
                                                                        value
                                                                            .target
                                                                            .value
                                                                ) {
                                                                    setTimeEnd(
                                                                        moment(
                                                                            value
                                                                                .target
                                                                                .value
                                                                        )
                                                                            .add(
                                                                                15,
                                                                                'minutes'
                                                                            )
                                                                            .toISOString()
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {pickerOptions.map(
                                                                (el) => (
                                                                    <option
                                                                        key={
                                                                            el.value
                                                                        }
                                                                        value={
                                                                            el.value
                                                                        }
                                                                    >
                                                                        {
                                                                            el.label
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                        <select
                                                            className="srchTimeSelect"
                                                            value={timeEnd}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                console.log(
                                                                    value
                                                                );
                                                                setTimeEnd(
                                                                    value.target
                                                                        .value
                                                                );
                                                            }}
                                                        >
                                                            {pickerOptions
                                                                .filter(
                                                                    (el) => {
                                                                        return moment(
                                                                            el.value
                                                                        ).isAfter(
                                                                            moment(
                                                                                timeStart
                                                                            )
                                                                        );
                                                                    }
                                                                )
                                                                .map((el) => (
                                                                    <option
                                                                        key={
                                                                            el.value
                                                                        }
                                                                        value={
                                                                            el.value
                                                                        }
                                                                    >
                                                                        {
                                                                            el.label
                                                                        }
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Select
                                                            size={
                                                                media ===
                                                                    MediaPoint.TABLET ||
                                                                media ===
                                                                    MediaPoint.MOBILE
                                                                    ? 'small'
                                                                    : 'middle'
                                                            }
                                                            className="srchTimeSelect"
                                                            options={
                                                                pickerOptions
                                                            }
                                                            optionFilterProp={
                                                                'label'
                                                            }
                                                            optionLabelProp={
                                                                'label'
                                                            }
                                                            placeholder={
                                                                '00:00'
                                                            }
                                                            value={timeStart}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setTimeStart(
                                                                    value
                                                                );
                                                            }}
                                                            getPopupContainer={(
                                                                triggerNode
                                                            ) =>
                                                                triggerNode.parentElement
                                                            }
                                                        />
                                                        <Select
                                                            size={
                                                                media ===
                                                                    MediaPoint.TABLET ||
                                                                media ===
                                                                    MediaPoint.MOBILE
                                                                    ? 'small'
                                                                    : 'middle'
                                                            }
                                                            className="srchTimeSelect"
                                                            options={pickerOptions.filter(
                                                                (el) => {
                                                                    return moment(
                                                                        el.value
                                                                    ).isAfter(
                                                                        moment(
                                                                            timeStart
                                                                        )
                                                                    );
                                                                }
                                                            )}
                                                            optionFilterProp={
                                                                'label'
                                                            }
                                                            optionLabelProp={
                                                                'label'
                                                            }
                                                            placeholder={
                                                                '00:00'
                                                            }
                                                            value={timeEnd}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setTimeEnd(
                                                                    value
                                                                );
                                                            }}
                                                            getPopupContainer={(
                                                                triggerNode
                                                            ) =>
                                                                triggerNode.parentElement
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* при .srchServiceExtra.hasNext открывается видимость следующего блока в CSS */}
                                {/* при .stopSelect прекращ-ся выбор чекбок. и показ .srchExtra:not(.srchAction)*/}
                                <div
                                    className={`srchServiceExtra${
                                        servExtraNext ? ' hasNext' : ''
                                    }${servExtraStop > 3 ? ' stopSelect' : ''}`}
                                    data-cat={
                                        servLines[1].value ||
                                        tabs.find((el) => el.disabled).value
                                    }
                                >
                                    <div
                                        className="srchService"
                                        onClick={clicLine}
                                    >
                                        <div className="srchServiceTitle">
                                            Выберите услугу{' '}
                                            <i>
                                                &nbsp;{' '}
                                                {servLines[1]
                                                    ? '(' +
                                                      servLines[1].label +
                                                      ')'
                                                    : null}
                                            </i>
                                        </div>
                                        <div className="srchServiceRequest">
                                            <div>(не выбрано)</div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`srchServiceExtraTwo${
                                        servExtraTwoNext ? ' hasNext' : ''
                                    }`}
                                    data-cat={servLines[2].value}
                                >
                                    <div
                                        className="srchService"
                                        onClick={clicLine}
                                    >
                                        <div className="srchServiceTitle">
                                            Выберите услугу{' '}
                                            <i>
                                                &nbsp;{' '}
                                                {servLines[2]
                                                    ? '(' +
                                                      servLines[2].label +
                                                      ')'
                                                    : null}
                                            </i>
                                        </div>
                                        <div className="srchServiceRequest">
                                            <div>(не выбрано)</div>
                                        </div>
                                    </div>
                                    <Select
                                        className="srchExtra"
                                        defaultValue={10}
                                        options={tabs}
                                        popupClassName="srchExtraOptions srchTabs"
                                        onChange={extraTwoChange}
                                        size={
                                            media === MediaPoint.TABLET ||
                                            media === MediaPoint.MOBILE
                                                ? 'small'
                                                : 'middle'
                                        }
                                    />
                                </div>
                                <div
                                    className="srchServiceExtraThree"
                                    data-cat={servLines[3].value}
                                >
                                    <div
                                        className="srchService"
                                        onClick={clicLine}
                                    >
                                        <div className="srchServiceTitle">
                                            Выберите услугу{' '}
                                            <i>
                                                &nbsp;{' '}
                                                {servLines[3]
                                                    ? '(' +
                                                      servLines[3].label +
                                                      ')'
                                                    : null}
                                            </i>
                                        </div>
                                        <div className="srchServiceRequest">
                                            <div>(не выбрано)</div>
                                        </div>
                                    </div>
                                    <div
                                        className="srchExtra srchAction"
                                        onClick={handleSearch}
                                    >
                                        <span>Начать поиск</span>
                                    </div>
                                </div>

                                <div className="srchYouFind">
                                    <div className="srchServiceTitle">
                                        Вы ищете:
                                    </div>
                                    <div className="srchFullResult">
                                        (запрос поиска)
                                    </div>
                                </div>
                                <div
                                    className="srchAction"
                                    onClick={handleSearch}
                                >
                                    <span>Начать поиск</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {media !== MediaPoint.MOBILE && (
                        <div className="stackBlock advaSite">
                            <div className="advaSiteIn">
                                <dl className="advaSiteEl advaSiteEl1">
                                    <dt className="term">
                                        {countSite?.countSalons}
                                    </dt>
                                    <dd className="descr">
                                        салонов открыли
                                        <br /> для вас свои двери
                                    </dd>
                                </dl>
                                <dl className="advaSiteEl advaSiteEl3">
                                    <dt className="term">
                                        {countSite?.countMasters}
                                    </dt>
                                    <dd className="descr">
                                        мастеров готовы
                                        <br /> творить волшебство
                                    </dd>
                                </dl>
                                <dl className="advaSiteEl advaSiteEl3">
                                    <dt className="term">
                                        {countSite?.countOrders}
                                    </dt>
                                    <dd className="descr">
                                        раз навели
                                        <br /> красоту
                                    </dd>
                                </dl>
                                <dl className="advaSiteEl advaSiteEl4">
                                    <dt className="term">
                                        {countSite?.countOnline}
                                    </dt>
                                    <dd className="descr">
                                        человек сейчас
                                        <br />
                                        &#8199;на сайте
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    )}
                    <div className="stackBlock bfCarou action">
                        <div className="stackIn bfCabinet">
                            <h2>Акции</h2>
                            {promptList && (
                                <Splide
                                    hasTrack={false}
                                    options={{
                                        perPage:
                                            media === MediaPoint.DESkTOP
                                                ? 4
                                                : media === MediaPoint.MOBILE
                                                ? 1
                                                : 2,
                                        start: 0,
                                        gap:
                                            media === MediaPoint.DESkTOP
                                                ? '5.2em'
                                                : '2.9em',
                                        height: 400,
                                        type: 'slide',
                                        rewind: true,
                                        autoplay: true,
                                        drag: 'free',
                                        autoWidth: true,
                                        interval: 12000,
                                        arrows: false,
                                    }}
                                >
                                    <SplideTrack>
                                        {promptList
                                            .sort((a, b) => a.sort - b.sort)
                                            .map((el) => (
                                                <SplideSlide
                                                    onClick={() => {
                                                        handleClickSlide(el);
                                                    }}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    key={el.id}
                                                >
                                                    <Image
                                                        height={360}
                                                        src={
                                                            el.actions[0].thumbs
                                                        }
                                                        alt={
                                                            el.actions[0]
                                                                .description
                                                        }
                                                        preview={false}
                                                        width={310}
                                                    />
                                                    <div>
                                                        {el.actions[0].title}
                                                    </div>
                                                </SplideSlide>
                                            ))}
                                    </SplideTrack>
                                    {promptList?.length > 4 && (
                                        <>
                                            <div className="splide__arrows">
                                                <img
                                                    src={carouArrowRight}
                                                    className="splide__arrow splide__arrow--prev carouArrowLeft"
                                                    alt="%lt;"
                                                />
                                                <img
                                                    src={carouArrowRight}
                                                    className="splide__arrow splide__arrow--next carouArrowRight"
                                                    alt="%lt;"
                                                />
                                            </div>
                                            <div className="splide__progress">
                                                <div className="splide__progress__bar" />
                                            </div>
                                        </>
                                    )}
                                </Splide>
                            )}
                        </div>
                    </div>
                    <HomeSalonsMap />
                </div>
                {isSlideOpened && selectedSlide !== undefined && (
                    <div className="bs-slidePreview">
                        <div
                            className="bs-slidePreview__close"
                            onClick={handleCloseSlide}
                        >
                            <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 1L11 11M21 21L11 11M11 11L21 1L1 21"
                                    stroke="#1A2835"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                        <div className="bs-slidePreview__inner">
                            <img
                                src={selectedSlide?.actions[0].fullImage}
                                alt={selectedSlide?.actions[0].description}
                                className="bs-slidePreview__image"
                            />
                            {selectedSlide?.actions[0].salon && (
                                <NavLink
                                    to={`/salon/${selectedSlide?.actions[0].salon.id}`}
                                    className="bs-slidePreview__link"
                                >
                                    <Button
                                        type={'primary'}
                                        className="bs-slidePreview__btn"
                                    >
                                        Перейти в салон
                                    </Button>
                                </NavLink>
                            )}
                        </div>
                    </div>
                )}
            </Layout>
            <PageFoot />
        </>
    );
}
export default Home;
