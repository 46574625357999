import { baseAPIInstance } from '../index.ts';
import {
    IScheduleToEdit,
    IScheduleToSend,
} from '../../Modals/AddScheduleModalForm.tsx';
import { AxiosResponse } from 'axios';
import { IEventsResponse, IServerEvent } from '../../Types/ScheduleTypes.ts';

interface IApi {
    getScheduleBySalon: (
        salonId: string,
        date: string
    ) => Promise<AxiosResponse<IEventsResponse>>;
    addSchedule: (data: IScheduleToSend) => Promise<AxiosResponse>;
    editSchedule: (
        data: IScheduleToEdit,
        id: string | number
    ) => Promise<AxiosResponse>;
    getByProfile: () => Promise<AxiosResponse<IServerEvent[]>>;
    addReview: (props: {
        estimation: number;
        type: 'client2salon' | 'salon2client';
        salonId: number | string;
        clientId: number | string;
        scheduleId: string;
    }) => Promise<AxiosResponse>;
}

export const SalonScheduleAPI: IApi = {
    getScheduleBySalon: async (salonId, date) => {
        return baseAPIInstance.get('schedule/by-salon', {
            params: {
                salonId,
                currentDate: date,
            },
        });
    },
    addSchedule: async (data) => {
        return baseAPIInstance.post('schedule', data);
    },
    editSchedule: async (data, id) => {
        return baseAPIInstance.patch(`schedule/${id}`, data);
    },
    getByProfile: async () => {
        return baseAPIInstance.get('/schedule/by-account');
    },
    addReview: async (data) => {
        return baseAPIInstance.post('/review', data);
    },
};
