import React, {useEffect, useRef} from 'react';
import axios from 'axios';
import {columnSchema} from '../data/columnSchema';
import {Table, Card, Space, Row, Col, Button, Input, DatePicker, Modal} from 'antd';
import {Content} from 'antd/es/layout/layout';
import Breadcrumbs from '../../../Components/Breadcrumbs';
import {DeleteOutlined, EditOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';
import {FormComponent} from '../../../Components/FormComponent';

const {confirm} = Modal;

export default function CategoryDataComponent(){
    const $stor = useSelector(state => state);
    const dispatch = useDispatch();

    const inputRef = useRef([]);
    const handler = data => e => {
        if(e) switch(data.item.type){
            case 'enum':
                dispatch({
                    type: 'FORM-DATA',
                    formDData: {
                        ...$stor.formData,
                        [data.item.key]: e,
                    }
                });
                // setFormData({
                //     ...formData,
                //     [data.item.key]: e
                // })
                break;
            case 'input':
                dispatch({
                    type: 'FORM-DATA',
                    formDData: {
                        ...$stor.formData,
                        [e.target.name]: e.target.value,
                    }
                });
                // setFormData({
                //     ...formData,
                //     [e.target.name]: e.target.value
                // })
                break;
            case 'textarea':
                // setFormData({
                //     ...formData,
                //     [e.target.name]: e.target.value
                // })
                dispatch({
                    type: 'FORM-DATA',
                    formDData: {
                        ...$stor.formData,
                        [e.target.name]: e.target.value,
                    }
                });
                break;
            case 'number':
                // setFormData({
                //     ...formData,
                //     [e.target.name]: e.target.value
                // })
                dispatch({
                    type: 'FORM-DATA',
                    formDData: {
                        ...$stor.formData,
                        [e.target.name]: e.target.value,
                    }
                });
                break;
            case 'date':
                // setFormData({
                //     ...formData,
                //     'birthday': e.format()
                // })

                dispatch({
                    type: 'FORM-DATA',
                    formDData: {
                        ...$stor.formData,
                        'birthday': e.format(),
                    }
                });
                break;
            case 'select':
                // setFormData({
                //     ...formData,
                //     [data.item.key]: e
                // })
                dispatch({
                    type: 'FORM-DATA',
                    formDData: {
                        ...$stor.formData,
                        [data.item.key]: e,
                    }
                });
                break;
            case 'file':
                dispatch({
                    type: 'FORM-DATA',
                    formDData: {
                        ...$stor.formData,
                        [data.item.key]: e.target.value
                    }
                })
                break;
            case 'time':
                // setFormData({
                //     ...formData,
                //     [data.item.key]: e
                // })

                dispatch({
                    type: 'FORM-DATA',
                    formDData: {
                        ...$stor.formData,
                        [data.item.key]: e,
                    }
                });
        }
        const next = inputRef.current[e];
        if(next){
            next.focus()
        }
    };

    const title='Категории услуг', parent='Главная';
    const remoteUrl = `${process.env.REACT_APP_REMOTE_HOST}category/`;

    function getTableData(){
        return dispatch => {
            axios.get(remoteUrl).then(res => {
                console.log(res);
                dispatch({
                    type: 'SOURCE-TABLE',
                    tableSource: res.data,
                });
            });
        };
    }

    function getAdditionalData(){
        return dispatch => {
            axios.get(`${process.env.REACT_APP_REMOTE_HOST}category`).then(res => {
                res.data?.categories?.map(el => { // вырезание пустых массивов children на 2-м уровне
                    return {...el, ...{children: (el?.children ||[]).map(e2 => {
                        if(!e2?.children?.length) delete e2.children;
                        return e2;
                    })}};
                }).unshift(columnSchema[2].options[0]);
                dispatch({
                    type: 'ADDITIONAL-DATA-CATEGORY',
                    category: res.data,
                })
            })
        }
    }

    function getTableColumn(){
        return dispatch => {
            if(columnSchema.some(item => {
                return item.key === 'action'
            })){
                ;
            }else{
                columnSchema.push({
                    title: 'Action',
                    key: 'action',
                    width: 80,
                    render: (text, record) => (
                      <Space size="middle">
                          <a onClick={() => onDeleteClick(record.id)}><DeleteOutlined/></a>
                          <a onClick={() => onEditClick(text)}><EditOutlined/></a>
                      </Space>
                    ),
                })
            }
            dispatch({
                type: 'COLUMN-TABLE',
                tableColumn: columnSchema,
            })
        }
    }

    function deleteDateFromUrl(url, id){
        axios.delete(remoteUrl + id).then(async response => {
            dispatch(getAdditionalData());
            dispatch(getTableData());
            dispatch(getTableColumn());
        }).catch(er => {
            console.log(er)
        })
    }
    let onEditClick = data => {
        let obj = {}
        columnSchema.forEach(item => {
            obj = {
                ...obj,
                [item.key]: data[item.key],
            }
        })
        dispatch({
            type: 'TOGGLE-FORM',
            isFormShow: false,
        })
        dispatch({
            type: 'FORM-IS-EDIT',
            isEditForm: {
                flag: true,
                id: data.id,
            }
        })
        dispatch({
            type: 'FORM-DATA',
            formDData: obj,
        })
    }
    let onDeleteClick = id => {
        confirm({
            title: 'Вы действительно хотите удалить этот элемент?',
            icon: <ExclamationCircleOutlined />,
            content: 'При удалении этот элемент удалится навсегда',
            okText: 'Удалить',
            cancelText: 'Отменить',
            onOk(){
                deleteDateFromUrl(remoteUrl, id)
            },
            onCancel(){
                console.log('Cancel');
            },
        })
    }

    let onNewClick = () => {
        dispatch({
            type: 'TOGGLE-FORM',
            isFormShow: false,
        })
        dispatch({
            type: 'FORM-IS-EDIT',
            isEditForm: {
                flag: false,
                id: 0,
            }
        })
    }
    useEffect(() => {
        dispatch(getAdditionalData());
        dispatch(getTableData());
        dispatch(getTableColumn());
        // TODO Когда переходим с другой страницы, надо сделать, чтобы форма автоматом была в состоянии 'закрыто'
    }, [])
    return <Content style={{margin: 20}}>
      <Card style={{marginBottom: '20px'}}>
          <Row gutter={24}>
              <Col span={18}>
                  <Breadcrumbs parent={parent} title={title} style={{margin:0}}/>
              </Col>
              <Col span={6} style={{display: 'flex', justifyContent: 'flex-end', alignItems:'center'}}>
                  <Row>
                      <Button type={'primary'} onClick={() => onNewClick()}>
                          Новая запись
                      </Button>
                  </Row>
              </Col>
          </Row>
      </Card>
      <FormComponent columtSchema={columnSchema} inputRef={inputRef} additionalData={$stor.additionalData}
          handler={handler} formData={$stor.formData} url={'category/'}/>
      <Card>
          <Table locale={{emptyText: (<span>Нет данных</span>)}} columns={$stor.tableColumn}
              dataSource={$stor.tableSource.categories} scroll={{x: null, y: null}}/>
      </Card>
  </Content>;
}
