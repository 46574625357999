import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { columnSchema } from '../data/columnSchema';
import MaskedInput from 'antd-mask-input';
// import {clientColumnSchema} from '../data/columnSchema';
// import {masterColumnSchema} from '../data/columnSchema';
// import {scheduleColumnSchema} from '../../Schedule/data/columnSchema';
// import {serviceColumnSchema} from '../../Service/data/columnSchema';

import {
    Table,
    Card,
    Space,
    Row,
    Col,
    Button,
    Input,
    DatePicker,
    Modal,
    Select,
    Tabs,
    Checkbox,
    notification,
    Tag,
    Collapse,
} from 'antd';
import { Content } from 'antd/es/layout/layout';

import Breadcrumbs from '../../../Components/Breadcrumbs';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { YMaps, Map, Placemark } from 'react-yandex-maps';

import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { FormComponent } from '../../../Components/FormComponent';
import { FormComponentWithMap } from '../../../Components/FormComponentWithMap';
import ScheduleComponent from './ScheduleComponent';
import Text from 'antd/es/typography/Text';
import ServiceListDataComponent from './ServiceListDataComponent';
import ServiceListComponent from '../../../Components/ServiceListComponent';

const { confirm } = Modal;
const { TabPane } = Tabs;
const { Panel } = Collapse;

export default function SalonDataComponent({ url }) {
    let [currentDate, setCurrentDate] = useState(moment().format());
    let [currentSalon, setCurrentSalon] = useState();
    let [modalVisible, setModalVisible] = useState({
        service: false,
        client: false,
        master: false,
        schedule: false,
    });
    let [price, setPrice] = useState({});
    let [client, setClient] = useState({});
    let [master, setMaster] = useState({});
    let [schedule, setSchedule] = useState({ isNewClient: false });
    let [isNewClient, setIsNewClient] = useState(false);

    let [masterServiceList, setMasterServiceList] = useState();

    let [selectedService, setSelectedService] = useState([]);

    const content = useSelector((state) => state);
    const dispatch = useDispatch();

    let inputRef = useRef([]);

    const scheduleColumnSchema = [
        // {
        //     title: 'ID',
        //     key: 'id',
        //     dataIndex: 'id',
        //     type: 'input',
        //     show: true,
        //     value: '',
        //     required: true
        // },
        {
            title: 'Дата',
            key: 'scheduleDatetime',
            dataIndex: 'scheduleDatetime',
            type: 'input',
            show: true,
            value: '',
            required: true,
            render: (index, record) => {
                // console.log(moment(record.scheduleDatetime).format('YYYY-MM-DD HH:mm'))
                return moment(record.scheduleDatetime)
                    .subtract(1, 'minute')
                    .format('YYYY-MMMM-DD HH:mm');
            },
        },
        {
            title: 'Клиент',
            key: 'clientId',
            dataIndex: ['client', 'firstname'],
            type: 'selectClient',
            show: true,
            value: '',
            required: true,
            options: [],
        },
        {
            title: 'Мастер',
            key: 'masterId',
            dataIndex: ['master', 'name'],
            type: 'selectMaster',
            show: true,
            value: '',
            required: true,
            options: [],
        },
        {
            title: 'Салон',
            key: 'salonId',
            dataIndex: ['salon', 'title'],
            type: 'selectSalon',
            show: true,
            value: '',
            required: true,
            options: [],
        } /*, {
      title: 'Услуги',
      key: 'serviceId',
      dataIndex: ['service', 'name'],
      type: 'multiselectService',
      show: true,
      value: '',
      required: true,
      options: []
    }*/,
        {
            title: 'Статус',
            key: 'status',
            dataIndex: 'status',
            type: 'enum',
            show: true,
            value: '',
            required: true,
            options: ['В системе', 'Не в системе'],
        },
        {
            width: 120,
            fixed: 'right',
            render: (index, record) => {
                return (
                    <Space size="middle">
                        <a
                            onClick={() => {
                                // setModalVisible({master: true, isEdit: true});
                                // setMaster(record)

                                let sumServiceTiming = 0;
                                let sumServicePrice = 0;
                                record.service.forEach((item) => {
                                    //console.log(item.timing)
                                    sumServiceTiming += item.timing;
                                    sumServicePrice += item.price;
                                });
                                record.sumServiceTiming = sumServiceTiming;
                                record.sumServicePrice = sumServicePrice;
                                // console.log(record)
                                dispatch({
                                    type: 'SCHEDULE-DETAIL',
                                    scheduleDetail: record,
                                });
                                dispatch({
                                    type: 'SCHEDULE-VIEW-DETAIL',
                                    scheduleDetailView: true,
                                });
                            }}
                        >
                            <EditOutlined />
                        </a>
                        <a
                            onClick={() => {
                                // axios.delete(process.env.REACT_APP_REMOTE_HOST + "master/" + record.id).then(res => {
                                //   getMastersData(currentSalon)
                                // })
                            }}
                        >
                            <DeleteOutlined />
                        </a>
                    </Space>
                );
            },
        },
    ];

    const masterColumnSchema = [
        /*{
        title: 'Салон',
        key: 'salonId',
        dataIndex: ['salon','title'],
        type: 'select',
        show: true,
        required: true,
        value: '',
        options: [

        ],
    },*/ {
            title: 'Имя',
            key: 'name',
            dataIndex: 'name',
            type: 'input',
            show: true,
            required: true,
            value: '',
        },
        {
            title: 'Телефон',
            key: 'phone',
            dataIndex: 'phone',
            type: 'input',
            show: true,
            required: true,
            value: '',
        },
        /*{
      title: 'Аватар',
      key: 'avatar',
      dataIndex: 'avatar',
      type: 'file',
      show: true,
      required: false,
      value: ''
    }, */ {
            title: 'День рождения',
            key: 'birthday',
            dataIndex: 'birthday',
            type: 'date',
            show: true,
            required: true,
            value: '',
            render: (index, record) => {
                return moment(record).format('YYYY-MM-DD');
            },
        },
        {
            title: 'Подробнее',
            key: 'description',
            dataIndex: 'description',
            type: 'textarea',
            show: true,
            required: true,
            value: '',
        },
        {
            title: 'Наценка',
            key: 'priceAspectRatio',
            dataIndex: 'priceAspectRatio',
            type: 'number',
            step: 0.1,
            min: 0,
            max: 2,
            show: true,
            required: true,
            value: '',
        },
        {
            title: 'Рейтинг',
            key: 'rating',
            dataIndex: 'rating',
            type: 'number',
            step: 1,
            min: 1,
            max: 5,
            show: true,
            required: true,
            value: '',
        },
        /* {
      title: 'Услуги',
      key: 'serviceTypeIds',
      dataIndex: 'serviceTypeIds',
      type: 'multiselect',
      show: true,
      required: true,
      value: '',
      options: []
    }, */ {
            title: 'Статус',
            key: 'status',
            dataIndex: 'status',
            type: 'enum',
            show: true,
            required: true,
            value: '',
            options: ['Активная', 'Отключена'],
        },
        {
            title: 'Действия',
            key: 'action',
            width: 120,
            fixed: 'right',
            render: (index, record) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            setModalVisible({ master: true, isEdit: true });
                            setMaster(record);
                            // console.log(record);
                            let arr = [],
                                _arr = [];
                            record.service.forEach((item) => {
                                arr.push(item.name);
                            });

                            /*content.service.forEach(menu => {
              menu.children.forEach(subMenu => {
                _arr.push({value: subMenu.id, label: subMenu.name, key: subMenu.id})
              })
            })*/

                            //_arr.push({value: item.name})
                            // setDefaultSelect(arr)
                            // setDefaultSelectOptions(_arr)
                        }}
                    >
                        <EditOutlined />
                    </a>
                    <a
                        onClick={() => {
                            axios
                                .delete(
                                    process.env.REACT_APP_REMOTE_HOST +
                                        'master/' +
                                        record.id
                                )
                                .then((res) => {
                                    getMastersData(currentSalon);
                                });
                        }}
                    >
                        <DeleteOutlined />
                    </a>
                </Space>
            ),
        },
    ];

    const clientColumnSchema = [
        {
            title: 'Имя',
            key: 'firstName',
            dataIndex: 'firstName',
            type: 'input',
            show: true,
            value: '',
        },
        {
            title: 'Фамилия',
            key: 'lastName',
            dataIndex: 'lastName',
            type: 'input',
            show: true,
            value: '',
        },
        {
            title: 'Отчество',
            key: 'patronymic',
            dataIndex: 'patronymic',
            type: 'input',
            show: true,
            value: '',
        },
        {
            title: 'День рождения',
            key: 'birthDate',
            dataIndex: 'birthDate',
            type: 'date',
            show: true,
            required: true,
            value: '',
            render: (index, record) => {
                return moment(record).format('YYYY-MM-DD');
            },
        },
        {
            title: 'Телефон',
            key: 'phone',
            dataIndex: 'phone',
            type: 'input',
            show: true,
            value: '',
        },
        {
            title: 'Почта',
            key: 'email',
            dataIndex: 'email',
            type: 'input',
            show: true,
            value: '',
        },
        {
            title: 'Город',
            key: 'city',
            dataIndex: 'city',
            type: 'input',
            show: true,
            value: '',
        },
        {
            title: 'Рейтинг',
            key: 'clientRating',
            dataIndex: 'clientRating',
            type: 'number',
            step: 1,
            min: 1,
            max: 5,
            show: true,
            value: '',
        },
        {
            title: 'Статус',
            key: 'status',
            dataIndex: 'status',
            type: 'enum',
            show: true,
            value: '',
            options: ['Активные', 'Отключенный'],
        },
        {
            title: 'Салон',
            key: 'salon',
            dataIndex: 'salon',
            type: 'select-salons',
            show: true,
            value: '',
            options: [],
        },
        {
            title: 'Действия',
            key: 'action',
            width: 120,
            fixed: 'right',
            render: (index, record) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            setModalVisible({ client: true });
                            setClient(record);
                        }}
                    >
                        <EditOutlined />
                    </a>
                    <a
                        onClick={() => {
                            axios
                                .delete(
                                    process.env.REACT_APP_REMOTE_HOST +
                                        'clients/' +
                                        record.id
                                )
                                .then((res) => {
                                    getClientsData(currentSalon);
                                });
                        }}
                    >
                        <DeleteOutlined />
                    </a>
                </Space>
            ),
        },
    ];

    const serviceColumnSchema = [
        {
            title: 'Название услуги',
            key: 'name',
            dataIndex: 'name',
            type: 'input',
            show: true,
            value: '',
        },
        {
            title: 'Время исполнения',
            key: 'timing',
            dataIndex: 'timing',
            type: 'number',
            step: 15,
            min: 0,
            show: true,
            value: '',
        },
        {
            title: 'Цена',
            key: 'price',
            dataIndex: 'price',
            type: 'number',
            step: 1.0,
            min: 0,
            show: true,
            value: '',
        },
        {
            title: 'Активно',
            key: 'enable',
            dataIndex: 'enable',
            type: 'boolean',
            render: (index, item) => (
                <Checkbox
                    checked={item.enable}
                    onClick={() => {
                        const data = {
                            ...item,
                            enable: !item.enable,
                        };
                        savePrice(data);
                    }}
                />
            ),
            show: true,
            value: '',
        },
        {
            title: 'Действия',
            key: 'action',
            render: (index, record) => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            setModalVisible({ service: true });
                            setPrice(record);
                        }}
                    >
                        <EditOutlined />
                    </a>
                    <a
                        onClick={() => {
                            axios
                                .delete(
                                    process.env.REACT_APP_REMOTE_HOST +
                                        'service/' +
                                        record.id
                                )
                                .then((res) => {
                                    getServicesData(currentSalon);
                                });
                        }}
                    >
                        <DeleteOutlined />
                    </a>
                </Space>
            ),
        },
    ];

    let [map, setMap] = useState({
        mapCenter: [55.751574, 37.573856],
        zoom: 12,
        placemarkCoords: [55.751574, 37.573856],
    });

    let title = 'Салоны',
        parent = 'Главная';

    const remoteUrl = process.env.REACT_APP_REMOTE_HOST + 'salon/';
    const remoteClientUrl = process.env.REACT_APP_REMOTE_HOST + 'client/';
    const remoteMasterUrl = process.env.REACT_APP_REMOTE_HOST + 'master/';

    const handler = (data) => (ev) => {
        if (ev) {
            switch (data.item.type) {
                case 'multiselectService':
                    console.log(data.item);
                    break;
                case 'enum':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...content.formData,
                            [data.item.key]: ev,
                        },
                    });
                    break;
                case 'input':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...content.formData,
                            [ev.target.name]: ev.target.value,
                        },
                    });
                    break;
                case 'phone':
                    let x,
                        inp = ev.currentTarget;
                    console.log('==', {
                        type: 'FORM-DATA',
                        formDData: (x = {
                            ...content.formData,
                            [ev.target.name]:
                                inp.value.substr(0, inp.selectionStart) +
                                ev.key +
                                ev.maskedValue.substr(inp.selectionStart + 1),
                        }),
                    });
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...content.formData,
                            [ev.target.name]: x,
                        },
                    });
                    setTimeout(
                        ((inp, val, pos) => {
                            inp.value = val; // eslint-disable-next-line no-debugger
                            //inp.focus();
                            inp.selectionStart &&
                                inp.setSelectionRange(pos, pos);
                        }).bind(0, inp, x.phone, inp.selectionStart + 1),
                        0
                    );
                    break;
                case 'textarea':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...content.formData,
                            [ev.target.name]: ev.target.value,
                        },
                    });
                    break;
                case 'number':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...content.formData,
                            [ev.target.name]: ev.target.value,
                        },
                    });
                    break;
                case 'date':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...content.formData,
                            birthday: ev.format(),
                        },
                    });
                    break;
                case 'select':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...content.formData,
                            [data.item.key]: ev,
                        },
                    });
                    break;
                case 'file':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...content.formData,
                            [data.item.key]: ev.target.value,
                        },
                    });
                    break;
                case 'time':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...content.formData,
                            [data.item.key]: ev,
                        },
                    });
                    break;
                case 'jobTime':
                    dispatch({
                        type: 'FORM-DATA',
                        formDData: {
                            ...content.formData,
                            [data.item.key]: ev,
                        },
                    });
                    break;
            }
        }
        const next = inputRef.current[ev];
        if (next) next.focus();
    };

    function getTableData() {
        return (dispatch) => {
            axios.get(`${remoteUrl}all`).then((res) => {
                dispatch({
                    type: 'SOURCE-TABLE',
                    tableSource: res.data,
                });
            });

            axios.get(`${remoteClientUrl}all`).then((res) => {
                dispatch({
                    type: 'SOURCE-CLIENT-BY-SALON-TABLE',
                    tableClientSource: res.data.items,
                });
            });

            axios.get(`${remoteMasterUrl}all`).then((res) => {
                dispatch({
                    type: 'SOURCE-MASTER-BY-SALON-TABLE',
                    tableMasterSource: res.data.items,
                });
            });
        };
    }

    // function getAdditionalData(){
    //   return dispatch => {
    //     axios.get(process.env.REACT_APP_REMOTE_HOST + 'job-time').then(res => {
    //       dispatch({
    //         type: 'ADDITIONAL-DATA-JOB-TIME',
    //         jobTime: res.data.items
    //       })
    //     })
    //
    //
    //     axios.get(process.env.REACT_APP_REMOTE_HOST + 'service').then(res => {
    //       dispatch({
    //         type: 'ADDITIONAL-DATA-SERVICES',
    //         services: res.data.items
    //       })
    //     })
    //   }
    // }

    function getTableColumn() {
        return (dispatch) => {
            if (
                columnSchema.some((item) => {
                    return item.key == 'action';
                })
            ) {
            } else {
                columnSchema.push({
                    title: 'Action',
                    key: 'action',
                    width: 120,
                    fixed: 'right',
                    render: (text, record) => (
                        <Space size="middle">
                            <a onClick={() => onViewClick(record.id)}>
                                <EyeOutlined />
                            </a>
                            <a onClick={() => onDeleteClick(record.id)}>
                                <DeleteOutlined />
                            </a>
                            <a onClick={() => onEditClick(text)}>
                                <EditOutlined />
                            </a>
                        </Space>
                    ),
                });
            }
            dispatch({
                type: 'COLUMN-TABLE',
                tableColumn: columnSchema,
            });
        };
    }

    function deleteDateFromUrl(url, id) {
        axios
            .delete(remoteUrl + id)
            .then(async (response) => {
                //dispatch(getAdditionalData());
                dispatch(getTableData());
                dispatch(getTableColumn());
            })
            .catch((er) => {
                console.log(er);
            });
    }

    let onEditClick = (data) => {
        let obj = {};
        columnSchema.forEach((item) => {
            obj = {
                ...obj,
                [item.key]: data[item.key],
            };
        });

        dispatch({
            type: 'TOGGLE-FORM',
            isFormShow: false,
        });

        dispatch({
            type: 'FORM-IS-EDIT',
            isEditForm: {
                flag: true,
                id: data.id,
            },
        });

        dispatch({
            type: 'FORM-DATA',
            formDData: obj,
        });

        axios
            .get(process.env.REACT_APP_REMOTE_HOST + 'job-time/all')
            .then((res) => {
                dispatch({
                    type: 'ADDITIONAL-DATA-JOB-TIME',
                    jobTime: res.data.items,
                });
            });

        axios
            .get(process.env.REACT_APP_REMOTE_HOST + 'service/all')
            .then((res) => {
                dispatch({
                    type: 'ADDITIONAL-DATA-SERVICES',
                    services: res.data.items,
                });
            });
    };

    let onDeleteClick = (id) => {
        confirm({
            title: 'Вы действительно хотите удалить этот элемент?',
            icon: <ExclamationCircleOutlined />,
            content: 'При удалении этот элемент удалится навсегда',
            okText: 'Удалить',
            cancelText: 'Отменить',
            onOk() {
                deleteDateFromUrl(remoteUrl, id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    let onNewClick = () => {
        dispatch({
            type: 'TOGGLE-FORM',
            isFormShow: false,
        });

        dispatch({
            type: 'FORM-IS-EDIT',
            isEditForm: {
                flag: false,
                id: 0,
            },
        });

        axios
            .get(process.env.REACT_APP_REMOTE_HOST + 'job-time/all')
            .then((res) => {
                dispatch({
                    type: 'ADDITIONAL-DATA-JOB-TIME',
                    jobTime: res.data.items,
                });
            });

        axios
            .get(process.env.REACT_APP_REMOTE_HOST + 'service/all')
            .then((res) => {
                dispatch({
                    type: 'ADDITIONAL-DATA-SERVICES',
                    services: res.data.items,
                });
            });

        //getAdditionalData()
    };

    let onNewSchedule = () => {
        dispatch({
            type: 'TOGGLE-FORM',
            isFormShow: false,
        });
    };

    const onViewClick = (id) => {
        dispatch({
            type: 'TOGGLE-VIEW-SALON-DETAIL',
            isSalonDetailView: true,
        });

        setCurrentSalon(id);

        getScheduleData(id, currentDate);
        getMastersData(id);
        getClientsData(id);
        getServicesData(id);
    };

    const onDetailClose = () => {
        dispatch({
            type: 'TOGGLE-VIEW-SALON-DETAIL',
            isSalonDetailView: true,
        });

        dispatch({
            type: 'ADDITIONAL-DATA-SCHEDULE',
            schedule: [],
        });
    };
    //
    const onDeleteMasterClick = (id) => {
        console.log(id);
    };
    const onEditMasterClick = (id) => {
        console.log(id);
    };

    const onDeleteClientClick = (id) => {
        console.log(id);
    };
    const onEditClientClick = (id) => {
        console.log(id);
    };

    useEffect(() => {
        //dispatch(getAdditionalData());
        dispatch(getTableData());
        dispatch(getTableColumn());
        dispatch(getCategoryData());
    }, []);

    console.log(content);

    const changeCurrentDate = (ev) => {
        if (ev) {
            getScheduleData(currentSalon, ev.format());
            getClientsData(currentSalon);
        }
    };

    function savePrice(data) {
        console.log(data);
        axios
            .patch(
                process.env.REACT_APP_REMOTE_HOST + 'service/' + data.id,
                data
            )
            .then((res) => {
                // console.log(res)
                getServicesData(currentSalon);
            });
    }

    function saveClient(data) {
        const formData = {
            ...data,
            salonIds: [currentSalon],
        };
        axios
            .post(process.env.REACT_APP_REMOTE_HOST + 'client/', formData)
            .then((res) => {
                getClientsData(currentSalon);
            })
            .catch((er) => {
                // notification['error']({
                //   message: 'Error',
                //   description:
                //   er.response.data.message,
                // });
                console.log(er.response.data);
            });
    }

    function saveMaster(data) {
        // console.log(data)
        const formData = {
            ...data,
            salonId: currentSalon,
        };

        if (!modalVisible.isEdit) {
            axios
                .post(process.env.REACT_APP_REMOTE_HOST + 'master/', formData)
                .then((res) => {
                    getMastersData(currentSalon);
                })
                .catch((er) => {
                    notification['error']({
                        message: 'Error',
                        description: er.response.data.message,
                    });
                    console.log(er.response.data);
                });
        } else {
            console.log(formData);
            axios
                .patch(
                    process.env.REACT_APP_REMOTE_HOST + 'master/' + data.id,
                    formData
                )
                .then((res) => {
                    getMastersData(currentSalon);
                })
                .catch((er) => {
                    notification['error']({
                        message: 'Error',
                        description: er.response.data.message,
                    });
                    console.log(er.response.data);
                });
        }
    }

    function saveSchedule(data) {
        const formData = {
            ...data,
            salonId: currentSalon,
        };
        console.log(formData);
        axios
            .post(process.env.REACT_APP_REMOTE_HOST + 'schedules/', formData)
            .then((res) => {
                console.log(res);
                getScheduleData(currentSalon, currentDate);
            })
            .catch((er) => {
                console.log(er);
                notification['error']({
                    message: 'Error',
                    description: er.response.data.message,
                });
            });
    }

    const time = timeslots('10:00', '23:00');

    function getScheduleData(id, date) {
        // TODO нет в сваггере /schedules
        axios
            .get(
                process.env.REACT_APP_REMOTE_HOST +
                    'schedules/bySalon/' +
                    id +
                    '/' +
                    date
            )
            .then((res) => {
                dispatch({
                    type: 'ADDITIONAL-DATA-SCHEDULE',
                    schedule: res.data.items,
                });
            });
    }

    function getMastersData(id) {
        axios
            .get(process.env.REACT_APP_REMOTE_HOST + 'master/all' + id)
            .then((res) => {
                // console.log(res)
                dispatch({
                    type: 'ADDITIONAL-DATA-MASTERS',
                    masters: res.data.items,
                });
            });
    }

    function getMasterServiceData(id) {
        // console.log(content.masters.filter(item => item.id === id)[0].service)
        setMasterServiceList(
            content.masters.filter((item) => item.id === id)[0].service
        );
        //console.log(content.masters)
        // axios.get(process.env.REACT_APP_REMOTE_HOST + 'master/' + id).then(res => {
        //   //console.log(res.data)
        //
        // })
    }

    function getClientsData(id) {
        axios
            .get(process.env.REACT_APP_REMOTE_HOST + 'salon/' + id)
            .then((res) => {
                dispatch({
                    type: 'ADDITIONAL-DATA-CLIENTS',
                    clients: res.data.clients,
                });
            });
    }

    function getServicesData(id) {
        axios
            .get(process.env.REACT_APP_REMOTE_HOST + 'salon/' + id)
            .then((res) => {
                dispatch({
                    type: 'ADDITIONAL-DATA-CLIENTS', // TODO одинаково же с getClientsData()!
                    clients: res.data.clients,
                });

                // dispatch({
                //     type: 'ADDITIONAL-DATA-SERVICE-LIST',
                //     service: res.data.service
                // })
            });

        axios
            .get(process.env.REACT_APP_REMOTE_HOST + 'services/salon/' + id)
            .then((res) => {
                // TODO /services/salon
                // console.log(res)
                dispatch({
                    type: 'ADDITIONAL-DATA-SERVICE-LIST', // данные по салону (услуги с категориями)
                    service: res.data,
                });
            });
    }

    function setPriceForm(data) {
        dispatch({
            type: 'ADDITIONAL-DATA-PRICE-FORM', // услуги одного салона
            price: data,
        });
    }

    function getCategoryData() {
        return (dispatch) => {
            console.log(`${process.env.REACT_APP_REMOTE_HOST}category`);
            axios
                .get(`${process.env.REACT_APP_REMOTE_HOST}category`)
                .then((res) => {
                    dispatch({
                        type: 'ADDITIONAL-DATA-CATEGORIES', // но оно же в другом месте называется SOURCE-TABLE, tableSource
                        categories: res.data.items,
                    });
                });
        };
    }

    function timeslots(startTime, endTime, one_client_time = 15) {
        let currDate = new Date(),
            __start = startTime.split(':'),
            __end = endTime.split(':');

        let _start = Number(currDate.setHours(__start[0], __start[1], 0, 0));
        let _end = Number(currDate.setHours(__end[0], __end[1], 0, 0));

        let worktime = (_end - _start) / 1000 / 60,
            slots = worktime / one_client_time;

        let slots_arr = [];
        for (var i = 0; i < slots; i++) {
            var nextClientTimeStart = new Date(_start),
                nextClientTime = new Date(_start);
            slots_arr.push({
                is_empty: true,
                client_id: 0,
                client_time:
                    nextClientTimeStart.getHours() +
                    ':' +
                    nextClientTimeStart.getMinutes(),
                client_time_end:
                    nextClientTimeStart.getHours() +
                    ':' +
                    (nextClientTimeStart.getMinutes() + one_client_time - 1),
            });
            _start =
                nextClientTimeStart.getTime() + one_client_time * 60 * 1000;
        }
        return slots_arr;
    }

    const dateOfBirth = 'YYYY-MM-DD';

    function geocode(data) {
        var url =
            'https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address';
        var token = '0c91cba5ed00198ec2aacc999c5d4124d9d00e6c';
        data = Object.assign(data, {
            count: 1,
            radius_meters: 20,
        });
        var options = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Token ' + token,
            },
            body: JSON.stringify(data),
        };

        fetch(url, options)
            .then((response) => response.text())
            .then((result) => {
                //console.log(JSON.parse(result))
                const suggestions = JSON.parse(result);
                dispatch({
                    type: 'FORM-DATA',
                    formDData: {
                        ...content.formData,
                        address: suggestions.suggestions[0].value,
                    },
                });
            })
            .catch((error) => console.log('error', error));
    }

    return (
        <Content style={{ margin: 20 }}>
            <Card style={{ marginBottom: '20px' }}>
                <Row gutter={24}>
                    <Col span={18}>
                        <Breadcrumbs
                            parent={parent}
                            title={title}
                            style={{ margin: 0 }}
                        />
                    </Col>
                    <Col
                        span={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <Row>
                            <Button
                                type={'primary'}
                                onClick={() => onNewClick()}
                            >
                                Новая запись
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Card>
            {content.isSalonDetailView ? (
                <Row>
                    <Col span={24}>
                        <Card style={{ marginBottom: '20px' }}>
                            <Row>
                                <Col
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        type={'primary'}
                                        onClick={() => onDetailClose()}
                                    >
                                        Close
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Tabs
                                        defaultActiveKey="1"
                                        onChange={(ev) => {
                                            // if(ev == 3){
                                            //     drawSchedule(content.schedule)
                                            // }
                                        }}
                                    >
                                        <TabPane tab="Услуги" key="1">
                                            <ServiceListDataComponent
                                                category={content.categories}
                                                salonId={currentSalon}
                                            />
                                            <Table
                                                style={{ marginTop: 20 }}
                                                locale={{
                                                    emptyText: (
                                                        <span>Нет данных</span>
                                                    ),
                                                }}
                                                columns={serviceColumnSchema}
                                                dataSource={content.service}
                                                rowKey={'id'}
                                            />
                                            <Modal
                                                visible={modalVisible.service}
                                                onOk={() => {
                                                    setModalVisible({
                                                        service: false,
                                                    });
                                                    savePrice(price);
                                                }}
                                                onCancel={() =>
                                                    setModalVisible({
                                                        service: false,
                                                    })
                                                }
                                                title={'Редактирование услуги'}
                                            >
                                                <label>Цена</label>
                                                <Input
                                                    type={'number'}
                                                    step={50}
                                                    min={50}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'price'
                                                        ] = el)
                                                    }
                                                    name={'price'}
                                                    value={price.price}
                                                    onChange={(ev) => {
                                                        // console.log(ev.target.value)
                                                        setPrice({
                                                            ...price,
                                                            price: ev.target
                                                                .value,
                                                        });
                                                    }}
                                                />

                                                <label>Время исполнения</label>
                                                <Input
                                                    type={'number'}
                                                    step={15}
                                                    min={15}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'time'
                                                        ] = el)
                                                    }
                                                    name={'time'}
                                                    value={price.timing}
                                                    onChange={(ev) => {
                                                        // console.log(ev.target.value)
                                                        setPrice({
                                                            ...price,
                                                            timing: ev.target
                                                                .value,
                                                        });
                                                    }}
                                                />
                                            </Modal>
                                        </TabPane>
                                        <TabPane tab="Клиенты" key="2">
                                            <Button
                                                type={'primary'}
                                                style={{ marginBottom: 10 }}
                                                onClick={() =>
                                                    setModalVisible({
                                                        client: true,
                                                    })
                                                }
                                            >
                                                Добавить клиента
                                            </Button>
                                            <Modal
                                                visible={modalVisible.client}
                                                onOk={() => {
                                                    setModalVisible({
                                                        client: false,
                                                    });
                                                    saveClient(client);
                                                }}
                                                onCancel={() =>
                                                    setModalVisible({
                                                        client: false,
                                                    })
                                                }
                                                title={'Клиенты'}
                                            >
                                                <label>Имя</label>
                                                <Input
                                                    type={'text'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'firstname'
                                                        ] = el)
                                                    }
                                                    name={'firstname'}
                                                    value={client.firstname}
                                                    onChange={(ev) => {
                                                        // console.log(ev.target.value)
                                                        setClient({
                                                            ...client,
                                                            firstname:
                                                                ev.target.value,
                                                        });
                                                    }}
                                                />
                                                <label>Фамилия</label>
                                                <Input
                                                    type={'text'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'lastname'
                                                        ] = el)
                                                    }
                                                    name={'lastname'}
                                                    value={client.lastname}
                                                    onChange={(ev) => {
                                                        // console.log(ev.target.value)
                                                        setClient({
                                                            ...client,
                                                            lastname:
                                                                ev.target.value,
                                                        });
                                                    }}
                                                />
                                                <label>Отчество</label>
                                                <Input
                                                    type={'text'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'patronymic'
                                                        ] = el)
                                                    }
                                                    name={'patronymic'}
                                                    value={client.patronymic}
                                                    onChange={(ev) => {
                                                        // console.log(ev.target.value)
                                                        setClient({
                                                            ...client,
                                                            patronymic:
                                                                ev.target.value,
                                                        });
                                                    }}
                                                />
                                                <br />
                                                <label>Дата рождения</label>
                                                <br />
                                                <DatePicker
                                                    defaultDate={moment(
                                                        new Date(),
                                                        dateOfBirth
                                                    )}
                                                    showTime={true}
                                                    format={dateOfBirth}
                                                    name={'birthday'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'birthday'
                                                        ] = el)
                                                    }
                                                    onChange={(ev) => {
                                                        // console.log(ev.target.value)
                                                        setClient({
                                                            ...client,
                                                            birthday:
                                                                ev.format(),
                                                        });
                                                    }}
                                                    autoComplete={'false'}
                                                />
                                                <br />
                                                <label>Телефон</label>
                                                <MaskedInput
                                                    mask={'7(111)111-11-11'}
                                                    name={'phone'}
                                                    value={client.phone}
                                                    type={'text'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'phone'
                                                        ] = el)
                                                    }
                                                    onChange={(ev) => {
                                                        // console.log(ev.target.value)
                                                        setClient({
                                                            ...client,
                                                            phone: ev.target
                                                                .value,
                                                        });
                                                    }}
                                                />
                                                {/*<Input*/}
                                                {/*  type={'text'}*/}
                                                {/*  ref={el => inputRef.current['phone'] = el}*/}
                                                {/*  name={'phone'}*/}
                                                {/*  value={client.phone}*/}
                                                {/*  onChange={(ev) => {*/}
                                                {/*    // console.log(ev.target.value)*/}
                                                {/*    setClient({...client, phone: ev.target.value})*/}
                                                {/*  }}*/}
                                                {/*/>*/}
                                                <label>Статус телефона</label>
                                                <Select
                                                    name={'phoneStatus'}
                                                    onChange={(ev) => {
                                                        setClient({
                                                            ...client,
                                                            phoneStatus: ev,
                                                        });
                                                    }}
                                                    style={{ width: '100%' }}
                                                    //defaultValue={item.options[0]}
                                                    placeholder="Выбрать"
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'phoneStatus'
                                                        ] = el)
                                                    }
                                                    autoComplete={'false'}
                                                >
                                                    <Select.Option
                                                        value={'Подтвержден'}
                                                        key={1}
                                                    >
                                                        Подтвержден
                                                    </Select.Option>
                                                    <Select.Option
                                                        value={'Не подтвержден'}
                                                        key={2}
                                                    >
                                                        Не подтвержден
                                                    </Select.Option>
                                                </Select>
                                                <label>Почта</label>
                                                <Input
                                                    type={'text'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'email'
                                                        ] = el)
                                                    }
                                                    name={'email'}
                                                    value={client.email}
                                                    onChange={(ev) => {
                                                        // console.log(ev.target.value)
                                                        setClient({
                                                            ...client,
                                                            email: ev.target
                                                                .value,
                                                        });
                                                    }}
                                                />
                                                <label>Статус почты</label>
                                                <Select
                                                    name={'emailStatus'}
                                                    onChange={(ev) => {
                                                        setClient({
                                                            ...client,
                                                            emailStatus: ev,
                                                        });
                                                    }}
                                                    style={{ width: '100%' }}
                                                    //defaultValue={item.options[0]}
                                                    placeholder="Выбрать"
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'emailStatus'
                                                        ] = el)
                                                    }
                                                    autoComplete={'false'}
                                                >
                                                    <Select.Option
                                                        value={'Подтвержден'}
                                                        key={1}
                                                    >
                                                        Подтвержден
                                                    </Select.Option>
                                                    <Select.Option
                                                        value={'Не подтвержден'}
                                                        key={2}
                                                    >
                                                        Не подтвержден
                                                    </Select.Option>
                                                </Select>

                                                <label>Город</label>
                                                <Input
                                                    type={'text'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'city'
                                                        ] = el)
                                                    }
                                                    name={'city'}
                                                    value={client.city}
                                                    onChange={(ev) => {
                                                        // console.log(ev.target.value)
                                                        setClient({
                                                            ...client,
                                                            city: ev.target
                                                                .value,
                                                        });
                                                    }}
                                                />
                                                <label>Рейтинг</label>
                                                <Input
                                                    type={'number'}
                                                    step={0.1}
                                                    min={0}
                                                    max={5}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'rating'
                                                        ] = el)
                                                    }
                                                    name={'rating'}
                                                    //value={price.rating}
                                                    onChange={(ev) => {
                                                        // console.log(ev.target.value)
                                                        setClient({
                                                            ...client,
                                                            rating: ev.target
                                                                .value,
                                                        });
                                                    }}
                                                />

                                                <label>Статус</label>
                                                <Select
                                                    onChange={(ev) => {
                                                        setClient({
                                                            ...client,
                                                            status: ev,
                                                        });
                                                    }}
                                                    style={{ width: '100%' }}
                                                    //defaultValue={item.options[0]}
                                                    placeholder="Выбрать"
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'status'
                                                        ] = el)
                                                    }
                                                    autoComplete={'false'}
                                                >
                                                    <Select.Option
                                                        value={'Активные'}
                                                        key={1}
                                                    >
                                                        Активные
                                                    </Select.Option>
                                                    <Select.Option
                                                        value={'Отключенный'}
                                                        key={2}
                                                    >
                                                        Отключенный
                                                    </Select.Option>
                                                </Select>
                                            </Modal>
                                            <Table
                                                locale={{
                                                    emptyText: (
                                                        <span>Нет данных</span>
                                                    ),
                                                }}
                                                columns={clientColumnSchema}
                                                dataSource={content.clients}
                                                scroll={{ x: 1500, y: null }}
                                            />
                                        </TabPane>
                                        <TabPane tab="Мастера" key="3">
                                            <Button
                                                type={'primary'}
                                                style={{ marginBottom: 10 }}
                                                onClick={() => {
                                                    master.name = '';
                                                    master.description = '';
                                                    master.phone = '';
                                                    master.priceAspectRatio =
                                                        '';
                                                    master.rating = '';
                                                    master.serviceTypeIds = [];
                                                    setModalVisible({
                                                        master: true,
                                                        isEdit: false,
                                                    });
                                                }}
                                            >
                                                Добавить мастера
                                            </Button>
                                            <Modal
                                                visible={modalVisible.master}
                                                onOk={() => {
                                                    setModalVisible({
                                                        master: false,
                                                    });
                                                    saveMaster(master);
                                                }}
                                                onCancel={() =>
                                                    setModalVisible({
                                                        master: false,
                                                    })
                                                }
                                                title={'Мастера'}
                                            >
                                                <label>Имя</label>
                                                <Input
                                                    type={'text'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'name'
                                                        ] = el)
                                                    }
                                                    name={'name'}
                                                    value={master.name}
                                                    onChange={(ev) => {
                                                        // console.log(ev.target.value)
                                                        setMaster({
                                                            ...master,
                                                            name: ev.target
                                                                .value,
                                                        });
                                                    }}
                                                />
                                                <label>Телефон</label>
                                                <MaskedInput
                                                    mask={'7(111)111-11-11'}
                                                    name={'phone'}
                                                    value={master.phone}
                                                    type={'text'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'phone'
                                                        ] = el)
                                                    }
                                                    onChange={(ev) => {
                                                        // console.log(ev.target.value)
                                                        setMaster({
                                                            ...master,
                                                            phone: ev.target
                                                                .value,
                                                        });
                                                    }}
                                                />
                                                {/*<Input*/}
                                                {/*  type={'text'}*/}
                                                {/*  ref={el => inputRef.current['phone'] = el}*/}
                                                {/*  name={'phone'}*/}
                                                {/*  value={master.phone}*/}
                                                {/*  onChange={(ev) => {*/}
                                                {/*    // console.log(ev.target.value)*/}
                                                {/*    setMaster({...master, phone: ev.target.value})*/}
                                                {/*  }}*/}
                                                {/*/>*/}
                                                <br />
                                                <label>Дата рождения</label>
                                                <br />
                                                <DatePicker
                                                    defaultDate={moment(
                                                        new Date(),
                                                        'YYYY-MM-DD'
                                                    )}
                                                    showTime={true}
                                                    format={'YYYY-MM-DD'}
                                                    name={'birthday'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'birthday'
                                                        ] = el)
                                                    }
                                                    onChange={(ev) => {
                                                        // console.log(ev.target.value)
                                                        setMaster({
                                                            ...master,
                                                            birthday:
                                                                ev.format(),
                                                        });
                                                    }}
                                                    autoComplete={'false'}
                                                />
                                                <br />
                                                <label>Подробнее</label>
                                                <TextArea
                                                    rows={10}
                                                    name={'description'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'description'
                                                        ] = el)
                                                    }
                                                    onChange={(ev) => {
                                                        setMaster({
                                                            ...master,
                                                            description:
                                                                ev.target.value,
                                                        });
                                                    }}
                                                    value={master.description}
                                                />
                                                <label>{'Наценка'}</label>
                                                <br />
                                                <Input
                                                    type={'number'}
                                                    name={'priceAspectRatio'}
                                                    placeholder={'Наценка'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'priceAspectRatio'
                                                        ] = el)
                                                    }
                                                    onChange={(ev) => {
                                                        setMaster({
                                                            ...master,
                                                            priceAspectRatio:
                                                                ev.target.value,
                                                        });
                                                    }}
                                                    step={1}
                                                    disabled={false}
                                                    min={0}
                                                    max={5}
                                                    value={
                                                        master.priceAspectRatio
                                                    }
                                                    autoComplete={'false'}
                                                    style={{ width: '100%' }}
                                                />
                                                <label>{'Рейтинг'}</label>
                                                <br />
                                                <Input
                                                    type={'number'}
                                                    name={'rating'}
                                                    placeholder={'рейтинг'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'rating'
                                                        ] = el)
                                                    }
                                                    onChange={(ev) => {
                                                        setMaster({
                                                            ...master,
                                                            rating: ev.target
                                                                .value,
                                                        });
                                                    }}
                                                    step={1}
                                                    disabled={false}
                                                    min={0}
                                                    max={5}
                                                    value={master.rating}
                                                    autoComplete={'false'}
                                                    style={{ width: '100%' }}
                                                />
                                                <label>{'Услуги'}</label>

                                                {/*<ServiceListComponent data={content.service} selected={master.service}/>*/}

                                                {content.service
                                                    ? content.service.map(
                                                          (item, key) => {
                                                              return (
                                                                  <Collapse>
                                                                      <Panel
                                                                          header={
                                                                              <Text>
                                                                                  {
                                                                                      item.name
                                                                                  }
                                                                              </Text>
                                                                          }
                                                                          key={
                                                                              key
                                                                          }
                                                                      >
                                                                          <Checkbox
                                                                              index={
                                                                                  item.id
                                                                              }
                                                                              onChange={(
                                                                                  ev
                                                                              ) =>
                                                                                  console.log(
                                                                                      ev
                                                                                          .target
                                                                                          .index
                                                                                  )
                                                                              }
                                                                              checked={
                                                                                  item.isChecked
                                                                              }
                                                                          >
                                                                              <Text
                                                                                  style={{
                                                                                      fontWeight:
                                                                                          'bold',
                                                                                  }}
                                                                              >
                                                                                  Выбрать
                                                                                  все
                                                                              </Text>
                                                                          </Checkbox>
                                                                          <ul
                                                                              style={{
                                                                                  flex: 1,
                                                                                  alignItems:
                                                                                      'center',
                                                                                  justifyContent:
                                                                                      'center',
                                                                                  marginTop: 10,
                                                                              }}
                                                                          >
                                                                              {item.children.map(
                                                                                  (
                                                                                      children,
                                                                                      k
                                                                                  ) => {
                                                                                      return (
                                                                                          <Checkbox
                                                                                              rootIndex={
                                                                                                  item.id
                                                                                              }
                                                                                              childrenIndex={
                                                                                                  children.id
                                                                                              }
                                                                                              key={
                                                                                                  k
                                                                                              }
                                                                                              onChange={(
                                                                                                  ev
                                                                                              ) => {
                                                                                                  let otherSelected =
                                                                                                      selectedService;
                                                                                                  content.service
                                                                                                      .filter(
                                                                                                          (
                                                                                                              item
                                                                                                          ) =>
                                                                                                              item.id ===
                                                                                                              ev
                                                                                                                  .target
                                                                                                                  .rootIndex
                                                                                                      )[0]
                                                                                                      .children.map(
                                                                                                          (
                                                                                                              item
                                                                                                          ) => {
                                                                                                              if (
                                                                                                                  item.id ===
                                                                                                                  ev
                                                                                                                      .target
                                                                                                                      .childrenIndex
                                                                                                              ) {
                                                                                                                  if (
                                                                                                                      item.isChecked
                                                                                                                  ) {
                                                                                                                      item.isChecked = false;
                                                                                                                      const index =
                                                                                                                          otherSelected.findIndex(
                                                                                                                              (
                                                                                                                                  item
                                                                                                                              ) =>
                                                                                                                                  (item.id =
                                                                                                                                      ev.target.childrenIndex)
                                                                                                                          );
                                                                                                                      otherSelected.splice(
                                                                                                                          index,
                                                                                                                          1
                                                                                                                      );
                                                                                                                  } else {
                                                                                                                      item.isChecked = true;

                                                                                                                      if (
                                                                                                                          otherSelected.filter(
                                                                                                                              (
                                                                                                                                  item
                                                                                                                              ) =>
                                                                                                                                  item.id ===
                                                                                                                                  ev
                                                                                                                                      .target
                                                                                                                                      .childrenIndex
                                                                                                                          )
                                                                                                                      ) {
                                                                                                                          //console.log(otherSelected.filter(item => item.id === ev.target.childrenIndex))
                                                                                                                          otherSelected.push(
                                                                                                                              {
                                                                                                                                  id: ev
                                                                                                                                      .target
                                                                                                                                      .childrenIndex,
                                                                                                                              }
                                                                                                                          );
                                                                                                                      }

                                                                                                                      // if(!master.service){
                                                                                                                      //   //setMaster({...master, service:[]})
                                                                                                                      // }else{
                                                                                                                      //   //master.service.push({id: ev.target.childrenIndex});
                                                                                                                      //   //setMaster({...master, service: master.service})
                                                                                                                      // }
                                                                                                                  }

                                                                                                                  //console.log(otherSelected)
                                                                                                              }
                                                                                                          }
                                                                                                      );
                                                                                                  setSelectedService(
                                                                                                      otherSelected
                                                                                                  );
                                                                                                  //master.service = otherSelected;

                                                                                                  console.log();
                                                                                                  setMaster(
                                                                                                      {
                                                                                                          ...master,
                                                                                                          service:
                                                                                                              otherSelected,
                                                                                                      }
                                                                                                  );
                                                                                              }}
                                                                                              defaultChecked={
                                                                                                  children.isChecked
                                                                                              }
                                                                                              ref={(
                                                                                                  el
                                                                                              ) =>
                                                                                                  (inputRef.current[
                                                                                                      'serviceTypeIds'
                                                                                                  ] =
                                                                                                      el)
                                                                                              }
                                                                                          >
                                                                                              <li>
                                                                                                  {
                                                                                                      children.name
                                                                                                  }
                                                                                              </li>
                                                                                          </Checkbox>
                                                                                      );
                                                                                  }
                                                                              )}
                                                                          </ul>
                                                                      </Panel>
                                                                  </Collapse>
                                                              );
                                                          }
                                                      )
                                                    : null}

                                                {/*<Select*/}
                                                {/*  onChange={(ev,index) => {*/}
                                                {/*    //console.log(ev)*/}
                                                {/*    // console.log(modalVisible.isEdit)*/}
                                                {/*    // if(modalVisible.isEdit){*/}
                                                {/*    //   // console.log()*/}
                                                {/*    //   content.service[0].children.map(item => {*/}
                                                {/*    //     console.log(item)*/}
                                                {/*    //     console.log(index)*/}
                                                {/*    //   })*/}
                                                {/*    // }else{*/}
                                                {/*    //   content.service[0].children.map(item => {*/}
                                                {/*    //     console.log(item)*/}
                                                {/*    //     console.log(index)*/}
                                                {/*    //   })*/}
                                                {/*    //   console.log(defaultSelect)*/}
                                                {/*    // }*/}
                                                {/*    //console.log(ev)*/}

                                                {/*    setMaster({...master, service: ev})*/}
                                                {/*  }}*/}
                                                {/*  defaultValue={defaultSelect}*/}
                                                {/*  style={{width: '100%'}}*/}
                                                {/*  mode="multiple"*/}
                                                {/*  placeholder="Выбрать"*/}
                                                {/*  ref={el => inputRef.current['serviceTypeIds'] = el}*/}
                                                {/*  // maxTagCount={2}*/}
                                                {/*  // autoComplete={"false"}*/}
                                                {/*  options={defaultSelectOptions}*/}
                                                {/*  optionLabelProp="label"*/}
                                                {/*  tagRender={(props => {*/}
                                                {/*    //console.log(props)*/}
                                                {/*    const onPreventMouseDown = event => {*/}
                                                {/*      event.preventDefault();*/}
                                                {/*      event.stopPropagation();*/}
                                                {/*    };*/}
                                                {/*    return(*/}
                                                {/*      <Tag*/}
                                                {/*        onMouseDown={onPreventMouseDown}*/}
                                                {/*        closable={props.closable}*/}
                                                {/*        onClose={props.onClose}*/}
                                                {/*        style={{ marginRight: 3 }}*/}
                                                {/*        // defaultValue={props.value}*/}
                                                {/*        // value={props.value}*/}
                                                {/*      >*/}
                                                {/*        <strong>{props.label}</strong>*/}
                                                {/*      </Tag>*/}
                                                {/*    )*/}
                                                {/*  })}*/}
                                                {/*>*/}
                                                {/*  {*/}
                                                {/*    (content.service)*/}
                                                {/*      ?*/}
                                                {/*      content.service.map((option, index) => {*/}
                                                {/*        if(option.children){*/}
                                                {/*          return (*/}
                                                {/*            option.children.map((subOption, index) => {*/}
                                                {/*              return (*/}
                                                {/*                <Select.Option value={subOption.id}*/}
                                                {/*                               key={subOption.id}*/}
                                                {/*                               disabled={false}*/}
                                                {/*                               label={subOption.name}*/}
                                                {/*                >*/}
                                                {/*                  <strong>{subOption.value} </strong></Select.Option>*/}
                                                {/*              )*/}
                                                {/*            })*/}
                                                {/*          )*/}
                                                {/*        } else {*/}
                                                {/*          return null*/}
                                                {/*        }*/}

                                                {/*      })*/}
                                                {/*      :*/}
                                                {/*      null*/}
                                                {/*  }*/}
                                                {/*</Select>*/}
                                                <label>{'Статус'}</label>
                                                <Select
                                                    onChange={(ev) => {
                                                        setMaster({
                                                            ...master,
                                                            status: ev,
                                                        });
                                                    }}
                                                    defaultValue={master.status}
                                                    style={{ width: '100%' }}
                                                    placeholder="Выбрать"
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'status'
                                                        ] = el)
                                                    }
                                                    autoComplete={'false'}
                                                >
                                                    <Select.Option
                                                        value={'Активен'}
                                                        key={1}
                                                    >
                                                        {'Активен'}
                                                    </Select.Option>
                                                    <Select.Option
                                                        value={'Отключен'}
                                                        key={2}
                                                    >
                                                        {'Отключен'}
                                                    </Select.Option>
                                                </Select>
                                            </Modal>
                                            <Table
                                                locale={{
                                                    emptyText: (
                                                        <span>Нет данных</span>
                                                    ),
                                                }}
                                                columns={masterColumnSchema}
                                                dataSource={content.masters}
                                                scroll={{ x: 1500, y: null }}
                                            />
                                        </TabPane>
                                        <TabPane tab="Журнал записей" key="4">
                                            <div style={{ marginBottom: 10 }}>
                                                <DatePicker
                                                    defaultValue={moment(
                                                        new Date(),
                                                        'YYYY-MM-DD'
                                                    )}
                                                    format={'YYYY-MM-DD'}
                                                    // disabledDate={current => {
                                                    //     return current < moment().add('-1', 'day');
                                                    // }}
                                                    onChange={(ev) =>
                                                        changeCurrentDate(ev)
                                                    }
                                                />
                                                <Button
                                                    type={'primary'}
                                                    style={{ marginLeft: 10 }}
                                                    onClick={() => {
                                                        setModalVisible({
                                                            schedule:
                                                                !modalVisible.schedule,
                                                        });
                                                    }}
                                                >
                                                    Добавить запись
                                                </Button>
                                            </div>

                                            <Modal
                                                visible={modalVisible.schedule}
                                                onOk={() => {
                                                    //setModalVisible({schedule: false})
                                                    saveSchedule(schedule);
                                                }}
                                                onCancel={() =>
                                                    setModalVisible({
                                                        schedule: false,
                                                    })
                                                }
                                                title={'Заявки'}
                                            >
                                                <br />
                                                <label>{'Дата и время'}</label>
                                                <br />
                                                <DatePicker
                                                    //defaultDate={moment(new Date(), 'YYYY-MM-DD HH:ss')}
                                                    showNow={false}
                                                    showTime={{
                                                        hourStep: 1,
                                                        minuteStep: 15,
                                                    }}
                                                    format={'YYYY-MM-DD HH:mm'}
                                                    name={'scheduleDatetime'}
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'scheduleDatetime'
                                                        ] = el)
                                                    }
                                                    onChange={(ev) => {
                                                        setSchedule({
                                                            ...schedule,
                                                            scheduleDatetime:
                                                                ev.format(),
                                                        });
                                                    }}

                                                    //required={true}
                                                    //autoComplete={"false"}
                                                />
                                                <br />
                                                <br />
                                                <label>
                                                    <Checkbox
                                                        onChange={(ev) => {
                                                            setIsNewClient(
                                                                !isNewClient
                                                            );
                                                            setSchedule({
                                                                ...schedule,
                                                                isNewClient:
                                                                    !isNewClient,
                                                            });
                                                        }}
                                                    >
                                                        Новый/Существующий
                                                        клиент
                                                    </Checkbox>
                                                </label>
                                                <br />
                                                {isNewClient ? (
                                                    <div>
                                                        <label>Имя</label>
                                                        <Input
                                                            type={'text'}
                                                            ref={(el) =>
                                                                (inputRef.current[
                                                                    'firstname'
                                                                ] = el)
                                                            }
                                                            name={'firstname'}
                                                            value={
                                                                schedule.firstname
                                                            }
                                                            onChange={(ev) => {
                                                                // console.log(ev.target.value)
                                                                setSchedule({
                                                                    ...schedule,
                                                                    firstname:
                                                                        ev
                                                                            .target
                                                                            .value,
                                                                });
                                                            }}
                                                        />
                                                        <label>Фамилия</label>
                                                        <Input
                                                            type={'text'}
                                                            ref={(el) =>
                                                                (inputRef.current[
                                                                    'lastname'
                                                                ] = el)
                                                            }
                                                            name={'lastname'}
                                                            value={
                                                                schedule.lastname
                                                            }
                                                            onChange={(ev) => {
                                                                // console.log(ev.target.value)
                                                                setSchedule({
                                                                    ...schedule,
                                                                    lastname:
                                                                        ev
                                                                            .target
                                                                            .value,
                                                                });
                                                            }}
                                                        />
                                                        <label>Телефон</label>
                                                        <MaskedInput
                                                            mask={
                                                                '7(111)111-11-11'
                                                            }
                                                            name={'phone'}
                                                            value={
                                                                schedule.phone
                                                            }
                                                            type={'phone'}
                                                            ref={(el) =>
                                                                (inputRef.current[
                                                                    'phone'
                                                                ] = el)
                                                            }
                                                            onChange={(ev) => {
                                                                // console.log(ev.target.value)
                                                                setSchedule({
                                                                    ...schedule,
                                                                    phone: ev
                                                                        .target
                                                                        .value,
                                                                });
                                                            }}
                                                        />
                                                        {/*<Input*/}
                                                        {/*  type={'phone'}*/}
                                                        {/*  ref={el => inputRef.current['phone'] = el}*/}
                                                        {/*  name={'phone'}*/}
                                                        {/*  value={schedule.phone}*/}
                                                        {/*  onChange={(ev) => {*/}
                                                        {/*    // console.log(ev.target.value)*/}
                                                        {/*    setSchedule({...schedule, phone: ev.target.value})*/}
                                                        {/*  }}*/}
                                                        {/*/>*/}
                                                    </div>
                                                ) : (
                                                    <Select
                                                        onChange={(ev) => {
                                                            setSchedule({
                                                                ...schedule,
                                                                clientId: ev,
                                                            });
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        //defaultValue={item.options[0]}
                                                        placeholder="Выбрать"
                                                        ref={(el) =>
                                                            (inputRef.current[
                                                                'clientId'
                                                            ] = el)
                                                        }
                                                        //required={item.required}
                                                        autoComplete={'false'}
                                                    >
                                                        {content.clients
                                                            ? content.clients.map(
                                                                  (
                                                                      option,
                                                                      index
                                                                  ) => {
                                                                      return (
                                                                          <Select.Option
                                                                              value={
                                                                                  option.id
                                                                              }
                                                                              key={
                                                                                  index
                                                                              }
                                                                          >
                                                                              {option.firstname +
                                                                                  ' ' +
                                                                                  option.lastname +
                                                                                  ' (' +
                                                                                  option.email +
                                                                                  ')'}
                                                                          </Select.Option>
                                                                      );
                                                                  }
                                                              )
                                                            : null}
                                                    </Select>
                                                )}

                                                <label>{'Мастер'}</label>
                                                <Select
                                                    onChange={(ev) => {
                                                        setSchedule({
                                                            ...schedule,
                                                            masterId: ev,
                                                        });
                                                        // TODO
                                                        getMasterServiceData(
                                                            ev
                                                        );
                                                    }}
                                                    style={{ width: '100%' }}
                                                    //defaultValue={item.options[0]}
                                                    placeholder="Выбрать"
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'masterId'
                                                        ] = el)
                                                    }
                                                    //required={item.required}
                                                    autoComplete={'false'}
                                                >
                                                    {content.masters
                                                        ? content.masters.map(
                                                              (
                                                                  option,
                                                                  index
                                                              ) => {
                                                                  return (
                                                                      <Select.Option
                                                                          value={
                                                                              option.id
                                                                          }
                                                                          key={
                                                                              index
                                                                          }
                                                                      >
                                                                          {
                                                                              option.name
                                                                          }
                                                                      </Select.Option>
                                                                  );
                                                              }
                                                          )
                                                        : null}
                                                </Select>
                                                <label>{'Услуги'}</label>
                                                <Select
                                                    onChange={(ev) => {
                                                        setSchedule({
                                                            ...schedule,
                                                            serviceIds: ev,
                                                        });
                                                    }}
                                                    style={{ width: '100%' }}
                                                    mode="multiple"
                                                    placeholder="Выбрать"
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'serviceIds'
                                                        ] = el)
                                                    }
                                                    maxTagCount={2}
                                                    autoComplete={'false'}
                                                >
                                                    {masterServiceList
                                                        ? masterServiceList.map(
                                                              (
                                                                  options,
                                                                  index
                                                              ) => {
                                                                  //console.log(options)
                                                                  return (
                                                                      <Select.Option
                                                                          value={
                                                                              options.id
                                                                          }
                                                                          key={
                                                                              options.id
                                                                          }
                                                                          disabled={
                                                                              false
                                                                          }
                                                                      >
                                                                          <strong>
                                                                              {
                                                                                  options.name
                                                                              }{' '}
                                                                          </strong>
                                                                      </Select.Option>
                                                                  );
                                                              }
                                                          )
                                                        : // content.service.map((option, index) => {
                                                          //   if(option.children){
                                                          //     return (
                                                          //       option.children.map((subOption, index) => {
                                                          //         return (
                                                          //           <Select.Option value={subOption.id}
                                                          //                          key={subOption.id}
                                                          //                          disabled={false}>
                                                          //             <strong>{subOption.name} </strong></Select.Option>
                                                          //         )
                                                          //       })
                                                          //     )
                                                          //   } else {
                                                          //     return null
                                                          //   }
                                                          // })
                                                          null}
                                                </Select>
                                                <label>{'Статус'}</label>
                                                <Select
                                                    onChange={(ev) => {
                                                        setSchedule({
                                                            ...schedule,
                                                            status: ev,
                                                        });
                                                    }}
                                                    style={{ width: '100%' }}
                                                    placeholder="Выбрать"
                                                    ref={(el) =>
                                                        (inputRef.current[
                                                            'status'
                                                        ] = el)
                                                    }
                                                    autoComplete={'false'}
                                                >
                                                    <Select.Option
                                                        value={'Новый'}
                                                        key={1}
                                                    >
                                                        {'Новый'}
                                                    </Select.Option>
                                                    <Select.Option
                                                        value={'В работе'}
                                                        key={2}
                                                    >
                                                        {'В работе'}
                                                    </Select.Option>
                                                    <Select.Option
                                                        value={'Завершен'}
                                                        key={3}
                                                    >
                                                        {'Завершен'}
                                                    </Select.Option>
                                                </Select>
                                            </Modal>

                                            {/*<FormComponent*/}
                                            {/*  columtSchema={scheduleColumnSchema}*/}
                                            {/*  inputRef={inputRef}*/}
                                            {/*  serviceList={content.service}*/}
                                            {/*  additionalData={content.additionalData}*/}
                                            {/*  handler={handler}*/}
                                            {/*  formData={content.formData}*/}
                                            {/*  url={'schedules'}*/}
                                            {/*  masters={content.masters}*/}
                                            {/*  clients={content.clients}*/}
                                            {/*/>*/}

                                            <Table
                                                locale={{
                                                    emptyText: (
                                                        <span>Нет данных</span>
                                                    ),
                                                }}
                                                columns={scheduleColumnSchema}
                                                dataSource={content.schedule}
                                                style={{ width: '100%' }}
                                            />

                                            <Modal
                                                visible={
                                                    content.scheduleDetailView
                                                }
                                                onOk={() => {
                                                    //setModalVisible({schedule: false})
                                                    //saveSchedule(schedule);
                                                    //setModalVisible({scheduleDetail: false});
                                                    dispatch({
                                                        type: 'SCHEDULE-VIEW-DETAIL',
                                                        scheduleDetailView: false,
                                                    });
                                                }}
                                                onCancel={() => {
                                                    dispatch({
                                                        type: 'SCHEDULE-VIEW-DETAIL',
                                                        scheduleDetailView: false,
                                                    });
                                                }}
                                                title={'Заявки'}
                                            >
                                                {content.scheduleDetail &&
                                                content.scheduleDetail.service
                                                    .length > 0 ? (
                                                    <>
                                                        <p>
                                                            ФИО:{' '}
                                                            {
                                                                content
                                                                    .scheduleDetail
                                                                    .client
                                                                    .firstname
                                                            }{' '}
                                                            {
                                                                content
                                                                    .scheduleDetail
                                                                    .client
                                                                    .patronymic
                                                            }{' '}
                                                            {
                                                                content
                                                                    .scheduleDetail
                                                                    .client
                                                                    .lastname
                                                            }{' '}
                                                            / рейтинг 3{' '}
                                                        </p>
                                                        <p>
                                                            Телефон:{' '}
                                                            {
                                                                content
                                                                    .scheduleDetail
                                                                    .client
                                                                    .phone
                                                            }
                                                        </p>
                                                        <p>
                                                            Мастер:{' '}
                                                            {
                                                                content
                                                                    .scheduleDetail
                                                                    .master.name
                                                            }{' '}
                                                            / рейтинг 3{' '}
                                                        </p>
                                                        <p>
                                                            Услуги:{' '}
                                                            {content.scheduleDetail.service
                                                                .map(
                                                                    (elem) =>
                                                                        elem.name
                                                                )
                                                                .join(', ')}
                                                        </p>
                                                        <p>
                                                            Стоимость услуг:{' '}
                                                            {
                                                                content
                                                                    .scheduleDetail
                                                                    .sumServicePrice
                                                            }{' '}
                                                            руб.
                                                        </p>
                                                        <p>
                                                            Продолжительность:{' '}
                                                            {
                                                                content
                                                                    .scheduleDetail
                                                                    .sumServiceTiming
                                                            }{' '}
                                                            мин.
                                                        </p>
                                                        <p>
                                                            Стутс заявки:{' '}
                                                            {
                                                                content
                                                                    .scheduleDetail
                                                                    .status
                                                            }
                                                        </p>
                                                        {/*<Select*/}
                                                        {/*  // onChange={(ev) => {*/}
                                                        {/*  //   setSchedule({...schedule, status: ev})*/}
                                                        {/*  // }}*/}
                                                        {/*  style={{width: '100%'}}*/}
                                                        {/*  placeholder="Новый статус"*/}
                                                        {/*  ref={el => inputRef.current['status'] = el}*/}
                                                        {/*  autoComplete={"false"}*/}
                                                        {/*>*/}
                                                        {/*  <Select.Option value={'Новый'}*/}
                                                        {/*                 key={1}>{'Новый'}</Select.Option>*/}
                                                        {/*  <Select.Option value={'В работе'}*/}
                                                        {/*                 key={2}>{'В работе'}</Select.Option>*/}
                                                        {/*  <Select.Option value={'Завершен'}*/}
                                                        {/*                 key={3}>{'Завершен'}</Select.Option>*/}
                                                        {/*</Select>*/}

                                                        {/*<br/>*/}
                                                        {/*<br/>*/}
                                                        {/*<DatePicker*/}
                                                        {/*  placeholder={'Передвинуть время'}*/}
                                                        {/*  style={{width: '100%'}}*/}
                                                        {/*  //defaultDate={moment(new Date(), 'YYYY-MM-DD HH:ss')}*/}
                                                        {/*  showNow={false}*/}
                                                        {/*  showTime={{*/}
                                                        {/*    hourStep: 1,*/}
                                                        {/*    minuteStep: 15,*/}
                                                        {/*  }}*/}
                                                        {/*  format={'YYYY-MM-DD HH:mm'}*/}
                                                        {/*  name={'scheduleDatetime'}*/}
                                                        {/*  ref={el => inputRef.current['scheduleDatetime'] = el}*/}
                                                        {/*  onChange={(ev) => {*/}
                                                        {/*    // setSchedule({*/}
                                                        {/*    //   ...schedule,*/}
                                                        {/*    //   scheduleDatetime: ev.format()*/}
                                                        {/*    // })*/}
                                                        {/*  }}*/}

                                                        {/*  disabledDate={current => {*/}
                                                        {/*      return current < moment().add('-1', 'day');*/}
                                                        {/*  }}*/}
                                                        {/*  disableTime={current => {*/}
                                                        {/*    return current < moment().add('-1', 'hour')*/}
                                                        {/*  }}*/}
                                                        {/*  //required={true}*/}
                                                        {/*  //autoComplete={"false"}*/}
                                                        {/*/>*/}
                                                    </>
                                                ) : null}
                                            </Modal>
                                            <ScheduleComponent
                                                time={time}
                                                masters={content.masters}
                                                schedule={content.schedule}
                                            />
                                        </TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            ) : null}

            {content.isFormShow ? (
                <Card>
                    <Row>
                        <Col span={10}>
                            <FormComponentWithMap
                                columtSchema={columnSchema}
                                inputRef={inputRef}
                                additionalData={content}
                                handler={handler}
                                formData={content.formData}
                                url={'salon/'}
                            />
                        </Col>
                        <Col span={14} style={{ paddingLeft: '10px' }}>
                            <YMaps>
                                <Map
                                    defaultState={{
                                        center: map.mapCenter,
                                        zoom: map.zoom,
                                    }}
                                    width={'100%'}
                                    height={'95vh'}
                                    modules={['geocode']}
                                    onLoad={(ymaps) => {
                                        // console.log()
                                        // ymaps.geocode('москва').then(result => {
                                        //   console.log(result)
                                        // }).catch(er => {
                                        //   console.log(er)
                                        // })
                                    }}
                                >
                                    <Placemark
                                        geometry={map.placemarkCoords}
                                        options={{ draggable: true }}
                                        onDragEnd={(ev) => {
                                            const coords = ev
                                                .get('target')
                                                .geometry.getCoordinates();
                                            console.log(coords);
                                            geocode({
                                                lat: coords[0],
                                                lon: coords[1],
                                            });
                                            // console.log(content);
                                            dispatch({
                                                type: 'FORM-DATA',
                                                formDData: {
                                                    ...content.formData,
                                                    longitude: coords[1],
                                                    latitude: coords[0],
                                                },
                                            });

                                            // setFormData({
                                            //     ...formData,
                                            //     longitude: coords[1],
                                            //     latitude: coords[0]
                                            // })
                                        }}
                                    />
                                </Map>
                            </YMaps>
                        </Col>
                    </Row>
                </Card>
            ) : null}

            <Card>
                <Table
                    locale={{ emptyText: <span>Нет данных</span> }}
                    columns={content.tableColumn}
                    dataSource={content.tableSource.salons}
                    scroll={{ x: 1200, y: null }}
                    rowKey={'id'}
                />
            </Card>
        </Content>
    );
}
