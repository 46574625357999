import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Button,
    DatePicker,
    Form,
    FormInstance,
    Input,
    message,
    Select,
    SelectProps,
    Space,
    Switch,
    TimePicker,
    Typography,
} from 'antd';
import moment, { Moment } from 'moment';
import { IEmployer, ISalon, IService } from '../Types/SalonTypes.ts';
import { IEventStatus } from '../Types/ScheduleTypes.ts';
import { SalonScheduleAPI } from '../api/schedule/index.ts';
import { IModalPrevState } from '../Components/Cabinet/Salon/SalonSheduleTab.tsx';
import { CommonAPI } from '../api/common/common.ts';
import { CommonSalonsAPI } from '../api/common/salons.ts';
import { useLoadAvailableClients } from '../hooks/use-load-available-clients.ts';

const FormItem = Form.Item;

const { RangePicker } = DatePicker;

export enum ScheduleType {
    day_off = 'day_off',
    schedule = 'schedule',
    time_off = 'time_off',
}

export type IScheduleForm =
    | {
          type: ScheduleType.day_off;
          dateOffRange: [Moment, Moment];
          employerOff: string;
      }
    | {
          type: ScheduleType.time_off;
          dateOffRange: [Moment, Moment];
          statusDescription: string;
          employerOff: string;
      }
    | {
          type: ScheduleType.schedule;
          services: number[];
          employer: string;
          client: string;
          dateStart: Moment;
          timeStart: Moment;
          isAutoDetectDateEnd: true;
      }
    | {
          type: ScheduleType.schedule;
          services: number[];
          employer: string;
          client: string;
          dateStart: Moment;
          timeStart: Moment;
          timeEnd: Moment;
          isAutoDetectDateEnd: false;
      };

export interface IScheduleToSend {
    status: IEventStatus;
    dateEnd: string;
    dateStart: string;
    clientId?: string;
    employerId: string;
    salonId: string | number;
    serviceIds: number[];
    statusDescription?: string;
}

export type IScheduleToEdit = Partial<IScheduleToSend>;

interface Props {
    form: FormInstance<IScheduleForm>;
    salonData: ISalon;
    handleCloseModal: () => void;
    setLoading: Dispatch<SetStateAction<boolean>>;
    handleSetNeedLoad: () => void;
    mode?: 'salon' | 'client';
    clientId?: string;
    startTime?: [number, number];
    endTime?: [number, number];
    modalPrevState?: IModalPrevState;
    clearPrevState?: () => void;
}

export const DISABLED_MASTER_STATUS = 'Выходной мастера' as const;

export const AddScheduleModalForm: FC<Props> = ({
    form,
    salonData,
    setLoading,
    handleCloseModal,
    mode,
    handleSetNeedLoad,
    clientId,
    startTime,
    endTime,
    modalPrevState,
    clearPrevState,
}) => {
    const [clientOptions, setClientOptions] = useState<SelectProps['options']>(
        salonData.clients
            ?.filter((el) => Boolean(el.phone))
            .map((el) => ({
                label: el.phone,
                value: el.phone,
            })) ?? []
    );

    const {
        clientsAvailable,
        loaded: clientsPending,
        handleLoadClients,
    } = useLoadAvailableClients(salonData.clients.map((el) => el.id));

    useEffect(() => {
        setClientOptions((prev) => {
            const result: SelectProps['options'] = [];

            result.push(
                ...prev.filter(
                    (el) =>
                        !salonData.clients.find((cEl) => cEl.phone === el.value)
                )
            );

            result.push(
                ...salonData.clients.map((el) => ({
                    label: el.phone,
                    value: el.phone,
                }))
            );

            return result;
        });
    }, [salonData]);

    const [createClientLoading, setCreateClientLoading] = useState(false);

    const selectServicesData: Array<{
        label: string;
        options: Array<{
            value: number;
            label: string;
        }>;
    }> = useMemo(() => {
        const services = salonData.employers.reduce((res, acc) => {
            return [...res, ...acc.services].sort((a, b) => a.id - b.id);
        }, [] as IService[]);

        const servByCats = services.reduce((res, acc) => {
            if (res[acc.service.parent.id]) {
                res = {
                    ...res,
                    [acc.service.parent.id]: {
                        ...res[acc.service.parent.id],
                        options: [
                            ...res[acc.service.parent.id].options.filter(
                                (el) => el.value !== acc.id
                            ),
                            {
                                value: acc.id,
                                label: acc.service.title,
                            },
                        ],
                    },
                };
            } else {
                res = {
                    ...res,
                    [acc.service.parent.id]: {
                        label: acc.service.parent.title,
                        options: [
                            {
                                value: acc.id,
                                label: acc.service.title,
                            },
                        ],
                    },
                };
            }

            return res;
        }, {});

        return Object.values(servByCats);
    }, [salonData]);

    const handleSendForm = useCallback(
        async (data: IScheduleToSend) => {
            const existTimeSlotId = modalPrevState?.existTimeSlotId;
            try {
                setLoading(true);
                if (existTimeSlotId !== undefined) {
                    await SalonScheduleAPI.editSchedule(data, existTimeSlotId);
                } else {
                    await SalonScheduleAPI.addSchedule(data);
                }
                if (mode === 'client') {
                    message.success(
                        existTimeSlotId
                            ? 'Запись изменена'
                            : 'Вы успешно записались'
                    );
                } else {
                    message.success(
                        existTimeSlotId
                            ? 'Запись изменена'
                            : 'Форма успешно отправлена'
                    );
                }
                handleCloseModal();
                setLoading(() => false);
                handleSetNeedLoad();
                clearPrevState();
            } catch (e) {
                setLoading(() => false);
                if (e?.response?.data?.message) {
                    message.error(
                        'Ошибка при отправке формы: ' +
                            e?.response?.data?.message
                    );
                } else {
                    message.error(
                        'Ошибка при отправке формы. Попробуйте позже'
                    );
                }
            }
        },
        [mode, modalPrevState]
    );

    const [searchValue, setSearchValue] = useState<string | undefined>(
        undefined
    );

    const addClientToSalon: (
        clientId: string,
        salonData: ISalon
    ) => Promise<void> = useCallback(
        async (clientId) => {
            const loadingAddClient = message.loading(
                'Добавляем нового клиента в салон',
                0
            );

            try {
                const clientsList = salonData.clients.map((el) => el.id);

                await CommonSalonsAPI.addClient(
                    [...clientsList.filter((el) => el !== clientId), clientId],
                    salonData.id
                );

                message.success(
                    'Клиент добавлен, обновляем данные салона, ожидайте появления клиента в списке клиентов.'
                );
            } catch (e) {
                message.error(
                    'Не удалось добавить нового клиента в салон, попробуйте позже'
                );
                throw new Error(e);
            } finally {
                loadingAddClient();
            }
        },
        [salonData]
    );

    const handleCreateNewClient: (
        clientPhoneNumber: string
    ) => Promise<string> = useCallback(
        async (phone: string) => {
            if (!phone) {
                return;
            }

            const clientInSystem = clientsAvailable.find(
                (el) => el.phone === phone
            );

            if (clientInSystem) {
                try {
                    await addClientToSalon(clientInSystem.id, salonData);
                    setClientOptions((prev) => {
                        if (
                            prev.find((el) => el.value === clientInSystem.phone)
                        ) {
                            return prev;
                        } else {
                            return [
                                ...prev,
                                {
                                    label: clientInSystem.phone,
                                    value: clientInSystem.phone,
                                },
                            ];
                        }
                    });
                    return clientInSystem.id;
                } catch (e) {
                    throw new Error('Не удалось привязать клиента в салон');
                }
            } else {
                const loading = message.loading(
                    'Регистрируем нового клиента',
                    0
                );
                setCreateClientLoading(true);

                try {
                    await CommonAPI.createClient(phone);

                    loading();
                    message.success('Клиент успешно зарегистрирован');

                    const getClientsLoading = message.loading(
                        'Получаем список всех клиентов',
                        0
                    );
                    const newAvailableClients = await handleLoadClients();
                    getClientsLoading();

                    const createdClient = newAvailableClients.find(
                        (el) => el.phone === phone
                    );

                    if (!createdClient) {
                        message.error(
                            'Зарегистрированный клиент не найден в общем списке клиентов. Попробуйте добавить запись через пару минут.'
                        );
                        throw new Error(
                            'Зарегистрированный клиент не найден в общем списке клиентов.'
                        );
                    }

                    await addClientToSalon(createdClient.id, salonData);

                    setClientOptions((prev) => {
                        if (
                            prev.find((el) => el.value === createdClient.phone)
                        ) {
                            return prev;
                        } else {
                            return [
                                ...prev,
                                {
                                    label: createdClient.phone,
                                    value: createdClient.phone,
                                },
                            ];
                        }
                    });

                    return createdClient.id;
                } catch (e) {
                    console.error(e);
                } finally {
                    setCreateClientLoading(false);
                }
            }
        },
        [clientsAvailable, handleLoadClients, salonData]
    );

    const handleFinishForm = useCallback(
        async (values: IScheduleForm) => {
            let dataToSubmit: IScheduleToSend = {
                status: modalPrevState?.status ?? IEventStatus.PLANNED,
                dateEnd: '',
                dateStart: '',
                clientId: undefined,
                employerId: '',
                salonId: salonData.id,
                serviceIds: [],
                statusDescription: undefined,
            };

            if (mode === 'client') {
                let values2: any = values;

                let minutesNeedToServices = 0;

                const services = salonData.employers.reduce((res, acc) => {
                    return [...res, ...acc.services];
                }, [] as IService[]);

                values2.services.forEach((service) => {
                    const serviceInSalon = services.find(
                        (el) => el.id === service
                    );

                    if (serviceInSalon) {
                        minutesNeedToServices += serviceInSalon.serviceTiming;
                    }
                });

                dataToSubmit = {
                    status: IEventStatus.PLANNED,
                    dateEnd: moment(values2.dateStart)
                        .startOf('minute')
                        .add(minutesNeedToServices, 'minutes')
                        .toISOString(),
                    dateStart: moment(values2.dateStart)
                        .startOf('minute')
                        .toISOString(),
                    clientId: clientId,
                    employerId: values2.employer,
                    salonId: salonData.id,
                    serviceIds: values2.services,
                    statusDescription: '',
                };
            } else {
                switch (values.type) {
                    case ScheduleType.day_off:
                        dataToSubmit = {
                            status:
                                modalPrevState?.status ??
                                IEventStatus.DISABLED_MASTER,
                            dateEnd: values.dateOffRange[1]
                                .set({
                                    hours: endTime[0],
                                    minutes: endTime[1],
                                })
                                .toISOString(),
                            dateStart: values.dateOffRange[0]
                                .set({
                                    hours: startTime[0],
                                    minutes: startTime[1],
                                })
                                .toISOString(),
                            clientId: undefined,
                            employerId: values.employerOff,
                            salonId: salonData.id,
                            serviceIds: [],
                            statusDescription: DISABLED_MASTER_STATUS,
                        };
                        break;
                    case ScheduleType.time_off:
                        dataToSubmit = {
                            status:
                                modalPrevState?.status ??
                                IEventStatus.DISABLED_MASTER,
                            dateEnd: values.dateOffRange[1].toISOString(),
                            dateStart: values.dateOffRange[0].toISOString(),
                            clientId: undefined,
                            employerId: values.employerOff,
                            salonId: salonData.id,
                            serviceIds: [],
                            statusDescription: values.statusDescription,
                        };
                        break;
                    case ScheduleType.schedule:
                        let dateEnd: Moment;

                        if (values.isAutoDetectDateEnd === true) {
                            let minutesNeedToServices = 0;

                            const services = salonData.employers.reduce(
                                (res, acc) => {
                                    return [...res, ...acc.services];
                                },
                                [] as IService[]
                            );

                            values.services.forEach((service) => {
                                const serviceInSalon = services.find(
                                    (el) => el.id === service
                                );

                                if (serviceInSalon) {
                                    minutesNeedToServices +=
                                        serviceInSalon.serviceTiming;
                                }
                            });

                            dateEnd = moment(values.dateStart)
                                .set({
                                    hours: values.timeStart.hours(),
                                    minutes: values.timeStart.minutes(),
                                })
                                .startOf('minute')
                                .add(minutesNeedToServices, 'minutes');
                        } else {
                            dateEnd = moment(values.dateStart)
                                .set({
                                    hours: values.timeEnd.hours(),
                                    minutes: values.timeEnd.minutes(),
                                })
                                .startOf('minute');
                        }

                        let clientId = '';

                        const selectedClient = salonData.clients.find(
                            (el) => el.phone === values.client
                        );

                        if (selectedClient) {
                            clientId = selectedClient.id;
                        } else {
                            clientId = await handleCreateNewClient(
                                values.client
                            );
                        }

                        dataToSubmit = {
                            status:
                                modalPrevState?.status ?? IEventStatus.PLANNED,
                            dateEnd: dateEnd.toISOString(),
                            dateStart: moment(values.dateStart)
                                .set({
                                    hours: values.timeStart.hours(),
                                    minutes: values.timeStart.minutes(),
                                })
                                .startOf('minute')
                                .toISOString(),
                            clientId: clientId,
                            employerId: values.employer,
                            salonId: salonData.id,
                            serviceIds: values.services,
                            statusDescription: '',
                        };
                        break;
                }
            }

            void handleSendForm(dataToSubmit);
        },
        [salonData, mode, clientId, modalPrevState, handleCreateNewClient]
    );

    const disabledHours = useMemo(() => {
        let res = [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19, 20, 21, 22, 23,
        ];

        if (!startTime || !endTime) {
            return res;
        }

        for (let i = startTime[0]; i < endTime[0]; i++) {
            res = res.filter((e) => e !== i);
        }

        return res;
    }, [startTime, endTime]);

    const ServicesFieldFormItem = useMemo(
        () => (
            <FormItem
                shouldUpdate={(prevValues, curValues) =>
                    curValues.type !== prevValues.type ||
                    curValues.employer !== prevValues.employer
                }
                noStyle
            >
                {({ getFieldValue }) => {
                    const selectedEmployer = getFieldValue('employer');
                    let options = selectServicesData;

                    if (selectedEmployer) {
                        const selectedEmployerServices =
                            salonData.employers.find(
                                (el) => el.id === selectedEmployer
                            )?.services;

                        if (selectedEmployerServices) {
                            options = selectServicesData.filter((el) =>
                                selectedEmployerServices.find((fEl) =>
                                    el.options.some((el) => el.value === fEl.id)
                                )
                            );
                        }
                    }

                    return getFieldValue('type') === ScheduleType.schedule ? (
                        <FormItem
                            name={'services'}
                            label={'Услуги'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле обязательно для заполнения',
                                },
                            ]}
                        >
                            <Select
                                placeholder={'Выберите'}
                                showSearch
                                allowClear
                                mode={'multiple'}
                                filterOption={(val, opt) => {
                                    const label = (
                                        opt.label as string
                                    ).toLowerCase();
                                    const value = val.toLowerCase();

                                    return (
                                        label.includes(value) ||
                                        !!opt.options?.find((el) =>
                                            el.label
                                                .toLowerCase()
                                                .includes(value)
                                        )
                                    );
                                }}
                                options={options}
                            />
                        </FormItem>
                    ) : null;
                }}
            </FormItem>
        ),
        [selectServicesData, salonData]
    );

    const MasterFieldFormItem = useMemo(
        () => (
            <FormItem
                shouldUpdate={(prevValues, curValues) =>
                    curValues.type !== prevValues.type ||
                    curValues.services !== prevValues.services
                }
                noStyle
            >
                {({ getFieldValue }) => {
                    const selectedServices: undefined | number[] =
                        getFieldValue('services');

                    const availableMasters =
                        selectedServices && selectedServices.length > 0
                            ? salonData.employers.reduce((res, acc) => {
                                  if (
                                      acc.services.find(
                                          (el) =>
                                              selectedServices.indexOf(el.id) >
                                              -1
                                      )
                                  ) {
                                      return [...res, acc];
                                  } else return res;
                              }, [] as IEmployer[])
                            : [...salonData.employers];

                    return getFieldValue('type') === ScheduleType.schedule ? (
                        <FormItem
                            name={'employer'}
                            label={'Мастер'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле обязательно для заполнения',
                                },
                                () => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            !!availableMasters.find(
                                                (el) => el.id === value
                                            )
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                'Выбранный мастер не предоставляет выбранных услуг'
                                            )
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                filterOption={(val, opt) =>
                                    opt.label.includes(val)
                                }
                                placeholder={'Выберите'}
                                options={availableMasters.map((el) => ({
                                    label: `${el.master.firstName} ${el.master.lastName}`,
                                    value: el.id,
                                }))}
                            />
                        </FormItem>
                    ) : null;
                }}
            </FormItem>
        ),
        [salonData]
    );

    return (
        <Form<IScheduleForm>
            form={form}
            initialValues={{
                type: 'schedule',
                isAutoDetectDateEnd: mode !== 'client' ? true : undefined,
            }}
            layout={'vertical'}
            onFinish={handleFinishForm}
        >
            {mode !== 'client' && (
                <FormItem
                    name={'type'}
                    rules={[
                        {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                        },
                    ]}
                    label={'Тип записи'}
                >
                    <Select
                        placeholder={'Выберите'}
                        options={[
                            {
                                value: 'day_off',
                                label: 'Выходной мастера',
                            },
                            {
                                value: 'time_off',
                                label: 'Отсутствие мастера',
                            },
                            {
                                value: 'schedule',
                                label: 'Запись клиента',
                            },
                        ]}
                    />
                </FormItem>
            )}
            {mode !== 'client' && (
                <FormItem
                    shouldUpdate={(prevValues, curValues) =>
                        curValues.type !== prevValues.type
                    }
                    noStyle
                >
                    {({ getFieldValue }) => {
                        return getFieldValue('type') ===
                            ScheduleType.time_off ? (
                            <FormItem
                                label={'Причина отсутствия'}
                                name={'statusDescription'}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Поле обязательно для заполнения',
                                    },
                                ]}
                            >
                                <Input />
                            </FormItem>
                        ) : null;
                    }}
                </FormItem>
            )}
            {mode !== 'client' && (
                <FormItem
                    shouldUpdate={(prevValues, curValues) =>
                        curValues.type !== prevValues.type
                    }
                    noStyle
                >
                    {({ getFieldValue }) => {
                        return getFieldValue('type') ===
                            ScheduleType.time_off ||
                            getFieldValue('type') === ScheduleType.day_off ? (
                            <FormItem
                                label={'Период отсутствия'}
                                name={'dateOffRange'}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Поле обязательно для заполнения',
                                    },
                                ]}
                            >
                                <RangePicker
                                    format={
                                        getFieldValue('type') ===
                                        ScheduleType.day_off
                                            ? 'DD.MM.YYYY'
                                            : 'DD.MM.YYYY HH:mm'
                                    }
                                    showTime={
                                        !(
                                            getFieldValue('type') ===
                                            ScheduleType.day_off
                                        )
                                    }
                                    hideDisabledOptions
                                    showSecond={false}
                                    disabledHours={() => disabledHours}
                                    minuteStep={5}
                                />
                            </FormItem>
                        ) : null;
                    }}
                </FormItem>
            )}
            <FormItem
                shouldUpdate={(prevValues, curValues) =>
                    curValues.type !== prevValues.type ||
                    curValues.services !== prevValues.services
                }
                noStyle
            >
                {({ getFieldValue }) => {
                    return getFieldValue('type') === ScheduleType.day_off ||
                        getFieldValue('type') === ScheduleType.time_off ? (
                        <FormItem
                            name={'employerOff'}
                            label={'Мастер'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Поле обязательно для заполнения',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                filterOption={(val, opt) =>
                                    opt.label.includes(val)
                                }
                                placeholder={'Выберите'}
                                options={salonData.employers.map((el) => ({
                                    label: `${el.master.firstName} ${el.master.lastName}`,
                                    value: el.id,
                                }))}
                            />
                        </FormItem>
                    ) : null;
                }}
            </FormItem>
            {mode !== 'client' && (
                <FormItem
                    shouldUpdate={(prevValues, curValues) =>
                        curValues.type !== prevValues.type
                    }
                    noStyle
                >
                    {({ getFieldValue }) => {
                        return getFieldValue('type') ===
                            ScheduleType.schedule ? (
                            <FormItem
                                name={'client'}
                                label={'Клиент'}
                                tooltip="Введите номер телефона клиента в формате 7XXXXXXXXXX"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Поле обязательно для заполнения',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="79123456789"
                                    showSearch
                                    searchValue={searchValue}
                                    onSearch={setSearchValue}
                                    optionFilterProp="value"
                                    loading={clientsPending}
                                    notFoundContent={
                                        <Space align="center">
                                            <Typography.Paragraph
                                                style={{
                                                    color: '#ffffff',
                                                }}
                                            >
                                                Не найдено клиентов с таким
                                                номером
                                            </Typography.Paragraph>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    void handleCreateNewClient(
                                                        searchValue
                                                    );
                                                }}
                                                loading={createClientLoading}
                                            >
                                                Создать нового клиента
                                            </Button>
                                        </Space>
                                    }
                                    options={clientOptions.filter((el) =>
                                        Boolean(el.label)
                                    )}
                                />
                            </FormItem>
                        ) : null;
                    }}
                </FormItem>
            )}
            {mode !== 'client' || !!modalPrevState?.employer ? (
                <>
                    {MasterFieldFormItem}
                    {ServicesFieldFormItem}
                </>
            ) : (
                <>
                    {ServicesFieldFormItem}
                    {MasterFieldFormItem}
                </>
            )}
            <FormItem
                shouldUpdate={(prevValues, curValues) =>
                    curValues.type !== prevValues.type
                }
                noStyle
            >
                {({ getFieldValue }) => {
                    return getFieldValue('type') === ScheduleType.schedule ? (
                        <>
                            <FormItem
                                label={'Дата проведения услуг'}
                                name={'dateStart'}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Поле обязательно для заполнения',
                                    },
                                ]}
                            >
                                <DatePicker
                                    showTime={mode === 'client'}
                                    showSecond={false}
                                    placeholder={'Выберите'}
                                    format={
                                        mode === 'client'
                                            ? 'DD.MM.YYYY HH:mm'
                                            : 'DD.MM.YYYY'
                                    }
                                    minuteStep={5}
                                    hideDisabledOptions
                                    disabledDate={(date) =>
                                        date.isBefore(
                                            moment().startOf('minute')
                                        )
                                    }
                                />
                            </FormItem>
                        </>
                    ) : null;
                }}
            </FormItem>
            {mode !== 'client' && (
                <FormItem
                    shouldUpdate={(prevValues, curValues) =>
                        curValues.type !== prevValues.type
                    }
                    noStyle
                >
                    {({ getFieldValue }) => {
                        return getFieldValue('type') ===
                            ScheduleType.schedule ? (
                            <FormItem
                                label={
                                    'Автоматически определить дату окончания услуги'
                                }
                                name={'isAutoDetectDateEnd'}
                                valuePropName="checked"
                            >
                                <Switch />
                            </FormItem>
                        ) : null;
                    }}
                </FormItem>
            )}
            {mode !== 'client' && (
                <FormItem
                    shouldUpdate={(prevValues, curValues) =>
                        curValues.type !== prevValues.type
                    }
                    noStyle
                >
                    {({ getFieldValue }) => {
                        return getFieldValue('type') ===
                            ScheduleType.schedule ? (
                            <Input.Group compact>
                                <FormItem
                                    noStyle
                                    shouldUpdate={(prevValues, curValues) =>
                                        curValues.type !== prevValues.type
                                    }
                                >
                                    <FormItem
                                        label={'Время начала'}
                                        name={'timeStart'}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Поле обязательно для заполнения',
                                            },
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder={'Начало'}
                                            disabled={false}
                                            showSecond={false}
                                            disabledTime={() => ({
                                                disabledHours: () =>
                                                    disabledHours,
                                            })}
                                            format={'HH:mm'}
                                            hideDisabledOptions
                                            minuteStep={5}
                                        />
                                    </FormItem>
                                </FormItem>
                                <FormItem
                                    shouldUpdate={(prevValues, curValues) =>
                                        curValues.type !== prevValues.type ||
                                        curValues.isAutoDetectDateEnd !==
                                            prevValues.isAutoDetectDateEnd ||
                                        curValues.timeStart !==
                                            prevValues.timeStart ||
                                        curValues.services !==
                                            prevValues.services
                                    }
                                    noStyle
                                >
                                    {({ getFieldValue, setFieldValue }) => {
                                        const startDate =
                                            getFieldValue('timeStart');
                                        const servicesFromForm =
                                            getFieldValue('services');

                                        const isNeedToSelectDate: boolean =
                                            getFieldValue(
                                                'isAutoDetectDateEnd'
                                            );

                                        let minutesNeedToServices = 0;

                                        const services =
                                            salonData.employers.reduce(
                                                (res, acc) => {
                                                    return [
                                                        ...res,
                                                        ...acc.services,
                                                    ];
                                                },
                                                [] as IService[]
                                            );

                                        servicesFromForm?.forEach((service) => {
                                            const serviceInSalon =
                                                services.find(
                                                    (el) => el.id === service
                                                );

                                            if (serviceInSalon) {
                                                minutesNeedToServices +=
                                                    serviceInSalon.serviceTiming;
                                            }
                                        });

                                        let fieldValue = moment(startDate)
                                            .startOf('minute')
                                            .add(
                                                minutesNeedToServices,
                                                'minutes'
                                            );

                                        if (!servicesFromForm) {
                                            fieldValue = undefined;
                                            setFieldValue('timeEnd', undefined);
                                        } else {
                                            if (isNeedToSelectDate) {
                                                setFieldValue(
                                                    'timeEnd',
                                                    fieldValue
                                                );
                                            }
                                        }

                                        return (
                                            <FormItem
                                                label={'Время окончания'}
                                                name={'timeEnd'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Поле обязательно для заполнения',
                                                    },
                                                ]}
                                            >
                                                <TimePicker
                                                    placeholder={'Конец'}
                                                    disabled={
                                                        isNeedToSelectDate
                                                    }
                                                    showSecond={false}
                                                    disabledTime={() => ({
                                                        disabledHours: () =>
                                                            disabledHours,
                                                    })}
                                                    disabledDate={(date) =>
                                                        moment(
                                                            startDate
                                                        ).isAfter(date)
                                                    }
                                                    format={'HH:mm'}
                                                    hideDisabledOptions
                                                    defaultValue={fieldValue}
                                                    minuteStep={5}
                                                />
                                            </FormItem>
                                        );
                                    }}
                                </FormItem>
                            </Input.Group>
                        ) : null;
                    }}
                </FormItem>
            )}
        </Form>
    );
};
