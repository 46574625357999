import {Image, Layout} from 'antd';
import React from 'react';
import {PageHead} from '../part/PageParts';
import SideMenu from '../../Components/SideMenu';
import MasterDataComponent from './component/MasterDataComponent';

export default class Home extends React.Component {
    render(){return <Layout style={{height: '100vh'}}>
        <PageHead/>
        <Layout height="100%">
            <SideMenu/>
            <MasterDataComponent/>
        </Layout>
    </Layout>;}
};
