import { FC } from 'react';
import { RatesTable } from './RatesTable.tsx';
import { CommentItem } from './CommentItem.tsx';

import styles from './ReviewsStyles.module.scss';
import { IReviewItem } from '../../../../api/common/salons.ts';
import { Empty } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

type Props = {
    reviews: IReviewItem[];
    salonRate: string | number;
    handleClose: () => void;
};

export const ReviewTab: FC<Props> = ({ reviews, salonRate, handleClose }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.inner}>
                <div className={styles.close} onClick={handleClose}>
                    <CloseOutlined className={styles.ico} />
                </div>
                <div className={styles.container}>
                    <h2 className={styles.title}>
                        Рейтинг рассчитан на основании{' '}
                        <mark>{reviews.length}</mark> оценок
                    </h2>
                </div>
                <div className={styles.container}>
                    <RatesTable reviews={reviews} salonRate={salonRate} />
                </div>
                <div className={styles.lightBg}>
                    <div className={styles.container}>
                        <span className={styles.commentsTitle}>
                            Комментарии{' '}
                            <mark>
                                {
                                    reviews.filter((el) => !!el.description)
                                        .length
                                }
                            </mark>
                        </span>
                        <div className={styles.commentsList}>
                            {reviews.filter((el) => !!el.description).length >
                            0 ? (
                                <>
                                    {reviews
                                        .filter((el) => !!el.description)
                                        .map((el) => (
                                            <CommentItem
                                                review={el}
                                                key={el.id}
                                            />
                                        ))}
                                </>
                            ) : (
                                <>
                                    <Empty
                                        description={'Комментарии отсутствуют'}
                                        className={styles.empty}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
