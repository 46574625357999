import React, { useEffect, useState } from 'react';
import { Checkbox, Collapse } from 'antd';
import Text from 'antd/es/typography/Text';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

const { Panel } = Collapse;

export default function ServiceListDataComponent(props) {
    let [services, setList] = useState([]);
    const content = useSelector((state) => state);
    const dispatch = useDispatch();

    useEffect(() => {
        content.categories.map((item) => {
            item.isChecked = false;
        });
        setList(content.categories);
    }, []);

    const onChangeRootCheckbox = (ev) => {
        const selectedList = services.filter(
            (item) => item.id == ev.target.index
        );
        const otherList = services.filter(
            (item) => item.id !== ev.target.index
        );
        selectedList[0].isChecked = !selectedList[0].isChecked;
        selectedList[0].children.map((item, key) => {
            return (selectedList[0].children[key].isChecked =
                !selectedList[0].children[key].isChecked);
        });

        otherList.unshift(selectedList[0]);
        otherList.sort((a, b) => {
            return a.id - b.id;
        });
        setList(otherList);

        axios
            .post(process.env.REACT_APP_REMOTE_HOST + 'services', {
                salonId: props.salonId,
                items: selectedList,
            })
            .then((item) => {
                axios
                    .get(
                        process.env.REACT_APP_REMOTE_HOST +
                            'services/salon/' +
                            props.salonId
                    )
                    .then((res) => {
                        // console.log(res)
                        dispatch({
                            type: 'ADDITIONAL-DATA-SERVICE-LIST', // данные по салону
                            service: res.data,
                        });
                    });
            })
            .catch((er) => {
                console.log('==Er.services|services/salon', er);
            });
    };

    const onChangeChildrenCheckbox = (ev) => {
        const selectedList = services.filter(
            (item) => item.id == ev.target.rootIndex
        );
        const otherList = services.filter(
            (item) => item.id !== ev.target.rootIndex
        );
        selectedList[0].children.map((children, key) => {
            // return selectedList[0].children[key].isCheck = !selectedList[0].children[key].isCheck
            if (children.id === ev.target.childrenIndex) {
                return (children.isChecked = !children.isChecked);
            }
        });

        otherList.unshift(selectedList[0]);
        otherList.sort((a, b) => {
            return a.id - b.id;
        });
        setList(otherList);

        axios
            .post(`${process.env.REACT_APP_REMOTE_HOST}services`, {
                salonId: props.salonId,
                items: selectedList,
            })
            .then((item) => {
                axios
                    .get(
                        `${process.env.REACT_APP_REMOTE_HOST}services/salon/${props.salonId}`
                    )
                    .then((res) => {
                        // console.log(res)
                        dispatch({
                            type: 'ADDITIONAL-DATA-SERVICE-LIST', // данные по салону
                            service: res.data,
                        });
                    });
            })
            .catch((er) => {
                console.log(er);
            });
    };
    return services.map((item, key) => {
        return (
            <Collapse>
                <Panel header={<Text>{item.title}</Text>} key={key}>
                    <Checkbox
                        index={item.id}
                        onChange={onChangeRootCheckbox}
                        checked={item.isChecked}
                    >
                        <Text style={{ fontWeight: 'bold' }}>Выбрать все</Text>
                    </Checkbox>
                    <ul
                        style={{
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 20,
                        }}
                    >
                        {item.children.map((children, k) => {
                            return (
                                <Checkbox
                                    rootIndex={item.id}
                                    childrenIndex={children.id}
                                    key={k}
                                    onChange={onChangeChildrenCheckbox}
                                    checked={children.isChecked}
                                >
                                    <li>{children.title}</li>
                                </Checkbox>
                            );
                        })}
                    </ul>
                </Panel>
            </Collapse>
        );
    });
}
