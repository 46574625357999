import React, { FC, useCallback } from 'react';
import { Button, Form, Input, Layout } from 'antd';
import { PageHead, BanrHead, PageFoot } from '../part/PageParts';

import styles from './contacts.module.scss';

export const Contacts: FC = () => {
    const [form] = Form.useForm();

    const handleFormFinish = useCallback(
        (values: { name: string; email: string; message?: string }) => {
            console.log(values);
        },
        []
    );

    return (
        <>
            <Layout className={styles.wrapper}>
                <PageHead />
                <div className="layCont">
                    <BanrHead hide={process.env.REACT_APP_VISUAL_FOR_DEV} />
                    <div className={styles.inner}>
                        <span className={styles.title}>Контакты</span>
                        <div className={styles.content}>
                            <div className={styles.contacts_data}>
                                <div className={styles.contact_item}>
                                    <span className={styles.name}>
                                        ТЕЛЕФОН / WHATSAP / TELEGRAM
                                    </span>
                                    <a
                                        href="tel:89671085108"
                                        className={styles.value}
                                    >
                                        8-967-108-5-108
                                    </a>
                                </div>
                                <div className={styles.contact_item}>
                                    <span className={styles.name}>АДРЕС</span>
                                    <span className={styles.value}>
                                        Москва, ул. Кузнецкий мост, 19с1
                                    </span>
                                </div>
                                <div className={styles.contact_item}>
                                    <span className={styles.name}>ПОЧТА</span>
                                    <a
                                        href="mailto:admin@beautysputnik.ru"
                                        className={styles.value}
                                    >
                                        admin@beautysputnik.ru
                                    </a>
                                </div>
                            </div>
                            <div className={styles.form_wrapper}>
                                <span className={styles.form_title}>
                                    НАПИШИТЕ НАМ
                                </span>
                                <Form
                                    layout={'vertical'}
                                    form={form}
                                    onFinish={handleFormFinish}
                                >
                                    <Form.Item
                                        name={'name'}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Поле обязательно для заполнения',
                                            },
                                        ]}
                                    >
                                        <Input
                                            type={'text'}
                                            name={'name'}
                                            placeholder={'Ваше имя'}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={'email'}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Поле обязательно для заполнения',
                                            },
                                        ]}
                                    >
                                        <Input
                                            name={'email'}
                                            type={'email'}
                                            placeholder={'E-mail'}
                                        />
                                    </Form.Item>
                                    <Form.Item name={'message'}>
                                        <Input.TextArea
                                            autoSize={{
                                                minRows: 4,
                                                maxRows: 6,
                                            }}
                                            autoComplete={'off'}
                                            placeholder={'Сообщение'}
                                        />
                                    </Form.Item>
                                </Form>
                                <Button
                                    className={styles.btn}
                                    type={'primary'}
                                    onClick={form.submit}
                                >
                                    ОТПРАВИТЬ
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            {/*@ts-ignore*/}
            <PageFoot />
        </>
    );
};
