import { Dispatch, FC, SetStateAction } from 'react';
import { Filters, IResultFilter } from '../Filters/index.tsx';
import { useSalonsMap } from '../../../hooks/useSalonsMap.ts';
import { ISalon } from '../../../Types/SalonTypes.ts';
import { LoadingOutlined } from '@ant-design/icons';

import './result-on-map.scss';

interface Props {
    filtersList: IResultFilter;
    setFiltersList: Dispatch<SetStateAction<IResultFilter>>;
    handleResetFilters: () => void;
    handleGoToList: () => void;
    salonsList: ISalon[];
    selectedServices: number[];
    handleSelectOrderToSalon?: (id: number) => void;
}

export const ResultOnMap: FC<Props> = ({
    filtersList,
    setFiltersList,
    handleResetFilters,
    handleGoToList,
    salonsList,
    selectedServices,
    handleSelectOrderToSalon,
}) => {
    const [isInited] = useSalonsMap(
        'r-on-map__map',
        salonsList,
        true,
        selectedServices,
        handleSelectOrderToSalon
    );

    return (
        <div className="r-on-map__wrapper">
            <div className="r-on-map__inner">
                <div className="r-on-map__controls">
                    <div className="r-on-map__controls-tags">
                        <h3>Метки на карте</h3>
                        <div className="r-on-map__tags-list">
                            <div className="r-on-map__tags-list-item">
                                <span className="r-on-map__tag green">
                                    999 р.
                                </span>
                                <span className="r-on-map__tag-text">
                                    - Записаться сейчас
                                </span>
                            </div>
                            <div className="r-on-map__tags-list-item">
                                <span className="r-on-map__tag blue">
                                    999 р.
                                </span>
                                <span className="r-on-map__tag-text">
                                    - Доступно в другое время
                                </span>
                            </div>
                            <div className="r-on-map__tags-list-item">
                                <span className="r-on-map__tag red">
                                    999 р.
                                </span>
                                <span className="r-on-map__tag-text">
                                    - Акции
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="r-on-map__filters">
                        <h3>Фильтры услуг</h3>
                        <Filters
                            filtersList={filtersList}
                            handleResetFilters={handleResetFilters}
                            setFiltersList={setFiltersList}
                            hideCrumbs
                            hideTitle
                            fixButtons
                        />
                    </div>
                </div>
                <div className="r-on-map__map" id="r-on-map__map">
                    {!isInited && <LoadingOutlined />}
                    <span
                        className="r-on-map__map-control back text"
                        onClick={handleGoToList}
                    >
                        Обратно к списку
                    </span>
                    {isInited && (
                        <div className="r-on-map__map-control__list">
                            <span className="r-on-map__map-control filter text">
                                <span className="r-on-map-c-tag green" />-
                                записаться сейчас
                            </span>
                            <span className="r-on-map__map-control filter text">
                                <span className="r-on-map-c-tag blue" />-
                                доступно в другое время
                            </span>
                            <span className="r-on-map__map-control filter text">
                                <span className="r-on-map-c-tag red" />- акции
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
