export const columnSchema = [
    {
        title: 'Начало',
        key: 'startTime',
        dataIndex: 'startTime',
        type: 'time',
        show: true
    },
    {
        title: 'Окончание',
        key: 'endTime',
        dataIndex: 'endTime',
        type: 'time',
        show: true
    }
]
