import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Form } from 'antd';
import { ISalon, IService } from '../Types/SalonTypes.ts';
import {
    AddScheduleModalForm,
    IScheduleForm,
} from './AddScheduleModalForm.tsx';
import { IModalPrevState } from '../Components/Cabinet/Salon/SalonSheduleTab.tsx';
import moment from 'moment';

interface Props {
    open: boolean;
    setOpen: (state: boolean) => void;
    salonData: ISalon;
    handleSetNeedLoad: () => void;
    mode?: 'salon' | 'client';
    clientId?: string;
    startTime?: [number, number];
    endTime?: [number, number];
    modalPrevState?: IModalPrevState;
    clearPrevState?: () => void;
}

export const AddScheduleModal: FC<Props> = ({
    open,
    setOpen,
    salonData,
    handleSetNeedLoad,
    mode,
    clientId,
    startTime,
    endTime,
    modalPrevState,
    clearPrevState,
}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm<IScheduleForm>();

    const handleCancel = useCallback(() => {
        if (clearPrevState) {
            clearPrevState();
        }

        form.resetFields();
        setOpen(false);
    }, [form]);

    const handleOk = useCallback(() => {
        form.submit();
    }, []);

    useEffect(() => {
        if (modalPrevState) {
            let isTimeAnother = false;

            if (
                modalPrevState?.existTimeSlotId &&
                modalPrevState?.servicesIds &&
                modalPrevState?.dateStart &&
                modalPrevState?.dateEnd
            ) {
                let minutesNeedToServices = 0;

                const services = salonData.employers.reduce((res, acc) => {
                    return [...res, ...acc.services];
                }, [] as IService[]);

                modalPrevState.servicesIds.forEach((service) => {
                    const serviceInSalon = services.find(
                        (el) => el.id === service
                    );

                    if (serviceInSalon) {
                        minutesNeedToServices += serviceInSalon.serviceTiming;
                    }
                });

                let dateEnd = moment(modalPrevState?.dateStart).add(
                    minutesNeedToServices,
                    'minutes'
                );

                if (
                    dateEnd.toISOString() !==
                    moment(modalPrevState?.dateEnd).toISOString()
                ) {
                    isTimeAnother = true;
                }
            }

            let firstEmployerByServices = salonData.employers.find(
                (el) =>
                    !modalPrevState?.servicesIds?.reduce((acc, res) => {
                        if (!el.services.find((el) => el.service.id === res)) {
                            return false;
                        }
                    }, true)
            );

            const selectedEmployer =
                mode === 'client' &&
                modalPrevState?.servicesIds?.length > 0 &&
                !modalPrevState?.employer &&
                firstEmployerByServices
                    ? firstEmployerByServices.id
                    : modalPrevState?.employer;

            if (isTimeAnother) {
                form.setFields([
                    {
                        name: 'type',
                        value: modalPrevState?.type,
                    },
                    {
                        name: 'dateStart',
                        value: modalPrevState?.dateStart,
                    },
                    {
                        name: 'employer',
                        value: selectedEmployer,
                    },
                    {
                        name: 'employerOff',
                        value: modalPrevState?.employerOff,
                    },
                    {
                        name: 'services',
                        value: modalPrevState?.servicesIds,
                    },
                    {
                        name: 'statusDescription',
                        value: modalPrevState?.statusDescription,
                    },
                    {
                        name: 'dateOffRange',
                        value: [
                            moment(modalPrevState?.dateStart),
                            moment(modalPrevState?.dateEnd),
                        ],
                    },
                    {
                        name: 'client',
                        value: salonData?.clients?.find(
                            (el) => el.id === modalPrevState?.clientId
                        )?.phone,
                    },
                    {
                        name: 'isAutoDetectDateEnd',
                        value: false,
                    },
                    {
                        name: 'timeStart',
                        value: moment(modalPrevState?.dateStart),
                    },
                    {
                        name: 'timeEnd',
                        value: moment(modalPrevState?.dateEnd),
                    },
                ]);
            } else {
                form.setFields([
                    {
                        name: 'type',
                        value: modalPrevState?.type,
                    },
                    {
                        name: 'dateStart',
                        value: modalPrevState?.dateStart,
                    },
                    {
                        name: 'employer',
                        value: selectedEmployer,
                    },
                    {
                        name: 'employerOff',
                        value: modalPrevState?.employerOff,
                    },
                    {
                        name: 'services',
                        value: modalPrevState?.servicesIds,
                    },
                    {
                        name: 'statusDescription',
                        value: modalPrevState?.statusDescription,
                    },
                    {
                        name: 'dateOffRange',
                        value: [
                            moment(modalPrevState?.dateStart),
                            moment(modalPrevState?.dateEnd),
                        ],
                    },
                    {
                        name: 'client',
                        value: salonData?.clients?.find(
                            (el) => el.id === modalPrevState?.clientId
                        )?.phone,
                    },
                    {
                        name: 'isAutoDetectDateEnd',
                        value: true,
                    },
                    {
                        name: 'timeStart',
                        value: moment(modalPrevState?.dateStart),
                    },
                ]);
            }
        }
    }, [modalPrevState, salonData]);

    const modalTitle: string = useMemo(() => {
        if (modalPrevState?.existTimeSlotId) {
            return 'Изменить запись';
        } else {
            return mode === 'client'
                ? 'Записаться на услугу'
                : 'Создать запись';
        }
    }, [modalPrevState, mode]);

    const modalOkText: string = useMemo(() => {
        if (modalPrevState?.existTimeSlotId) {
            return 'Изменить';
        } else {
            return mode === 'client' ? 'Записаться' : 'Создать';
        }
    }, [modalPrevState, mode]);

    return (
        <Modal
            title={modalTitle}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={modalOkText}
            cancelText={
                modalPrevState?.existTimeSlotId
                    ? 'Отменить изменения'
                    : 'Отмена записи'
            }
            closable={false}
            maskClosable={false}
            destroyOnClose
            zIndex={999}
            okButtonProps={{
                loading,
            }}
        >
            {open && (
                <AddScheduleModalForm
                    handleCloseModal={handleCancel}
                    handleSetNeedLoad={handleSetNeedLoad}
                    salonData={salonData}
                    form={form}
                    setLoading={setLoading}
                    mode={mode}
                    clientId={clientId}
                    startTime={startTime}
                    endTime={endTime}
                    modalPrevState={modalPrevState}
                    clearPrevState={clearPrevState}
                />
            )}
        </Modal>
    );
};
