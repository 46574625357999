import React, { FC } from 'react';
import { Calendar } from 'antd';
import { Moment as IMoment } from 'moment';

import styles from './SalonShedule.module.scss';

interface Props {
    selectedDate: IMoment;
    onSelect: (value: IMoment) => void;
    calendarClassName?: string;
}

export const CalendarItem: FC<Props> = ({
    selectedDate,
    onSelect,
    calendarClassName,
}) => {
    return (
        <div className={styles.calendar}>
            <div className={styles.calendarText}>
                {selectedDate.format('MMMM YYYY')}
            </div>
            <Calendar
                fullscreen={false}
                headerRender={() => undefined}
                value={selectedDate}
                onSelect={onSelect}
                className={calendarClassName}
            />
        </div>
    );
};
