let initState = {
    auth: JSON.parse(localStorage.auth || '{}'),
    meProfile: '',
    tableSource: '', // Категории услуг -- Услуги
    tableColumn: '',
    formData: '',
    isEditForm: '',
    isFormShow: false,
    isSalonDetailView: false,
};
function reducer(state = initState, action) {
    switch (action.type) {
        case 'ADVANTAGES-SITE': // пафос (преимущества) для сайта на Главной
            return { ...state, countSite: action.countSite };
        case 'AUTH-LOGIN':
            localStorage.setItem('auth', JSON.stringify(action.auth));
            return { ...state, auth: action.auth };
        case 'AUTH-LOGOUT':
            localStorage.removeItem('auth');
            return {
                ...state,
                auth: { ...state.auth, accessToken: '', refreshToken: '' },
            };
        case 'ME-PROFILE':
            return { ...state, meProfile: action.meProfile };
        case 'TOGGLE-FORM':
            return {
                ...state,
                isFormShow: !state.isFormShow,
            };
        case 'TOGGLE-VIEW-SALON-DETAIL':
            return {
                ...state,
                isSalonDetailView: !state.isSalonDetailView,
            };
        case 'SCHEDULE-VIEW-DETAIL':
            return {
                ...state,
                scheduleDetailView: !state.scheduleDetailView,
            };
        case 'SCHEDULE-DETAIL':
            return {
                ...state,
                scheduleDetail: action.scheduleDetail,
            };
        case 'FORM-IS-EDIT':
            return {
                ...state,
                isEditForm: {
                    flag: action.isEditForm.flag,
                    id: action.isEditForm.id,
                },
            };
        case 'FORM-DATA':
            return {
                ...state,
                formData: action.formDData,
            };
        case 'SOURCE-TABLE':
            if (!action.tableSource) action.tableSource = {};
            action.tableSource.categories =
                action.tableSource?.categories?.map((el) => {
                    // вырезание пустых массивов children на 2-м уровне
                    return {
                        ...el,
                        ...{
                            children: (el?.children || []).map((e2) => {
                                if (!e2?.children?.length) delete e2.children;
                                return e2;
                            }),
                        },
                    };
                }) || [];
            return {
                ...state,
                tableSource: action.tableSource,
            };
        case 'GET-SALON-DESCR':
            return { ...state, salonDescr: action.salonDescr };
        case 'PATCH-SALON-DESCR':
            return { ...state, salonDescr: action.salonDescr };
        case 'SOURCE-CLIENT-BY-SALON-TABLE':
            return {
                ...state,
                tableClientSource: action.tableClientSource,
            };
        case 'SOURCE-MASTER-BY-SALON-TABLE':
            return {
                ...state,
                tableMasterSource: action.tableMasterSource,
            };
        case 'COLUMN-TABLE':
            return {
                ...state,
                tableColumn: action.tableColumn,
            };
        case 'ADDITIONAL-DATA':
            return {
                ...state,
                additionalData: { salonId: action.salonId.items },
            };
        case 'ADDITIONAL-DATA-SALON':
            return {
                ...state,
                salons: action.salon.items,
            };
        case 'ADDITIONAL-DATA-CATEGORY':
            return {
                ...state,
                additionalData: { category: action.category.items },
            };
        // return {additionalData
        //     ...state,
        //     : { parentId: action.salonId.items }
        // }

        // return {additionalData
        //     ...state,
        //     : { parentId: action.salonId.items }
        // }
        case 'ADDITIONAL-DATA-JOB-TIME':
            // console.log(action)
            return {
                ...state,
                jobTime: action.jobTime,
            };
        case 'ADDITIONAL-DATA-MASTERS':
            return {
                ...state,
                masters: action.masters,
            };
        case 'SALONS-ID-MASTER': // список мастеров салона с услугами и категорией каждой услуги
            return {
                ...state,
                masters: action.master,
            };
        case 'ADDITIONAL-DATA-CLIENTS':
            return {
                ...state,
                clients: action.clients,
            };
        case 'ADDITIONAL-DATA-SCHEDULE':
            return {
                ...state,
                schedule: action.schedule,
            };
        case 'ADDITIONAL-DATA-SERVICES':
            return {
                ...state,
                additionalData: { services: action.items },
            };
        case 'ADDITIONAL-DATA-SERVICE-LIST': // данные по салону
            return {
                ...state,
                service: action.service,
            };

        case 'ADDITIONAL-DATA-SERVICE-MASTER-LIST':
            return {
                ...state,
                masterService: action.masterService,
            };
        case 'ADDITIONAL-DATA-PRICE-FORM':
            return {
                ...state,
                price: action.price,
            };
        case 'ADDITIONAL-DATA-CATEGORIES':
            return {
                ...state,
                categories: action.categories,
            };
        case 'ADDITIONAL-DATA-CATEGORY-FOR-SALON':
            return {
                ...state,
                salonsData: action.salonsData.items,
            };
        case 'ADDITIONAL-CATEGORIES-FLAT-DATA':
            return {
                ...state,
                list: action.list,
            };
        default:
            return state;
    }
}

export default reducer;
