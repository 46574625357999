import { FC, useCallback, useEffect, useState } from 'react';
import { IAdvantage } from '../../../../Types/SalonTypes.ts';

import styles from './Adventage.module.scss';
import { Empty, message } from 'antd';
import { AdventItem } from './AdventItem.tsx';
import { CommonSalonsAPI } from '../../../../api/common/salons.ts';
import { AddAdvantageModal } from '../../../../Modals/AddAdvantageModal.tsx';
import { useDispatch } from 'react-redux';

interface Props {
    advantages: IAdvantage[] | undefined;
    salonId: number;
}

export const AdvantagesTab: FC<Props> = ({ advantages, salonId }) => {
    const [addModalOpened, setAddModalOpened] = useState(false);
    const [editModalOpened, setEditModalOpened] = useState(false);
    const dispatch = useDispatch();

    const [isProcessing, setIsProcessing] = useState(false);

    const [deleteLoading, setDeleteLoading] = useState(false);

    const [advantagesIds, setAdvantagesIds] = useState<(number | string)[]>([]);

    const handleOpenAddModal = useCallback(() => {
        setAdvantagesIds(advantages?.map((el) => el.id) ?? []);
        setAddModalOpened(true);
    }, [advantages]);

    const handleStartEdit = useCallback(() => {
        setAdvantagesIds(advantages?.map((el) => el.id) ?? []);
        setEditModalOpened(true);
    }, [advantages]);

    const handleDeleteAdvantage = useCallback(
        async (serviceId: string | number) => {
            const loading = message.loading('Удаление...');

            try {
                setDeleteLoading(true);

                const res = await CommonSalonsAPI.editSalonAdvantages(
                    salonId,
                    advantages
                        ?.map((el) => el.id)
                        .filter((el) => el !== serviceId)
                );

                dispatch({
                    type: 'PATCH-SALON-DESCR',
                    salonDescr: res.data,
                });
                message.success('Преимущество удалено');
                setDeleteLoading(false);
                loading();
            } catch (e) {
                message.error('Ошибка при удалении преимущества');
                setDeleteLoading(false);
                loading();
            }
        },
        [advantages, salonId]
    );

    const handleCreate: (advId: (string | number)[]) => Promise<void> =
        useCallback(
            async (adv) => {
                setIsProcessing(true);

                try {
                    const res = await CommonSalonsAPI.editSalonAdvantages(
                        salonId,
                        adv
                    );

                    dispatch({
                        type: 'PATCH-SALON-DESCR',
                        salonDescr: res.data,
                    });
                } catch (e) {
                    message.error('Ошибка при добавлении преимуществ');
                }

                setIsProcessing(false);
            },
            [salonId]
        );

    const handleEdit: (advId: (string | number)[]) => Promise<void> =
        useCallback(
            async (adv) => {
                setIsProcessing(true);

                try {
                    const res = await CommonSalonsAPI.editSalonAdvantages(
                        salonId,
                        adv
                    );

                    dispatch({
                        type: 'PATCH-SALON-DESCR',
                        salonDescr: res.data,
                    });
                } catch (e) {
                    message.error('Ошибка при добавлении преимуществ');
                }

                setIsProcessing(false);
            },
            [salonId]
        );

    return (
        <div>
            <div
                className={`${styles.serviceList}${
                    advantages && advantages.length > 0 ? '' : ` ${styles.empt}`
                }`}
            >
                {advantages ? (
                    <>
                        {advantages.length > 0 ? (
                            <>
                                {advantages.map((el) => (
                                    <AdventItem
                                        advantage={el}
                                        key={el.id}
                                        handleStartEdit={handleStartEdit}
                                        handleDeleteAdvantage={
                                            handleDeleteAdvantage
                                        }
                                    />
                                ))}
                            </>
                        ) : (
                            <Empty description={'Преимущества отсутствуют'} />
                        )}
                    </>
                ) : (
                    <Empty description={'Преимущества не найдены'} />
                )}
                <div className={styles.createBtn} onClick={handleOpenAddModal}>
                    Добавить преимущество
                </div>
            </div>

            <AddAdvantageModal
                mode="create"
                salonId={salonId}
                advantageId={advantagesIds}
                handleCreate={handleCreate}
                isOpen={addModalOpened}
                setOpen={setAddModalOpened}
                isProcessing={isProcessing}
            />
            <AddAdvantageModal
                mode="edit"
                salonId={salonId}
                advantageId={advantagesIds}
                handleCreate={handleEdit}
                isOpen={editModalOpened}
                setOpen={setEditModalOpened}
                isProcessing={isProcessing}
            />
        </div>
    );
};
