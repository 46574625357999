import {Image, Layout} from 'antd';
import React from 'react';
import {PageHead} from '../part/PageParts';
import SideMenu from '../../Components/SideMenu';
import JobTimesDataComponent from './component/JobTimesDataComponent';

function JobTime(){
    return <Layout style={{ height: '100vh'}}>
        <PageHead/>
        <Layout height="100%">
            <SideMenu/>
            <JobTimesDataComponent/>
        </Layout>
    </Layout>;
}
export default JobTime;
