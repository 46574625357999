import {Image, Layout} from 'antd';
import React from 'react';
import {PageHead} from '../part/PageParts';
import SideMenu from '../../Components/SideMenu';
import CategoryDataComponent from './component/CategoryDataComponent';

function Home({match}){
    const {
        params: { url },
    } = match;
    return <Layout style={{height: '100vh'}}>
        <PageHead/>
        <Layout height="100%">
            <SideMenu/>
            <CategoryDataComponent/>
        </Layout>
    </Layout>;
}
export default Home;
